<app-loader-two [isLoading]="isLoading"></app-loader-two>
<app-header-one></app-header-one>
@if(bookData){
<app-sticky-tab [instituteData]="bookDetails"></app-sticky-tab>

<!-- Relatd books section  -->
<section class="wrapper">
    <div class="row">
        <div class="title-3 text-start inner-title">
            <h2>Related Books</h2>
        </div>
        @if(isRelatedBooksLoading){
        <div class="item">
            <ngx-skeleton-loader count="4" [theme]="{
                        height: '22.5rem',
                        width: '22%',
                        'border-radius': '10px',
                        'margin-right': '0.5rem',
                        
                                }" />
        </div>
        }
        @else {
        <div id="card-area">
            <div class="bookWrapper">
                <div class="box-area">
                    <!-- Loop through books and display each one -->
                    @for (books of relatedBookData; track books) {
                    <div class="box">
                        <img alt="Book-Image" [src]="books.imageBase64" />
                        <div class="overlay">
                            <h3 class="line-clamp1">{{ books.title }}</h3>
                            <p class="desLineClamp">
                                {{ books.bookDescription }}
                            </p>
                            <a (click)="navigateToBookDetails(books.id)" aria-label="clickable-text"
                                class="see-details">See Details</a>
                        </div>
                    </div>
                    }
                </div>
            </div>
        </div>
        }

    </div>
</section>
<app-adly-footer></app-adly-footer>
}