import { ChangeDetectionStrategy, Component, HostListener, inject } from '@angular/core';
import { ApiCallService } from 'src/app/shared/services/api-call.service';
import { CommonUtilsServiceService } from 'src/app/shared/services/common-utils-service.service';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';
import { PropertyService } from 'src/app/shared/services/property.service';

@Component({
  selector: 'app-latest-scholar-ships',
  templateUrl: './latest-scholar-ships.component.html',
  styleUrl: './latest-scholar-ships.component.scss',
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class LatestScholarShipsComponent {
  public tag: string = 'Scholarship';
  public title = 'slider_filter_search';
  public isDataLoading = true;
  public isErrorOccurred = false;
  public isAdLoading = true;
  public noRecordFound = false;
  public isDescriptionShown: boolean = true;
  public isScreenSmall: boolean = false;
  public scholarshipsData: any[] = [];
  // public adImages: any[] = [
  //   { redirectLink: 'https://youtube.com', imageUrl: '../../../../../assets/images/advertisements/institutes/1.jpeg' },
  //   { redirectLink: 'https://youtube.com', imageUrl: '../../../../../assets/images/advertisements/institutes/2.jpeg' },
  //   { redirectLink: 'https://youtube.com', imageUrl: '../../../../../assets/images/advertisements/institutes/3.jpeg' },
  //   { redirectLink: 'https://youtube.com', imageUrl: '../../../../../assets/images/advertisements/institutes/4.jpeg' },
  // ];

  addSectionCss: any = { height: '100%', width: '100%' };

  private _utils = inject(CommonUtilsServiceService);
  private _apicall = inject(ApiCallService);
  private readonly errorHandlerService = inject(ErrorHandlingService);

  constructor(public propertyService: PropertyService) { }

  ngOnInit(): void {
    this.loadScholarships();
    this.loadAds();
    this.checkScreenSize();
    setTimeout(() => { this.isAdLoading = false; }, 5000);
  }

  // Fetch latest scholarships and convert images to Blob URLs if needed
  loadScholarships(): void {
    this.isDataLoading = true;
    this.scholarshipsData = [];

    this.propertyService.getLatestScholarships().subscribe(
      (response) => {
        this.scholarshipsData = response;
        this.noRecordFound = !this.scholarshipsData || this.scholarshipsData.length === 0;
        this.convertScholarshipImagesToBlob();
        this.isDataLoading = false;
      },
      (error) => {
        this.isDataLoading = false;
        this.isErrorOccurred = true;
        console.error("Error fetching latest scholarships:", error);
      }
    );
  }

  // Convert each scholarship's image to a Blob URL if it's in base64 format
  private convertScholarshipImagesToBlob(): void {
    this.scholarshipsData.forEach((scholarship) => {
      if (scholarship?.img?.url?.includes('base64,')) {
        const base64Data = scholarship.img.url.split('base64,')[1];
        scholarship.blobUrl = this._utils.convertBase64ToBlobUrl(base64Data, 'image/jpeg');
      } else {
        scholarship.blobUrl = 'path/to/default/image.png';
        console.warn("Image data is not in the expected base64 format:", scholarship.img?.url || "No img property");
      }
    });
  }

  // Fetch advertisement data and convert images to Blob URLs if needed
  adImages: any[] = [];
  loadAds(): void {
    this.propertyService.getAds( "Ad/GetAdvertisements?moduleName=Scholarship").subscribe(
      (res) => {
        if (res.responseCode === 200) {
          this.adImages = res.data;
          this.convertAdImagesToBlob();
          // Convert each ad's image to a Blob URL

        } else {
          this.adImages = [];
          this.errorHandlerService.handleResponseError(res);
        }
        this.isAdLoading = false;
      },
      (error) => {
        this.errorHandlerService.handleHttpError(error);
        this.adImages = [];
        this.isAdLoading = false;
      }
    );
  }

  // Convert each ad's image to a Blob URL if it's in base64 format
  private convertAdImagesToBlob(): void {
    this.adImages.forEach((ad) => {
      if (ad.imageUrl?.includes('base64,')) {
        const base64Data = ad.imageUrl.split('base64,')[1];
        ad.imageUrl = this._utils.convertBase64ToBlobUrl(base64Data, 'image/jpeg');
        // console.log("Ad Scholarship Blob URL:", ad.imageUrl);
      }
    });
  }

  // Pause the scroll effect on ads section when mouse enters
  pauseScroll(): void {
    const adsSection = document.querySelector('.ad-images-wrapper') as HTMLElement;
    if (adsSection) adsSection.style.animationPlayState = 'paused';
  }

  // Resume the scroll effect on ads section when mouse leaves
  startScroll(): void {
    const adsSection = document.querySelector('.ad-images-wrapper') as HTMLElement;
    if (adsSection) adsSection.style.animationPlayState = 'running';
  }

  // Adjust section CSS based on screen size
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.checkScreenSize();
  }

  checkScreenSize(): void {
    const width = window.innerWidth;
    this.isScreenSmall = width < 1200;
    this.addSectionCss.height = this.isScreenSmall ? '280px' : '100%';
  }
}
