export interface sideMenu {
  id: number;
  title: string;
  value: string;
  path?: string;
  subMenu?: any[];
}

export interface cards {
  id: number;
  cardType: string;
  cardName: string;
  cardNumber: string;
  expDate: string;
  holderName: string;
  cardImage: string;
}
export const userPanelSideMenu: sideMenu[] = [
  {
    id: 1,
    title: 'dashboard',
    value: 'dashboard',
    path: '/user/user-dashboard',
  },

  {
    id: 2,
    title: 'my profile',
    value: 'create-property',
    path: '/user/profile',
  },

  {
    id: 2,
    title: 'My Services',
    value: 'services',
    path: '/user/my-services',
  },

  {
    id: 3,
    title: 'Institutes',
    value: 'add-institute',
    path: '/user/add-institute',
  },
  {
    id: 4,
    title: 'Admissions',
    value: 'add-admission',
    path: '/user/add-admission',
  },
  {
    id: 5,
    title: 'Jobs',
    value: 'add-jobs',
    path: '/user/add-job',
  },
  {
    id: 6,
    title: 'Scholrships',
    value: 'add-scholarships',
    path: '/user/add-scholarship',
  },
  {
    id: 7,
    title: 'Library',
    value: 'add-books',
    path: '/user/add-books',
  },
  {
    id: 8,
    title: 'CV Builder',
    value: 'add-cv',
    path: '/user/add-cv',
  },
  {
    id: 9,
    title: 'Hostels',
    value: 'add-hostel',
    path: '/user/add-hostel',
  },
  {
    id: 10,
    title: 'Tutorials',
    value: 'add-tutorial',
    // path: '/user/add-hostel',
    subMenu: [
      {
        value: 'add-tutorial',
        title: 'Video',
        path : ''
      },
      {
        value: 'update-tutorial',
        title: 'Documentation',
        path : '/user/add-tutorialDocumentation'
      }
    ]
  },
  {
    id: 11,
    title: 'Upgrade Level',
    value: 'teacher-test',
    //path: '/user/teacher-test',
    subMenu: [
      {
        value: 'teacher-test',
        title: 'All Tests',
        path: '/user/teacher-test',
      },
      {
        value: 'test-summary',
        title: 'Attempted Tests',
        path: '/user/test-summary',
      }
    ]
  },
  {
    id: 12,
    title: 'privacy',
    value: 'privacy',
    path: '/user/privacy',
  },
  {
    id: 13,
    title: 'Setting',
    value: 'profile',
    path: '/user/my-profile',
  },
  {
    id: 14,
    title: 'log out',
    value: 'logout',
    path: '',
  },
];


export const salesOverviewCommonData = [
  {
    title: 'Earned today',
    earned: '31415',
    pr: '+30%',
    labelColor: 'success',
  },
  {
    title: 'Earned weekly',
    earned: '78812',
    pr: '+20%',
    labelColor: 'success',
  },
  {
    title: 'Earned monthly',
    earned: '67810',
    pr: '-10%',
    labelColor: 'danger',
  },
];

export const propertyOverviewData = [
  {
    img: 'assets/images/property/2.jpg',
    title: 'Orchard House',
    type: 'Sold',
    date: '15/2/22',
    status: 'Paid',
  },
  {
    img: 'assets/images/property/3.jpg',
    title: 'Neverland',
    type: 'Sold',
    date: '8/9/22',
    status: 'Paid',
  },
  {
    img: 'assets/images/property/4.jpg',
    title: 'Sea Breezes',
    type: 'Sold',
    date: '26/10/22',
    status: 'Paid',
  },
];

export const cardsData: cards[] = [
  {
    id: 1,
    cardType: 'master',
    cardName: 'Credit',
    cardNumber: 'XXXX-XXXX-XXXX-3401',
    expDate: '12/24',
    holderName: 'Zack Lee',
    cardImage: 'assets/images/icon/master.png',
  },
  {
    id: 2,
    cardType: 'visa',
    cardName: 'Credit',
    cardNumber: 'XXXX-XXXX-XXXX-3401',
    expDate: '12/24',
    holderName: 'Zack Lee',
    cardImage: 'assets/images/icon/visa.png',
  },
  {
    id: 3,
    cardType: 'american-express',
    cardName: 'Credit',
    cardNumber: 'XXXX-XXXX-XXXX-3401',
    expDate: '12/24',
    holderName: 'Zack Lee',
    cardImage: 'assets/images/icon/american.jpg',
  },
];
