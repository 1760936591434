import { HttpErrorResponse } from '@angular/common/http';
import { Component, inject } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonUtilsServiceService } from 'src/app/shared/services/common-utils-service.service';
import { DataShareService } from 'src/app/shared/services/data-share.service';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';
import { MetaService } from 'src/app/shared/services/meta.service';
import { PropertyService } from 'src/app/shared/services/property.service';

@Component({
  selector: 'app-hostel-list',
  templateUrl: './hostel-list.component.html',
  styleUrl: './hostel-list.component.scss'
})
export class HostelListComponent {
  public noRecordFound = false;
  public isDataLoading = true;
  isErrorOccurred: boolean = false;
  public isSpinnerShown = false;
  private _dataShare = inject(DataShareService);
  private _utils = inject(CommonUtilsServiceService);
  private _spinner = inject(NgxSpinnerService)
  private errorHandlingService = inject(ErrorHandlingService);
  DropdownonReset : string = '';
  public title = 'slider_filter_search';

  bannerImage: string = 'assets/images/banner/all-institute-banner.jpg';
  country!: string;
  state!: string;
  city!: string;

  data: any;
  receivedData: any;
  hostelData: any[] = [];
  // totalRecords: number = 0;

  private readonly metaService: MetaService = inject(MetaService);

  constructor(public propertyService: PropertyService) {

  }

  ngOnInit(): void {
    this.fetchHostelData(this.currentPage);
    this.metaService.updateTitle('All Hostels | AdmissionLylo');
    this.metaService.updateMetaTags([
      { name: 'description', content: 'Your page description here' },
      { name: 'keywords', content: 'angular, seo, meta tags' },
      { name: 'author', content: 'Your Name' },
      { name: 'robots', content: 'index, follow' }
    ]);
    this.metaService.setCanonicalURL();
    this.metaService.initializeMetaData();

    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  onReset() {
    this.currentPage = 1
    this._spinner.show();
    this.DropdownonReset = '';
    this.isSpinnerShown = true;
    this._dataShare.refreshDropdowns(true);
    this.fetchHostelData(this.currentPage);
  }

  handleChildEvent(data: any) {
    this.receivedData = data;
    this.CheckAndCallAPI();
  }

  handleSearchData(event: any) {
    if (event === '') {
      this.isSpinnerShown = true;
      this.receivedData.search = '';

      this.fetchHostelData(1)
    }
  }

  ///////////////////   Pagination  //////////////////////////////

  pages: (number | string)[] = [];
  currentPage: number = 1;
  totalRecords: number = 0;
  itemsPerPage: number = 8;
  maxVisiblePages: number = 5;
  displayedItems: any[] = [];

  fetchHostelData(pageNumber: number): void {
    this.hostelData = [];
    this.isDataLoading = true;
    this.propertyService.getHostels(this.receivedData || {}, pageNumber, this.itemsPerPage).subscribe(
      (response) => {
        this.isDataLoading = false;
        this.isErrorOccurred = false;
        this.hostelData = response.data;
        this.totalRecords = this.hostelData[0]?.totalRecords;
        this.calculatePages();

        if (this.hostelData == null || this.hostelData?.length <= 0) {
          this.noRecordFound = true;
          this.hostelData = [];
          this.totalRecords = 0;
        } else {
          this.noRecordFound = false;

          // Convert each institute's image array to Blob URLs
          this.loadBlobUrls();
        }
        this._spinner.hide();
        this.isSpinnerShown = false;
      },
      (error) => {
        this.isDataLoading = false;
        this.isErrorOccurred = true;
        this._spinner.hide();
        this.isSpinnerShown = false;
        this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
      }
    );
  }


  onPageChanged(event: any): void {
    this.currentPage = event;
    this.fetchHostelData(this.currentPage);
    this.isDataLoading = true;
    this.scrollToContentStart();
  }

  calculatePages(): void {
    const totalPages = Math.ceil(this.totalRecords / this.itemsPerPage);
    this.pages = [];
    if (totalPages <= this.maxVisiblePages) {
      this.pages = Array.from({ length: totalPages }, (_, i) => i + 1);
    } else {
      const startPage = Math.max(
        this.currentPage - Math.floor(this.maxVisiblePages / 2),
        1
      );
      const endPage = Math.min(startPage + this.maxVisiblePages - 1, totalPages);

      if (startPage > 1) {
        this.pages.push(1);
        if (startPage > 2) {
          this.pages.push('...');
        }
      }

      for (let i = startPage; i <= endPage; i++) {
        this.pages.push(i);
      }

      if (endPage < totalPages) {
        if (endPage < totalPages - 1) {
          this.pages.push('...');
        }
        this.pages.push(totalPages);
      }
    }
  }

  navigateToPage(page: any): void {
    if (page >= 1 && page <= this.pages.length) {
      this.currentPage = page;
      this.fetchHostelData(this.currentPage);
      this.scrollToContentStart();
    }
  }

  navigatePage(direction: 'prev' | 'next'): void {
    if (direction === 'prev' && this.currentPage > 1) {
      this.currentPage--;
    } else if (
      direction === 'next' &&
      this.currentPage < Math.ceil(this.totalRecords / this.itemsPerPage)
    ) {
      this.currentPage++;
    }
    this.fetchHostelData(this.currentPage);
    this.scrollToContentStart();
  }

  getDisplayRange(): string {
    const start = (this.currentPage - 1) * this.itemsPerPage + 1;
    const end = Math.min(start + this.itemsPerPage - 1, this.totalRecords);
    return `${start} – ${end}`;
  }

  scrollToContentStart(): void {
    const contentStart = document.getElementById('content-start');
    if (contentStart) {
      const topOffset = contentStart.getBoundingClientRect().top + window.pageYOffset - 95;
      window.scrollTo({ top: topOffset, behavior: 'smooth' });
    }
  }

  resetPagination(): void {
    this.currentPage = 1;
    this.calculatePages();
    this.fetchHostelData(this.currentPage);
    this.scrollToContentStart();
  }

  onResetPagination(): void {
    this.resetPagination();
  }

  // TS Code for Pagination End

  CheckAndCallAPI() {
    if (
      this.receivedData?.country !== '' ||
      this.receivedData?.state !== '' ||
      this.receivedData?.city !== '' ||
      this.receivedData?.search !== '' || 
      this.receivedData?.hostelType !== ''
    ) {
      this.hostelData = [];
      this.currentPage = 1;
      this.isDataLoading = true;
      this.isSpinnerShown = true;
      this.propertyService.getHostels(this.receivedData, this.currentPage, this.itemsPerPage).subscribe(
        (response) => {

          this.hostelData = response.data;
          this.totalRecords = this.hostelData[0]?.totalRecords;
          this.calculatePages();
          if (this.hostelData == null || this.hostelData?.length <= 0) {
            this.noRecordFound = true;
            this.hostelData = [];
            this.totalRecords = 0;
          } else {
            this.noRecordFound = false;
            this.loadBlobUrls();
          }
          this.isSpinnerShown = false;
          this.isDataLoading = false;
        },
        (error) => {
          this.isDataLoading = false;
          this.isSpinnerShown = false;
        }
      );

    }
  }


  loadBlobUrls(){
    this.hostelData.forEach((institute) => {
      institute.blobUrls = [];  // Initialize an array to store Blob URLs

      if (Array.isArray(institute.img)) {
        institute.img.forEach((image: { url: string; }) => {
          if (image.url && image.url.includes('base64,')) {
            const base64Data = image.url.split('base64,')[1];
            const blobUrl = this._utils.convertBase64ToBlobUrl(base64Data, 'image/jpeg');
            institute.blobUrls.push(blobUrl);
          } else {
            institute.blobUrls.push('../../../../assets/images/others/no-image-found.jpg');
          }
        });
      }
    });
  }
}
