import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, TemplateRef, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { ApiCallService } from 'src/app/shared/services/api-call.service';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';

@Component({
  selector: 'app-my-listing',
  templateUrl: './my-listing.component.html',
  styleUrls: ['./my-listing.component.scss'],
})
export class MyListingComponent {
  isLoading: boolean = true;
  servicesData: any = [];
  addServiceForm: FormGroup;
  public isSpinnerShown = false;
  public FormName: string = "Add";

  private destroy$ = new Subject<void>();

  public _spinner = inject(NgxSpinnerService);
  public __apiCall = inject(ApiCallService);
  public _toastr = inject(ToastrService);
  private readonly errorHandlingService = inject(ErrorHandlingService);

  constructor(public modal: NgbModal, private fb: FormBuilder,) {
    this.addServiceForm = this.fb.group({
      fee: ['', [Validators.required, Validators.min(0)]]
    });
  }


  ngOnInit(): void {
    this.getSubjectList();
    this.getServices();
  }


  addCard(content: TemplateRef<any>) {
    this.addServiceForm.reset();
    this.subjectError = false;
    this.durationError = false;
    this.subject = '';
    this.timeSlot = '';
    this.teacherType = '';
    this.imageBase64='';
    this.imageError = false;
    this.files = [];
    this.modal.open(content, { centered: true, size: 'lg' });
    this.FormName = "Add";
  }
  editId: number = 0;
  editCard(content: TemplateRef<any>, data: any) {
    this.addServiceForm.reset();
    this.subjectError = false;
    this.durationError = false;
    this.imageError = false;
    this.subject = '';
    this.timeSlot = '';
    this.teacherType = '';
    this.imageBase64='';
    this.files = [];
    this.addServiceForm.reset();
    this.modal.open(content, { centered: true, size: 'lg' });
    this.FormName = "Edit";
    this.editId = data?.id;
    this.subject = data?.subject;
    this.timeSlot = data?.duration;
    this.teacherType = data?.serviceType;
    this.addServiceForm.get("fee")?.setValue(data?.fee);
    this.imageBase64 = data?.imageBase64;

  }

  delId: number;
  deleteCard(content: TemplateRef<any>, id: number) {
    this.modal.open(content, { centered: true, size: 'sm' });
    this.delId = id;
  }


  public async deleteService(): Promise<void> {
    this.isSpinnerShown = true;
    this._spinner.show();
    try {
      const res = await this.__apiCall.PostCallWithToken(null, `Teacher/DeleteTeacherServices?id=${this.delId}`).pipe(takeUntil(this.destroy$))
        .toPromise();
      // .subscribe((res) => {
      if (res.responseCode == 200) {
        this._toastr.success(res.responseMessage);
         this.getServices();
        this.modal.dismissAll();
        this.isSpinnerShown = false;
        this._spinner.hide();
      } else {
        this.errorHandlingService.handleResponseError(res);
        this.modal.dismissAll();
      }
    } catch (error) {
      this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
    } finally {
      this.isSpinnerShown = false;
      this._spinner.hide();
    }
    // })
  }

  public getServices(): void {
    this.isLoading = true;

    this.__apiCall.GetCallWithToken("Teacher/GetTeacherServices")
      .subscribe(
        (response) => {
          if (response.responseCode === 200) {
            this.servicesData = response.data;
          } else {
            this.servicesData = [];
            this.errorHandlingService.handleResponseError(response);
          }
          this.isLoading = false;
        },
        (error) => {
          this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
          this.isLoading = false;
        }
      );
  }

  public saveServiceDetails(): void {
    this.addServiceForm.markAllAsTouched();
    this.ShowError();

    const isAlreadyExists = this.checksubjectExsists();
    if (isAlreadyExists) {
      return;
    }
    if (
      this.addServiceForm.invalid ||
       this.subject === "" || 
       this.timeSlot ==="" || 
       this.teacherType === "" ||
       this.imageBase64 === ""
      ) {
        const errorMessage = this.getFirstError();
        if (errorMessage) {
          this._toastr.error(errorMessage);
          return;
        }
    }

    const payload = this.createApiPayload();
    this.isSpinnerShown = true;

    this.__apiCall.PostCallWithToken(payload, 'Teacher/SaveTeacherServices')
      .subscribe(
        (response) => {
          if (response.responseCode === 200) {
            this._toastr.success(response.responseMessage);
            this.addServiceForm.reset();
            this.editId = 0;
            this.subject = "";
            this.timeSlot = "";
            this.modal.dismissAll();
            this.getServices();
          } else {
            this.errorHandlingService.handleResponseError(response);
          }
          this.isSpinnerShown = false;
        },
        (error) => {
          this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
          this.isSpinnerShown = false;
        }
      );
  }

  createApiPayload() {
    this.editId = this.FormName == "Add" ? 0 : this.editId;
    return {
      id: this.editId,
      subject: this.subject,
      duration: this.timeSlot,
      serviceType: this.teacherType,
      fee: this.addServiceForm.get('fee')?.value,
      imageBase64: this.imageBase64,
    };
  }

  private getFirstError(): string {
    for (const key of Object.keys(this.addServiceForm.controls)) {
      const control = this.addServiceForm.get(key);
      if (control && control.invalid) {
        if (this.getFriendlyFieldName(key) != "") {
          return `${this.getFriendlyFieldName(key)} is invalid.`;
        } else {
          return "";
        }
      }
    }

    if (this.subject === "") return "Subject is required.";
    if (this.timeSlot === "") return "Duration is required.";
    if (this.teacherType === "") return "Service Type is required.";
    if (this.imageBase64 === "") return "Image is required.";
    
    return "";
  }

  private getFriendlyFieldName(field: string): string {
    const fieldNames: { [key: string]: string } = {
      imageBase64: "Image",
      fee:"Fee",
    };

    return fieldNames[field] || field;
    
  }


  checksubjectExsists(): boolean {
    const payload = this.createApiPayload();
    if (this.servicesData?.length > 0) {
      if (this.FormName == "Add") {
        debugger
        const isExsist = this.servicesData.some((tag: any) => {
          tag.subject == payload.subject && tag.duration == payload.duration && tag.fee == payload.fee
        });
        if (isExsist) {
          this._toastr.error("This Subject Already Exist");
          return true;
        }
      } else {
        let newSubject = this.servicesData;
        debugger
        newSubject = newSubject.filter((item: any) => item.id != this.editId);
        const isExsist = newSubject.some((tag: any) => tag.subject == payload.subject && tag.id != this.editId && tag.duration == payload.duration && tag.fee == payload.fee);
        if (isExsist) {
          this._toastr.error("This Subject Already Exist");
          return true;
        }
      }

      return false;
    }
    return false;
  }

  //////////  dropdown data /////////////// /////////
  ////////////////////////////////////////////////////////////

  ///1

  subject: string = '';
  onSubjectSelected(option: any) {
    this.subject = option.value;;
  }
  onSubjectValueChange(searchValue: any) {
    this.subject = searchValue
  }

  subjectList = [];
  getSubjectList() {
    this.__apiCall
      .GetCallWithoutToken("DropDown/GetSubjectDropDown")
      .subscribe((response) => {
        this.subjectList = response?.data;
      });
  }


  ///  2

  timeSlot: string = '';

  onTimeSelcted(option: any) {
    this.timeSlot = option.value;;
  }



  timeList = [
    { value: 'Hourly' },
    { value: 'Weekly' },
    { value: 'Monthly' }
  ];

  //3 teachear type dropdowns

  teacherTypeList = [
    { id: 1, value: "Remote" },
    { id: 2, value: "On-Site" }
  ];
  teacherType: string = "";
  onTypeSelected(option: any) {
    this.teacherType = option.value;
  }


  /////////////////////////////////////////////////
  /////////////errors of dropdowns //////////////////
  ///////////////////////////////////////////////////
  subjectError: boolean = false;
  durationError: boolean = false;
  imageError: boolean = false;
  teacherTypeError: boolean = false;
  ShowError() {
    this.subjectError = this.subject == "" ? true : false;
    this.durationError = this.timeSlot == "" ? true : false;
    this.imageError = this.images.length < 1 ? true : false;
    this.teacherTypeError = this.teacherType == "" ? true : false;
  }


 ////////////////////////////////////////////
  //////////// code for image /////////////////////
  //////////////////////////////////////////////
  
  files: File[] = [];
  images: { imageBase64: string }[] = [];
  imageBase64: string | null = null;
  imageSizeError: boolean = false;

  onSelect(event: NgxDropzoneChangeEvent) {
    const newFile = event.addedFiles[0];
    if (newFile) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const sizeInKB = Math.round(newFile.size / 1024);
        const isToDel = this.checkImgeSizeValidity(sizeInKB, newFile);
        if (!isToDel) {
          const base64String = e.target.result as string;
          this.files = [newFile]; 
          this.images = [{ imageBase64: base64String }]; 
          this.imageBase64 = base64String;
        }
      };
      reader.readAsDataURL(newFile);
    }

  }

  onRemove(event: File) {
    this.files = [];
  this.images = [];
  }
  
  checkImgeSizeValidity(size: number, file: any) {
    if (size > 1024) {
      this.imageSizeError = true;
      return true;
    }
    else {
      this.imageSizeError = false;
      return false;
    }
  }


  /////////////////////////////////////////////////////
  //////////// image update case //////////////////////
  //////////////////////////////////////////////////

  onFileSelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {

        const sizeInKB = Math.round(file.size / 1024);
        const isToDel = this.checkImgeSizeValidity(sizeInKB, file)
        if (!isToDel) {
          const base64String = reader.result as string;
          this.files = [file]; 
          this.images = [{ imageBase64: base64String }];
          this.imageBase64 = base64String;
        };
      };
    }
  }

  removeFile(file: any): void {
    this.files = [];
    this.images = [];
    this.imageBase64 = "";
  }


}
