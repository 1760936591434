import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, inject, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { ApiCallService } from 'src/app/shared/services/api-call.service';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';
import { UserCentralizedDataService } from 'src/app/shared/services/user-centralized-data.service';

@Component({
  selector: 'app-cv-experience-details',
  templateUrl: './cv-experience-details.component.html',
  styleUrl: './cv-experience-details.component.scss'
})
export class CvExperienceDetailsComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  experienceForm: FormGroup;
  public FormName: string = "Add";
  isLoading: boolean = true;
  public isExpanded: boolean = false;
  hasBeenOpened: boolean = false;
  isExperienceAdded: boolean = false;
  experienceDetails: any = [
    //   {
    //   role: "Frontend Developer",
    //   company: "ATG SYSTEM",
    //   startDate: "2005-07-21T00:00:00",
    //   endDate: "2005-07-21T00:00:00",
    //   description: "As a frontend developer"
    // },{
    //   role: "backend Developer",
    //   company: "ATG SYSTEM",
    //   startDate: "2005-07-21T00:00:00",
    //   endDate: "2005-07-21T00:00:00",
    //   description: "As a frontend developer"
    // }
  ];
  delId: number;
  editId: number = 0;

  private readonly _apiCall = inject(ApiCallService);
  private readonly _toaster = inject(ToastrService);
  private readonly _spinner = inject(NgxSpinnerService);
  private readonly errorHandlingService = inject(ErrorHandlingService);
  private readonly _userCentralizedDataService = inject(UserCentralizedDataService);

  constructor(private fb: FormBuilder, public modal: NgbModal, private cdr: ChangeDetectorRef) {
    this.experienceForm = this.fb.group({
      role: ['', Validators.required],
      company: ['', Validators.required],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      description: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.getExperienceDetails();
  }


  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }


  addCard(content: TemplateRef<any>) {
    this.experienceForm.reset();
    this.isPresent = false;
    if (!this.isPresent) {
      this.experienceForm.get('endDate')?.enable();
    }
    this.updateCharCount();
    this.modal.open(content, { centered: true, size: 'lg' });
    this.FormName = "Add";
  }

  editCard(content: TemplateRef<any>, tag: any) {
    this.experienceForm.reset();
    this.isPresent = false;
    this.modal.open(content, { centered: true, size: 'lg' });
    this.FormName = "Edit";
    this.editId = tag?.id;
    this.experienceForm.get("role")?.setValue(tag?.role);
    this.experienceForm.get("company")?.setValue(tag?.company);
    this.experienceForm.get("startDate")?.setValue(tag?.startDate?.substring(0, 10));
    this.experienceForm.get("endDate")?.setValue(tag?.endDate?.substring(0, 10));
    this.experienceForm.get("description")?.setValue(tag?.description);
    this.updateCharCount();
    this.isPresent = tag?.isPresent;
    if (this.isPresent) {
      this.experienceForm.get('endDate')?.disable();
    } else {
      this.experienceForm.get('endDate')?.enable();
    }

  }

  deleteCard(content: TemplateRef<any>, id: number) {
    this.modal.open(content, { centered: true, size: 'sm' });
    this.delId = id;
  }


  checkExperienceExsists(): boolean {
    const payload = this.createApiPayload();
    const experienceDetails = this._userCentralizedDataService.getExperienceDetails(); // Fetch stored experience details

    if (experienceDetails?.length > 0) {
      if (this.FormName == "Add") {
        const isExsist = experienceDetails.some((tag: any) => tag.role === payload.role);
        if (isExsist) {
          this._toaster.error("This Experience Already Exists");
          return true;
        }
      } else {
        let newExperience = experienceDetails.filter((item: any) => item.id !== this.editId);
        const isExsist = newExperience.some((tag: any) => tag.role === payload.role && tag.id !== this.editId);
        if (isExsist) {
          this._toaster.error("This Experience Already Exists");
          return true;
        }
      }
      return false;
    }
    return false;
  }

  public async saveExperienceDetails(): Promise<void> {
    this.experienceForm.markAllAsTouched();
    const isAlreadyExsists = this.checkExperienceExsists();
    if (!isAlreadyExsists) {
      const payload = this.createApiPayload();
      const description = this.experienceForm.get('description')?.value;
      if (this.experienceForm.invalid || description?.length < 200) {
        this._toaster.error("Invalid Form");
      } else {
        this._userCentralizedDataService.saveExperienceDetails(payload);
        this.getExperienceDetails();
        this.modal.dismissAll();

      }
    }
  }

  // Function to create the payload
  createApiPayload() {
    const date = new Date().toJSON();
    this.editId = this.FormName == "Add" ? 0 : this.editId;
    return {
      id: this.editId,
      role: this.experienceForm.get('role')?.value,
      company: this.experienceForm.get('company')?.value,
      startDate: this.experienceForm.get('startDate')?.value || date,
      endDate: this.experienceForm.get('endDate')?.value || date,
      description: this.experienceForm.get('description')?.value,
      IsPresent: this.isPresent
    };
  }



  // public async deleteExperience(): Promise<void> {
  //   this._spinner.show();
  //   try {
  //     const res = await this._apiCall.PostCallWithToken(null, `AdmissionLeloUsers/DeleteTeacherExperience?ExperienceId=${this.delId}`).pipe(takeUntil(this.destroy$))
  //       .toPromise();
  //     if (res.responseCode == 200) {
  //       this._toaster.success(res.responseMessage);
  //       this.getExperienceDetails();
  //       this._spinner.hide();
  //       this.modal.dismissAll();
  //     } else {
  //       this.errorHandlingService.handleResponseError(res);
  //     }
  //   } catch (error) {
  //     this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
  //   } finally {
  //     this._spinner.hide();
  //     this.cdr.detectChanges();
  //   }
  // }



  // FUNCTION TO GET Experience DETAILS

  private async getExperienceDetails(): Promise<void> {
    this.isLoading = true;
    try {
      this.experienceDetails = this._userCentralizedDataService.getExperienceDetails();
      this.isExperienceAdded = this.experienceDetails && this.experienceDetails.length > 0
      if (!this.experienceDetails || this.experienceDetails.length === 0) {
        this.experienceDetails = [];
      }
    } catch (error) {
      this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
    } finally {
      this._spinner.hide();
      this.isLoading = false;
      this.cdr.detectChanges();
    }

  }



  /////////////////////////////////////////////////////////////////
  //////// Functions to handle dates //////////////////////////////
  ///////////////////////////////////////////////////////////////

  getMaxDate(): string {
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    return formattedDate;
  }

  minmumDate: string;

  getMinDate() {
    const startDate = this.experienceForm.get('startDate')?.value;
    this.experienceForm.get('endDate')?.reset();
    this.minmumDate = startDate;
  }



  // PRESENT BUTTON LOGISTICS
  isPresent: boolean = false;
  getCheckboxValue() {
    console.log(this.isPresent);
    if (this.isPresent) {
      this.experienceForm.get('endDate')?.disable();
    } else {
      this.experienceForm.get('endDate')?.enable();
    }
  }


  // FUNCTION FOR DESCRIPTION CHAR COUNT

  charCount: number = 0;

  updateCharCount(): void {
    let description = this.experienceForm.get("description")?.value;
    this.charCount = description?.length || 0;
  }


  // collapsed the tab
  toggleForm(event: MouseEvent) {
    event.stopPropagation();  // Stop click event propagation to prevent parent click
    this.isExpanded = !this.isExpanded;  // Toggle expanded state for the arrow
  }

}
