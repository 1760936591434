import { ChangeDetectionStrategy, Component, HostListener, inject } from '@angular/core';
import { ApiCallService } from 'src/app/shared/services/api-call.service';
import { CommonUtilsServiceService } from 'src/app/shared/services/common-utils-service.service';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';
import { PropertyService } from 'src/app/shared/services/property.service';

@Component({
  selector: 'app-latest-admissions',
  templateUrl: './latest-admissions.component.html',
  styleUrl: './latest-admissions.component.scss',
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class LatestAdmissionsComponent {
  addSectionCss: any = {
    height: '100%',
    width: '100%',
  };
  isAdLoading: boolean = true;
  public tag: string = 'Admissions';
  public title = 'slider_filter_search';

  private _utils = inject(CommonUtilsServiceService);
  private _apicall = inject(ApiCallService);
  private readonly errorHandlerService = inject(ErrorHandlingService);
  isDescriptionShown: boolean = true;
  isErrorOccurred: boolean = false;

  admissionsData: any[] = [

  ];
  // adImages: any = [
  //   {
  //     redirectLink: 'https://youtube.com',
  //     imageUrl: '../../../../../assets/images/advertisements/admissions/1.jpeg'
  //   },
  //   {
  //     redirectLink: 'https://youtube.com',
  //     imageUrl: '../../../../../assets/images/advertisements/admissions/2.jpeg'
  //   },
  //   {
  //     redirectLink: 'https://youtube.com',
  //     imageUrl: '../../../../../assets/images/advertisements/admissions/3.jpeg'
  //   },
  //   {
  //     redirectLink: 'https://youtube.com',
  //     imageUrl: '../../../../../assets/images/advertisements/admissions/4.jpeg'
  //   },
  //   {
  //     redirectLink: 'https://youtube.com',
  //     imageUrl: '../../../../../assets/images/advertisements/admissions/5.jpeg'
  //   },
  //   {
  //     redirectLink: 'https://youtube.com',
  //     imageUrl: '../../../../../assets/images/advertisements/admissions/6.jpeg'
  //   },
  // ];
  public noRecordFound = false;
  public isDataLoading = true;

  constructor(public propertyService: PropertyService) { }

  ngOnInit(): void {
    this.getLatestAdmissions();
    this.checkScreenSize();
    this.getAds();

    setInterval(() => {
      this.isAdLoading = false;
    }, 5000)
  }


  isScreenSmall: boolean = false;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenSize();
  }

  checkScreenSize() {
    const width = window.innerWidth;
    this.isScreenSmall = width < 1200;

    if (width > 1200) {
      this.addSectionCss.height = '100%';
    } else {
      this.addSectionCss.height = '280px';
    }
  }

  getLatestAdmissions(): void {
    this.isErrorOccurred = false;
    this.isDataLoading = true;
    this.propertyService.getLatestAdmissions().subscribe(
      (response) => {
        this.isDataLoading = false;
        this.admissionsData = response;
        this.noRecordFound = !this.admissionsData || this.admissionsData.length === 0;
        // console.log("Admissions data:", this.admissionsData);

        // Convert each admission's image URL to a Blob URL
        this.admissionsData.forEach((admission) => {
          // Ensure admission object exists
          if (admission) {
            // Check if imageUrl exists and contains base64 data
            if (admission.img && admission.img.url && admission.img.url.includes('base64,')) {
              const base64Data = admission.img.url.split('base64,')[1]; // Extract base64 data
              admission.blobUrl = this._utils.convertBase64ToBlobUrl(base64Data, 'image/jpeg');
              // console.log("Admission Blob URL:", admission.blobUrl);
            } else {
              // Set a default image if the image data is not in the expected format
              admission.blobUrl = 'path/to/default/image.png';
              console.warn("Image data is not in the expected base64 format:", admission.img ? admission.img.url : "No img property");
            }
          } else {
            console.warn("Admission object is undefined:", admission);
          }
        });
      },
      (error) => {
        this.isErrorOccurred = true;
        this.isDataLoading = false;
        console.error("Error fetching latest admissions:", error);
      }
    );
  }




adImages: any = []
  getAds(): void {
    this.propertyService.getAds( "Ad/GetAdvertisements?moduleName=Admission").subscribe(
      (res) => {
        if (res.responseCode === 200) {
          this.adImages = res.data;

          this.adImages.forEach((ad: any) => {
            if (ad.imageUrl && ad.imageUrl.includes('base64,')) {
              const base64Data = ad.imageUrl.split('base64,')[1];
              ad.imageUrl = this._utils.convertBase64ToBlobUrl(base64Data, 'image/jpeg');
              // console.log("Ad Blob URL:", ad.imageUrl);
            }
          });

          this.isAdLoading = false;
        } else {
          this.adImages = [];
          this.isAdLoading = false;
          this.errorHandlerService.handleResponseError(res);
        }
      },
      (error) => {
        this.errorHandlerService.handleHttpError(error);
        this.adImages = [];
        this.isAdLoading = false;
      }
    );
  }


  pauseScroll() {
    const adsSection = document.querySelector('.ad-images-wrapper') as HTMLElement;
    if (adsSection) {
      adsSection.style.animationPlayState = 'paused';
    }
  }

  startScroll() {
    const adsSection = document.querySelector('.ad-images-wrapper') as HTMLElement;
    if (adsSection) {
      adsSection.style.animationPlayState = 'running';
    }
  }




}

