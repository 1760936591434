import { Component, HostListener, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, debounceTime, takeUntil } from 'rxjs';
import { ApiCallService } from 'src/app/shared/services/api-call.service';
import { CommonUtilsServiceService } from 'src/app/shared/services/common-utils-service.service';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';
import { PropertyService } from 'src/app/shared/services/property.service';

@Component({
  selector: 'app-top-hostels',
  templateUrl: './top-hostels.component.html',
  styleUrl: './top-hostels.component.scss'
})
export class TopHostelsComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  resizeSubject: Subject<void> = new Subject<void>();
  addSectionCss: any = {
    height: '100%',
    width: '100%',
  };

  @Input() heading: string = "Latest For Sale";
  public title = 'slider_filter_search';
  public noRecordsFound: boolean = false;
  public selectedHostelType: string = 'All';
  public tag: string = 'Hostels'

  private _utils = inject(CommonUtilsServiceService);
  private _apicall = inject(ApiCallService);
  private readonly errorHandlerService = inject(ErrorHandlingService);

  HostelsData: any[] = [];
  public isDataLoading = true;
  public noRecordFound = false;
  isDescriptionShown: boolean = false;
  isAdLoading: boolean = true;
  isErrorOccurred: boolean = false;

  constructor(public propertyService: PropertyService) { }

  ngOnInit(): void {
    this.getTopHostels();
     this.getAds();

    this.resizeSubject.pipe(debounceTime(300), takeUntil(this.destroy$)).subscribe(() => {
      this.checkScreenSize();
    });

    setInterval(() => {
      this.isAdLoading = false;
    }, 5000);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getTopHostels(): void {
    this.isDataLoading = true;
    this.noRecordsFound = false;
    this.isErrorOccurred = false;
    this.HostelsData = [];

    this.propertyService.getTopHostels(this.selectedHostelType).subscribe(
      (response) => {
        this.HostelsData = response;
        this.noRecordsFound = !this.HostelsData || this.HostelsData.length <= 0;
        this.isDataLoading = false;

        // Convert each hostels's image to a Blob URL
        this.HostelsData.forEach((hostels) => {
          if (hostels && hostels.img && hostels.img.url && hostels.img.url.includes('base64,')) {
            const base64Data = hostels.img.url.split('base64,')[1];
            hostels.blobUrl = this._utils.convertBase64ToBlobUrl(base64Data, 'image/jpeg');
          } else {
            hostels.blobUrl = 'path/to/default/image.png';
          }
        });
      },
      (error) => {
        this.isDataLoading = false;
        this.isErrorOccurred = true;
        console.error("Error fetching top hostelss:", error);
      }
    );
  }
  adImages: any[] = [];
  getAds(): void {
    this.isAdLoading = true;
    this.propertyService.getAds( "Ad/GetAdvertisements?moduleName=Hostel").subscribe(
      (res) => {
        if (res.responseCode === 200) {
          this.adImages = res.data;

          // Convert each ad's image to a Blob URL
          this.adImages.forEach((ad) => {
            if (ad.imageUrl && ad.imageUrl.includes('base64,')) {
              const base64Data = ad.imageUrl.split('base64,')[1];
              ad.imageUrl = this._utils.convertBase64ToBlobUrl(base64Data, 'image/jpeg');
              // console.log("Ad Blob URL:", ad.imageUrl);
            }
          });

          this.isAdLoading = false;
        } else {
          this.adImages = [];
          this.errorHandlerService.handleResponseError(res);
          this.isAdLoading = false;
        }
      },
      (error) => {
        this.errorHandlerService.handleHttpError(error);
        this.adImages = [];
        this.isAdLoading = false;
      }
    );
  }

  onHostelFilterClick(type: string): void {
    this.selectedHostelType = type;
    this.getTopHostels();
  }

  isScreenSmall: boolean = false;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.resizeSubject.next();
  }

  checkScreenSize() {
    const width = window.innerWidth;
    this.isScreenSmall = width < 1200;

    if (width > 1200) {
      this.addSectionCss.height = '100%';
    } else {
      this.addSectionCss.height = '280px';
    }
  }

  // adImages: any[] = [
  //   {
  //     redirectLink: 'https://youtube.com',
  //     imageUrl: '../../../../../assets/images/advertisements/institutes/1.jpeg'
  //   },
  //   {
  //     redirectLink: 'https://youtube.com',
  //     imageUrl: '../../../../../assets/images/advertisements/institutes/2.jpeg'
  //   },
  //   {
  //     redirectLink: 'https://youtube.com',
  //     imageUrl: '../../../../../assets/images/advertisements/institutes/3.jpeg'
  //   },
  //   {
  //     redirectLink: 'https://youtube.com',
  //     imageUrl: '../../../../../assets/images/advertisements/institutes/4.jpeg'
  //   }
  // ];

  pauseScroll() {
    const adsSection = document.querySelector('.ad-images-wrapper') as HTMLElement;
    if (adsSection) {
      adsSection.style.animationPlayState = 'paused';
    }
  }

  startScroll() {
    const adsSection = document.querySelector('.ad-images-wrapper') as HTMLElement;
    if (adsSection) {
      adsSection.style.animationPlayState = 'running';
    }
  }
}
