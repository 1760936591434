import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, inject, TemplateRef } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ApiCallService } from 'src/app/shared/services/api-call.service';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';
import { PropertyService } from 'src/app/shared/services/property.service';
import intlTelInput from "intl-tel-input";
import { CommonUtilsServiceService } from 'src/app/shared/services/common-utils-service.service';
@Component({
  selector: 'app-add-scholarship',
  templateUrl: './add-scholarship.component.html',
  styleUrl: './add-scholarship.component.scss',
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddScholarshipComponent {
  private intlTelInputInstance: any;
  public __apiCall = inject(ApiCallService);
  public _toastr = inject(ToastrService);
  public propertyService = inject(PropertyService);
  public _spinner = inject(NgxSpinnerService)
  private readonly errorHandlingService = inject(ErrorHandlingService);
  private readonly _utlis = inject(CommonUtilsServiceService);

  public themeLogo = 'assets/images/logo/2.png';
  public footerLogo = 'assets/images/logo/footer-logo.png';
  public bgImage = 'assets/images/banner/banner.jpg';
  public title = 'Dashboard';
  public parent = 'Scholarship';
  public child = 'Add Scholarship';
  scholarshipsData: any;
  isDataLoading: boolean = true;
  public isSpinnerShown = false;
  tagClass: string;
  addScholarshipForm: FormGroup;
  boostScholarshipForm: FormGroup;
  public FormName: string = "Add";
  scholarshipDescriptionCharCount: number = 0;
  howToApplyCharCount: number = 0;
  public selectedImage: string;




  ngOnInit() {
    this.getScholarships();
    this.getCountry();
    this.getScholarshipTypes();
  }

  getScholarships() {
    this.isDataLoading = true;
    this.__apiCall.GetCallWithToken("Dashboard/GetScholarships").subscribe((response) => {
      if (response.responseCode == 200) {
        this.scholarshipsData = response.data;
        this.isDataLoading = false;
      } else {
        this.scholarshipsData = [];
        this.isDataLoading = false;
      }
    }, (error) => {
      this._toastr.error('Error fetching institutes data', error);
      this.isDataLoading = false;
    }
    )

  }

  constructor(public modal: NgbModal, private fb: FormBuilder) {
    this.addScholarshipForm = this.fb.group({
      scholarship_name: ["", Validators.required],
      institute_name: ["", Validators.required],
      scholarship_address: ["", Validators.required],
      scholarship_addressTitle: ["", Validators.required],
      scholarship_addressLink: ["", [Validators.required, Validators.pattern('https://maps.google.com/maps.+')]],
      application_Link: ["", [Validators.required, Validators.pattern('https://.+')]],
      email_address: ["", [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]],
      scholarship_phone: ["", [Validators.required,
      Validators.pattern("^((\\+91-?)|0)?[0-9]{8,16}$"),
      Validators.minLength(8),
      Validators.maxLength(16)]],
      start_date: ["", [Validators.required,]],
      end_date: ["", Validators.required],
      minimum_cgpa: ["", [Validators.required, this.cgpaValidator]],
      scholarship_amount: ["", [Validators.required, Validators.pattern("^[0-9]*$")]],
      scholarship_fee: ["", [Validators.required, Validators.pattern("^[0-9]*$")]],
      scholarship_description: ["", [Validators.required, Validators.minLength(400)]],
      how_to_apply: ["", [Validators.required, Validators.minLength(400)]],
      courses_name: ["", Validators.required],
      required_documents: ["", Validators.required],
      coverage_Tags: ["", Validators.required],
    });

    this.boostScholarshipForm = this.fb.group({
      title: ["", Validators.required],
      start_date: ["", [Validators.required,]],
      end_date: ["", Validators.required],
      ad_description: ["", [Validators.required, Validators.minLength(200)]],
    });
  }


  loadPhoneScript() {
    setTimeout(() => {
      const inputElement = document.querySelector('#myInput') as HTMLInputElement;
      if (inputElement) {
        this.intlTelInputInstance = intlTelInput(inputElement, {
          initialCountry: 'pk',
          separateDialCode: true
        });
      }
    }, 500)
  }

  ///////////////////////////////////////////////////
  // Ad End Date limiter STARTS HERE 
  minmumAdDate: any
  getMinDate() {
    const maxDate = this.boostScholarshipForm.get('start_date')?.value;
    this.boostScholarshipForm.get('end_date')?.reset();
    this.minmumAdDate = maxDate
  }

  getMaxDate(): string {
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    return formattedDate;
  }

  minAdEndDate: any;
  updateAdEndDateMin(): void {
    const startDate = this.boostScholarshipForm.get('start_date')?.value;
    this.minAdEndDate = startDate ? startDate : null;
  }

  // Ad End Date limiter Ends HERE 
  ///////////////////////////////////////////

  adCharCount: number = 0;
  updateAdCharCount(): void {
    let description = this.boostScholarshipForm.get("ad_description")?.value;
    this.adCharCount = description?.length || 0;
  }



  isAdActivated: boolean = true;
  boostId: number;
  boostCard(content: TemplateRef<any>, id: number) {
    this.boostId = id;
    this.boostScholarshipForm.reset();
    this.imageBase64 = "";
    this.adFiles = [];
    this.modal.open(content, { centered: true, size: 'lg' });
    this.FormName = "Advertisment";
  }

  editAdCard(content: TemplateRef<any>, id: number) {
    this.boostScholarshipForm.reset();
    this.imageBase64 = "";
    this.adFiles = [];
    this.modal.open(content, { centered: true, size: 'lg' });
    this.FormName = "EditAdvertisment";
    this.editAd(id);
  }

  ////////////////////////////////////////////
  //////////// code for Ads image /////////////////////
  //////////////////////////////////////////////

  adFiles: File[] = [];
  adImages: { imageBase64: string }[] = [];
  imageBase64: string | null = null;
  adImageSizeError: boolean = false;

  onAdSelect(event: NgxDropzoneChangeEvent) {
    const newFile = event.addedFiles[0];
    if (newFile) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const sizeInKB = Math.round(newFile.size / 1024);
        const isToDel = this.checkImgeSizeValidity(sizeInKB, newFile);
        if (!isToDel) {
          const base64String = e.target.result as string;
          this.adFiles = [newFile];
          this.adImages = [{ imageBase64: base64String }];
          this.imageBase64 = base64String;
        }
      };
      reader.readAsDataURL(newFile);
    }

  }

  onAdRemove(event: File) {
    this.adFiles = [];
    this.adImages = [];
  }




  /////////////////////////////////////////////////////
  //////////// image update case //////////////////////
  //////////////////////////////////////////////////

  onAdFileSelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {

        const sizeInKB = Math.round(file.size / 1024);
        const isToDel = this.checkImgeSizeValidity(sizeInKB, file)
        if (!isToDel) {
          const base64String = reader.result as string;
          this.adFiles = [file];
          this.adImages = [{ imageBase64: base64String }];
          this.imageBase64 = base64String;
        };
      };
    }
  }

  removeAdFile(file: any): void {
    this.adFiles = [];
    this.adImages = [];
    this.imageBase64 = "";
  }


  public submitBoostAd(): void {

    const adsPayload = this.createAdsApiPayload();
    console.log("Payload", adsPayload);
    this.isSpinnerShown = true;

    this.__apiCall.PostCallWithToken(adsPayload, 'Ad/SaveAndUpdateAd')
      .subscribe(
        (response) => {
          if (response.responseCode === 200) {
            this._toastr.success(response.responseMessage);
            this.boostScholarshipForm.reset();
            this.modal.dismissAll();
          } else {
            this.errorHandlingService.handleResponseError(response);
          }
          this.isSpinnerShown = false;
        },
        (error) => {
          this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
          this.isSpinnerShown = false;
        }
      );
  }

  createAdsApiPayload() {
    this.updateAdId = this.FormName == "Advertisment" ? 0 : this.updateAdId;
    return {
      moduleId: this.boostId,
      moduleName: "scholarship",
      title: this.boostScholarshipForm.get('title')?.value,
      startDate: this.boostScholarshipForm.get('start_date')?.value,
      endDate: this.boostScholarshipForm.get('end_date')?.value,
      description: this.boostScholarshipForm.get('ad_description')?.value,
      imageUrl: this.imageBase64,
    };
  }

  adDataById: any;
  updateAdId: number = 0;
  editAdModuleId: number;
  public editAd(id: number): void {
    this.editAdModuleId = id;
    const payload = {
      id: this.editAdModuleId,
      moduleName: "Institute",
    }
    this.isSpinnerShown = true;
    this._spinner.show();

    this.__apiCall.PostCallWithToken(payload, "Ad/GetAdById")
      .subscribe(
        (response) => {
          if (response.responseCode === 200) {
            this.adDataById = response.data;
            this.updateAdId = this.adDataById.id;
            this.boostScholarshipForm.get('title')?.setValue(this.adDataById?.title);
            this.boostScholarshipForm.get("start_date")?.setValue(this.adDataById?.startDate.substring(0, 10));
            this.boostScholarshipForm.get("end_date")?.setValue(this.adDataById?.endDate.substring(0, 10));
            this.boostScholarshipForm.get("ad_description")?.setValue(this.adDataById?.description);

            this.imageBase64 = this.adDataById?.imageUrl;

            this.updateAdCharCount();

          } else {
            this.errorHandlingService.handleResponseError(response);
            this._toastr.error(response.responseMessage);
          }
          this.isSpinnerShown = false;

        },
        (error) => {
          this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
          this.isSpinnerShown = false;
        }
      );
  }



  addCard(content: TemplateRef<any>) {
    this.updateScholarshipId = 0;
    this.coursestags = [];
    this.requiredDocuments = [];
    this.coverageTags = [];
    this.Duration = "";
    this.ScholarshipType = "";
    this.City = "";
    this.Country = "";
    this.State = "";
    this.modal.open(content, { centered: true, size: 'lg' })
    this.FormName = "Add";
    this.images = [];
    this.files = [];
    this.scholarshipTypeError = false;
    this.countryError = false;
    this.stateError = false;
    this.cityError = false;
    this.imageError = false;
    this.scholarshipDescriptionCharCount = 0;
    this.howToApplyCharCount = 0;
    this.addScholarshipForm.reset();
    this.loadPhoneScript();

  }
  editCard(content: TemplateRef<any>, id: number) {
    this.modal.open(content, { centered: true, size: 'lg' })
    this.FormName = "Edit";
    this.addScholarshipForm.reset();
    this.Country = "";
    this.State = "";
    this.City = "";
    this.Duration = "";
    this.ScholarshipType = "";
    this.coursestags = [];
    this.requiredDocuments = [];
    this.coverageTags = [];
    this.images = [];
    this.files = [];
    this.scholarshipTypeError = false;
    this.countryError = false;
    this.stateError = false;
    this.cityError = false;
    this.imageError = false;
    this.scholarshipDescriptionCharCount = 0;
    this.howToApplyCharCount = 0;
    this.editScholarship(id);
    this.loadPhoneScript();
  }
  //////////////////////editScholarship//////////////////////////////
  updateScholarshipId: number = 0;
  scholarshipByIdData: any;

  editScholarship(id: number) {
    this.updateScholarshipId = id;
    this.isSpinnerShown = true;
    this._spinner.show();

    this.__apiCall.PostCallWithToken(null, `Dashboard/GetScholarshipById?ScholarshipId=${id}`).subscribe((res) => {
      if (res.responseCode === 200) {


        this.scholarshipByIdData = res.data;

        this.addScholarshipForm.get('scholarship_name')?.setValue(this.scholarshipByIdData?.scholarshipName);
        this.addScholarshipForm.get('institute_name')?.setValue(this.scholarshipByIdData?.instituteName);
        this.addScholarshipForm.get('scholarship_address')?.setValue(this.scholarshipByIdData?.address);
        this.addScholarshipForm.get('scholarship_addressTitle')?.setValue(this.scholarshipByIdData?.locationTitle);
        this.addScholarshipForm.get('scholarship_addressLink')?.setValue(this.scholarshipByIdData?.locationSrc);
        this.addScholarshipForm.get('application_Link')?.setValue(this.scholarshipByIdData?.applicationLink);
        this.addScholarshipForm.get('email_address')?.setValue(this.scholarshipByIdData?.email);
        this.addScholarshipForm.get('scholarship_phone')?.setValue(this.scholarshipByIdData?.contactNumber.substring(3));
        this.addScholarshipForm.get('start_date')?.setValue(this.scholarshipByIdData?.startDate?.substring(0, 10));
        this.addScholarshipForm.get('end_date')?.setValue(this.scholarshipByIdData?.endDate?.substring(0, 10));
        this.addScholarshipForm.get('minimum_cgpa')?.setValue(this.scholarshipByIdData?.minimumCGPA);
        this.addScholarshipForm.get('scholarship_amount')?.setValue(this.scholarshipByIdData?.amount);
        this.addScholarshipForm.get('scholarship_fee')?.setValue(this.scholarshipByIdData?.applicationFee);
        this.addScholarshipForm.get('scholarship_description')?.setValue(this.scholarshipByIdData?.description);
        this.addScholarshipForm.get('how_to_apply')?.setValue(this.scholarshipByIdData?.howToApply);

        this.Country = this.scholarshipByIdData?.country;
        this.State = this.scholarshipByIdData?.state;
        this.City = this.scholarshipByIdData?.city;
        this.Duration = this.scholarshipByIdData?.duration;
        this.ScholarshipType = this.scholarshipByIdData?.type;
        this.coursestags = this.scholarshipByIdData?.courses;
        this.requiredDocuments = this.scholarshipByIdData?.documents;
        this.coverageTags = this.scholarshipByIdData?.coverages;
        this.images = this.scholarshipByIdData?.images;


        //this line for updating phone number country code
        this.intlTelInputInstance.setNumber(this.scholarshipByIdData?.contactNumber);
        this.updateScholarshipDescriptionCharCount();
        this.updateHowToApplyCharCount();
        this.isSpinnerShown = false;
        this._spinner.hide();
      }
      else {
        this._toastr.error(res.responseMessage);
        this.isSpinnerShown = false;
        this._spinner.hide();
      }
    });

    this.addScholarshipForm.updateValueAndValidity();
  }

  delId: number;
  deleteCard(content: TemplateRef<any>, id: number) {
    this.modal.open(content, { centered: true, size: 'sm' });
    this.delId = id;
  }

  deleteScholarship() {
    this.isSpinnerShown = true;
    this._spinner.show();
    this.__apiCall
      .PostCallWithToken(null, `Dashboard/DeleteScholarship?id=${this.delId}`).subscribe((res) => {
        if (res.responseCode === 200) {
          this._toastr.success(res.responseMessage);
          this.getScholarships();
          this.modal.dismissAll();
          this.isSpinnerShown = false;
          this._spinner.hide();

        }
        else {
          this._toastr.error(res.responseMessage);
          this.modal.dismissAll();
          this.isSpinnerShown = false;
          this._spinner.hide();
        }
      })
  }

  //////////////////////Deletion Confirmation//////////////////////
  openModal(content: TemplateRef<any>, id: number) {
    this.modal.open(content, { centered: true, size: 'md' })
  }

  public Options = {
    items: 1,
    loop: true,
    nav: true,
    dots: true,
    autoplay: true,
    autoplayTimeout: 2000,
    navText: [
      "<i class='fa fa-chevron-left'></i>",
      "<i class='fa fa-chevron-right'></i>",
    ],
  };


  files: File[] = [];
  images: { imageBase64: string }[] = [];

  onSelect(event: NgxDropzoneChangeEvent) {
    const newFiles = event.addedFiles;
    this.files.push(...newFiles);
    // console.log("files", this.files)

    newFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = (e: any) => {

        const sizeInKB = Math.round(file.size / 1024);
        const istoDel = this.checkImgeSizeValidity(sizeInKB, file)
        if (!istoDel) {
          const base64String = e.target.result as string;
          this.images.push({ imageBase64: base64String });
        }
      };
      reader.readAsDataURL(file);
    });

  }

  onRemove(event: File) {
    const index = this.files.indexOf(event);
    if (index !== -1) {
      this.files.splice(index, 1);
      this.images.splice(index, 1);
    }
  }
  imageSizeError: boolean = false
  checkImgeSizeValidity(size: number, file: any) {
    if (size > 1024) {
      this.imageSizeError = true;
      this.onRemove(file)
      return true;

    }
    else {
      this.imageSizeError = false;
      return false;
    }
  }


  /////////////////////////////////////////////////////
  //////////// image update case //////////////////////
  //////////////////////////////////////////////////



  ////////////////////////////////////////////
  //////////// code for image /////////////////////
  //////////////////////////////////////////////


  onFileSelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {

        const sizeInKB = Math.round(file.size / 1024);
        const isToDel = this.checkImgeSizeValidity(sizeInKB, file)
        if (!isToDel) {
          const base64String = reader.result as string;
          this.images.push({ imageBase64: base64String });
        };
      };
    }
  }

  removeFile(file: any): void {

    this.images = this.images.filter((t: any) => t !== file);

  }



  ////////////////////////////////////////////
  //////////// code for image /////////////////////
  //////////////////////////////////////////////


  ////////////////////////////////////////////
  // code for Custom NonSearchable  DropDown for ScholarshipType Starts Here
  //////////////////////////////////////////////
  ScholarshipType: string = ""
  ScholarshipTypeList = [];

  getScholarshipTypes() {
    this.__apiCall
      .GetCallWithoutToken("DropDown/GetScholarshipDropDown")
      .subscribe((response) => {
        this.ScholarshipTypeList = response?.data;
      });
  }

  onScholarshipTypeSelected(option: any) {
    this.ScholarshipType = option.value;
  }
  ////////////////////////////////////////////
  // code for Custom NonSearchable  DropDown for ScholarshipType Ends Here
  //////////////////////////////////////////////

  ////////////////////////////////////////////
  // code for Custom NonSearchable  DropDown for  Duration Starts Here
  //////////////////////////////////////////////

  Duration: string = "";

  DurationTimeList = [
    { id: 1, value: "One Year" },
    { id: 2, value: "Two Year" },
    { id: 3, value: "Full Degree" },

  ];

  onDurationSelected(option: any) {
    this.Duration = option.value;

  }

  ////////////////////////////////////////////
  // code for Custom NonSearchable  DropDown for  Duration Ends Here
  //////////////////////////////////////////////

  ////////////////////////////////////////////////////
  /////////  Country drop down  //////////
  ///////////////////////////////////////////////////// 
  CountrysearchTerm: string = "";
  Country: string = "";
  State: string = '';
  City: string = '';
  CountryList = []
  SearchTermCountry(searchTerm: string) {
    this.CountrysearchTerm = searchTerm;
    this.Country = searchTerm;
  }
  getCountry() {
    this.__apiCall
      .GetCallWithoutToken('DropDown/GetCountryDropDown')
      .subscribe((response: any) => {
        if (response.responseCode === 200) {
          this.CountryList = response?.data;
        }
      })

  }


  onCountrySelected(option: any) {
    if (this.Country != option.value) {
      this.State = '';
      this.City = '';
    }
    this.Country = option.value;
    this.getStateList(option.id);

  }
  onCountryValueChange(searchValue: any) {
    this.Country = searchValue
    if (this.Country === '') {
      this.State = '';
      this.City = ''
    }
  }

  ////////////////////////////////////////////////////
  /////////  State drop down data //////////
  ////////////////////////////////////////////////////

  StatesearchTerm: string = "";

  StateList = []
  SearchTermState(searchTerm: string) {
    this.StatesearchTerm = searchTerm;
    this.State = searchTerm;
  }

  onStateSelected(option: any) {
    if (this.State !== option.value) {
      this.City = '';
    }
    this.State = option.value;
    this.getCityList(option.id);

  }
  onStateValueChange(searchValue: any) {
    this.State = searchValue
    if (this.State === '') {
      this.City = ''
    }
  }

  onCountryFocused(isTouched: boolean) {
    if (isTouched) {
      if (this.CountrysearchTerm === "" && this.Country === "") {
        this.countryError = true
      }
    }
  }



  getStateList(countryId: number) {
    this.__apiCall
      .GetCallWithoutToken('DropDown/GetStateDropDown?CountryId=' + countryId)
      .subscribe((response: any) => {
        if (response.responseCode === 200) {
          this.StateList = response?.data;
        }
      });
  }


  ////////////////////////////////////////////////////
  /////////  City drop down data //////////
  ////////////////////////////////////////////////////


  CityList = []
  onCitySelected(option: any): void {
    this.City = option.value;
  }

  onStateFocused(isTouched: boolean) {
    if (isTouched) {
      if (this.StatesearchTerm === "" && this.State === "") {
        this.stateError = true;
      }
    }

  }

  getCityList(stateId: number) {
    this.__apiCall.GetCallWithoutToken('DropDown/GetCityDropDown?StateId=' + stateId).subscribe(
      (response: any) => {
        if (response.responseCode === 200) {
          this.CityList = response?.data;
        }
      }
    )
  }

  ///////////////////////////////////////////////////////////
  // Function to add COURSE tag functionality start here
  coursestags: any[] = [];

  addCourseTag(): void {
    const tagInput = this.addScholarshipForm.get("courses_name")?.value.trim();

    if (
      tagInput &&
      !this.coursestags.some((tag) => tag.courseName === tagInput)
    ) {
      const newTag = { courseName: tagInput };
      this.coursestags.push(newTag);
      this.addScholarshipForm.get("courses_name")?.setValue("");
      this.courseTagError = this.coursestags.length < 1;
    }
  }

  removeCourseTag(tag: any): void {
    this.coursestags = this.coursestags.filter((t) => t !== tag);
    this.courseTagError = this.coursestags.length < 1;
  }

  onEnterKeyPress(event: Event): void {
    if (event instanceof KeyboardEvent && event.key === "Enter") {
      event.preventDefault();
      this.addCourseTag();
    }
  }

  // Function to add COURSE tag functionality end here
  /////////////////////////////////////////////////////////////////////////


  ///////////////////////////////////////////////////////////
  // Function to add Required Documents tag functionality start here
  requiredDocuments: any[] = [];

  addDocumentsTags(): void {
    const tagInput = this.addScholarshipForm.get("required_documents")?.value.trim();

    if (tagInput && !this.requiredDocuments.some((tag) => tag.documentName === tagInput)) {
      const newTag = { documentName: tagInput };
      this.requiredDocuments.push(newTag);
      this.addScholarshipForm.get("required_documents")?.setValue("");
      this.docuemntsTagError = this.requiredDocuments.length < 1;
    }
  }

  removeDocumentsTags(tag: any): void {
    this.requiredDocuments = this.requiredDocuments.filter((t) => t !== tag);
    this.docuemntsTagError = this.requiredDocuments.length < 1;
  }

  onEnterDocuments(event: Event): void {
    if (event instanceof KeyboardEvent && event.key === "Enter") {
      event.preventDefault();
      this.addDocumentsTags();
    }
  }
  // Function to add Required Documents tag functionality end here
  /////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////
  // Function to add Coverage Tags functionality start here
  coverageTags: any[] = [];

  addCoverageTags(): void {

    const tagInput = this.addScholarshipForm.get("coverage_Tags")?.value.trim();

    if (
      tagInput &&
      !this.coverageTags.some((tag) => tag.coverageName === tagInput)
    ) {
      const newTag = { coverageName: tagInput };
      this.coverageTags.push(newTag);
      this.addScholarshipForm.get("coverage_Tags")?.setValue("");
      this.coverageTagError = this.coverageTags.length < 1;
    }
  }

  removeCoverageTags(tag: any): void {
    this.coverageTags = this.coverageTags.filter((t) => t !== tag);
    this.coverageTagError = this.coverageTags.length < 1;
  }

  onEnterCoverage(event: Event): void {

    if (event instanceof KeyboardEvent && event.key === "Enter") {
      event.preventDefault();
      this.addCoverageTags();
    }
  }
  // Function to add  Coverage Tagsfunctionality end here
  /////////////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////
  // code for showig errors
  //////////////////////////////////////////////

  scholarshipTypeError: boolean = false;
  countryError: boolean = false;
  stateError: boolean = false;
  cityError: boolean = false;
  imageError: boolean = false;
  durationError: boolean = false;
  courseTagError: boolean = false;
  coverageTagError: boolean = false;
  docuemntsTagError: boolean = false;
  ShowError() {
    this.scholarshipTypeError = this.ScholarshipType == "" ? true : false;
    this.countryError = this.Country == "" ? true : false;
    this.stateError = this.State == "" ? true : false;
    this.cityError = this.City == "" ? true : false;
    this.durationError = this.Duration == "" ? true : false;
    this.courseTagError = this.coursestags.length < 1 ? true : false;
    this.coverageTagError = this.coverageTags.length < 1 ? true : false;
    this.docuemntsTagError = this.requiredDocuments.length < 1 ? true : false;
    this.imageError = this.images.length < 1 ? true : false;
  }

  ////////////////////////futureDateValidator//////////////////////////////////////
  minEndDate: any;
  updateEndDateMin(): void {
    const startDate = this.addScholarshipForm.get('start_date')?.value;
    this.minEndDate = startDate ? startDate : null;
  }


  // Method for word count validation
  minWordsValidator(minWords: number) {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value || '';
      const wordCount = value.trim().split(/\s+/).length;
      return wordCount < minWords ? { minWords: true } : null;
    };
  }


  // Method to update job description char count
  updateScholarshipDescriptionCharCount(): void {
    const scholarshipdescription = this.addScholarshipForm.get("scholarship_description")?.value;
    this.scholarshipDescriptionCharCount = scholarshipdescription?.length || 0;
  }

  // Method to update how to apply char count
  updateHowToApplyCharCount(): void {
    const howToApply = this.addScholarshipForm.get("how_to_apply")?.value;
    this.howToApplyCharCount = howToApply?.length || 0;
  }
  // Custom validator method
  cgpaValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    return value && (Number(value) > 4) ? { greaterThanFour: true } : null;
  }
  checkScholarshipFieldsValidity(): boolean {
    if (this.coursestags.length < 1 || this.requiredDocuments.length < 1 || this.coverageTags.length < 1) {
      return true;
    }
    return false;
  }
  removeUmwantedControl() {

    this.addScholarshipForm.removeControl('classes_name');
    this.addScholarshipForm.removeControl('courses_name');
    this.addScholarshipForm.removeControl('coverage_Tags');
    this.addScholarshipForm.removeControl('required_documents');
    this.addScholarshipForm.updateValueAndValidity();

    // console.log("val remove", this.addAdmissionForm.value)
  }

  addControls() {
    if (!this.addScholarshipForm.contains('courses_name')) {
      this.addScholarshipForm.addControl('courses_name', new FormControl("", Validators.required));
    }

    if (!this.addScholarshipForm.contains('required_documents')) {
      this.addScholarshipForm.addControl('required_documents', new FormControl("", Validators.required));
    }

    if (!this.addScholarshipForm.contains('coverage_Tags')) {
      this.addScholarshipForm.addControl('coverage_Tags', new FormControl("", Validators.required));
    }

    this.addScholarshipForm.updateValueAndValidity();
  }

  onSubmit() {
    debugger
    this.addScholarshipForm.markAllAsTouched();
    const tagsValidity = this.checkScholarshipFieldsValidity();
    this.ShowError();

    const description = this.addScholarshipForm.get("scholarship_description")?.value;
    const how_to_apply = this.addScholarshipForm.get("how_to_apply")?.value;
    this.removeUmwantedControl();
    if (
      this.Country === "" ||
      this.State === "" ||
      this.City === "" ||
      this.ScholarshipType === "" ||
      this.Duration === "" ||
      this.images.length < 1 ||
      tagsValidity ||
      description?.length < 400 ||
      how_to_apply?.lenght < 400 
    ) {
      const errorMessage = this.getFirstError();
      if (errorMessage) {
        this._toastr.error(errorMessage);
        this.addControls();
        return;
      }
      this.addControls();
    }
    else {
      this.isSpinnerShown = true;
      const payload = this.createApiPayload();

      this.__apiCall
        .PostCallWithToken(payload, "Dashboard/SaveScholarship")
        .subscribe((response: any) => {
          this.addControls();
          if (response.responseCode == 200) {
            this._toastr.success(response.responseMessage);
            this.isSpinnerShown = false;
            this.modal.dismissAll();
            this.getScholarships();
          } else {
            this._toastr.error(response.responseMessage);
            this.isSpinnerShown = false;
          }
        })
    }
  }

  private getFirstError(): string {
    debugger
    for (const key of Object.keys(this.addScholarshipForm.controls)) {
      const control = this.addScholarshipForm.get(key);
      if (control && control.invalid) {
        if (this.getFriendlyFieldName(key) != "") {
          console.log("data" , this.addScholarshipForm.value)
          return `${this.getFriendlyFieldName(key)} is invalid.`;
        } else {
          return "";
        }
      }

    }

    if (this.ScholarshipType === "") return "Scholarship Type is required.";
    if (this.Country === "") return "Country is required.";
    if (this.State === "") return "State is required.";
    if (this.City === "") return "City is required.";
    if (this.Duration === "") return "Duration is required.";

    if (this.coursestags?.length < 1) return "At least one Course tag is required.";
    if (this.coverageTags?.length < 1) return "At least one Coverage tag is required.";
    if (this.requiredDocuments?.length < 1) return "At least one Required Document is required.";


    if (this.images.length < 1) return "At least one image is required.";

    // const description = this.addScholarshipForm.get('scholarship_description')?.value;
    // const howToApply = this.addScholarshipForm.get('how_to_apply')?.value;

  


    return "";
  }


  private getFriendlyFieldName(field: string): string {
    debugger
    const fieldNames: { [key: string]: string } = {
      scholarship_name: "Scholarship Name",
      institute_name: "Institute Name",
      scholarship_address: "Scholarship Address",
      scholarship_addressTitle: "Scholarship Address Title",
      scholarship_addressLink: "Scholarship Google Maps Link",
      email_address: "Email Address",
      scholarship_phone: "Scholarship Phone Number",
      application_Link: "Application Link",
      scholarship_fee: "Scholarship Fee",
      start_date: "Start Date",
      end_date: "End Date",
      minimum_cgpa: "Minimum CGPA",
      scholarship_amount: "Scholarship Amount",
      scholarship_description: "Scholarship Description",
      how_to_apply: "How to Apply",
    };


    return fieldNames[field] || "";
  }
  createApiPayload() {
    const payload = {
      id: this.updateScholarshipId,
      scholarshipName: this.addScholarshipForm.get("scholarship_name")?.value,
      instituteName: this.addScholarshipForm.get("institute_name")?.value,
      address: this.addScholarshipForm.get("scholarship_address")?.value,
      locationTitle: this.addScholarshipForm.get("scholarship_addressTitle")?.value,
      locationSrc: this.addScholarshipForm.get("scholarship_addressLink")?.value,
      applicationLink: this.addScholarshipForm.get("application_Link")?.value,
      email: this.addScholarshipForm.get("email_address")?.value,
      contactNumber: this.addScholarshipForm.get("scholarship_phone")?.value,
      startDate: this.addScholarshipForm.get("start_date")?.value,
      endDate: this.addScholarshipForm.get("end_date")?.value,
      minimumCGPA: this.addScholarshipForm.get("minimum_cgpa")?.value,
      amount: this.addScholarshipForm.get("scholarship_amount")?.value,
      applicationFee: this.addScholarshipForm.get("scholarship_fee")?.value,
      description: this.addScholarshipForm.get("scholarship_description")?.value,
      howToApply: this.addScholarshipForm.get("how_to_apply")?.value,

      country: this.Country,
      state: this.State,
      city: this.City,
      duration: this.Duration,
      type: this.ScholarshipType,
      courses: this.coursestags,
      documents: this.requiredDocuments,
      coverages: this.coverageTags,
      images: this.images,
    };

    payload.contactNumber = this._utlis.numberWithCountryCode(this.addScholarshipForm.get("scholarship_phone")?.value, this.intlTelInputInstance);
    return payload;
  }



}
