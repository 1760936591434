import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, OnInit, Output, inject } from "@angular/core";
import { Subject, takeUntil } from "rxjs";
import { agencyAgent } from "src/app/shared/interface/property";
import { ApiCallService } from "src/app/shared/services/api-call.service";
import { AuthService } from "src/app/shared/services/auth.service";
import { CommonUtilsServiceService } from "src/app/shared/services/common-utils-service.service";
import { ErrorHandlingService } from "src/app/shared/services/error-handling.service";
import { PropertyService } from "src/app/shared/services/property.service";

@Component({
  selector: "app-top-teacher",
  templateUrl: "./top-teacher.component.html",
  styleUrl: "./top-teacher.component.scss",
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopTeacherComponent {
  @Input() heading: string = "Latest For Sale";

  public title = "slider_filter_search";
  public noRecordsFound: boolean = false;
  public selectedTeacherType: string = "All";
  public tag: string = "Teachers";
  isErrorOccurred: boolean = false;

  private _utils = inject(CommonUtilsServiceService);
  private readonly _authService = inject(AuthService);

  private _apicall = inject(ApiCallService);
  private readonly errorHandlerService = inject(ErrorHandlingService);
  private destroy$ = new Subject<void>();

  isDataLoading: boolean = true;
  public noRecordFound = false;
  addSectionCss: any = {
    height: '100%',
    width: '100%',
  };
  isAdLoading: boolean = true;

  @Input() agentsData: agencyAgent;
  @Output() filterButtonClick = new EventEmitter<string>();


  public isMobile: boolean = false;
  public mobile: string

  teachersData: any[] = [];

  isDescriptionShown: boolean = false;


  constructor(public propertyService: PropertyService) { }

  ngOnInit(): void {
    console.log('Ad Images:', this.adImages);

    this.mobile = this.agentsData?.mobile?.replace(
      this.agentsData.mobile.slice(-4), '****');

    this.fetchTopTeachers();
    this.checkScreenSize();
    this.getAds();
    setInterval(() => {
      this.isAdLoading = false;
    }, 5000)

    // Convert static ad images to Blob URLs
    this.adImages = this.adImages.map((ad: any) => {
      if (ad.imageUrl && ad.imageUrl.includes('base64,')) {
        const base64Data = ad.imageUrl.split('base64,')[1];
        ad.imageUrl = this._utils.convertBase64ToBlobUrl(base64Data, 'image/jpeg');
      }
      return ad;
    });




  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }



  isScreenSmall: boolean = false;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenSize();
  }

  checkScreenSize() {
    const width = window.innerWidth;
    this.isScreenSmall = width < 1200;

    if (width > 1200) {
      this.addSectionCss.height = '100%';
    } else {
      this.addSectionCss.height = '280px';
    }
  }
  showMobile(data: agencyAgent) {
    this.isMobile = !this.isMobile;
    if (this.isMobile) {
      this.mobile = data.mobile
    } else {
      this.mobile = data.mobile.replace(data.mobile.slice(-4), "****");
    }
  }

  navigateTospecificComponent(route: string, id: number) {
    this._utils.navigateTo(route, { id: id });
  }

  // Method to emit filter event
  onFilterClick(type: string): void {
    // this.filterButtonClick.emit(type);
    this.selectedTeacherType = type;
    this.fetchTopTeachers();
  }

  fetchTopTeachers(): void {
    this.isDataLoading = true;
    this.noRecordsFound = false;
    this.teachersData = [];
    this.isErrorOccurred = false;

    this.propertyService.getTopTeacher(this.selectedTeacherType).subscribe(
      (response) => {
        this.isDataLoading = false;
        this.teachersData = response;
        this.noRecordsFound = !this.teachersData || this.teachersData.length <= 0 || this.teachersData == null;
        // console.log('Teachers Data:', this.teachersData);
        this.teachersData.forEach((teacher) => {
          if (teacher.img && teacher.img.includes(',')) {
            // Attempt to split and validate base64 data
            const base64Data = teacher.img.split(',')[1];
            if (base64Data) {
              // Convert only if base64Data is valid
              teacher.blobUrl = this._utils.convertBase64ToBlobUrl(base64Data, 'image/jpeg');
            } else {
              const fallbackBase64 = this._authService.generateFallbackImage(teacher.title);
              teacher.img = fallbackBase64;
              teacher.blobUrl = teacher.img;
             
            }
          } else {
            const fallbackBase64 = this._authService.generateFallbackImage(teacher.title);
            teacher.img = fallbackBase64;
            teacher.blobUrl = teacher.img;
           
          }
        });
      },
      (error) => {
        this.isDataLoading = false;
        this.isErrorOccurred = true;
      }
    );
  }


  // Helper function to check if a string is valid base64
  private isValidBase64(base64String: string): boolean {
    try {
      // Check if the string is valid by trying to decode it
      return btoa(atob(base64String)) === base64String;
    } catch (e) {
      return false; // Return false if decoding fails
    }
  }

  adImages: any[] = [];
  getAds(): void {
    this.propertyService
      .getAds( "Ad/GetAdvertisements?moduleName=Teacher")
      .subscribe(
        (res) => {
          if (res.responseCode === 200) {
            this.adImages = res.data;

            // Convert image URLs to Blob URLs if they are base64 encoded
            this.adImages = this.adImages.map((ad: any) => {
              if (ad.imageUrl && ad.imageUrl.includes('base64,')) {
                const base64Data = ad.imageUrl.split('base64,')[1];
                ad.imageUrl = this._utils.convertBase64ToBlobUrl(base64Data, 'image/jpeg');
              }
              return ad;
            });

            this.isAdLoading = false;
          } else {
            this.adImages = [];
            this.isAdLoading = false;
            this.errorHandlerService.handleResponseError(res);
          }
        },
        (error) => {
          this.isAdLoading = false;
          this.errorHandlerService.handleHttpError(error);
          this.adImages = [];
        }
      );
  }




  // onFilterButtonClick(type: string): void {
  //   this.selectedTeacherType = type;
  //   this.fetchTopTeachers();
  // }



  // adImages: any[] = [
  //   {
  //     redirectLink: "https://youtube.com",
  //     imageUrl: '../../../../../assets/images/advertisements/admissions/1.jpeg'
  //   },
  //   {
  //     redirectLink: "https://youtube.com",
  //     imageUrl: '../../../../../assets/images/advertisements/admissions/2.jpeg'
  //   },
  //   {
  //     redirectLink: "https://youtube.com",
  //     imageUrl: '../../../../../assets/images/advertisements/admissions/3.jpeg'
  //   },
  //   {
  //     redirectLink: "https://youtube.com",
  //     imageUrl: '../../../../../assets/images/advertisements/admissions/4.jpeg'
  //   },
  // ];

  pauseScroll() {
    const adsSection = document.querySelector(
      ".ad-images-wrapper"
    ) as HTMLElement;
    if (adsSection) {
      adsSection.style.animationPlayState = "paused";
    }
  }

  startScroll() {
    const adsSection = document.querySelector(
      ".ad-images-wrapper"
    ) as HTMLElement;
    if (adsSection) {
      adsSection.style.animationPlayState = "running";
    }
  }


}
