import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TitleStrategy } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { ApiCallService } from 'src/app/shared/services/api-call.service';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';
import { PropertyService } from 'src/app/shared/services/property.service';
import intlTelInput from "intl-tel-input";
import { CommonUtilsServiceService } from 'src/app/shared/services/common-utils-service.service';
@Component({
  selector: 'app-add-institute',
  templateUrl: './add-institute.component.html',
  styleUrl: './add-institute.component.scss',
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddInstituteComponent {
  private intlTelInputInstance: any;
  public themeLogo = 'assets/images/logo/2.png';
  public footerLogo = 'assets/images/logo/footer-logo.png';
  public bgImage = 'assets/images/banner/banner.jpg';
  public title = 'Dashboard';
  public parent = 'Instiutes';
  public child = 'Add Institute';
  addInstituteForm: FormGroup;
  boostInstituteForm: FormGroup;
  institutesData: any;
  gridImages: boolean = false;
  listView: boolean = false;
  thumbnail: boolean = false;
  thumbnail_video: boolean = false;
  tagClass: string;
  total: number;
  public selectedImage: string;
  isDataLoading: boolean = true;
  public isSpinnerShown = false;

  private destroy$ = new Subject<void>();

  public __apiCall = inject(ApiCallService);
  public _toastr = inject(ToastrService);
  public _spinner = inject(NgxSpinnerService)
  public propertyService = inject(PropertyService);
  private readonly errorHandlingService = inject(ErrorHandlingService);
  private readonly _utlis = inject(CommonUtilsServiceService);
  public FormName: string = "Add";

  public AdditionalFeatures = [
    {
      title: "Hostels/Dormitories",
      id: "chk-ani",
      checked: false,
    },
    {
      title: "Laboratories",
      id: "chk-ani1",
      checked: false,
    },
    {
      title: "Scholarships and Financial Aid",
      id: "chk-ani2",
      checked: false,
    },
    {
      title: "Research Centers",
      id: "chk-ani3",
      checked: false,
    },
    {
      title: "Library Resources and Services",
      id: "chk-ani4",
      checked: false,
    },
    {
      title: "Health and Wellness Facilities",
      id: "chk-ani5",
      checked: false,
    }
  ]

  public theme_default3 = '#ff5c41';
  public theme_default4 = '#ff8c41';



  constructor(public modal: NgbModal, private fb: FormBuilder, private cdr: ChangeDetectorRef) {
    this.addInstituteForm = this.fb.group({
      institute_name: ["", Validators.required],
      courses_name: ["", Validators.required],
      classes_name: ["", Validators.required],
      department: ["", Validators.required],
      institute_address: ["", Validators.required],
      institute_addressTitle: ["", Validators.required],
      institute_addressLink: ["", [Validators.required, Validators.pattern('https://maps.google.com/maps.+')]],
      site_link: ["", [Validators.required, Validators.pattern('https?://.+')]],

      email_address: ["", [
        Validators.required,
        Validators.email,
        Validators.pattern(
          "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$"
        ),
      ]],
      phone: ["", [
        Validators.required,
        Validators.pattern("^((\\+91-?)|0)?[0-9]{8,16}$"),
        Validators.minLength(8),
        Validators.maxLength(16)
      ]],
      description: ["", [Validators.required, Validators.minLength(400)]],
      video_link: [""],

    });

    this.boostInstituteForm = this.fb.group({
      title: ["", Validators.required],
      start_date: ["", [Validators.required,]],
      end_date: ["", Validators.required],
      ad_description: ["", [Validators.required, Validators.minLength(200)]],
    });


  }
  ngOnInit() {
    this.getInstitutes();
    this.getCountry();
  }



  loadPhoneScript() {
    setTimeout(() => {
      const inputElement = document.querySelector('#myInput') as HTMLInputElement;
      if (inputElement) {
        this.intlTelInputInstance = intlTelInput(inputElement, {
          initialCountry: 'pk',
          separateDialCode: true
        });
      }
    }, 500)
  }

  ///////////////////////////////////////////////////
  // Ad End Date limiter STARTS HERE 
  minmumDate: any
  getMinDate() {
    const maxDate = this.boostInstituteForm.get('start_date')?.value;
    this.boostInstituteForm.get('end_date')?.reset();
    this.minmumDate = maxDate
  }

  getMaxDate(): string {
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    return formattedDate;
  }

  minEndDate: any;
  updateEndDateMin(): void {
    const startDate = this.boostInstituteForm.get('start_date')?.value;
    this.minEndDate = startDate ? startDate : null;
  }

  // Ad End Date limiter Ends HERE 
  ///////////////////////////////////////////

  adCharCount: number = 0;
  updateAdCharCount(): void {
    let description = this.boostInstituteForm.get("ad_description")?.value;
    this.adCharCount = description?.length || 0;
  }



  isAdActivated: boolean = true;
  boostId: number;
  boostCard(content: TemplateRef<any>, id: number) {
    this.boostId = id;
    this.boostInstituteForm.reset();
    this.imageBase64 = "";
    this.adFiles = [];
    this.modal.open(content, { centered: true, size: 'lg' });
    this.FormName = "Advertisment";
  }

  editAdCard(content: TemplateRef<any>, id: number) {
    this.boostInstituteForm.reset();
    this.imageBase64 = "";
    this.adFiles = [];
    this.modal.open(content, { centered: true, size: 'lg' });
    this.FormName = "EditAdvertisment";
    this.editAd(id);
  }

  ////////////////////////////////////////////
  //////////// code for Ads image /////////////////////
  //////////////////////////////////////////////

  adFiles: File[] = [];
  adImages: { imageBase64: string }[] = [];
  imageBase64: string | null = null;
  adImageSizeError: boolean = false;

  onAdSelect(event: NgxDropzoneChangeEvent) {
    const newFile = event.addedFiles[0];
    if (newFile) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const sizeInKB = Math.round(newFile.size / 1024);
        const isToDel = this.checkImgeSizeValidity(sizeInKB, newFile);
        if (!isToDel) {
          const base64String = e.target.result as string;
          this.adFiles = [newFile];
          this.adImages = [{ imageBase64: base64String }];
          this.imageBase64 = base64String;
        }
      };
      reader.readAsDataURL(newFile);
    }

  }

  onAdRemove(event: File) {
    this.adFiles = [];
    this.adImages = [];
  }




  /////////////////////////////////////////////////////
  //////////// image update case //////////////////////
  //////////////////////////////////////////////////

  onAdFileSelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {

        const sizeInKB = Math.round(file.size / 1024);
        const isToDel = this.checkImgeSizeValidity(sizeInKB, file)
        if (!isToDel) {
          const base64String = reader.result as string;
          this.adFiles = [file];
          this.adImages = [{ imageBase64: base64String }];
          this.imageBase64 = base64String;
        };
      };
    }
  }

  removeAdFile(file: any): void {
    this.adFiles = [];
    this.adImages = [];
    this.imageBase64 = "";
  }


  public submitBoostAd(): void {
    const adsPayload = this.createAdsApiPayload();
    console.log("Payload", adsPayload);
    this.isSpinnerShown = true;
    this.__apiCall.PostCallWithToken(adsPayload, 'Ad/SaveAndUpdateAd')
      .subscribe(
        (response) => {
          if (response.responseCode === 200) {
            this._toastr.success(response.responseMessage);
            this.boostInstituteForm.reset();
            this.modal.dismissAll();
          } else {
            this.errorHandlingService.handleResponseError(response);
          }
          this.isSpinnerShown = false;
          // this.getInstitutes();
        },
        (error) => {
          this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
          this.isSpinnerShown = false;
        }
      );
  }

  createAdsApiPayload() {
    this.updateAdId = this.FormName == "Advertisment" ? 0 : this.updateAdId;
    return {
      id: this.updateAdId,
      moduleId: this.boostId,
      moduleName: "institute",
      title: this.boostInstituteForm.get('title')?.value,
      startDate: this.boostInstituteForm.get('start_date')?.value,
      endDate: this.boostInstituteForm.get('end_date')?.value,
      description: this.boostInstituteForm.get('ad_description')?.value,
      imageUrl: this.imageBase64,
    };
  }

  adDataById: any;
  updateAdId: number = 0;
  editAdModuleId: number;
  public editAd(id: number): void {
    this.editAdModuleId = id;
    const payload = {
      id: this.editAdModuleId,
      moduleName: "Institute",
    }
    this.isSpinnerShown = true;
    this._spinner.show();

    this.__apiCall.PostCallWithToken(payload, "Ad/GetAdById")
      .subscribe(
        (response) => {
          if (response.responseCode === 200) {
            this.adDataById = response.data;
            this.updateAdId = this.adDataById.id;
            this.boostInstituteForm.get('title')?.setValue(this.adDataById?.title);
            this.boostInstituteForm.get("start_date")?.setValue(this.adDataById?.startDate.substring(0, 10));
            this.boostInstituteForm.get("end_date")?.setValue(this.adDataById?.endDate.substring(0, 10));
            this.boostInstituteForm.get("ad_description")?.setValue(this.adDataById?.description);

            this.imageBase64 = this.adDataById?.imageUrl;

            this.updateAdCharCount();

          } else {
            this.errorHandlingService.handleResponseError(response);
            this._toastr.error(response.responseMessage);
          }
          this.isSpinnerShown = false;

        },
        (error) => {
          this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
          this.isSpinnerShown = false;
        }
      );
  }


  private async getInstitutes(): Promise<void> {
    this.isDataLoading = true;
    try {
      const response = await this.__apiCall.GetCallWithToken("Dashboard/GetInstitutes").pipe()
        .toPromise();
      if (response.responseCode == 200) {
        this.institutesData = response.data;
      } else {
        this.errorHandlingService.handleResponseError(response);
        this.institutesData = [];
      }
    } catch (error) {
      this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
    } finally {
      this.isSpinnerShown = false;
      this._spinner.hide();
      this.isDataLoading = false;
      this.cdr.detectChanges();
    }
  }


  ngOnDestroy(): void {

    this.destroy$.next();
    this.destroy$.complete();
  }


  chehckedFeatures: { featureName: string }[] = [];

  saveCheckedValues() {
    this.chehckedFeatures = this.AdditionalFeatures
      .filter(feature => feature.checked)
      .map(feature => ({ featureName: feature.title }));
  }

  charCount: number = 0;

  updateCharCount(): void {
    let description = this.addInstituteForm.get("description")?.value;
    this.charCount = description?.length || 0;
  }

  updateCheckedValues(feature: any) {
    feature.checked = !feature.checked;
  }

  delId: number;
  deleteCard(content: TemplateRef<any>, id: number) {
    this.modal.open(content, { centered: true, size: 'sm' });
    this.delId = id;
  }

  addCard(content: TemplateRef<any>) {
    this.addInstituteForm.reset();
    this.AdditionalFeatures.forEach(fea => {
      fea.checked = false;
    });
    this.showDepartment = false;
    this.showClasses = false;
    this.updateInstituteId = 0;
    this.departmentTags = [];
    this.coursestags = [];
    this.classesTags = [];
    this.images = [];
    this.files = [];
    this.InstitueType = "";
    this.City = "";
    this.Country = "";
    this.State = "";
    this.updateCharCount();
    this.FormName = "Add";
    this.instituteTypeError = false;
    this.countryError = false;
    this.stateError = false;
    this.cityError = false;
    this.imageError = false;
    this.modal.open(content, { centered: true, size: 'lg' });
    this.loadPhoneScript();

  }


  editCard(content: TemplateRef<any>, id: number) {
    this.addInstituteForm.reset();
    this.showDepartment = false;
    this.showClasses = false;
    this.Country = "";
    this.State = "";
    this.InstitueType = "";
    this.City = "";
    this.images = [];
    this.files = [];
    this.coursestags = [];
    this.departmentTags = [];
    this.classesTags = [];
    this.updateCharCount();
    this.instituteTypeError = false;
    this.countryError = false;
    this.stateError = false;
    this.cityError = false;
    this.imageError = false;
    this.AdditionalFeatures.forEach(fea => {
      fea.checked = false;
    });
    this.modal.open(content, { centered: true, size: 'lg' });
    this.FormName = "Edit";

    this.editInstitute(id);
    this.loadPhoneScript();
  }



  public Options = {
    items: 1,
    loop: true,
    nav: false,
    dots: true,
    autoplay: true,
    autoplayTimeout: 2000,
    navText: [
      "<i class='fa fa-chevron-left'></i>",
      "<i class='fa fa-chevron-right'></i>",
    ],
  };

  public thumbnailCarouselOptions = {
    items: 4,
    margin: 10,
    center: true,
    loop: true,
    nav: false,
    dots: false,
  };

  changeImage(image: string) {
    this.selectedImage = image;
  }

  //edit form logistics ///
  instituteByIdData: any;

  updateInstituteId: number = 0;

  public async editInstitute(id: number): Promise<void> {
    this.updateInstituteId = id;
    this.isSpinnerShown = true;
    this._spinner.show();
    try {
      const respone = await this.__apiCall.PostCallWithToken(null, `Dashboard/GetInstituteById?InstituteId=${id}`).pipe(takeUntil(this.destroy$))
        .toPromise();
      if (respone.responseCode == 200) {
        this.instituteByIdData = respone.data;

        this.addInstituteForm.get('institute_name')?.setValue(this.instituteByIdData?.instituteName);
        this.addInstituteForm.get("description")?.setValue(this.instituteByIdData?.description);
        this.addInstituteForm.get("phone")?.setValue(this.instituteByIdData?.contactNumber?.substring(3));
        this.addInstituteForm.get("site_link")?.setValue(this.instituteByIdData?.websiteLink);
        this.addInstituteForm.get("email_address")?.setValue(this.instituteByIdData?.email);
        this.addInstituteForm.get("institute_address")?.setValue(this.instituteByIdData?.address);
        this.addInstituteForm.get("institute_addressTitle")?.setValue(this.instituteByIdData?.locationTitle);
        this.addInstituteForm.get("institute_addressLink")?.setValue(this.instituteByIdData?.locationSrc);
        this.addInstituteForm.get("video_link")?.setValue(this.instituteByIdData?.videoLink);
        this.InstitueType = this.instituteByIdData?.instituteType;
        this.Country = this.instituteByIdData?.country;
        this.State = this.instituteByIdData?.state;
        this.City = this.instituteByIdData?.city;
        this.images = this.instituteByIdData.images;
        this.chehckedFeatures = this.instituteByIdData.additionalFeatures;
        this.coursestags = this.instituteByIdData?.courses;
        this.classesTags = this.instituteByIdData?.classes;
        this.departmentTags = this.instituteByIdData?.departments;


        this.AdditionalFeatures.forEach(feature => {
          this.chehckedFeatures.forEach((checkedField: any) => {
            if (feature.title === checkedField.featureName) {
              feature.checked = true;
            }
          });
        });
        //this line for updating phone number country code
        this.intlTelInputInstance.setNumber(this.instituteByIdData?.contactNumber);

        if (this.InstitueType == "University") {
          this.showDepartment = true;
          this.showClasses = false;
        } else {
          this.showClasses = true;
          this.showDepartment = false;
        }
        this.updateCharCount();
        this.isSpinnerShown = false;
        this.isSpinnerShown = false;
        this.removeUmwantedControl();
        // this.getInstitutes();
      }
      else {
        this.errorHandlingService.handleResponseError(respone);
        this._toastr.error(respone.responseMessage);
        this.isSpinnerShown = false;
      }

    } catch (error) {
      this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
    } finally {
      this.isSpinnerShown = false;
      this._spinner.hide();
      this.cdr.detectChanges();
      this.addInstituteForm.updateValueAndValidity();

    }

  }



  public async deleteInstitute(): Promise<void> {
    this.isSpinnerShown = true;
    this._spinner.show();
    try {
      const res = await this.__apiCall.PostCallWithToken(null, `Dashboard/DeleteInstitute?id=${this.delId}`).pipe(takeUntil(this.destroy$))
        .toPromise();
      // .subscribe((res) => {
      if (res.responseCode == 200) {
        this._toastr.success(res.responseMessage);
        this.getInstitutes();
        this.modal.dismissAll();
        this.isSpinnerShown = false;
        this._spinner.hide();
      } else {
        this.errorHandlingService.handleResponseError(res);
        this.modal.dismissAll();
      }
    } catch (error) {
      this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
    } finally {
      this.isSpinnerShown = false;
      this._spinner.hide();
      this.cdr.detectChanges();
    }
    // })
  }




  files: File[] = [];
  images: { imageBase64: string }[] = [];

  onSelect(event: NgxDropzoneChangeEvent) {
    const newFiles = event.addedFiles;
    this.files.push(...newFiles);
    // console.log("files", this.files)

    newFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = (e: any) => {

        const sizeInKB = Math.round(file.size / 1024);
        const istoDel = this.checkImgeSizeValidity(sizeInKB, file)
        if (!istoDel) {
          const base64String = e.target.result as string;
          this.images.push({ imageBase64: base64String });
        }
      };
      reader.readAsDataURL(file);
    });

  }

  onRemove(event: File) {
    const index = this.files.indexOf(event);
    if (index !== -1) {
      this.files.splice(index, 1);
      this.images.splice(index, 1);
    }
  }
  imageSizeError: boolean = false
  checkImgeSizeValidity(size: number, file: any) {
    if (size > 1024) {
      this.imageSizeError = true;
      this.onRemove(file)
      return true;

    }
    else {
      this.imageSizeError = false;
      return false;
    }
  }


  /////////////////////////////////////////////////////
  //////////// image update case //////////////////////
  //////////////////////////////////////////////////



  ////////////////////////////////////////////
  //////////// code for image /////////////////////
  //////////////////////////////////////////////


  onFileSelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {

        const sizeInKB = Math.round(file.size / 1024);
        const isToDel = this.checkImgeSizeValidity(sizeInKB, file)
        if (!isToDel) {
          const base64String = reader.result as string;
          this.images.push({ imageBase64: base64String });
        };
      };
    }
  }

  removeFile(file: any): void {

    this.images = this.images.filter((t: any) => t !== file);

  }



  ////////////////////////////////////////////
  //////////// code for image /////////////////////
  //////////////////////////////////////////////



  InstitueTypeList = [
    { id: 1, value: "University" },
    { id: 2, value: "College" },
    { id: 3, value: "School" },
    { id: 4, value: "Academy" }
  ];
  InstitueType: string = "";
  showDepartment: boolean = false;
  showClasses: boolean = false;
  onInstitueTypeSelected(option: any) {
    this.InstitueType = option.value;
    this.addControls();
    if (option.value == "University") {
      this.showDepartment = true;
      this.showClasses = false;
    } else {
      this.showClasses = true;
      this.showDepartment = false;
    }
  }



  ////////////////////////////////////////////////////
  /////////  Country drop down  //////////
  ///////////////////////////////////////////////////// 
  CountrysearchTerm: string = "";
  Country: string = "";
  State: string = '';
  City: string = '';
  CountryList = []
  SearchTermCountry(searchTerm: string) {
    this.CountrysearchTerm = searchTerm;
    this.Country = searchTerm;
  }
  getCountry() {
    this.__apiCall
      .GetCallWithoutToken('DropDown/GetCountryDropDown')
      .subscribe((response: any) => {
        if (response.responseCode === 200) {
          this.CountryList = response?.data;
        }
      })

  }

  onCountrySelected(option: any) {
    if (this.Country != option.value) {
      this.State = '';
      this.City = '';
    }
    this.Country = option.value;
    this.getStateList(option.id);

  }
  onCountryValueChange(searchValue: any) {
    this.Country = searchValue
    if (this.Country === '') {
      this.State = '';
      this.City = ''
    }
  }



  ////////////////////////////////////////////////////
  /////////  State drop down data //////////
  ////////////////////////////////////////////////////

  StatesearchTerm: string = "";

  StateList = []
  SearchTermState(searchTerm: string) {
    this.StatesearchTerm = searchTerm;
    this.State = searchTerm;
  }

  onStateSelected(option: any) {
    if (this.State !== option.value) {
      this.City = '';
    }
    this.State = option.value;
    this.getCityList(option.id);

  }
  onStateValueChange(searchValue: any) {
    debugger
    this.State = searchValue
    if (this.State === '') {
      this.City = ''
    }
  }

  onCountryFocused(isTouched: boolean) {
    if (isTouched) {
      if (this.CountrysearchTerm === "" && this.Country === "") {
        this.countryError = true
      }
    }
  }

  getStateList(countryId: number) {
    this.__apiCall
      .GetCallWithoutToken('DropDown/GetStateDropDown?CountryId=' + countryId)
      .subscribe((response: any) => {
        if (response.responseCode === 200) {
          this.StateList = response?.data;
        }
      });
  }


  ////////////////////////////////////////////////////
  /////////  City drop down data //////////
  ////////////////////////////////////////////////////


  CityList = []
  onCitySelected(option: any): void {
    this.City = option.value;
  }

  onStateFocused(isTouched: boolean) {
    if (isTouched) {
      if (this.StatesearchTerm === "" && this.State === "") {
        this.stateError = true;
      }
    }

  }

  getCityList(stateId: number) {
    this.__apiCall.GetCallWithoutToken('DropDown/GetCityDropDown?StateId=' + stateId).subscribe(
      (response: any) => {
        if (response.responseCode === 200) {
          this.CityList = response?.data;
        }
      }
    )
  }




  ////////////////////////////////////////////
  // code for showig errors
  //////////////////////////////////////////////

  instituteTypeError: boolean = false;
  countryError: boolean = false;
  stateError: boolean = false;
  cityError: boolean = false;
  imageError: boolean = false;
  departmentTagError: boolean = false;
  coursesTagError: boolean = false;
  classesTagError: boolean = false;

  ShowError() {
    this.instituteTypeError = this.InstitueType == "" ? true : false;
    this.countryError = this.Country == "" ? true : false;
    this.stateError = this.State == "" ? true : false;
    this.cityError = this.City == "" ? true : false;
    this.imageError = this.images.length < 1 ? true : false;
    this.departmentTagError = this.departmentTags.length < 1 ? true : false;
    this.coursesTagError = this.coursestags.length < 1 ? true : false;
    this.classesTagError = this.classesTags.length < 1 ? true : false;
  }



  ///////////////////////////////////////////////////////////
  // Function to add COURSE tag //////////////////////////////
  ////////////////////////////////////////////////////////////


  coursestags: any[] = [];

  addCourseTag(): void {

    const tagInput = this.addInstituteForm.get("courses_name")?.value.trim();

    if (
      tagInput &&
      !this.coursestags.some((tag) => tag.courseName === tagInput)
    ) {
      const newTag = { courseName: tagInput };
      this.coursestags.push(newTag);
      this.addInstituteForm.get("courses_name")?.setValue("");
      this.coursesTagError = this.coursestags.length < 1;
    }
  }

  removeCourseTag(tag: any): void {
    this.coursestags = this.coursestags.filter((t) => t !== tag);
    this.coursesTagError = this.coursestags.length < 1;
  }

  onEnterKeyPress(event: Event): void {
    if (event instanceof KeyboardEvent && event.key === "Enter") {
      event.preventDefault();
      this.addCourseTag();
    }
  }


  ///////////////////////////////////////////////////////////
  // Function to add COURSE tag //////////////////////////////
  ////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////
  // Function to add COURSE tag ////////////////////////////
  ////////////////////////////////////////////////////////////


  classesTags: any[] = [];

  addClassTags(): void {
    const tagInput = this.addInstituteForm.get("classes_name")?.value.trim();

    if (
      tagInput &&
      !this.classesTags.some((tag) => tag.classTitle === tagInput)
    ) {
      const newTag = { classTitle: tagInput };
      this.classesTags.push(newTag);
      this.addInstituteForm.get("classes_name")?.setValue("");
      this.classesTagError = this.classesTags.length < 1;
    }
  }

  removeClassTags(tag: any): void {
    this.classesTags = this.classesTags.filter((t) => t !== tag);
    this.classesTagError = this.classesTags.length < 1;
  }

  onEnterKey(event: Event): void {
    if (event instanceof KeyboardEvent && event.key === "Enter") {
      event.preventDefault();
      this.addClassTags();
    }
  }

  ///////////////////////////////////////////////////////////
  // Function to add COURSE tag ////////////////////////////
  ////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////
  // Function to add Department tag ///////////////////////
  //////////////////////////////////////////////////////////////


  departmentTags: any[] = [];

  adddepartmentTags(): void {
    const tagInput = this.addInstituteForm.get("department")?.value.trim();

    if (tagInput && !this.departmentTags.some((tag) => tag.departmentName === tagInput)) {
      const newTag = { departmentName: tagInput };
      this.departmentTags.push(newTag);
      this.addInstituteForm.get("department")?.setValue("");
      this.departmentTagError = this.departmentTags.length < 1;
    }
  }

  removeDepartmentTags(tag: any): void {
    this.departmentTags = this.departmentTags.filter((t) => t !== tag);
    this.departmentTagError = this.departmentTags.length < 1;
  }

  onEnterDepartment(event: Event): void {
    if (event instanceof KeyboardEvent && event.key === "Enter") {
      event.preventDefault();
      this.adddepartmentTags();
    }
  }


  ///////////////////////////////////////////////////////////
  // Function to add Department tag ///////////////////////
  //////////////////////////////////////////////////////////////


  checkTagsValidity(): boolean {
    if (this.InstitueType === "University") {
      if (this.departmentTags.length < 1 || this.coursestags.length < 1) {
        return true;
      }
    }
    else if (this.InstitueType !== "University") {
      if (this.classesTags.length < 1) {
        return true;
      }
    }
    return false;
  }


  ////////////////////////////////////////////
  // function to clear and add Validations 
  //////////////////////////////////////////////


  removeUmwantedControl() {

    this.addInstituteForm.removeControl('classes_name');
    this.addInstituteForm.removeControl('courses_name');
    this.addInstituteForm.removeControl('department');
    this.addInstituteForm.removeControl('video_link');
    this.addInstituteForm.updateValueAndValidity();
  }
  addControls() {

    if (this.InstitueType === "University") {
      if (!this.addInstituteForm.contains('courses_name')) {
        this.addInstituteForm.addControl('courses_name', new FormControl("", Validators.required));
      }
      if (!this.addInstituteForm.contains('department')) {
        this.addInstituteForm.addControl('department', new FormControl("", Validators.required));
      }
    } else {
      if (!this.addInstituteForm.contains('classes_name')) {
        this.addInstituteForm.addControl('classes_name', new FormControl("", Validators.required));
      }
    }

    if (!this.addInstituteForm.contains('video_link')) {
      this.addInstituteForm.addControl('video_link', new FormControl(""));
    }

    this.addInstituteForm.updateValueAndValidity();
  }


  ////////////////////////////////////////////
  // function to clear and add Validations 
  //////////////////////////////////////////////




  ////////////////////////////////////
  // API CALL AND VALIDATIONS CHECK
  ////////////////////////////////////


  vediolink: any;
  public async onSubmit(): Promise<void> {
    this.addInstituteForm.markAllAsTouched();
    const tagsValidity = this.checkTagsValidity();
    this.ShowError();
    this.removeUmwantedControl();
    if (
      this.Country === "" ||
      this.InstitueType === "" ||
      this.City === "" ||
      this.State === "" ||
      this.images.length < 1 ||
      tagsValidity ||
      this.addInstituteForm.invalid
    ) {
      const errorMessage = this.getFirstError();
      if (errorMessage) {
        this._toastr.error(errorMessage);
        this.addControls();
        return;
      }
      this.addControls();
    }
    else {
      this.isSpinnerShown = true;
      this._spinner.show();
      try {
        this.saveCheckedValues();
        const payload = this.createApiPayload();
        const response = await this.__apiCall
          .PostCallWithToken(payload, "Dashboard/SaveInstitute").pipe()
          .toPromise();
        // .subscribe((response: any) => {
        this.addControls();
        if (response.responseCode == 200) {
          this.isSpinnerShown = false;
          this._spinner.hide();
          this._toastr.success(response.responseMessage);
          this.modal.dismissAll();
          this.getInstitutes();
        } else {
          this.errorHandlingService.handleResponseError(response);

        }
      } catch (error) {
        this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
      } finally {
        this.isSpinnerShown = false;
        this._spinner.hide();
        this.cdr.detectChanges();
      }
    }

  }


  private getFirstError(): string {
    for (const key of Object.keys(this.addInstituteForm.controls)) {
      const control = this.addInstituteForm.get(key);
      if (control && control.invalid) {
        if (this.getFriendlyFieldName(key) != "") {
          return `${this.getFriendlyFieldName(key)} is invalid.`;
        } else {
          return "";
        }
      }
    }

    if (this.InstitueType === "") return "Institute Type is required.";
    if (this.Country === "") return "Country is required.";
    if (this.State === "") return "State is required.";
    if (this.City === "") return "City is required.";
    if (this.InstitueType === "University") {
      if (this.departmentTags?.length < 1) return "At least one Department tag is required";
      if (this.coursestags?.length < 1) return "At least one Course tag is required";
    } else {
      if (this.classesTags?.length < 1) return "At least one Class tag is required";
    }
    if (this.images.length < 1) return "At least one image is required.";

    return "";
  }

  private getFriendlyFieldName(field: string): string {
    const fieldNames: { [key: string]: string } = {
      institute_name: "Institute Name",
      institute_address: "Institute Address",
      institute_addressTitle: "Institute Address Title",
      institute_addressLink: "Institute Google Maps Link",
      site_link: "Site Link",
      email_address: "Email Address",
      phone: "Phone Number",
      description: "Description",

    };

    return fieldNames[field] || "";
  }


  ///////////////////////////////////////
  ///////   function for API PAYLOAD ////
  /////////////////////////////////////


  createApiPayload() {
    const payload: any = {
      id: this.updateInstituteId,
      videoLink: this.addInstituteForm.get("video_link")?.value || "",
      instituteName: this.addInstituteForm.get("institute_name")?.value,
      description: this.addInstituteForm.get("description")?.value,
      instituteType: this.InstitueType,
      contactNumber: this.addInstituteForm.get("phone")?.value,
      websiteLink: this.addInstituteForm.get("site_link")?.value,
      email: this.addInstituteForm.get("email_address")?.value,
      country: this.Country,
      state: this.State,
      address: this.addInstituteForm.get("institute_address")?.value,
      locationTitle: this.addInstituteForm.get("institute_addressTitle")?.value,
      locationSrc: this.addInstituteForm.get("institute_addressLink")?.value,
      city: this.City,
      images: this.images,
      additionalFeatures: this.chehckedFeatures
    }


    if (this.InstitueType == "University") {
      payload.courses = this.coursestags;
      payload.departments = this.departmentTags;
      const classControl = this.addInstituteForm.get('classes_name');
      classControl?.clearValidators();
    }
    else {
      payload.classes = this.classesTags;
      const courseControl = this.addInstituteForm.get('courses_name');
      const departmentControl = this.addInstituteForm.get('department');
      courseControl?.clearValidators();
      departmentControl?.clearValidators();
    }

    payload.contactNumber = this._utlis.numberWithCountryCode(this.addInstituteForm.get("phone")?.value, this.intlTelInputInstance);

    return payload;

  }

  ///////////////////////////////////////
  ///////   function for API PAYLOAD ////
  /////////////////////////////////////


}
