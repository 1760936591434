import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, inject, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl, FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ApiCallService } from 'src/app/shared/services/api-call.service';
import { DataShareService } from 'src/app/shared/services/data-share.service';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';
import { PropertyService } from 'src/app/shared/services/property.service';
import intlTelInput from "intl-tel-input";
import { CommonUtilsServiceService } from 'src/app/shared/services/common-utils-service.service';
@Component({
  selector: 'app-job-form',
  templateUrl: './job-form.component.html',
  styleUrl: './job-form.component.scss'
})
export class JobFormComponent {
  private intlTelInputInstance: any;
  @ViewChild('addJob') addJob!: TemplateRef<any>;
  jobsData: any;
  public isSpinnerShown = false;
  addJobForm: FormGroup;
  jobDescriptionCharCount: number = 0;
  howToApplyCharCount: number = 0;
  responsibilitiesCharCount: number = 0;
  additionalNoteCharCount: number = 0;
  private popUP$ = new Subscription;


  public readonly __apiCall = inject(ApiCallService);
  public readonly _toastr = inject(ToastrService);
  private readonly errorHandlingService = inject(ErrorHandlingService);
  private readonly _utlis = inject(CommonUtilsServiceService);



  constructor(public modal: NgbModal, private fb: FormBuilder, private cdr: ChangeDetectorRef, private popUp: DataShareService) {
    this.addJobForm = this.fb.group({
      job_title: ["", Validators.required],
      company_name: ["", Validators.required],
      salary: ["", [Validators.required]],
      job_address: ["", Validators.required],
      job_addressTitle: ["", Validators.required],
      job_addressLink: ["", [Validators.required, Validators.pattern('https://maps.google.com/maps.+')]],
      job_siteLink: ["", [Validators.required, Validators.pattern('https://.+')]],
      job_phone: ["", [Validators.required,
        Validators.pattern("^((\\+91-?)|0)?[0-9]{8,16}$"),
        Validators.minLength(8),
        Validators.maxLength(16)]],
      education: ["", Validators.required],
      experience: ["", Validators.required],
      skills: ["", Validators.required],
      application_deadline: ["", [Validators.required]],
      job_description: ["", [Validators.required]],
      how_to_apply: ["", [Validators.required]],
      responsibilities: [""],
      additional_note: [""],

    });



    this.popUP$ = this.popUp.currentPopup$.subscribe((popUpName) => {
      if (popUpName == 'job') {
        if (this.addJob) {
          this.modal.open(this.addJob, { centered: true, size: 'xl' });
          this.loadPhoneScript();
        } else {
          setTimeout(() => {
            if (this.addJob) {
              this.modal.open(this.addJob, { centered: true, size: 'xl' });
              this.loadPhoneScript();
            }
          }, 100);
        }
        this.addJobForm.reset();
        this.Country = "";
        this.State = "";
        this.City = "";
        this.jobType = "";
        this.skillsTags = [];
        this.educationTags = [];
        this.experienceTags = [];
        this.jobTypeError = false;
        this.countryTypeError = false;
        this.stateError = false;
        this.cityError = false;
        this.imageError = false;

        this.updateAdditionalNoteCharCount();
        this.updateHowToApplyCharCount();
        this.updateJobDescriptionCharCount();
        this.updateResponsibilitiesCharCount();
      }
    })
  }


  ngOnInit() {
    this.getCountry();
  }





  ngOnDestroy(): void {
    if (this.popUP$) {
      this.popUP$.unsubscribe();
    }
  }

  loadPhoneScript() {
    setTimeout(() => {
      const inputElement = document.querySelector('#myInput') as HTMLInputElement;
      if (inputElement) {
        this.intlTelInputInstance = intlTelInput(inputElement, {
          initialCountry: 'pk',
          separateDialCode: true
        });
      }
    }, 500);
  }



  files: File[] = [];
  images: { imageBase64: string }[] = [];

  onSelect(event: NgxDropzoneChangeEvent) {
    const newFiles = event.addedFiles;
    this.files.push(...newFiles);


    newFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = (e: any) => {

        const sizeInKB = Math.round(file.size / 1024);
        const istoDel = this.checkImgeSizeValidity(sizeInKB, file)
        if (!istoDel) {
          const base64String = e.target.result as string;
          this.images.push({ imageBase64: base64String });
        }
      };
      reader.readAsDataURL(file);
    });

  }

  onRemove(event: File) {
    const index = this.files.indexOf(event);
    if (index !== -1) {
      this.files.splice(index, 1);
      this.images.splice(index, 1);
    }
  }
  imageSizeError: boolean = false
  checkImgeSizeValidity(size: number, file: any) {
    if (size > 1024) {
      this.imageSizeError = true;
      this.onRemove(file)
      return true;

    }
    else {
      this.imageSizeError = false;
      return false;
    }
  }

  ////////////////////////////////////////////
  //////////// code for image /////////////////////
  //////////////////////////////////////////////



  ////////////////////////////////////////////
  // code for Custom Searchable DropDown for Job 
  //////////////////////////////////////////////
  // jobTypeSearchTerm: string = '';
  JobTypeList = [
    { id: 1, value: "Full-Time" },
    { id: 2, value: "Part-Time" },
    { id: 3, value: "Contract" },
    { id: 4, value: "Internship" },
    { id: 5, value: "Remote" },
  ];
  jobType: string = "";

  onJobTypeSelected(option: any) {
    this.jobType = option.value;
  }


  ////////////////////////////////////////////////////
  /////////  Country drop down  //////////
  ///////////////////////////////////////////////////// 
  CountrysearchTerm: string = "";
  Country: string = "";
  State: string = '';
  City: string = '';
  CountryList = []
  SearchTermCountry(searchTerm: string) {
    this.CountrysearchTerm = searchTerm;
    this.Country = searchTerm;
  }
  getCountry() {
    this.__apiCall
      .GetCallWithoutToken('DropDown/GetCountryDropDown')
      .subscribe((response: any) => {
        if (response.responseCode === 200) {
          this.CountryList = response?.data;
        }
      })

  }
  jobTypeError: boolean = false;
  countryTypeError: boolean = false;
  stateError: boolean = false;
  cityError: boolean = false;
  imageError: boolean = false;
  ShowError() {
    this.jobTypeError = this.jobType == "" ? true : false;
    this.countryTypeError = this.Country == "" ? true : false;
    this.stateError = this.State == "" ? true : false;
    this.cityError = this.City == "" ? true : false;
    this.imageError = this.images.length < 1 ? true : false
  }

  onCountrySelected(option: any) {
    if (this.Country != option.value) {
      this.State = '';
      this.City = '';
    }
    this.Country = option.value;
    this.getStateList(option.id);

  }
  onCountryValueChange(searchValue: any) {
    this.Country = searchValue
    if (this.Country === '') {
      this.State = '';
      this.City = ''
    }
  }

  ////////////////////////////////////////////////////
  /////////  State drop down data //////////
  ////////////////////////////////////////////////////

  StatesearchTerm: string = "";

  StateList = []
  SearchTermState(searchTerm: string) {
    this.StatesearchTerm = searchTerm;
    this.State = searchTerm;
  }

  onStateSelected(option: any) {
    if (this.State !== option.value) {
      this.City = '';
    }
    this.State = option.value;
    this.getCityList(option.id);

  }
  onStateValueChange(searchValue: any) {
    this.State = searchValue
    if (this.State === '') {
      this.City = ''
    }
  }

  getStateList(countryId: number) {
    this.__apiCall
      .GetCallWithoutToken('DropDown/GetStateDropDown?CountryId=' + countryId)
      .subscribe((response: any) => {
        if (response.responseCode === 200) {
          this.StateList = response?.data;
        }
      });
  }


  ////////////////////////////////////////////////////
  /////////  City drop down data //////////
  ////////////////////////////////////////////////////


  CityList = []
  onCitySelected(option: any): void {
    this.City = option.value;
  }

  getCityList(stateId: number) {
    this.__apiCall.GetCallWithoutToken('DropDown/GetCityDropDown?StateId=' + stateId).subscribe(
      (response: any) => {
        if (response.responseCode === 200) {
          this.CityList = response?.data;
        }
      }
    )
  }


  //////////////////// Education Tags\\\\\\\\\\\\\\\\\
  educationTags: any[] = [];

  addEducationTag(): void {
    const tagInput = this.addJobForm.get("education")?.value.trim();
    if (tagInput && !this.educationTags.some(tag => tag.educationTitle === tagInput)) {
      const newTag = { educationTitle: tagInput };
      this.educationTags.push(newTag);
      this.addJobForm.get("education")?.setValue("");
    }
  }

  removeEducationTag(tag: any): void {
    this.educationTags = this.educationTags.filter(t => t !== tag);
  }

  onEnterEducation(event: Event): void {
    if (event instanceof KeyboardEvent && event.key === "Enter") {
      event.preventDefault();
      this.addEducationTag();
    }
  }

  ///////////// Experience Tags\\\\\\\\\\\\\\\\
  experienceTags: any[] = [];

  addExperienceTag(): void {
    const tagInput = this.addJobForm.get("experience")?.value.trim();
    if (tagInput && !this.experienceTags.some(tag => tag.experienceTitle === tagInput)) {
      const newTag = { experienceTitle: tagInput };
      this.experienceTags.push(newTag);
      this.addJobForm.get("experience")?.setValue("");
    }
  }

  removeExperienceTag(tag: any): void {
    this.experienceTags = this.experienceTags.filter(t => t !== tag);
  }

  onEnterExperience(event: Event): void {
    if (event instanceof KeyboardEvent && event.key === "Enter") {
      event.preventDefault();
      this.addExperienceTag();
    }
  }

  //////////// Skill Tags ////////////////
  skillsTags: any[] = [];

  addSkillTag(): void {
    const tagInput = this.addJobForm.get("skills")?.value.trim();
    if (tagInput && !this.skillsTags.some(tag => tag.skillTitle === tagInput)) {
      const newTag = { skillTitle: tagInput };
      this.skillsTags.push(newTag);
      this.addJobForm.get("skills")?.setValue("");
    }
  }

  removeSkillTag(tag: any): void {
    this.skillsTags = this.skillsTags.filter(t => t !== tag);
  }

  onEnterSkills(event: Event): void {
    if (event instanceof KeyboardEvent && event.key === "Enter") {
      event.preventDefault();
      this.addSkillTag();
    }
  }


  futureDateValidator(control: AbstractControl): { [key: string]: any } | null {
    const selectedDate = new Date(control.value);
    const currentDate = new Date();
    return selectedDate <= currentDate ? { invalidDate: true } : null;
  }

  // Method for word count validation
  minWordsValidator(minWords: number) {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value || '';
      const wordCount = value.trim().split(/\s+/).length;
      return wordCount < minWords ? { minWords: true } : null;
    };
  }


  // Method to update job description char count
  updateJobDescriptionCharCount(): void {
    const jobDescription = this.addJobForm.get("job_description")?.value;
    this.jobDescriptionCharCount = jobDescription?.length || 0;
  }

  // Method to update how to apply char count
  updateHowToApplyCharCount(): void {
    const howToApply = this.addJobForm.get("how_to_apply")?.value;
    this.howToApplyCharCount = howToApply?.length || 0;
  }

  // Method to update responsibilities char count
  updateResponsibilitiesCharCount(): void {
    const responsibilities = this.addJobForm.get("responsibilities")?.value;
    this.responsibilitiesCharCount = responsibilities?.length || 0;
  }

  // Method to update additional note char count
  updateAdditionalNoteCharCount(): void {
    const additionalNote = this.addJobForm.get("additional_note")?.value;
    this.additionalNoteCharCount = additionalNote?.length || 0;
  }
  checkJobFieldsValidity(): boolean {
    if (this.educationTags.length < 1 || this.experienceTags.length < 1 || this.skillsTags.length < 1) {
      return true;
    }
    return false;
  }

  removeUmwantedControl() {

    this.addJobForm.removeControl('education');
    this.addJobForm.removeControl('experience');
    this.addJobForm.removeControl('skills');
    this.addJobForm.updateValueAndValidity();
  }



  addControls() {
    // Adding tag controls unconditionally
    if (!this.addJobForm.contains('education')) {
      this.addJobForm.addControl('education', new FormControl("", Validators.required));
    }

    if (!this.addJobForm.contains('experience')) {
      this.addJobForm.addControl('experience', new FormControl("", Validators.required));
    }

    if (!this.addJobForm.contains('skills')) {
      this.addJobForm.addControl('skills', new FormControl("", Validators.required));
    }

    // Update the form's validity
    this.addJobForm.updateValueAndValidity();
  }



  public async onSubmit(): Promise<void> {

    this.addJobForm.markAllAsTouched();
    const tagsValidity = this.checkJobFieldsValidity();

    this.ShowError();
    this.removeUmwantedControl();
    const description = this.addJobForm.get('job_description')?.value;
    const applyDescription = this.addJobForm.get('how_to_apply')?.value;
    console.log("data from job form ", this.addJobForm.value)



    if (
      this.Country === "" ||
      this.State === "" ||
      this.City === "" ||
      this.jobType === "" ||
      this.images.length < 1 ||
      tagsValidity ||
      description?.length < 400 ||
      applyDescription?.length < 400 ||
      this.addJobForm.invalid
    ) {
      const errorMessage = this.getFirstError();
      if (errorMessage) {
        this._toastr.error(errorMessage);
        this.addControls();
        return;
      }
      this.addControls();
    }
    else {
      this.isSpinnerShown = true;
      try {
        const payload = this.createApiPayload();
        this.__apiCall
          .PostCallWithToken(payload, "Dashboard/SaveJob")
          .subscribe((response) => {
            this.addControls();
            if (response.responseCode == 200) {
              this.isSpinnerShown = false;
              this._toastr.success(response.responseMessage);
              this.modal.dismissAll();
            } else {
              this.errorHandlingService.handleResponseError(response);
            }
          });

      } catch (error) {
        this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
      } finally {
        this.isSpinnerShown = false;
      }
    }
  }

  private getFirstError(): string {


    // Check for invalid form controls
    for (const key of Object.keys(this.addJobForm.controls)) {
      const control = this.addJobForm.get(key);
      if (control && control.invalid) {
        return `${this.getFriendlyFieldName(key)} is invalid.`;
      }
    }

    // Check specific fields in priority order
    if (this.Country === "") return "Country is required.";
    if (this.jobType === "") return "Job Type is required.";
    if (this.City === "") return "City is required.";
    if (this.State === "") return "State is required.";
    if (this.images.length < 1) return "At least one image is required.";
    if (this.checkJobFieldsValidity()) return "Tags are invalid.";

    // No errors
    return "";
  }

  private getFriendlyFieldName(field: string): string {
    const fieldNames: { [key: string]: string } = {
      job_title: "Job Title",
      company_name: "Company Name",
      salary: "Salary",
      job_address: "Job Address",
      job_addressTitle: "Job Address Title",
      job_addressLink: "Job Google Maps Link",
      job_siteLink: "Job Website Link",
      job_phone: "Job Phone Number",
      education: "Education",
      experience: "Experience",
      skills: "Skills",
      application_deadline: "Application Deadline",
      job_description: "Job Description",
      how_to_apply: "How to Apply",
      responsibilities: "Responsibilities",
      additional_note: "Additional Note",
      Country: "Country",
      State: "State",
      City: "City",
      jobType: "Job Type",
      images: "Images",
      tags: "Tags",
    };

    return fieldNames[field] || field;
  }

  ///////////////////////////////////////
  ///////   function for API PAYLOAD ////
  /////////////////////////////////////

  createApiPayload() {
    const payload: any = {
      jobName: this.addJobForm.get("job_title")?.value,
      companyName: this.addJobForm.get("company_name")?.value,
      jobType: this.jobType,
      salary: this.addJobForm.get("salary")?.value,
      countryName: this.Country,
      stateName: this.State,
      cityName: this.City,
      location: this.addJobForm.get("job_address")?.value,
      locationTitle: this.addJobForm.get("job_addressTitle")?.value,
      locationSrc: this.addJobForm.get("job_addressLink")?.value,
      websiteLink: this.addJobForm.get("job_siteLink")?.value || '',
      contactNumber: this.addJobForm.get("job_phone")?.value,
      educationVMs: this.educationTags,
      experienceVMs: this.experienceTags,
      skillVMs: this.skillsTags,
      deadline: this.addJobForm.get("application_deadline")?.value,
      description: this.addJobForm.get("job_description")?.value || '',
      applyDescription: this.addJobForm.get("how_to_apply")?.value || '',
      responsibilitiesDescription: this.addJobForm.get("responsibilities")?.value || '',
      additionalDescription: this.addJobForm.get("additional_note")?.value || '',
      imageVMs: this.images,
    };

    payload.contactNumber = this._utlis.numberWithCountryCode(this.addJobForm.get("job_phone")?.value, this.intlTelInputInstance);
    return payload;

  }

}
