<ng-template #addhostel>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">
                Add New Hostel
            </h5>
            <button type="button" class="btn-close" (click)="modal.dismissAll()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <form [formGroup]="addHostelForm">
                <div class="row gx-3">
                    <div class="form-group col-6 col-sm-4">
                        <label for="a-na">Hostel Name<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="a-na" placeholder="Enter Hostel Name"
                            formControlName="hostel_name">
                        @if(addHostelForm.get('hostel_name')?.hasError('required') &&
                        addHostelForm.get('hostel_name')?.touched){
                        <small class="text-danger">Name is Required</small>
                        }
                    </div>
                    <div class="form-group col-6 col-sm-4 modal-container">
                        <label for="a-n">Hostel Type<span class="text-danger">*</span></label>
                        <app-common-dropdowns class="first-dropdown" [defaultText]="'Select hostel Type'"
                            [dataList]="HostelTypeList" [isDisabled]="true" [selectedOption]="HostelType"
                            (optionSelected)="onHostelSelected($event)"
                            [clearThevalue]="HostelType"></app-common-dropdowns>
                        @if(HostelType == "" && hostelTypeError){
                        <small class="text-danger">Hostel Type is Required</small>
                        }
                    </div>
                    <div class="form-group col-6 col-sm-4 modal-container">
                        <label for="a-n">Country<span class="text-danger">*</span></label>
                        <app-common-dropdowns class="second-dropdown" [defaultText]="'Enter Country Name'"
                            [dataList]="CountryList" (optionSelected)="onCountrySelected($event)"
                            [selectedOption]="Country" [clearThevalue]="Country"
                            (sendTheValue)="onCountryValueChange($event)"></app-common-dropdowns>
                        @if(Country == "" && countryTypeError){
                        <small class="text-danger">Country is Required</small>
                        }
                    </div>
                    <div class="form-group col-6 col-sm-4 modal-container">
                        <label for="a-na">State<span class="text-danger">*</span></label>
                        <app-common-dropdowns class="third-dropdown" [defaultText]="'Enter State Name'"
                            [dataList]="StateList" (optionSelected)="onStateSelected($event)" [selectedOption]="State"
                            [clearThevalue]="State" (sendTheValue)="onStateValueChange($event)"></app-common-dropdowns>
                        @if(State == "" && stateError){
                        <small class="text-danger">State is Required</small>
                        }
                    </div>
                    <div class="form-group col-6 col-sm-4 modal-container">
                        <label for="a-n">City<span class="text-danger">*</span></label>
                        <app-common-dropdowns class="fourth-dropdown" [defaultText]="'Enter City Name'"
                            [dataList]="CityList" (optionSelected)="onCitySelected($event)" [selectedOption]="City"
                            [clearThevalue]="City"></app-common-dropdowns>
                        @if(City == "" && cityError){
                        <small class="text-danger">City is Required</small>
                        }
                    </div>
                    <div class="form-group col-6 col-sm-4">
                        <label for="a-n">Address<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="a-n" placeholder="Enter Address"
                            formControlName="hostel_address">
                        @if(addHostelForm.get('hostel_address')?.hasError('required') &&
                        addHostelForm.get('hostel_address')?.touched){
                        <small class="text-danger">Address is Required</small>
                        }
                    </div>
                    <div class="form-group col-6 col-sm-4">
                        <label for="a-na">Address title<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="a-na" placeholder="Enter Location title"
                            formControlName="hostel_addressTitle">
                        @if(addHostelForm.get('hostel_addressTitle')?.hasError('required') &&
                        addHostelForm.get('hostel_addressTitle')?.touched){
                        <small class="text-danger">location is Required</small>
                        }
                    </div>
                    <div class="form-group col-6 col-sm-4">
                        <label for="a-n">Address link<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="a-n" placeholder="Enter Location link"
                            formControlName="hostel_addressLink">
                        @if(addHostelForm.get('hostel_addressLink')?.hasError('required') &&
                        addHostelForm.get('hostel_addressLink')?.touched){
                        <small class="text-danger">location link is Required</small>
                        }
                        @if(addHostelForm.get('hostel_addressLink')?.touched &&
                        addHostelForm.get('hostel_addressLink')?.hasError('pattern')
                        ){
                        <small class="text-danger"> https://maps.google.com/maps</small>
                        }
                    </div>
                    <div class="form-group col-6 col-sm-4">
                        <label for="a-n">Site link<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="a-n" placeholder="Enter Website link"
                            formControlName="site_link">
                        @if(addHostelForm.get('site_link')?.hasError('required') &&
                        addHostelForm.get('site_link')?.touched){
                        <small class="text-danger">Site link is Required</small>
                        }
                        @if(addHostelForm.get('site_link')?.touched &&
                        addHostelForm.get('site_link')?.hasError('pattern')
                        ){
                        <small class="text-danger"> https://</small>
                        }
                    </div>
                    <div class="form-group col-6 col-sm-4">
                        <label for="a-na">Email<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="a-na" placeholder="Enter Email"
                            formControlName="email_address">
                        @if(addHostelForm.get('email_address')?.hasError('required') &&
                        addHostelForm.get('email_address')?.touched){
                        <small class="text-danger">Email is Required</small>
                        }
                        @if(addHostelForm.get('email_address')?.touched &&
                        addHostelForm.get('email_address')?.hasError('pattern')
                        ){
                        <small class="text-danger">Invalid Email</small>
                        }
                    </div>
                    <div class="form-group col-6 col-sm-4">
                        <label for="a-n">Phone Number<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="myInput" minlength="8" maxlength="16"
                            placeholder="Enter Phone Number" formControlName="phone" min="0">
                        @if(addHostelForm.get('phone')?.hasError('required') &&
                        addHostelForm.get('phone')?.touched){
                        <small class="text-danger">Phone is Required</small>
                        }
                        @else if(addHostelForm.get('phone')?.touched && addHostelForm.get('phone')?.hasError('pattern')
                        ){
                        <small class="text-danger">Phone Number must in between 10-18</small>
                        }
                    </div>

                    <div class="form-group col-6 col-sm-4">
                        <label for="opening-time">Opening Time<span class="text-danger">*</span></label>
                        <input type="time" class="form-control" id="opening-time" formControlName="opening_time">
                        <small
                            *ngIf="addHostelForm.get('opening_time')?.hasError('required') && addHostelForm.get('opening_time')?.touched"
                            class="text-danger">Opening time is required</small>
                    </div>

                    <div class="form-group col-6 col-sm-4">
                        <label for="closing-time">Closing Time<span class="text-danger">*</span></label>
                        <input type="time" class="form-control" id="closing-time" formControlName="closing_time">
                        <small
                            *ngIf="addHostelForm.get('closing_time')?.hasError('required') && addHostelForm.get('closing_time')?.touched"
                            class="text-danger">Closing time is required</small>
                        <small
                            *ngIf="addHostelForm.hasError('invalidTimeRange') && addHostelForm.get('closing_time')?.touched"
                            class="text-danger">Closing time must be after opening time</small>
                    </div>

                    <div class="form-group col-6 col-sm-4">
                        <label for="number-of-seats"> Seats<span class="text-danger">*</span></label>
                        <input type="number" class="form-control" id="number-of-seats" formControlName="number_of_seats"
                            placeholder="Enter number of seats">
                        <small
                            *ngIf="addHostelForm.get('number_of_seats')?.hasError('required') && addHostelForm.get('number_of_seats')?.touched"
                            class="text-danger">Number of seats is required</small>
                        <small *ngIf="addHostelForm.get('number_of_seats')?.hasError('min')" class="text-danger">Number
                            of seats must be at least 1</small>
                        <small *ngIf="addHostelForm.get('number_of_seats')?.hasError('pattern')"
                            class="text-danger">Only positive numbers are allowed</small>
                    </div>

                    <div class="form-group col-6 col-sm-4">
                        <label for="rent-per-seat">Rent per Seat<span class="text-danger">*</span></label>
                        <input type="number" class="form-control" id="rent-per-seat" formControlName="rent_per_seat"
                            placeholder="Enter rent per seat">
                        <small
                            *ngIf="addHostelForm.get('rent_per_seat')?.hasError('required') && addHostelForm.get('rent_per_seat')?.touched"
                            class="text-danger">Rent per seat is required</small>
                        <small *ngIf="addHostelForm.get('rent_per_seat')?.hasError('min')" class="text-danger">Rent per
                            seat must be at least 1</small>
                        <small *ngIf="addHostelForm.get('rent_per_seat')?.hasError('pattern')" class="text-danger">Only
                            positive numbers are allowed</small>
                    </div>

                    <div class="form-group col-12 col-sm-4">
                        <label for="a-n">Facilitess<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="a-n" placeholder="Add Facilitess"
                            formControlName="facilites" (keyup.enter)="onEnterFacilites($event)">
                        @if(FacilitesTags.length == 0 &&
                        addHostelForm.get('facilites')?.touched){
                        <small class="text-danger">Add atleast one Facility</small>
                        }
                        @for(facilityTag of FacilitesTags; track facilityTag){
                        <div class="tag" (click)="removeFacilitesTags(facilityTag)">
                            {{ facilityTag.featureTitle }}
                            <span class="close-icon">&times;</span>
                        </div>
                        }

                    </div>


                </div>

                <div class="row gx-3">
                    <div class="form-group col-12">
                        <label for="a-na">Description<span class="text-danger">*</span></label>
                        <textarea rows="4" name="" id="" class="form-control" placeholder="Enter Description"
                            formControlName="description" (input)="updateCharCount()"></textarea>
                        <small class="">{{ charCount }}/400</small>
                        @if(addHostelForm.get('description')?.hasError('required') &&
                        addHostelForm.get('description')?.touched){
                        <small class="text-danger">Description is Required</small>
                        }
                    </div>
                </div>


            </form>
            <div class="dropzone-admin mb-0">
                <label>Media <span class="font-danger">*</span></label>
                <form class="dropzone" id="multiFileUpload">
                    <ngx-dropzone (change)="onSelect($event)" accept="image/jpeg,image/jpg,image/png"
                        [multiple]="false">
                        <ngx-dropzone-label>
                            <div class="dz-message needsclick">
                                <i class="fas fa-cloud-upload-alt"></i>
                                <h6 class="f-w-700 mb-0">
                                    Drop files here or click to upload.
                                </h6>
                            </div>
                        </ngx-dropzone-label>
                        @for(f of files; track f){
                        <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" [removable]="true"
                            (removed)="onRemove(f)" [file]="f">
                            <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>

                        </ngx-dropzone-image-preview>
                        }
                    </ngx-dropzone>
                </form>





            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-dashed color-2 btn-pill" (click)="modal.dismissAll()">Cancel</button>
            <button type="button" class="btn btn-gradient color-2 btn-pill" (click)="onSubmit()">

                Add hostel
            </button>
        </div>
    </div>

</ng-template>