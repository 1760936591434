import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, TemplateRef, inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { ApiCallService } from 'src/app/shared/services/api-call.service';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';
import { PropertyService } from 'src/app/shared/services/property.service';

@Component({
  selector: 'app-add-books',
  templateUrl: './add-books.component.html',
  styleUrl: './add-books.component.scss',
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddBooksComponent {
  public bgImage = 'assets/images/banner/banner.jpg';
  public title = 'Dashboard';
  public parent = 'Library';
  public child = 'Add Book';
  addBookForm: FormGroup;
  boostBookForm: FormGroup;
  booksData: any;
  isDataLoading: boolean = true;
  public isSpinnerShown = false;
  tagClass: string;
  //for update case
  public fileBase64: any = null;
  public imageBase64: any = null;
  private destroy$ = new Subject<void>();

  public _apiCall = inject(ApiCallService);
  public _toastr = inject(ToastrService);
  public _spinner = inject(NgxSpinnerService)
  public propertyService = inject(PropertyService);
  private errorHandlingService = inject(ErrorHandlingService);
  public FormName: string = "Add";


  constructor(public modal: NgbModal, private fb: FormBuilder, private sanitizer: DomSanitizer) {
    this.addBookForm = this.fb.group({
      book_title: ["", Validators.required],
      book_edition: ["", Validators.required],
      publisher: ["", Validators.required],
      publish_date: ["", Validators.required],
      ISBN_Number: ["", Validators.required],
      authors_name: ["", Validators.required],
      keywords: ["", Validators.required],
      description: ["", [Validators.required,]],


    });

    this.boostBookForm = this.fb.group({
      title: ["", Validators.required],
      start_date: ["", [Validators.required,]],
      end_date: ["", Validators.required],
      ad_description: ["", [Validators.required, Validators.minLength(200)]],
    });

  }

  ngOnInit() {
    debugger
    this.getCategoryDropDown();
    this.getBooksData();
    this.getLaguageDropdown();
  }

  ///////////////////////////////////////////////////
  // Ad End Date limiter STARTS HERE 
  minmumDate: any
  getMinDate() {
    const maxDate = this.boostBookForm.get('start_date')?.value;
    this.boostBookForm.get('end_date')?.reset();
    this.minmumDate = maxDate
  }

  getAdMaxDate(): string {
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    return formattedDate;
  }

  minEndDate: any;
  updateEndDateMin(): void {
    const startDate = this.boostBookForm.get('start_date')?.value;
    this.minEndDate = startDate ? startDate : null;
  }

  // Ad End Date limiter Ends HERE 
  ///////////////////////////////////////////

  adCharCount: number = 0;
  updateAdCharCount(): void {
    let description = this.boostBookForm.get("ad_description")?.value;
    this.adCharCount = description?.length || 0;
  }



  isAdActivated: boolean = true;
  boostId: number;
  boostCard(content: TemplateRef<any>, id: number) {
    this.boostId = id;
    this.boostBookForm.reset();
    this.adImageBase64 = "";
    this.adFiles = [];
    this.modal.open(content, { centered: true, size: 'lg' });
    this.FormName = "Advertisment";
  }

  editAdCard(content: TemplateRef<any>, id: number) {
    this.boostBookForm.reset();
    this.imageBase64 = "";
    this.adFiles = [];
    this.modal.open(content, { centered: true, size: 'lg' });
    this.FormName = "EditAdvertisment";
    this.editAd(id);
  }

  ////////////////////////////////////////////
  //////////// code for Ads image /////////////////////
  //////////////////////////////////////////////

  adFiles: File[] = [];
  adImages: { ImageBase64: string }[] = [];
  adImageBase64: string | null = null;
  adImageSizeError: boolean = false;

  onAdSelect(event: NgxDropzoneChangeEvent) {
    const newFile = event.addedFiles[0];
    if (newFile) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const sizeInKB = Math.round(newFile.size / 1024);
        const isToDel = this.checkADImageSizeValidity(sizeInKB, newFile);
        if (!isToDel) {
          const base64String = e.target.result as string;
          this.adFiles = [newFile];
          this.adImages = [{ ImageBase64: base64String }];
          this.adImageBase64 = base64String;
        }
      };
      reader.readAsDataURL(newFile);
    }

  }

  onAdRemove(event: File) {
    this.adFiles = [];
    this.adImages = [];
  }




  /////////////////////////////////////////////////////
  //////////// image update case //////////////////////
  //////////////////////////////////////////////////

  onAdFileSelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {

        const sizeInKB = Math.round(file.size / 1024);
        const isToDel = this.checkADImageSizeValidity(sizeInKB, file)
        if (!isToDel) {
          const base64String = reader.result as string;
          this.adFiles = [file];
          this.adImages = [{ ImageBase64: base64String }];
          this.adImageBase64 = base64String;
        };
      };
    }
  }


  checkADImageSizeValidity(size: number, file: any) {
    if (size > 1024) {
      this.adImageSizeError = true;
      this.onAdRemove(file)
      return true;

    }
    else {
      this.adImageSizeError = false;
      return false;
    }
  }

  removeAdFile(file: any): void {
    this.adFiles = [];
    this.adImages = [];
    this.adImageBase64 = "";
  }


  public submitBoostAd(): void {

    const adsPayload = this.createAdsApiPayload();
    console.log("Payload", adsPayload);
    this.isSpinnerShown = true;

    this._apiCall.PostCallWithToken(adsPayload, 'Ad/SaveAndUpdateAd')
      .subscribe(
        (response) => {
          if (response.responseCode === 200) {
            this._toastr.success(response.responseMessage);
            this.boostBookForm.reset();
            this.modal.dismissAll();
          } else {
            this.errorHandlingService.handleResponseError(response);
          }
          this.isSpinnerShown = false;
        },
        (error) => {
          this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
          this.isSpinnerShown = false;
        }
      );
  }

  createAdsApiPayload() {
    this.updateAdId = this.FormName == "Advertisment" ? 0 : this.updateAdId;
    return {
      moduleId: this.boostId,
      moduleName: "book",
      title: this.boostBookForm.get('title')?.value,
      startDate: this.boostBookForm.get('start_date')?.value,
      endDate: this.boostBookForm.get('end_date')?.value,
      description: this.boostBookForm.get('ad_description')?.value,
      imageUrl: this.adImageBase64,
    };
  }

  adDataById: any;
  updateAdId: number = 0;
  editAdModuleId: number;
  public editAd(id: number): void {
    this.editAdModuleId = id;
    const payload = {
      id: this.editAdModuleId,
      moduleName: "Book",
    }
    this.isSpinnerShown = true;
    this._spinner.show();

    this._apiCall.PostCallWithToken(payload, "Ad/GetAdById")
      .subscribe(
        (response) => {
          if (response.responseCode === 200) {
            this.adDataById = response.data;
            this.updateAdId = this.adDataById.id;
            this.boostBookForm.get('title')?.setValue(this.adDataById?.title);
            this.boostBookForm.get("start_date")?.setValue(this.adDataById?.startDate.substring(0, 10));
            this.boostBookForm.get("end_date")?.setValue(this.adDataById?.endDate.substring(0, 10));
            this.boostBookForm.get("ad_description")?.setValue(this.adDataById?.description);

            this.imageBase64 = this.adDataById?.imageUrl;

            this.updateAdCharCount();

          } else {
            this.errorHandlingService.handleResponseError(response);
            this._toastr.error(response.responseMessage);
          }
          this.isSpinnerShown = false;

        },
        (error) => {
          this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
          this.isSpinnerShown = false;
        }
      );
  }




  charCount: number = 0;
  updateCharCount(): void {
    let description = this.addBookForm.get("description")?.value;
    this.charCount = description?.length || 0;
  }

  addCard(content: TemplateRef<any>) {
    this.updateBookId = 0;
    this.Language = "";
    this.bookCategory = "";
    this.authorTags = [];
    this.keywordTags = [];
    this.coverImageFile = null;
    this.pdfFile = null;
    this.coverImages = [];
    this.coverImageBase64 = null;
    this.pdfFileBase64 = null;
    this.modal.open(content, { centered: true, size: 'lg' });
    this.FormName = "Add";
    this.addBookForm.reset();
  }

  delId: number;
  deleteCard(content: TemplateRef<any>, id: number) {
    this.modal.open(content, { centered: true, size: 'sm' });
    this.delId = id;
  }

  editCard(content: TemplateRef<any>, id: number) {
    this.modal.open(content, { centered: true, size: 'lg' });
    this.FormName = "Edit";
    this.addBookForm.reset();
    this.Language = "";
    this.bookCategory = "";
    this.authorTags = [];
    this.keywordTags = [];
    this.coverImageFile = null;
    this.pdfFile = null;
    this.coverImages = [];
    this.coverImageBase64 = null;
    this.pdfFileBase64 = null;
    this.editBook(id);
  }

  //////////////////////editScholarship//////////////////////////////
  updateBookId: number = 0;
  bookByIdData: any;


  editBook(id: number) {
    this.updateBookId = id;
    this.isSpinnerShown = true;
    this._spinner.show();

    this._apiCall.PostCallWithToken(null, `Dashboard/GetBookById?Id=${id}`).subscribe((res) => {
      if (res.responseCode === 200) {


        this.bookByIdData = res.data;

        this.addBookForm.get('book_title')?.setValue(this.bookByIdData?.title);
        this.addBookForm.get('book_edition')?.setValue(this.bookByIdData?.edition);
        this.addBookForm.get('publisher')?.setValue(this.bookByIdData?.publisherName);
        this.addBookForm.get('ISBN_Number')?.setValue(this.bookByIdData?.isbNumber);
        this.addBookForm.get('publish_date')?.setValue(this.bookByIdData?.publishedDate?.substring(0, 10));
        this.addBookForm.get('description')?.setValue(this.bookByIdData?.bookDescription);
        this.Language = this.bookByIdData?.language;
        this.bookCategory = this.bookByIdData?.category;
        this.authorTags = this.bookByIdData?.bookAuthorVMs;
        this.keywordTags = this.bookByIdData?.bookKeyWordVMs;
        this.pdfFileBase64 = this.sanitizer.bypassSecurityTrustResourceUrl(this.bookByIdData?.fileBase64);
        this.coverImageBase64 = this.bookByIdData?.imageBase64;

        this.updateCharCount();

        this.isSpinnerShown = false;
        this._spinner.hide();
        console.log("Data from books in edit case", this.bookByIdData);
      }
      else {
        this._toastr.error(res.responseMessage);
        this.isSpinnerShown = false;
        this._spinner.hide();
      }
    });

    this.addBookForm.updateValueAndValidity();
  }



  deleteBook() {
    this.isSpinnerShown = true;
    this._spinner.show();
    this._apiCall.PostCallWithToken(null, `Dashboard/DeleteBook?id=${this.delId}`).subscribe((res) => {
      if (res.responseCode == 200) {
        this._toastr.success(res.responseMessage);
        this.getBooksData();
        this.modal.dismissAll();
        this.isSpinnerShown = false;
        this._spinner.hide();
      } else {
        this._toastr.error(res.responseMessage);
        this.modal.dismissAll();
        this.isSpinnerShown = false;
        this._spinner.hide();
      }

    })
  }




  checkTagsValidity(): boolean {

    if (this.authorTags.length < 1 || this.keywordTags.length < 1) {
      return true;
    }

    return false;
  }

  ////////////////////////////////////////////
  // function to clear and add Validations 
  //////////////////////////////////////////////


  removeUmwantedControl() {

    this.addBookForm.removeControl('authors_name');
    this.addBookForm.removeControl('keywords');
    this.addBookForm.updateValueAndValidity();
  }
  addControls() {


    if (!this.addBookForm.contains('authors_name')) {
      this.addBookForm.addControl('authors_name', new FormControl("", Validators.required));
    }
    if (!this.addBookForm.contains('keywords')) {
      this.addBookForm.addControl('keywords', new FormControl("", Validators.required));
    }



    this.addBookForm.updateValueAndValidity();
  }




  ////////////////////////////////////
  // API CALL AND VALIDATIONS CHECK
  ////////////////////////////////////


  onSubmit() {
    this.addBookForm.markAllAsTouched();
    const tagsValidity = this.checkTagsValidity();
    this.ShowError();

    this.removeUmwantedControl();

    const description = this.addBookForm.get("description")?.value;

    if (
      this.Language === "" ||
      this.bookCategory === "" ||
      this.coverImageBase64 == null ||
      this.pdfFileBase64 == null ||
      description?.length < 400 ||
      tagsValidity ||
      this.addBookForm.invalid
    ) {
      const errorMessage = this.getFirstError();
      if (errorMessage) {
        this._toastr.error(errorMessage);
        this.addControls();
        return;
      }
      this.addControls();
    }
    else {
      this.isSpinnerShown = true;
      this._spinner.show();

      const payload = this.createApiPayload();
      console.log("Book Data:", payload)
      this._apiCall
        .PostCallWithToken(payload, "Dashboard/SaveBook")
        .subscribe((response: any) => {
          this.addControls();
          if (response.responseCode == 200) {
            this.isSpinnerShown = false;
            this._spinner.hide();
            this._toastr.success(response.responseMessage);
            this.modal.dismissAll();
            this.getBooksData();
          } else {
            this._toastr.error(response.responseMessage);
            this.isSpinnerShown = false;
            this._spinner.hide();
          }
        });
    }

  }

  private getFirstError(): string {
    for (const key of Object.keys(this.addBookForm.controls)) {
      const control = this.addBookForm.get(key);
      if (control && control.invalid) {
        if (this.getFriendlyFieldName(key) != "") {
          return `${this.getFriendlyFieldName(key)} is invalid.`;
        } else {
          return "";
        }
      }
    }

    if (this.Language === "") return "Language is required.";
    if (this.bookCategory === "") return "Book Category is required.";
    if (this.authorTags?.length < 1) return "At least one Author tag is required.";
    if (this.keywordTags?.length < 1) return "At least one Keyword tag is required.";

    if (this.coverImageBase64 == null) return "Cover Image is required.";
    if (this.pdfFileBase64 == null) return "PDF File is required.";

    const description = this.addBookForm.get('description')?.value;
    if ((description?.length ?? 0) < 400)
      return "Description must be at least 400 characters long.";



    return "";
  }

  private getFriendlyFieldName(field: string): string {
    const fieldNames: { [key: string]: string } = {
      book_title: "Book Title",
      book_edition: "Book Edition",
      publisher: "Publisher",
      publish_date: "Publish Date",
      ISBN_Number: "ISBN Number",
      description: "Description",
    };

    return fieldNames[field] || "";

  }

  ///////////////////////////////////////
  ///////   function for API PAYLOAD ////
  /////////////////////////////////////


  createApiPayload() {
    const payload: any = {
      // id: this.updateInstituteId,

      title: this.addBookForm.get("book_title")?.value,
      edition: this.addBookForm.get("book_edition")?.value,
      publisherName: this.addBookForm.get("publisher")?.value,
      isbNumber: this.addBookForm.get("ISBN_Number")?.value,
      publishedDate: this.addBookForm.get("publish_date")?.value,
      bookDescription: this.addBookForm.get("description")?.value,
      language: this.Language,
      category: this.bookCategory,
      bookAuthorVMs: this.authorTags,
      bookKeyWordVMs: this.keywordTags,
      fileBase64: this.pdfFileBase64,
      imageBase64: this.coverImageBase64,

    }


    return payload;

  }


  // pdf by id

  getPdfbyid() {
    // this.updateBookId
    this.isSpinnerShown = true;
    this._spinner.show();
    this._apiCall.PostCallWithoutToken(null, `Book/GetBookPdf?bookId=${this.updateBookId}`).subscribe((res) => {
      if (res.responseCode == 200) {
        const url = this.base64toUrl(res?.data?.pdfBase64);
        window.open(url);
        this.isSpinnerShown = false;
        this._spinner.hide();
      } else {
        this._toastr.error(res.responseMessage)
      }
    }, (error) => {
      this._toastr.error(error);
    })
  }

  private base64toUrl(bookFile: string): string {
    const byteCharacters = atob(bookFile.split(',')[1]);
    const byteNumbers = new Array(byteCharacters.length).fill(0).map((_, i) => byteCharacters.charCodeAt(i));
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });
    return window.URL.createObjectURL(blob);
  }


  getMaxDate(): string {
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    return formattedDate;
  }


  ////////////////////////////////////////////
  // code for  DropDown for Language
  //////////////////////////////////////////////

  languageList = [];
  languageType: string = "";
  Language: string = "";
  onLanguageSelected(option: any) {
    this.Language = option.value;

  }
  onLanguageValueChange(searchTerm: string) {

    this.Language = searchTerm;
  }


  getLaguageDropdown() {
    this._apiCall
      .GetCallWithoutToken("DropDown/GetLanguageDropDown")
      .subscribe((response) => {
        this.languageList = response?.data;
      });
  }


  ////////////////////////////////////////////
  // code for Custom Searchable DropDown for Book category
  //////////////////////////////////////////////

  bookCategory: string = ""
  categorySearchTerm: string = '';
  CategoryList = []
  onCategorySelected(option: any) {
    this.bookCategory = option.value

  }
  SearchTermCategory(searchTerm: any) {
    this.categorySearchTerm = searchTerm;
  }

  getCategoryDropDown() {
    debugger
    this._apiCall
      .GetCallWithoutToken("DropDown/GetBookDropDown")
      .subscribe((response) => {
        this.CategoryList = response?.data;
      });
  }

  ///////////////////////////////////////////////////////////
  // Function to add Author tag ///////////////////////
  //////////////////////////////////////////////////////////////


  authorTags: any[] = [];

  addAuthorTags(): void {
    const tagInput = this.addBookForm.get("authors_name")?.value.trim();

    if (tagInput && !this.authorTags.some((tag) => tag.authorName === tagInput)) {
      const newTag = { author: tagInput };
      this.authorTags.push(newTag);
      this.addBookForm.get("authors_name")?.setValue("");
      this.authorTagError = this.authorTags.length < 1;
    }
  }

  removeAuthorTags(tag: any): void {
    this.authorTags = this.authorTags.filter((t) => t !== tag);
    this.authorTagError = this.authorTags.length < 1;
  }

  onEnterAuthor(event: Event): void {
    if (event instanceof KeyboardEvent && event.key === "Enter") {
      event.preventDefault();
      this.addAuthorTags();
    }
  }

  ///////////////////////////////////////////////////////////
  // Function to add Keywords ///////////////////////
  //////////////////////////////////////////////////////////////


  keywordTags: any[] = [];

  addKeywordsTags(): void {
    const tagInput = this.addBookForm.get("keywords")?.value.trim();

    if (tagInput && !this.keywordTags.some((tag) => tag.tag === tagInput)) {
      const newTag = { keyWord: tagInput };
      this.keywordTags.push(newTag);
      this.addBookForm.get("keywords")?.setValue("");
      this.keywordTagError = this.keywordTags.length < 1;
    }
  }

  removeKeywordsTags(tag: any): void {
    this.keywordTags = this.keywordTags.filter((t) => t !== tag);
    this.keywordTagError = this.keywordTags.length < 1;
  }

  onEnterKeywords(event: Event): void {
    if (event instanceof KeyboardEvent && event.key === "Enter") {
      event.preventDefault();
      this.addKeywordsTags();
    }
  }

  ////////////////////////////////////////////
  // code for showig errors
  //////////////////////////////////////////////

  languageError: boolean = false;
  categoryError: boolean = false;
  imageError: boolean = false;
  imageSizeError: boolean = false;
  pdfError: boolean = false;
  authorTagError: boolean = false;
  keywordTagError: boolean = false;
  ShowError() {
    this.languageError = this.Language == "" ? true : false;
    this.categoryError = this.bookCategory == "" ? true : false;
    this.imageError = this.coverImageBase64  == null ? true : false
    this.pdfError = this.pdfFileBase64 == null ? true : false
    this.authorTagError = this.authorTags.length < 1 ? true : false
    this.keywordTagError = this.keywordTags.length < 1 ? true : false
  }


  // Variables to store the selected files
  pdfFile: File | null = null;
  pdfFileBase64: any | null = null;
  coverImageFile: File | null = null;
  coverImageBase64: string | null = null;
  coverImages: { imageBase64: string }[] = [];

  // Method to handle PDF selection
  onPdfSelect(event: NgxDropzoneChangeEvent) {
    const newFile = event.addedFiles[0];
    if (newFile) {
      this.pdfFile = newFile;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.pdfFileBase64 = e.target.result as string;
        console.log('PDF Base64:', this.pdfFileBase64);
      };
      reader.readAsDataURL(newFile);
    }
  }

  // Method to remove the selected PDF
  onRemovePdf() {
    this.pdfFile = null;
    this.pdfFileBase64 = null;
    console.log('PDF removed');
  }

  // Method to handle image (cover photo) selection
  onImageSelect(event: NgxDropzoneChangeEvent) {
    const newFile = event.addedFiles[0];
    if (newFile) {
      const sizeInKB = Math.round(newFile.size / 1024); // Convert size to KB
      const isInvalidSize = this.checkImageSizeValidity(sizeInKB);
      if (!isInvalidSize) {
        this.coverImageFile = newFile;
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.coverImageBase64 = e.target.result as string;
          console.log('Image Base64:', this.coverImageBase64);
        };
        reader.readAsDataURL(newFile);
      }
    }
  }


  // Method to remove the selected cover image
  onRemoveImage() {
    this.coverImageFile = null;
    this.coverImageBase64 = null;
    console.log('Image removed');
  }

  // Method to handle file selection in the edit case
  onFileSelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result as string;
        this.coverImages.push({ imageBase64: base64String });
        console.log('Cover Image (Edit Case) added:', base64String);
      };
      reader.readAsDataURL(file);
    }
  }

  // Method to remove cover image in the edit case
  removeCoverImage(image: { imageBase64: string }): void {
    this.coverImages = this.coverImages.filter((img) => img !== image);
    console.log('Cover Image (Edit Case) removed');
  }

  // Helper method to check image size and validate it
  checkImageSizeValidity(size: number): boolean {
    if (size > 1024) {
      this.imageSizeError = true;
      this.onRemoveImage()
      return true;

    }
    else {
      this.imageSizeError = false;
      return false;
    }
  }


  // for update case

  onImageFileSelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      const sizeInKB = Math.round(file.size / 1024); // Convert size to KB
      const isInvalidSize = this.checkImageSizeValidity(sizeInKB);
      if (!isInvalidSize) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.coverImageBase64 = reader.result as string;
          console.log('Image Base64:', this.coverImageBase64);
        };
      }
    }
  }

  removeImageFile(): void {
    this.coverImageBase64 = null;
    console.log('Image removed');

  }


  removeFile(): void {
    this.pdfFileBase64 = null;
    console.log('Pdf removed');

  }

  onPDFFileSelected(event: any): void {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = reader.result as string;
        this.pdfFileBase64 = base64String;
      };
    }
  }

  public Options = {
    items: 1,
    loop: true,
    nav: true,
    dots: true,
    autoplay: true,
    autoplayTimeout: 2000,
    navText: [
      "<i class='fa fa-chevron-left'></i>",
      "<i class='fa fa-chevron-right'></i>",
    ],
  };

  private async getBooksData() {
    debugger
    this.isDataLoading = true;
    try {
      this._apiCall.GetCallWithToken("Dashboard/GetBooks")
        .subscribe((response) => {
          if (response.responseCode == 200) {
            this.booksData = response.data;
            this.isDataLoading = false;
          } else {
            this.errorHandlingService.handleResponseError(response);
          }
        })

    } catch (error) {
      this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
    } finally {
      this.isDataLoading = false;
    }
  }

}
