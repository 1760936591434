<!-- <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="var(--mainColor)" type="ball-spin-clockwise"
  [fullScreen]="true">
  <p style="font: size 20px; color: var(--mainColor)">Loading...</p>
</ngx-spinner> -->
<app-loader-two [isLoading]="isSpinnerShown"></app-loader-two>
<div class="headers">
  <h6>
    Profile Picture
  </h6>
</div>
<div class="container">
  <div class="row">
    <!-- Image Preview Section -->
    <div class="col-12 text-center mb-4">
      <div class="image-container" (click)="triggerFileInput()">
        <!-- @if(profileBase64.length < 20){
        <img [src]="'assets/images/icon/profile.png'" alt="Profile Image Preview" class="profile-image">
        }@else{ -->
        <img [src]="profileBase64" alt="Profile Image Preview" class="profile-image">
        <!-- } -->
        <app-feather-icons [icon]="'camera'" class="camera-icon"></app-feather-icons>
        <input type="file" (change)="onFileSelect($event)" class="file-input" #fileInput accept="image/*">
      </div>
      @if(profileBase64 == "" && imageError){
      <small class="text-danger text-start d-flex">Image is Required</small>
      }
    </div>
    <div class="headers">
      <h6>
        Personal Details
      </h6>
    </div>
    <form class="row gx-2 gx-sm-3" [formGroup]="userForm">
      <div class="form-group col-sm-4">
        <label>First Name*</label>
        <input type="text" class="form-control" formControlName="firstName" placeholder="Enter first name">
        @if(userForm.get('firstName')?.hasError('required') &&
        userForm.get('firstName')?.touched){
        <small class="text-danger">First Name is Required</small>
        }
      </div>

      <div class="form-group col-sm-4">
        <label>Last Name*</label>
        <input type="text" class="form-control" formControlName="lastName" placeholder="Enter last name">
        @if(userForm.get('lastName')?.hasError('required') &&
        userForm.get('lastName')?.touched){
        <small class="text-danger">Last Name is Required</small>
        }
      </div>

      <div class="form-group col-sm-4">
        <label>Gender*</label>
        <app-common-dropdowns class="first-dropdown" [defaultText]="'Select Gender'" [dataList]="genderList"
          (optionSelected)="onGenderSelected($event)" [isDisabled]="true" [selectedOption]="gender"
          [clearThevalue]="gender"></app-common-dropdowns>
        @if(gender == "" && genderError){
        <small class="text-danger">Gender is Required</small>
        }
      </div>

      <div class="form-group col-sm-4">
        <label>Country*</label>
        <!-- <input type="text" class="form-control" formControlName="country" placeholder="Enter country"> -->
        <app-common-dropdowns class="third-dropdown" [defaultText]="'Enter Country Name'" [dataList]="CountryList"
          (optionSelected)="onCountrySelected($event)" [selectedOption]="Country" [clearThevalue]="Country"
          (sendTheValue)="onCountryValueChange($event)">
        </app-common-dropdowns>
        @if(Country == "" && countryTypeError){
        <small class="text-danger">Country is Required</small>
        }
      </div>

      <div class="form-group col-sm-4">
        <label>State*</label>
        <app-common-dropdowns class="fourth-dropdown" [defaultText]="'Enter State Name'" [dataList]="StateList"
          (optionSelected)="onStateSelected($event)" [selectedOption]="State" [clearThevalue]="State"
          (sendTheValue)="onStateValueChange($event)">
        </app-common-dropdowns>
        @if(State == "" && stateError){
        <small class="text-danger">State is Required</small>
        }
      </div>

      <div class="form-group col-sm-4">
        <label>City*</label>
        <app-common-dropdowns class="fifth-dropdown" [defaultText]="'Enter City Name'" [dataList]="CityList"
          (optionSelected)="onCitySelected($event)" [selectedOption]="City" [clearThevalue]="City">
        </app-common-dropdowns>
        @if(City == "" && cityError){
        <small class="text-danger">City is Required</small>
        }
      </div>


      <div class="form-group col-sm-6">
        <label>Phone Number*</label>
        <input type="tel" id="myInput" class="form-control" formControlName="phoneNumber"
          placeholder="Enter phone number" minlength="8" maxlength="16" />
        <div *ngIf="userForm.get('phoneNumber')?.hasError('required') && userForm.get('phoneNumber')?.touched">
          <small class="text-danger">Phone Number is Required</small>
        </div>
        <div *ngIf="userForm.get('phoneNumber')?.hasError('maxlength') && userForm.get('phoneNumber')?.touched">
          <small class="text-danger">Phone Number must be between 10-18 characters</small>
        </div>
        <div *ngIf="userForm.get('phoneNumber')?.hasError('minlength') && userForm.get('phoneNumber')?.touched">
          <small class="text-danger">Phone Number must be between 10-18 characters</small>
        </div>
        <div *ngIf="userForm.get('phoneNumber')?.hasError('pattern') && userForm.get('phoneNumber')?.touched">
          <small class="text-danger">Invalid Phone Number</small>
        </div>
      </div>


      <div class="form-group  col-sm-6">
        <label>Date of Birth*</label>
        <input type="date" class="form-control" formControlName="dateOfBirth">
        @if(userForm.get('dateOfBirth')?.hasError('required') &&
        userForm.get('dateOfBirth')?.touched){
        <small class="text-danger">Date of Birth is Required</small>
        }
      </div>

      <!-- <div class="form-group col-sm-4">
        <label>Type*</label>
        <app-common-dropdowns class="second-dropdown" [defaultText]="'Select Teachear Type'"
          [dataList]="teachearTypeList" (optionSelected)="onTypeSelected($event)" [isDisabled]="true"
          [selectedOption]="teachearType" [clearThevalue]="teachearType"></app-common-dropdowns>
        @if(teachearType == "" && teachearTypeError){
        <small class="text-danger">Type is Required</small>
        }
      </div> -->


      
      <div class="form-group col-12">
        <label>Address*</label>
        <input type="text" class="form-control" formControlName="address" placeholder="Enter address">
        @if(userForm.get('address')?.hasError('required') &&
        userForm.get('address')?.touched){
        <small class="text-danger">Address is Required</small>
        }
      </div>

      <!-- <div class="form-group col-sm-4">
        <label>Username</label>
        <input type="text" class="form-control" formControlName="username" placeholder="Enter username">
        @if(userForm.get('username')?.hasError('required') &&
        userForm.get('username')?.touched){
        <small class="text-danger">Username is Required</small>
        }
      </div> -->

      <!-- <div class="form-group col-sm-4">
        <label>Email</label>
        <input type="email" class="form-control" formControlName="email" placeholder="Enter email">
        @if(userForm.get('email')?.hasError('required') &&
        userForm.get('email')?.touched){
        <small class="text-danger">Email is Required</small>
        }
        @if(userForm.get('email')?.hasError('pattern') &&
        userForm.get('email')?.touched){
        <small class="text-danger">Invalid Email Format</small>
        }
      </div> -->

      <div class="form-group col-sm-12">
        <label>Description*</label>
        <textarea class="form-control" formControlName="description" rows="4"
          placeholder="Enter description"></textarea>
        @if(userForm.get('description')?.hasError('required') &&
        userForm.get('description')?.touched){
        <small class="text-danger">Description is Required</small>
        }
      </div>

      <div class="form-group col-sm-12 text-end">
        <button type="submit" class="btn btn-gradient color-2 btn-pill" (click)="savePersonalDetails()"
          aria-label="Save Changes">Save
          Changes</button>
      </div>
    </form>
  </div>