<!-- <h6>Request exploration</h6> -->
<div class="category-property">
  <form [formGroup]="requestForm" (ngSubmit)="sendRequest()">
    <div class="form-group">
      <input type="text" class="form-control" placeholder="Your Name" formControlName="userName">
      @if(requestForm.get('userName')?.hasError('required') && requestForm.get('userName')?.touched){
      <small class="text-danger">Name is Required</small>
      }
    </div>

    <!-- <div class="form-group">
      <input type="email" class="form-control" placeholder="Email Address" formControlName="userMail">
      @if(requestForm.get('userMail')?.hasError('required') && requestForm.get('userMail')?.touched){
      <small class="text-danger">Email is Required</small>
      }
      @if(requestForm.get('userMail')?.touched && requestForm.get('userMail')?.hasError('pattern')){
      <small class="text-danger">Invalid Email</small>
      }
    </div> -->

    <div class="form-group">
      <input placeholder="Phone Number" class="form-control" name="mobnumber" id="myInput" type="tel"
        formControlName="userNumber" minlength="8" maxlength="16">
      @if(requestForm.get('userNumber')?.hasError('required') && requestForm.get('userNumber')?.touched){
      <small class="text-danger">Phone Number is Required</small>
      }
      @if(requestForm.get('userNumber')?.touched && requestForm.get('userNumber')?.hasError('pattern')){
      <small class="text-danger">Phone number must be between 10 and 18 digits</small>
      }
    </div>

    <div class="form-group">
      <textarea placeholder="Message" class="form-control" rows="3" formControlName="userMesg"></textarea>
      @if(requestForm.get('userMesg')?.hasError('required') && requestForm.get('userMesg')?.touched){
      <small class="text-danger">Message is Required</small>
      }
    </div>
    <div class="d-flex justify-content-center">
      <app-center-button [buttonText]="'Submit Request'" (click)="openEmail()"></app-center-button>
    </div>



    <div class="divider">
      <span>Or</span>
    </div>
    <div class=" d-flex justify-content-center">
      <app-center-button [buttonText]="' Whatsapp'" [icon]="'fab fa-whatsapp'" (click)="openWhatsApp()">
      </app-center-button>
    </div>
  </form>
</div>