import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { agencyAgent, propertyDetailsData } from 'src/app/shared/interface/property';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CommonUtilsServiceService } from 'src/app/shared/services/common-utils-service.service';
import { DataShareService } from 'src/app/shared/services/data-share.service';
import { MetaService } from 'src/app/shared/services/meta.service';
import { PropertyService } from 'src/app/shared/services/property.service';

@Component({
  selector: 'app-teachear-list',
  templateUrl: './teachear-list.component.html',
  styleUrl: './teachear-list.component.scss',
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class TeachearListComponent {
  public title = 'Agency List';
  public parent = 'Home';
  public child = 'Agency List';
  country!: string;
  state!: string;
  city!: string;
  data: any;
  receivedData: any;
  teachersData: any[] = [];
  public noRecordsFound: boolean = false;
  public isDataLoading: boolean = true;
  public isErrorOccurred: boolean = false;
  public isSpinnerShown : boolean = false;
  DropdownonReset : string = '';



  bannerImage: string = 'assets/images/banner/teacher-banner.jpg';
  private readonly metaService: MetaService = inject(MetaService);
  private _spinner = inject(NgxSpinnerService)
  private _authService = inject(AuthService)
  public agencyData: agencyAgent[];
  public propertyData: propertyDetailsData;
    private readonly _dataShare = inject(DataShareService);

  constructor(private propertyService: PropertyService, private commonUtilsService: CommonUtilsServiceService) { }


  ngOnInit(): void {
    this.fetchTeachersData(this.currentPage);
    this.metaService.setCanonicalURL();
    this.metaService.initializeMetaData();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  onReset() {
    this.currentPage = 1;
    this.DropdownonReset = '';
    this.isSpinnerShown = true;
    this._dataShare.refreshDropdowns(true);
    this.isDataLoading = true;
    this.fetchTeachersData(this.currentPage);
  }


  handleChildEvent(data: any) {
    this.receivedData = data;
    this.CheckAndCallAPI();
  }

  handleSearchData(event: any) {
    if (event === '') {
      this.isSpinnerShown = true;
      this.receivedData.search = '';
      this.fetchTeachersData(1);
    }
  }

  ////////////////// PAGINATION  ///////////////////////
  pages: (number | string)[] = [];
  currentPage: number = 1;
  totalRecords: number = 0;
  itemsPerPage: number = 8;
  maxVisiblePages: number = 5;
  displayedItems: any[] = [];

  fetchTeachersData(pageNumber: number): void {
    this.teachersData = [];
    this.propertyService.getAllTeachers(this.receivedData || {}, pageNumber, this.itemsPerPage).subscribe(
      (response: any) => {
        this.isDataLoading = false;
        this.teachersData = response.data;
        // Convert base64 images to Blob URLs
        this.teachersData.forEach((teacher) => {
          if (teacher.img && teacher.img.includes(',')) {
            // Attempt to split and validate base64 data
            const base64Data = teacher.img.split(',')[1];
            if (base64Data) {
              // Convert only if base64Data is valid
              teacher.blobUrl = this.commonUtilsService.convertBase64ToBlobUrl(base64Data, 'image/jpeg');
            }
            else {
              const fallbackBase64 = this._authService.generateFallbackImage(teacher.title);
              teacher.img = fallbackBase64;
              teacher.blobUrl = teacher.img;
            }
          } else {
            const fallbackBase64 = this._authService.generateFallbackImage(teacher.title);
            teacher.img = fallbackBase64;
            teacher.blobUrl = teacher.img;
          }
        });

        this.totalRecords = this.teachersData[0]?.totalRecords;
        this.calculatePages();

        if (this.teachersData == null || this.teachersData.length <= 0) {
          this.noRecordsFound = true;
          this.teachersData = [];
          this.totalRecords = 0;
        } else {
          this.noRecordsFound = false;
        }
        this.isSpinnerShown = false;
        this.isDataLoading = false;
      },
      (error) => {
        this.isDataLoading = false;
        this.isErrorOccurred = true;
        this.isSpinnerShown = false;
      }
    );
  }

  onPageChanged(event: any): void {
    this.currentPage = event;
    this.fetchTeachersData(this.currentPage);
    this.isDataLoading = true;
    this.scrollToContentStart();
  }

  calculatePages(): void {
    const totalPages = Math.ceil(this.totalRecords / this.itemsPerPage);
    this.pages = [];
    if (totalPages <= this.maxVisiblePages) {
      this.pages = Array.from({ length: totalPages }, (_, i) => i + 1);
    } else {
      const startPage = Math.max(
        this.currentPage - Math.floor(this.maxVisiblePages / 2),
        1
      );
      const endPage = Math.min(startPage + this.maxVisiblePages - 1, totalPages);

      if (startPage > 1) {
        this.pages.push(1);
        if (startPage > 2) {
          this.pages.push('...');
        }
      }

      for (let i = startPage; i <= endPage; i++) {
        this.pages.push(i);
      }

      if (endPage < totalPages) {
        if (endPage < totalPages - 1) {
          this.pages.push('...');
        }
        this.pages.push(totalPages);
      }
    }

  }

  navigateToPage(page: any): void {
    if (page >= 1 && page <= this.pages.length) {
      this.currentPage = page;
      this.fetchTeachersData(this.currentPage);
      this.scrollToContentStart();
    }
  }

  navigatePage(direction: 'prev' | 'next'): void {
    if (direction === 'prev' && this.currentPage > 1) {
      this.currentPage--;
    }
    else if (direction === 'next' && this.currentPage < Math.ceil(this.totalRecords / this.itemsPerPage)) {
      this.currentPage++;
    }
    this.fetchTeachersData(this.currentPage);
    this.scrollToContentStart();
  }

  getDisplayRange(): string {
    const start = (this.currentPage - 1) * this.itemsPerPage + 1;
    const end = Math.min(start + this.itemsPerPage - 1, this.totalRecords);
    return `${start} – ${end}`;
  }

  scrollToContentStart(): void {
    const contentStart = document.getElementById('content-start');
    if (contentStart) {
      const topOffset = contentStart.getBoundingClientRect().top + window.pageYOffset - 95;
      window.scrollTo({ top: topOffset, behavior: 'smooth' });
    }
  }

  resetPagination(): void {
    this.currentPage = 1;
    this.calculatePages();
    this.fetchTeachersData(this.currentPage);
    this.scrollToContentStart();
  }

  onResetPagination() {
    this.resetPagination();
  }


  CheckAndCallAPI() {
    if (
      this.receivedData?.search !== '' ||
      this.receivedData?.country !== '' ||
      this.receivedData?.state !== '' ||
      this.receivedData?.subject !== '' ||
      this.receivedData?.city !== ''
    ) {
      this.teachersData = [];
      this.currentPage = 1;
      this.isDataLoading = true;
      this.isSpinnerShown = true;
      this.propertyService.getAllTeachers(this.receivedData, this.currentPage, this.itemsPerPage).subscribe(
        (response: any) => {
          this.teachersData = response.data;
          this.teachersData.forEach((teacher) => {
            if (teacher.img && teacher.img.includes(',')) {
              const base64Data = teacher.img.split(',')[1];
              if (base64Data) {
                teacher.blobUrl = this.commonUtilsService.convertBase64ToBlobUrl(base64Data, 'image/jpeg');
              }
              else {
                const fallbackBase64 = this._authService.generateFallbackImage(teacher.title);
                teacher.img = fallbackBase64;
                teacher.blobUrl = teacher.img;
              }
            } else {
              const fallbackBase64 = this._authService.generateFallbackImage(teacher.title);
              teacher.img = fallbackBase64;
              teacher.blobUrl = teacher.img;
            }
          });
          this.isDataLoading = false;
          this.isSpinnerShown = false;
          this.totalRecords = this.teachersData[0]?.totalRecords;
          this.calculatePages();
          // this.totalRecords = response, this.totalRecords;
          if (this.teachersData == null || this.teachersData?.length <= 0) {
            this.noRecordsFound = true;
            this.teachersData = [];
            this.totalRecords = 0;
          }
          else {
            this.noRecordsFound = false;
          }
        },
        (error) => {
          this.isDataLoading = false;
          this.isSpinnerShown = false;
        }
      );

    }
  }



}
