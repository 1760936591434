<app-loader-two [isLoading]="isSpinnerShown"></app-loader-two>
<app-header-one></app-header-one>
<app-common-banner-1 [imagePath]="bannerImage" [heading1]="'Tutorials'" [heading2]="'Share Your Knowledge'"
  (childEvent)="handleChildEvent($event)"  [showRangeSlider]="false" [showCountryDropdown]="false" [showStateDropdown]="false" [showCityDropdown]="false"
  [showRangeSlider]="false" [showDegreeDropdown]="false" [showJobTypeDropdown]="false"
  [showScholarshipTypeDropdown]="false" [totalRecords]="totalRecords" [showInstituteTypeDropdown]="false" (sendTheSearchValue)="handleSearchData($event)" [showTutorialTypeDropdown]="true"
  (callAPionEmptyFields)="onReset()" [totalRecords]="totalRecords"
  [popUpButton]="{ text: 'Add Tutorial', isShown: true, componentName: 'tutorial' }"
  > </app-common-banner-1>

<div class="container-fluid" id="content-start">
  <!-- <app-banner></app-banner> -->
  <app-grid-panel [heading]="'Tutorials'" [currentPage]="currentPage" [totalRecords]="totalRecords"
    [itemsPerPage]="itemsPerPage"></app-grid-panel>

  @if(isDataLoading){
  <div class="item">
    <ngx-skeleton-loader count="8" [theme]="{
        height: '300px',
        width: '300px',
        'border-radius': '10px',
        'margin-right': '1rem'
        }" />
  </div>
  }@else if(isErrorOccurred){
    <div class="d-flex justify-content-center align-items-center vh-100">
      <div class="no-record" >
        <img src="../../../../../../assets/images/svg/error.gif" alt="Error Ocuurred">
        <p class="align-items-center justify-content-center d-flex">An Error Occurred,Try refreshing Page</p>
      </div>
    </div>
  }@else if (noRecordFound) {
  <div class="no-record">
    <img src="../../../../assets/images/svg/Nodata.gif" alt="No records found">
    <p>No Tutorials Found</p>
  </div>

  }
  @else{
  <app-slider-filter-latest-rent [propertyClass]="true" [tagClass]="'color-6'" [title]="title" [heading]="'All Tutorials'"
  [institutesData]="tutorialData" [isDescriptionShown] = "false" [ShowTutorialTags]="true"></app-slider-filter-latest-rent>

  }

  <!-- pagination starts here -->
  @if(tutorialData.length !== 0){
  <div class="row pagination-container">
    <div class="mt30 d-flex justify-content-center">
      <ngb-pagination [collectionSize]="totalRecords" [(page)]="currentPage" [pageSize]="itemsPerPage"
        (pageChange)="onPageChanged($event)" [maxSize]="maxVisiblePages" [ellipses]="false" [boundaryLinks]="true">
      </ngb-pagination>
    </div>
    <div class="tRecord text-center">
      {{ getDisplayRange() }} of {{ totalRecords }}
    </div>
  </div>
  }
  <!-- pagination ends here -->
</div>

<app-adly-footer></app-adly-footer>