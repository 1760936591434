<app-loader-two [isLoading]="isLoading"></app-loader-two>
<app-header-one></app-header-one>
@if(jobsData){
<app-sticky-tab [instituteData]="jobsData"></app-sticky-tab>
    <app-related-property 
    [type]="'grid-3'" 
    [heading]="heading" 
    [totalData]="3" 
    [instituteData]="relatedJobsData"
    [isDescriptionShown]="true"
    [isSkeletonShown]="isRelatedJobsLoading"
    >
    </app-related-property>
    <app-adly-footer></app-adly-footer>
}


