<div class="row ratio_55 mt-5 advertisement">
  <div class="col-xl-10 ps-2" [ngClass]="{ 'col-xl-12': adImages.length == 0 }">
    <app-title [isLineAdded]="true" [isLabelAdded]="true" [heading]="'Top Teachers'" [tag]="tag" [textWhite]="false"
      [type]="'simple'"></app-title>

    <div class="col-xl-12">
      <div class="container filter-btns">
        <div class="toggle-btns">
          <input type="radio" id="all" name="toggle" checked />
          <label for="all" [ngClass]="{'active': selectedTeacherType === 'All'}" (click)="onFilterClick('All')">All</label>

          <input type="radio" id="math" name="toggle" />
          <label for="math" [ngClass]="{'active': selectedTeacherType === 'Mathematics'}" (click)="onFilterClick('Mathematics')">Math</label>

          <input type="radio" id="physics" name="toggle" />
          <label for="physics" [ngClass]="{'active': selectedTeacherType === 'Physics'}" (click)="onFilterClick('Physics')">Physics</label>


          <input type="radio" id="biology" name="toggle" />
          <label for="biology" [ngClass]="{'active': selectedTeacherType === 'Biology'}" (click)="onFilterClick('Biology')">Biology</label>

          <input type="radio" id="chemistry" name="toggle" />
          <label for="chemistry" [ngClass]="{'active': selectedTeacherType === 'Chemistry'}" (click)="onFilterClick('Chemistry')">Chemistry</label>

          <input type="radio" id="english" name="toggle" />
          <label for="english" [ngClass]="{'active': selectedTeacherType === 'English'}" (click)="onFilterClick('English')">English</label>

          <input type="radio" id="computerScience" name="toggle" />
          <label for="computerScience" [ngClass]="{'active': selectedTeacherType === 'Computer Science'}" (click)="onFilterClick('Computer Science')">Computer Science</label>

        </div>
      </div>
    </div>


    <!-- Show the slider when there are Teachers -->
    @defer {

    <section class="agent-section property-section">

      <app-common-agency [agencyData]="teachersData"></app-common-agency>


    </section>

    }

    <!-- Show "No teacher Found" message if no institutes are present -->
    @if(noRecordsFound){
    <div class="no-record">
      <img src="../../../../../assets/images/svg/Nodata.gif" alt="No Teacher Found" />
      <p>No Teacher Found</p>
    </div>
    }@else if(isErrorOccurred){
    <div>
      <div class="no-record error-svg">
        <img src="../../../../../assets/images/svg/error.gif" alt="Error Ocuurred">
        <p>An Error Occurred,Try refreshing Page</p>
      </div>
    </div>
    }


    <!-- Show the skeleton loader while data is being fetched -->
    <div *ngIf="isDataLoading && !noRecordsFound" class="item">
      <ngx-skeleton-loader count="8" [theme]="{
          height: '26.5rem',
          width: '23%',
          'border-radius': '10px',
          'margin-right': '0.5rem',
          'margin-left': '0.5rem'
        }"></ngx-skeleton-loader>
    </div>



    <div class="all-institute-btn" *ngIf="!isErrorOccurred">
      <app-center-button [buttonText]="'All Teachers'" [route]="'/teacher/teacher-list'"></app-center-button>
    </div>
    <!-- } -->
  </div>
  <div class=" advertisement ads-section" [ngClass]="{
    'col-lg-2': !isScreenSmall, 
    'hideAdvertsment': adImages.length == 0, 
    'col-lg-12': isScreenSmall 
  }">
    @if(!isAdLoading){
    <app-title [heading]="''" [textWhite]="false" [type]="'simple'"></app-title>
    <div class="main-ads-section" (mouseenter)="pauseScroll()" (mouseleave)="startScroll()">
      <div class="ad-images-wrapper">
        @for(ad of adImages; track ad) {
        @if(ad?.imageUrl?.length > 20) {
        <a [href]="ad?.redirectLink" aria-label="clickable-text" target="_blank">
          <img [src]="ad?.imageUrl" class="ad-image" alt="Advertisement" />
        </a>
        }
        }
        @for(ad of adImages;track ad){
        @if(ad?.imageUrl?.length > 20){
        <a [href]="ad?.redirectLink" target="_blank" aria-label="clickable-text">
          <img [src]="ad?.imageUrl" class="ad-image" alt="Advertisement" />
        </a>
        }
        }
      </div>
    </div>
    }

    @else{
    <!-- <ng-template #advertismnets> -->
    <div class="item add-container">
      <ngx-skeleton-loader count="1" [theme]="{
            height: addSectionCss.height,
            width: addSectionCss.width,
            'border-radius': '10px',
            'margin-right': '0.5rem',
            'margin-left': '0.5rem'
          }"></ngx-skeleton-loader>
    </div>
    }
  </div>
</div>