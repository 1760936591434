
@if(banner == true){

<app-header-one></app-header-one>


@if (isOnContactUsPage === true) {
<app-breadcrumb [bgImage]="bgImage" [title]="title" [parent]="parent" [child]="child"></app-breadcrumb>
} }

<section class="small-section get-in-touch">
  <div class="container">
    @if (isOnContactUsPage === false) {
    <app-title [isLineAdded]="true" [isLabelAdded]="true" [heading]="'Contact Us'" [tag]="tag1" [textWhite]="false"
      [type]="'simple'"></app-title>
    }
    <div class="row">
      <div class="col-lg-6 contact-img">
        <img src="../../../../../assets/images/contactus.png" class="img-fluid" alt="image" width="2000" height="2000" />
      </div>
      <div class="col-lg-6">
        <div class="log-in">
          <div class="mb-4 text-start">
            <h2>Let's Get In Touch</h2>
          </div>
          <form class="row gx-3" [formGroup]="contactUsForm">
            <div class="form-group col-md-12">
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    <app-feather-icons [icon]="'user'"style="color: var(--mainColor);"></app-feather-icons>
                  </div>
                </div>
                <input type="text" class="form-control" formControlName="name" placeholder="Enter your name"
                  required="" />
              </div>
              @if ( contactUsForm.get('name')?.touched &&
              contactUsForm.get('name')?.hasError('required')) {
              <small class="text-danger">Name is required</small>
              }
            </div>
            <div class="form-group col-md-6">
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    <app-feather-icons [icon]="'phone'" style="color: var(--mainColor);"></app-feather-icons>
                  </div>
                </div>
                <input placeholder="phone number" class="form-control" name="mobnumber" formControlName="mobnumber"
                id="phoneNumber" required="" />
              </div>
              @if ( contactUsForm.get('mobnumber')?.touched &&
              contactUsForm.get('mobnumber')?.hasError('required')) {
              <small class="text-danger">Phone Number is required</small>
              }
              @if(contactUsForm.get('mobnumber')?.hasError('pattern') &&
              contactUsForm.get('mobnumber')?.touched){
              <div class="text-danger"></div>
              <small class="text-danger">Invalid Phone Number Format</small>
              }
            </div>
            <div class="form-group col-md-6">
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    <app-feather-icons [icon]="'mail'" style="color: var(--mainColor);"></app-feather-icons>
                  </div>
                </div>
                <input type="email" class="form-control" placeholder="email address" formControlName="email"
                  required="" />
              </div>
              @if ( contactUsForm.get('email')?.touched &&
              contactUsForm.get('email')?.hasError('required')) {
              <small class="text-danger">Email is required</small>
              }
              @if(contactUsForm.get('email')?.hasError('pattern') &&
              contactUsForm.get('email')?.touched){
              <small class="text-danger">Invalid Email Format</small>
              }
            </div>
            <div class="form-group col-md-12">
              <textarea class="form-control" id="exampleFormControlTextarea1" rows="6" formControlName="message"
                placeholder="Enter your message"></textarea>
              @if ( contactUsForm.get('message')?.touched &&
              contactUsForm.get('message')?.hasError('required')) {
              <small class="text-danger">Message is required</small>
              }
            </div>

            <div class="col-md-12 submit-btn">
              <div class="submit-btn with-captcha">
                <!-- <div class="captcha">
                                    <div class="spinner">
                                        <label>
                                            <input type="checkbox">
                                            <span class="checkmark"><span>&nbsp;</span></span>
                                        </label>
                                    </div>
                                    <div class="text">
                                        I'm not a robot
                                    </div>
                                    <div class="logo">
                                        <img src="assets/images/inner-pages/recaptcha.png" alt="capture-image" />
                                        <p>reCAPTCHA</p>
                                        <small>Privacy - Terms</small>
                                    </div>
                                </div> -->
                <div></div>
                <div class="all-institute-btn">
                  <app-center-button [buttonText]="'Send Your Message'" (buttonClick)="openEmail()"></app-center-button>
                </div>
                <!-- (buttonClick)="onSubmit() -->
                <!-- <app-center-button (clickOutside)="onSubmit()" [style.cursor]="this.contactUsForm.invalid ? 'not-allowed' : 'pointer'" [buttonText]="'Send Your Message'"></app-center-button> -->
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  @if(location == true){ @if (isOnContactUsPage == true) {
  <section class="small-section contact_section pt-0 contact_bottom">
    <div class="container">
      <div class="row">
        <app-title [isLabelAdded]="true" [isLineAdded]="true" [tag]="tag" [titleClass]="titleClass" [heading]="heading"
          [textWhite]="false" [type]="type" [desc]="desc"></app-title>
        <div class="col-lg-8 col-sm-12 merged-columns">
          <div class="content_wrap">
            <div class="desc-box">
              <div class="page-section" id="location">
                <iframe class="iframe"  id="gmap_canvas"
                title="realestate location"
                  src="https://maps.google.com/maps?q=+221-22+B+Side+Commercial%2C+Tulip+Extension+Tulip+Block+Sector+C+Bahria+Town%2C+Lahore%2C+Punjab+53720&t=&z=13&ie=UTF8&iwloc=&output=embed">
                </iframe>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-sm-12 special">
          <div class="desc-box contact_wrap">
            <div class="center-content">
              <app-feather-icons [icon]="'map-pin'" class="icon" id="icon"></app-feather-icons>
              <h4>Where ?</h4>
              <ul>
                <li>Bahria Town</li>
                <li>Lahore</li>
                <li>+92 333 8828442</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  } }
</section>

@if (isOnContactUsPage == true) {
<app-adly-footer></app-adly-footer>
}