<ng-template #addJob>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">
                Add New Job
            </h5>
            <button type="button" class="btn-close" (click)="modal.dismissAll()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <form [formGroup]="addJobForm">
                <div class="row gx-3">
                    <div class="form-group col-6 col-sm-4 ">
                        <label for="a-na">Job Title<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="a-na" placeholder="Enter Job Title"
                            formControlName="job_title">
                        @if(addJobForm.get('job_title')?.hasError('required') &&
                        addJobForm.get('job_title')?.touched){
                        <small class="text-danger">Name is Required</small>
                        }
                    </div>
                    <div class="form-group col-6 col-sm-4 ">
                        <label for="a-na">Company Name<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="a-na" placeholder="Enter Company Name"
                            formControlName="company_name">
                        @if(addJobForm.get('company_name')?.hasError('required') &&
                        addJobForm.get('company_name')?.touched){
                        <small class="text-danger">Company Name is Required</small>
                        }
                    </div>
                    <div class="form-group col-6 col-sm-4 modal-container">
                        <label for="a-na">Job Type<span class="text-danger">*</span></label>
                        <!-- <input type="text" class="form-control" id="a-na"> -->
                        <app-common-dropdowns class="first-dropdown" [defaultText]="'Select Job Type'"
                            [dataList]="JobTypeList" (optionSelected)="onJobTypeSelected($event)" [isDisabled]="true"
                            [selectedOption]="jobType" [clearThevalue]="jobType"></app-common-dropdowns>
                        @if(jobType == "" && jobTypeError){
                        <small class="text-danger">Job Type is Required</small>
                        }
                    </div>
                    <!-- </div>
                <div class="row gx-3"> -->
                    <div class="form-group col-6 col-sm-4">
                        <label for="a-na">Salary<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="a-na" placeholder="Enter Salary"
                            formControlName="salary">
                        @if(addJobForm.get('salary')?.hasError('required') && addJobForm.get('salary')?.touched) {
                        <small class="text-danger">Salary is Required</small>
                        }
                    </div>


                    <div class="form-group col-6 col-sm-4 modal-container">
                        <label for="a-n">Country<span class="text-danger">*</span></label>
                        <app-common-dropdowns class="second-dropdown" [defaultText]="'Enter Country Name'"
                            [dataList]="CountryList" (optionSelected)="onCountrySelected($event)"
                            [selectedOption]="Country" [clearThevalue]="Country"
                            (sendTheValue)="onCountryValueChange($event)"></app-common-dropdowns>
                        @if(Country == "" && countryTypeError){
                        <small class="text-danger">Country is Required</small>
                        }
                    </div>
                    <div class="form-group col-6 col-sm-4 modal-container">
                        <label for="a-na">State<span class="text-danger">*</span></label>
                        <app-common-dropdowns class="third-dropdown" [defaultText]="'Enter State Name'"
                            [dataList]="StateList" (optionSelected)="onStateSelected($event)" [selectedOption]="State"
                            [clearThevalue]="State" (sendTheValue)="onStateValueChange($event)"></app-common-dropdowns>
                        @if(State == "" && stateError){
                        <small class="text-danger">State is Required</small>
                        }
                    </div>
                    <!-- </div>
                <div class="row gx-3"> -->
                    <div class="form-group col-6 col-sm-4 modal-container">
                        <label for="a-n">City<span class="text-danger">*</span></label>
                        <app-common-dropdowns class="fourth-dropdown" [defaultText]="'Enter City Name'"
                            [dataList]="CityList" (optionSelected)="onCitySelected($event)" [selectedOption]="City"
                            [clearThevalue]="City"></app-common-dropdowns>
                        @if(City == "" && cityError){
                        <small class="text-danger">City is Required</small>
                        }
                    </div>
                    <div class="form-group col-6 col-sm-4">
                        <label for="job-address">Address<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="job-address" placeholder="Enter Address"
                            formControlName="job_address">
                        @if(addJobForm.get('job_address')?.hasError('required') &&
                        addJobForm.get('job_address')?.touched) {
                        <small class="text-danger">Address is Required</small>
                        }
                    </div>

                    <div class="form-group col-6 col-sm-4">
                        <label for="job-address-title">Address Title<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="job-address-title" placeholder="Enter Address Title"
                            formControlName="job_addressTitle">
                        @if(addJobForm.get('job_addressTitle')?.hasError('required') &&
                        addJobForm.get('job_addressTitle')?.touched) {
                        <small class="text-danger">Address Title is Required</small>
                        }
                    </div>
                    <!-- </div>
                <div class="row gx-3"> -->

                    <div class="form-group col-6 col-sm-4">
                        <label for="job-address-link">Address Link<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="job-address-link" placeholder="Enter Address Link"
                            formControlName="job_addressLink">
                        @if(addJobForm.get('job_addressLink')?.hasError('required') &&
                        addJobForm.get('job_addressLink')?.touched) {
                        <small class="text-danger">Address Link is Required</small>
                        }
                        @if(addJobForm.get('job_addressLink')?.touched &&
                        addJobForm.get('job_addressLink')?.hasError('pattern')) {
                        <small class="text-danger">https://maps.google.com/maps link is required</small>
                        }
                    </div>

                    <div class="form-group col-6 col-sm-4">
                        <label for="job-site-link">Site Link<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="job-site-link" placeholder="Enter Website Link"
                            formControlName="job_siteLink">
                        @if(addJobForm.get('job_siteLink')?.hasError('required') &&
                        addJobForm.get('job_siteLink')?.touched) {
                        <small class="text-danger">Site link is Required</small>
                        }
                        @if(addJobForm.get('job_siteLink')?.touched &&
                        addJobForm.get('job_siteLink')?.hasError('pattern')) {
                        <small class="text-danger">Valid link starting with https:// is required</small>
                        }
                    </div>

                    <div class="form-group col-6 col-sm-4">
                        <label for="job-phone">Contact Number<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="job-phone" placeholder="Enter Phone Number"
                            formControlName="job_phone" id="myInput" minlength="8" maxlength="16">
                        @if(addJobForm.get('job_phone')?.hasError('required') && addJobForm.get('job_phone')?.touched) {
                        <small class="text-danger">Phone number is Required</small>
                        }
                        @if(addJobForm.get('job_phone')?.touched && addJobForm.get('job_phone')?.hasError('pattern')) {
                        <small class="text-danger">Phone number must be between 10 and 18 digits</small>
                        }
                    </div>

                    <div class="form-group col-6 col-sm-4">
                        <label for="application-deadline">Application Deadline<span class="text-danger">*</span></label>
                        <input type="date" class="form-control" id="application-deadline"
                            formControlName="application_deadline">
                        @if(addJobForm.get('application_deadline')?.hasError('required') &&
                        addJobForm.get('application_deadline')?.touched) {
                        <small class="text-danger">Application deadline is required</small>
                        }
                        @if(addJobForm.get('application_deadline')?.touched &&
                        addJobForm.get('application_deadline')?.hasError('invalidDate')) {
                        <small class="text-danger">Invalid deadline. Please select a future date.</small>
                        }
                    </div>

                    <!-- </div>
                <div class="row gx-3"> -->
                    <div class="form-group col-12 col-sm-4">
                        <label for="job-education">Education<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="job-education" placeholder="Add Education"
                            formControlName="education" (keyup.enter)="onEnterEducation($event)">
                        @if(educationTags.length == 0 && addJobForm.get('education')?.touched) {
                        <small class="text-danger">Add at least one Education</small>
                        }
                        @for(educationTag of educationTags; track educationTag) {
                        <div class="tag" (click)="removeEducationTag(educationTag)">
                            {{ educationTag.educationTitle }}
                            <span class="close-icon">&times;</span>
                        </div>
                        }
                    </div>

                    <div class="form-group col-12 col-sm-4">
                        <label for="job-experience">Experience<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="job-experience" placeholder="Add Experience"
                            formControlName="experience" (keyup.enter)="onEnterExperience($event)">
                        @if(experienceTags.length == 0 && addJobForm.get('experience')?.touched) {
                        <small class="text-danger">Add at least one Experience</small>
                        }
                        @for(experienceTag of experienceTags; track experienceTag) {
                        <div class="tag" (click)="removeExperienceTag(experienceTag)">
                            {{ experienceTag.experienceTitle }}
                            <span class="close-icon">&times;</span>
                        </div>
                        }
                    </div>

                    <div class="form-group col-12 col-sm-4">
                        <label for="job-skills">Skills<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="job-skills" placeholder="Add Skills"
                            formControlName="skills" (keyup.enter)="onEnterSkills($event)">
                        @if(skillsTags.length == 0 && addJobForm.get('skills')?.touched) {
                        <small class="text-danger">Add at least one Skill</small>
                        }
                        @for(skillTag of skillsTags; track skillTag) {
                        <div class="tag" (click)="removeSkillTag(skillTag)">
                            {{ skillTag.skillTitle }}
                            <span class="close-icon">&times;</span>
                        </div>
                        }

                    </div>

                    <!-- </div>
                <div class="row gx-3"> -->



                </div>

                <!-- Job Description -->
                <div class="form-group col-12">
                    <label for="job-description">Job Description<span class="text-danger">*</span></label>
                    <textarea rows="4" id="job-description" class="form-control" placeholder="Enter Job Description"
                        formControlName="job_description" (input)="updateJobDescriptionCharCount()"></textarea>
                    <small>{{ jobDescriptionCharCount }}/400</small>
                    @if(addJobForm.get('job_description')?.hasError('required') &&
                    addJobForm.get('job_description')?.touched){
                    <small class="text-danger">Job Description is Required</small>
                    }
                </div>

                <!-- How to Apply -->
                <div class="form-group col-12">
                    <label for="how-to-apply">How to Apply<span class="text-danger">*</span></label>
                    <textarea rows="4" id="how-to-apply" class="form-control" placeholder="Enter How to Apply"
                        formControlName="how_to_apply" (input)="updateHowToApplyCharCount()"></textarea>
                    <small>{{ howToApplyCharCount }}/400</small>
                    @if(addJobForm.get('how_to_apply')?.hasError('required') &&
                    addJobForm.get('how_to_apply')?.touched){
                    <small class="text-danger">How to Apply is Required</small>
                    }
                </div>

                <!-- Responsibilities -->
                <div class="form-group col-12">
                    <label for="responsibilities">Responsibilities</label>
                    <textarea rows="4" id="responsibilities" class="form-control" placeholder="Enter Responsibilities"
                        formControlName="responsibilities" (input)="updateResponsibilitiesCharCount()"></textarea>
                    <small>{{ responsibilitiesCharCount }}/400</small>
                    @if(addJobForm.get('responsibilities')?.hasError('required') &&
                    addJobForm.get('responsibilities')?.touched){
                    <small class="text-danger">Responsibilities are Required</small>
                    }
                </div>

                <!-- Additional Note -->
                <div class="form-group col-12">
                    <label for="additional-note">Additional Note</label>
                    <textarea rows="4" id="additional-note" class="form-control" placeholder="Enter Additional Note"
                        formControlName="additional_note" (input)="updateAdditionalNoteCharCount()"></textarea>
                    <small>{{ additionalNoteCharCount }}/400</small>
                    @if(addJobForm.get('additional_note')?.hasError('required') &&
                    addJobForm.get('additional_note')?.touched){
                    <small class="text-danger">Additional Note is Required</small>
                    }
                </div>


                <div class="dropzone-admin mb-0">
                    <label>Media <span class="font-danger">*</span></label>
                    <form class="dropzone" id="multiFileUpload">
                        <ngx-dropzone (change)="onSelect($event)" accept="image/jpeg,image/jpg,image/png"
                            [multiple]="false">
                            <ngx-dropzone-label>
                                <div class="dz-message needsclick">
                                    <i class="fas fa-cloud-upload-alt"></i>
                                    <h6 class="f-w-700 mb-0">
                                        Drop files here or click to upload.
                                    </h6>
                                </div>
                            </ngx-dropzone-label>
                            @for(f of files; track f){
                            <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" [removable]="true"
                                (removed)="onRemove(f)" [file]="f">
                                <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>

                            </ngx-dropzone-image-preview>
                            }
                        </ngx-dropzone>
                    </form>
                </div>

            </form>
            @if(imageError){
                <small class="text-danger">Image is Required</small>
                }
                @else if(imageSizeError){
                <small class="text-danger">Image size should be less than 1MB</small>
                }
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-dashed color-2 btn-pill" (click)="modal.dismissAll()">Cancel</button>
            <button type="button" class="btn btn-gradient color-2 btn-pill" (click)="onSubmit()"> 
                Add Job</button>
        </div>
    </div>

</ng-template>