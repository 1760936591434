<app-loader-two [isLoading]="isSpinnerShown"></app-loader-two>
<app-header-one></app-header-one>
<section class="user-dashboard small-section">
    <div class="container">
        <div class="row">
            <div class="col-lg-3">
                <div class="sidebar-user sticky-cls">
                    <div class="user-profile">
                        <app-user-info></app-user-info>
                    </div>
                    <div class="dashboard-list">
                        <app-user-panel-side-menu [activeTab]="'add-scholarships'"></app-user-panel-side-menu>
                    </div>
                </div>
            </div>
            <div class="col-lg-9">
                <div class="dashboard-content">
                    <div class="payment-user" id="payment">
                        <div class="common-card">
                            <div class="common-header">
                                <h5>Scholarships</h5>
                            </div>

                            <div class="row card-payment">
                                @if (isDataLoading) {
                                <div class="item">
                                    <ngx-skeleton-loader count="3" [theme]="{
                                                            height: '22.5rem',
                                                            width: '30%',
                                                            'border-radius': '10px',
                                                            'margin-right': '0.5rem',
                                                            'margin-left': '0.5rem'
                                                            }" />
                                </div>
                                }@else{
                                @for(data of scholarshipsData; track data){
                                <div class="col-xl-4 col-sm-6">

                                    <div class="property-box payment-card">
                                        <div class="property-image">
                                            <!-- <owl-carousel-o [options]="Options">
                                                @for(images of data?.images; track images){
                                                <ng-template carouselSlide>
                                                    <div class="property-slider" [class]="tagClass">
                                                        <a href="javascript:void(0)" class="bg-size"
                                                            [style.background-image]="'url('+images?.imageBase64+')'">
                                                            <img [src]="images?.imageBase64" class="bg-img"
                                                                alt="property-image" style="display: none;" />
                                                        </a>
                                                    </div>
                                                </ng-template>
                                               
                                                }
                                            </owl-carousel-o> -->
                                            <div class="property-slider" [class]="tagClass">
                                                <div href="javascript:void(0)" class="bg-size"
                                                    [style.background-image]="'url(' + data?.imageBase64 + ')'">
                                                    <img [src]="data?.imageBase64" class="bg-img" alt="property-image"
                                                        style="display: none;" />
                                                        <div class="card-labels">
                                                            @if(data?.status=="Verified"){
                                                            <span class="label  label-success">Verified
                                                            </span>
                                                            }@else if(data?.status=="Pending"){
                                                            <span class="label  label-dark">Pending
                                                            </span>
                                                            }@else if(data?.status=="Declined"){
                                                            <span class="label  label-danger">Declined
                                                            </span>
                                                            }    
                                                        </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="property-details">
                                            <span class="font-roboto card-title">
                                                <app-feather-icons class="color"
                                                    [icon]="'map-pin'"></app-feather-icons>{{ data?.city + ", "
                                                + data?.country }}</span>
                                            <a href="javascript:void(0)">
                                                <h3 class="line-clamp">{{ data?.scholarshipName }}</h3>
                                            </a>
                                            <p class="font-roboto description-clamp">{{ data.description }}</p>
                                            <ul>
                                                <li>
                                                    <img src="assets/images/svg/icon/telephone.png"
                                                        class="img-fluid ruler-tool" alt="ruler-icon" />{{
                                                    data?.contactNumber }}
                                                </li>
                                                <li class="border-line"></li>
                                                <li>
                                                    <img src="assets/images/svg/icon/email.png"
                                                        class="img-fluid ruler-tool" alt="ruler-icon" />
                                                    {{ data?.email }}
                                                </li>
                                            </ul>
                                            <!-- <div class="property-btn d-flex">
                                                <button class="btn btn-link">
                                                    Details<i class="fas fa-arrow-right ms-1"></i>
                                                </button>
                                            </div> -->
                                        </div>
                                        <div class="edit-card">
                                            <a href="javascript:void(0)"
                                                (click)="editCard(addScholarship,data?.id)">edit</a>
                                            <a href="javascript:void(0)"
                                                (click)="deleteCard(deleteModal,data?.id)">delete</a>
                                                <!-- <a href="javascript:void(0)" (click)="boostCard(boostScholarship,data?.id)">Boost</a>
                                                <a href="javascript:void(0)"
                                                (click)="editAdCard(boostScholarship,data?.id)">Edit Ad</a> -->
                                        </div>
                                    </div>


                                </div>
                                }
                                }
                                <div class=" col-xl-4 col-sm-6">
                                    <div class="payment-card add-card" (click)="addCard(addScholarship)">
                                        <div class="card-details">
                                            <div>
                                                <i class="fas fa-plus-circle"></i>
                                                <h5>Add new Scholarship</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<app-adly-footer></app-adly-footer>


<ng-template #addScholarship>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">
                @if(FormName == "Add"){
                    Add New Scholarship
                    }@else{
                    Update Scholarship
                    }
            </h5>
            <button type="button" class="btn-close" (click)="modal.dismissAll()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <form [formGroup]="addScholarshipForm">
                <div class="row gx-3">
                    <div class="form-group col-6 col-sm-4">
                        <label for="a-na">Scholarship Name<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="a-na" placeholder="Enter Scholarship Name"
                            formControlName="scholarship_name">
                        @if(addScholarshipForm.get('scholarship_name')?.hasError('required') &&
                        addScholarshipForm.get('scholarship_name')?.touched){

                        <small class="text-danger">Name is Required</small>
                        }

                    </div>
                    <div class="form-group col-6 col-sm-4">
                        <label for="a-in">Institute Name<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="a-in" placeholder="Enter Institute Name"
                            formControlName="institute_name">
                        @if(addScholarshipForm.get('institute_name')?.hasError('required') &&
                        addScholarshipForm.get('institute_name')?.touched) {
                        <small class="text-danger">Name is Required</small>
                        }

                    </div>
                    <div class="form-group col-6 col-sm-4 modal-container">
                        <label for="a-st">Scholarship Type<span class="text-danger">*</span></label>
                        <app-common-dropdowns class="first-dropdown" [defaultText]="'Select Scholarship Type'"
                            [dataList]="ScholarshipTypeList" (optionSelected)="onScholarshipTypeSelected($event)"
                            [selectedOption]="ScholarshipType"></app-common-dropdowns>

                        @if(ScholarshipType == "" && scholarshipTypeError){
                        <small class="text-danger">Type is Required</small>
                        }

                    </div>
                    <div class="form-group col-6 col-sm-4 modal-container">
                        <label for="a-n">Country<span class="text-danger">*</span></label>
                        <app-common-dropdowns 
                        class="second-dropdown" 
                        [defaultText]="'Enter Country Name'"
                        [dataList]="CountryList" 
                        (optionSelected)="onCountrySelected($event)"
                        [selectedOption]="Country" 
                        [clearThevalue]="Country"
                        (sendTheValue)="onCountryValueChange($event)"
                        ></app-common-dropdowns>
                        @if(Country == "" && countryError){
                        <small class="text-danger">Country is Required</small>
                        }
                    </div>
                    <div class="form-group col-6 col-sm-4 modal-container">
                        <label for="a-na">State<span class="text-danger">*</span></label>
                        <app-common-dropdowns class="third-dropdown" 
                        [defaultText]="'Enter State Name'"
                        [dataList]="StateList" 
                        (optionSelected)="onStateSelected($event)" 
                        [selectedOption]="State"
                        [clearThevalue]="State" 
                        (sendTheValue)="onStateValueChange($event)"
                        (touched)="onCountryFocused($event)"
                        ></app-common-dropdowns>
                        @if(countryError && Country === "" && CountrysearchTerm ===""){
                            <small class="text-danger">Please Select Country First</small>
                            }
                    </div>

                    <div class="form-group col-6 col-sm-4 modal-container">
                        <label for="a-n">City<span class="text-danger">*</span></label>
                        <app-common-dropdowns class="fourth-dropdown" 
                        [defaultText]="'Enter City Name'"
                        [dataList]="CityList" 
                        (optionSelected)="onCitySelected($event)" 
                        [selectedOption]="City"
                        [clearThevalue]="City"
                        (touched)="onStateFocused($event)"
                        ></app-common-dropdowns>
                        @if(stateError && State === "" && StatesearchTerm === ""){
                            <small class="text-danger">Please Select State First</small>
                            }
                    </div>
                    <div class="form-group col-6 col-sm-4">
                        <label for="a-ad">Address<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="a-ad" placeholder="Enter Address"
                            formControlName="scholarship_address">
                        @if(addScholarshipForm.get('scholarship_address')?.hasError('required') &&
                        addScholarshipForm.get('scholarship_address')?.touched) {
                        <small class="text-danger">Address is Required</small>
                        }

                    </div>

                    <div class="form-group col-6 col-sm-4">
                        <label for="a-at">Address Title<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="a-at" placeholder="Enter Address Title"
                            formControlName="scholarship_addressTitle">
                        @if(addScholarshipForm.get('scholarship_addressTitle')?.hasError('required') &&
                        addScholarshipForm.get('scholarship_addressTitle')?.touched) {
                        <small class="text-danger">Address is Required</small>
                        }

                    </div>

                    <div class="form-group col-6 col-sm-4">
                        <label for="a-al">Address Link<span class="text-danger">*</span></label>
                        <input type="url" class="form-control" id="a-al" placeholder="Enter Google Maps Link"
                            formControlName="scholarship_addressLink">
                        @if(addScholarshipForm.get('scholarship_addressLink')?.hasError('required') &&
                        addScholarshipForm.get('scholarship_addressLink')?.touched) {
                        <small class="text-danger">Address Link is Required</small>
                        }@else if(addScholarshipForm.get('scholarship_addressLink')?.touched &&
                        addScholarshipForm.get('scholarship_addressLink')?.hasError('pattern')) {
                        <small class="text-danger">https://maps.google.com/maps link is required</small>
                        }
                    </div>

                    <div class="form-group col-6 col-sm-4">
                        <label for="a-em">Email Address<span class="text-danger">*</span></label>
                        <input type="email" class="form-control" id="a-em" placeholder="Enter Email Address"
                            formControlName="email_address">
                        @if(addScholarshipForm.get('email_address')?.hasError('required') &&
                        addScholarshipForm.get('email_address')?.touched){
                        <small class="text-danger">Email is Required</small>
                        }@else if(addScholarshipForm.get('email_address')?.touched &&
                        addScholarshipForm.get('email_address')?.hasError('pattern')
                        ){
                        <small class="text-danger">Invalid Email</small>
                        }
                    </div>

                    <div class="form-group col-6 col-sm-4">
                        <label for="a-ph">Phone Number<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="myInput" placeholder="Enter Phone Number"
                            formControlName="scholarship_phone" minlength="8" maxlength="16">

                        @if(addScholarshipForm.get('scholarship_phone')?.hasError('required') &&
                        addScholarshipForm.get('scholarship_phone')?.touched) {
                        <small class="text-danger">Phone number is Required</small>
                        }@else if(addScholarshipForm.get('scholarship_phone')?.touched &&
                        addScholarshipForm.get('scholarship_phone')?.hasError('pattern')) {
                        <small class="text-danger">Phone number must be between 10 and 18 digits</small>
                        }

                    </div>

                    <div class="form-group col-6 col-sm-4">
                        <label for="a-sl">Application Link<span class="text-danger">*</span></label>
                        <input type="url" class="form-control" id="a-sl" placeholder="Enter Application Link"
                            formControlName="application_Link">


                        @if(addScholarshipForm.get('application_Link')?.hasError('required') &&
                        addScholarshipForm.get('application_Link')?.touched) {
                        <small class="text-danger">Application link is Required</small>
                        }@else if(addScholarshipForm.get('application_Link')?.touched &&
                        addScholarshipForm.get('application_Link')?.hasError('pattern')) {
                        <small class="text-danger">Valid Application starting with https:// is required</small>
                        }
                    </div>
                    <div class="form-group col-6 col-sm-4">
                        <label for="">Application Fee<span class="text-danger">*</span></label>
                        <input type="number" class="form-control" id="a-sa" placeholder="Enter Scholarship Fee"
                            formControlName="scholarship_fee">
                        @if(addScholarshipForm.get('scholarship_fee')?.hasError('required') &&
                        addScholarshipForm.get('scholarship_fee')?.touched) {
                        <small class="text-danger">Amount Required</small>
                        }

                    </div>
                    <div class="form-group col-6 col-sm-4">
                        <label for="a-sd">Start Date<span class="text-danger">*</span></label>
                        <input type="date" class="form-control" id="a-sd" placeholder="Select Start Date"
                            formControlName="start_date" (change)="updateEndDateMin()">
                        @if(addScholarshipForm.get('start_date')?.hasError('required') &&
                        addScholarshipForm.get('start_date')?.touched) {
                        <small class="text-danger">Start Date is Required</small>
                        }

                    </div>

                    <div class="form-group col-6 col-sm-4">
                        <label for="a-ed">End Date<span class="text-danger">*</span></label>
                        <input type="date" class="form-control" id="a-ed" placeholder="Select End Date"
                            formControlName="end_date" [min]="minEndDate">
                        @if(addScholarshipForm.get('end_date')?.hasError('required') &&
                        addScholarshipForm.get('end_date')?.touched) {
                        <small class="text-danger">End Date is Required</small>
                        }

                    </div>
                    <div class="form-group col-6 col-sm-4">
                        <label for="a-cgpa">Minimum CGPA<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="a-cgpa" placeholder="Enter Minimum CGPA"
                            formControlName="minimum_cgpa">

                        @if(addScholarshipForm.get('minimum_cgpa')?.hasError('required') &&
                        addScholarshipForm.get('minimum_cgpa')?.touched) {
                        <small class="text-danger">Minimum CGPA is Required</small>
                        }@else if(addScholarshipForm.get('minimum_cgpa')?.hasError('greaterThanFour') &&
                        addScholarshipForm.get('minimum_cgpa')?.touched) {
                        <small class="text-danger">CGPA cannot be greater than 4</small>
                        }

                    </div>
                    <div class="form-group col-6 col-sm-4 modal-container">
                        <label for="a-du">Scholarship Duration<span class="text-danger">*</span></label>
                        <app-common-dropdowns class="first-dropdown" [defaultText]="'Select Scholarship Duration'"
                            [dataList]="DurationTimeList" (optionSelected)="onDurationSelected($event)"
                            [isDisabled]="true" [selectedOption]="Duration"
                            [clearThevalue]="Duration"></app-common-dropdowns>
                        @if(Duration == "" && durationError ) {
                        <small class="text-danger">Duration is Required</small>
                        }

                    </div>
                    <div class="form-group col-6 col-sm-4">
                        <label for="a-sa">Scholarship Amount<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="a-sa" placeholder="Enter Scholarship Amount"
                            formControlName="scholarship_amount">
                        @if(addScholarshipForm.get('scholarship_amount')?.hasError('required') &&
                        addScholarshipForm.get('scholarship_amount')?.touched) {
                        <small class="text-danger">Invalid Amount</small>
                        }

                    </div>
                    <div class="form-group col-12 col-sm-4">
                        <label for="job-education">Eliglbe Courses<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="job-education" placeholder="Add Courses"
                            formControlName="courses_name" (keyup.enter)="onEnterKeyPress($event)">
                        @if((coursestags.length == 0  && addScholarshipForm.get('courses_name')?.touched) || courseTagError) {
                        <small class="text-danger">Add at least one Course</small>
                        }
                        @for(courseTag of coursestags; track courseTag) {
                        <div class="tag" (click)="removeCourseTag(courseTag)">
                            {{ courseTag.courseName }}
                            <span class="close-icon">&times;</span>
                        </div>
                        }
                    </div>

                    <div class="form-group col-12 col-sm-4">
                        <label for="job-experience">Coverages<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="job-experience" placeholder="Add Coverages"
                            formControlName="coverage_Tags" (keyup.enter)="onEnterCoverage($event)">
                        @if((coverageTags.length == 0 && addScholarshipForm.get('coverage_Tags')?.touched) || coverageTagError) {
                        <small class="text-danger">Add at least one Coverage</small>
                        }
                        @for(coverage of coverageTags; track coverageTags){
                        <div class="tag" (click)="removeCoverageTags(coverage)">
                            {{ coverage.coverageName }}
                            <span class="close-icon">&times;</span>
                        </div>
                        }
                    </div>

                    <div class="form-group col-12 col-sm-4">
                        <label for="job-skills">Required Documents<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="job-skills" placeholder="Add Required Documents"
                            formControlName="required_documents" (keyup.enter)="onEnterDocuments($event)">
                        @if((requiredDocuments.length == 0 && addScholarshipForm.get('required_documents')?.touched) || docuemntsTagError) {
                        <small class="text-danger">Add at least one Document</small>
                        }
                        @for(requiredDocument of requiredDocuments; track requiredDocument){
                        <div class="tag" (click)="removeDocumentsTags(requiredDocument)">
                            {{ requiredDocument.documentName }}
                            <span class="close-icon">&times;</span>
                        </div>
                        }

                    </div>

                </div>

                <div class="row gx-3">
                    <div class="form-group col-md-12 col-sm-12">
                        <label for="a-desc">Scholarship Description<span class="text-danger">*</span></label>
                        <textarea class="form-control" id="a-desc" rows="4" placeholder="Enter Scholarship Description"
                            formControlName="scholarship_description"
                            (input)="updateScholarshipDescriptionCharCount()"></textarea>

                        <small>{{ scholarshipDescriptionCharCount }}/400</small>

                        @if(addScholarshipForm.get('scholarship_description')?.hasError('required') &&
                        addScholarshipForm.get('scholarship_description')?.touched){
                        <br><small class="text-danger"> Description is Required</small>
                        }

                    </div>
                </div>

                <div class="row gx-3">
                    <div class="form-group col-md-12 col-sm-12">
                        <label for="a-apply">How to Apply<span class="text-danger">*</span></label>
                        <textarea class="form-control" id="a-apply" rows="5"
                            placeholder="Enter How to Apply Information" formControlName="how_to_apply"
                            (input)="updateHowToApplyCharCount()"></textarea>

                        <small>{{ howToApplyCharCount }}/400</small>
                        @if(addScholarshipForm.get('how_to_apply')?.hasError('required') &&
                        addScholarshipForm.get('how_to_apply')?.touched){
                        <br><small class="text-danger">How to Apply is Required</small>
                        }

                    </div>
                </div>

            </form>
            <div class="dropzone-admin mb-0">
                <label>Media <span class="font-danger">*</span></label>
                @if(FormName == "Add"){
                <form class="dropzone" id="multiFileUpload">
                    <ngx-dropzone (change)="onSelect($event)" accept="image/jpeg,image/jpg,image/png"
                        [multiple]="false">
                        <ngx-dropzone-label>
                            <div class="dz-message needsclick">
                                <i class="fas fa-cloud-upload-alt"></i>
                                <h6 class="f-w-700 mb-0">
                                    Drop files here or click to upload.
                                </h6>
                            </div>
                        </ngx-dropzone-label>
                        @for(f of files; track f){
                        <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" [removable]="true"
                            (removed)="onRemove(f)" [file]="f">
                            <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>

                        </ngx-dropzone-image-preview>
                        }
                    </ngx-dropzone>
                </form>
                @if(imageError){
                    <small class="text-danger">Image is Required</small>
                    }
                    @else if(imageSizeError){
                    <small class="text-danger">Image size should be less than 1MB</small>
                    }
                } @else if (FormName == "Edit"){
                <!-- for update case  -->
                <div class="image-gallery">
                    <div *ngFor="let image of images" class="image-container">
                        <app-feather-icons [icon]="'x'" class="closeImageIcon"
                            (click)="removeFile(image)"></app-feather-icons>
                        <img [src]="image.imageBase64" alt="image">
                    </div>
                </div>
                <div class="image-gallery">
                    <div class="upload-container" (click)="fileInput.click()">
                        <app-feather-icons [icon]="'upload'"></app-feather-icons>
                        <input #fileInput type="file" style="display: none;" accept="image/jpeg,image/jpg,image/png"
                            (change)="onFileSelected($event)">
                        Upload more Files
                    </div>
                </div>
                }




            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-dashed color-2 btn-pill" (click)="modal.dismissAll()">Cancel</button>
            <button type="button" class="btn btn-gradient color-2 btn-pill" (click)="onSubmit()"> @if(FormName ==
                "Add"){
                Add Scholarship
                }@else{
                Update Scholarship
                }

            </button>
        </div>
    </div>

</ng-template>

<ng-template #deleteModal>
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="btn-close" (click)="modal.dismissAll()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="text">
                Are You Sure you want to delete this Scholarship
            </div>
            <div class="deleteButtons">
                <button type="button" class="btn btn-dashed color-2 btn-pill"
                    (click)="modal.dismissAll()">Cancel</button>
                <button type="button" class="btn btn-gradient color-2 btn-pill" (click)="deleteScholarship()">
                    Delete
                </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #boostScholarship>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">
                @if(FormName == "Advertisment"){
                Boost Scholarship
                }@else if (FormName == "EditAdvertisment"){
                Edit Ad
                }
            </h5>
            <button type="button" class="btn-close" (click)="modal.dismissAll()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <form [formGroup]="boostScholarshipForm">
                <div class="row gx-3">
                    <div class="form-group col-12 col-md-12 col-sm-12">
                        <label for="a-na">Title<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="a-na" placeholder="Enter Title"
                            formControlName="title">
                        @if(boostScholarshipForm.get('title')?.hasError('required') &&
                        boostScholarshipForm.get('title')?.touched){
                        <small class="text-danger">title is Required</small>
                        }
                    </div>
                </div>
                <div class="row gx-3">
                    <div class="form-group col-6 col-md-6 col-sm-6">
                        <label for="a-n"> Start Date</label>
                        <input type="date" formControlName="start_date" [min]="getMaxDate()" (change)="getMinDate()"
                            (change)="updateAdEndDateMin()" class="form-control" id="a-n" placeholder="Enter Start Date">
                        @if(boostScholarshipForm.get('start_date')?.hasError('required') &&
                        boostScholarshipForm.get('start_date')?.touched){
                        <small class="text-danger">Start Date is Required</small>
                        }
                    </div>
                    <div class="form-group col-6 col-md-6 col-sm-6">
                        <label for="a-n"> End Date</label>
                        <input type="date" formControlName="end_date" class="form-control" id="a-n"
                            placeholder="Enter End Date" [min]="minEndDate">
                        @if(boostScholarshipForm.get('end_date')?.hasError('required') &&
                        boostScholarshipForm.get('end_date')?.touched){
                        <small class="text-danger">End Date is Required</small>
                        }
                    </div>
                </div>

                <div class="row gx-3">
                    <div class="form-group col-12">
                        <label for="a-na">Description<span class="text-danger">*</span></label>
                        <textarea rows="4" name="" id="" class="form-control" placeholder="Enter Description"
                            formControlName="ad_description" (input)="updateAdCharCount()"></textarea>
                        <small class="">{{ adCharCount }}/200</small>
                        @if(boostScholarshipForm.get('ad_description')?.hasError('required') &&
                        boostScholarshipForm.get('ad_description')?.touched){
                        <br><small class="text-danger">Description is Required</small>
                        }@else if(boostScholarshipForm.get('ad_description')?.touched &&
                        boostScholarshipForm.get('ad_description')?.value?.length < 200){ <br><small
                                class="text-danger">Description should be more than 200 characters</small>
                            }
                    </div>
                </div>

            </form>
            <div class="dropzone-admin mb-0">
                <label>Media <span class="font-danger">*</span></label>

                <!-- for add case  -->
                @if(FormName == "Advertisment"){
                <form class="dropzone" id="multiFileUpload">
                    <ngx-dropzone (change)="onAdSelect($event)" accept="image/jpeg,image/jpg,image/png"
                        [multiple]="false">
                        <ngx-dropzone-label>
                            <div class="dz-message needsclick">
                                <i class="fas fa-cloud-upload-alt"></i>
                                <h6 class="f-w-700 mb-0">
                                    Drop files here or click to upload.
                                </h6>
                            </div>
                        </ngx-dropzone-label>
                        @for(f of adFiles; track f){
                        <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" [removable]="true"
                            (removed)="onAdRemove(f)" [file]="f">
                            <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>

                        </ngx-dropzone-image-preview>
                        }
                    </ngx-dropzone>
                </form>
                @if(imageError){
                <small class="text-danger">Image is Required</small>
                }
                @else if(adImageSizeError){
                <small class="text-danger">Image size should be less than 1MB</small>
                }
                } @else if (FormName == "EditAdvertisment"){
                <!-- for update case  -->
                <div class="image-gallery">
                    <div class="image-gallery">
                        <div class="image-container">
                            <app-feather-icons [icon]="'x'" class="closeImageIcon"
                                (click)="removeAdFile(imageBase64)"></app-feather-icons>
                            <img [src]="imageBase64" alt="image" />
                        </div>
                    </div>
                </div>
                <div class="image-gallery">
                    <div class="upload-container" (click)="fileInput.click()">
                        <app-feather-icons [icon]="'upload'"></app-feather-icons>
                        <input #fileInput type="file" style="display: none;" accept="image/jpeg,image/jpg,image/png"
                            (change)="onAdFileSelected($event)">
                        Upload Other File
                    </div>
                </div>
                }




            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-dashed color-2 btn-pill" (click)="modal.dismissAll()">Cancel</button>
            <button type="button" class="btn btn-gradient color-2 btn-pill" (click)="submitBoostAd()">
                @if(FormName == "Advertisment"){
                Boost
                }@else if(FormName == "EditAdvertisment"){
                Update Ad
                }
            </button>
        </div>
    </div>

</ng-template>