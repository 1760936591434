import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TutorialListComponent } from './tutorial-list/tutorial-list.component';
import { TutorialDetailsComponent } from './tutorial-details/tutorial-details.component';

const routes: Routes = [
  {
    path: 'all-tutorials',
    component: TutorialListComponent

  },{
    path:'tutorial-details',
    component:TutorialDetailsComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TutorialsRoutingModule { }
