<div class="sidebar">
    <div class="search-bar">
        <input type="text" placeholder="Search here.." #searchInput (input)="onSearch(searchInput.value)" />
        <i class="icon-search"></i>
      </div>
    <div class="title" *ngIf="title?.length">
      <h3>{{ heading }}</h3>
      <ul>
        <li *ngFor="let item of title">
          <a href="#">{{ item.title }} </a>
        </li>
      </ul>
    </div>
    
    <div class="related-list" *ngIf="relatedItems?.length">
      <h3>{{ relatedListHeading }}</h3>
      <ul>
        <li *ngFor="let item of relatedItems">
          <a *ngIf="relatedConfig.linkProperty; else noLink" (click)="navigateToDetails(item.id)">
            <ng-container *ngIf="relatedConfig.imageProperty">
              <img [src]="item[relatedConfig.imageProperty]" alt="Item Image" />
            </ng-container>
            {{ item[relatedConfig.displayProperty] }}
          </a>
          <ng-template #noLink>
            <span>
              <ng-container *ngIf="relatedConfig.imageProperty">
                <img [src]="item[relatedConfig.imageProperty]" alt="Item Image" />
              </ng-container>
              {{ item[relatedConfig.displayProperty] }}
            </span>
          </ng-template>
        </li>
      </ul>
    </div>

  </div>