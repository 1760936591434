import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-cv8',
  templateUrl: './cv8.component.html',
  styleUrl: './cv8.component.scss'
})
export class Cv8Component {
  @Input() userData: any = {};
}