import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { DataShareService } from 'src/app/shared/services/data-share.service';

@Component({
  selector: 'app-common-dropdowns',
  templateUrl: './common-dropdowns.component.html',
  styleUrl: './common-dropdowns.component.scss',
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommonDropdownsComponent implements OnInit, OnDestroy {


  @Input() clearThevalue: any = ''
  @Input() dataList: any[] = [];
  @Input() defaultText: string = '';
  @Input() SetValue: any;
  @Input() mySelectedValue: any;

  @Input() isDisabled: boolean = false;

  @Output() optionSelected = new EventEmitter<any>();
  @Output() sendTheValue = new EventEmitter<any>();
  @Output() touched: EventEmitter<boolean> = new EventEmitter<boolean>();

  isOpen = false;
  @Input() selectedOption!: string;
  @Input() searchTerm: string = '';

  
  private resetDropDowns!: Subscription;


  constructor(
    private elementRef: ElementRef,
    private _dataShare: DataShareService
  ) {

    this.resetDropDowns = this._dataShare.RefreshDropdowns$.subscribe(res => {
      if (res) {
        this.searchTerm = '';
        this.getDisplayList();
      }
    });
    // if (this.resetDropDowns) {
    //   this.resetDropDowns.unsubscribe();
    // }
  }
  ngOnDestroy(): void {
    if (this.resetDropDowns) {
      this.resetDropDowns.unsubscribe();
    }
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isOpen = false;
    }
  }
  ngOnInit() {
  }


  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  isvaluePresent = false;


  selectOption(option: any) {
    this.selectedOption = option.value;
    this.isvaluePresent = true;
    this.optionSelected.emit(option);
    this.isOpen = false;
  }

  filterOptions(event: any) {
    this.sendTheValue.emit(event.target.value);
    this.searchTerm = event.target.value.toLowerCase();
    if (!this.isOpen) {
      this.isOpen = true;
    }
  }


  getDisplayList() {
    return this.searchTerm ? this.dataList.filter(item => item.value.toLowerCase().includes(this.searchTerm)) : this.dataList;
  }

  onInputFocus() {
    this.touched.emit(true);
  }
}
