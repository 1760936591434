import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiCallService } from 'src/app/shared/services/api-call.service';
import { CommonUtilsServiceService } from 'src/app/shared/services/common-utils-service.service';
import { PropertyService } from 'src/app/shared/services/property.service';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-tutorial-details',
  templateUrl: './tutorial-details.component.html',
  styleUrl: './tutorial-details.component.scss'
})
export class TutorialDetailsComponent {
  private _TutorialID: any;
  isLoading: boolean;
  public noRecordFound = false;
  public isDataLoading = true;
  isErrorOccurred: boolean = false;
  tutorialData: any;
  tutorialTopics: any;
  bannerImage : string = '';
  tutorialName : string = '';
  tutorialCategory : string = '';
  relatedTutorials: { name: string; image: string }[] = [];
  heading : string = '';
  private _utils = inject(CommonUtilsServiceService);
  private _apiCall = inject(ApiCallService);
  private _propertyService = inject(PropertyService);
  private readonly _spinner = inject(NgxSpinnerService);
  public get TutorialID(): any {
    return this._TutorialID;
  }
  public set TutorialID(value: any) {
    this._TutorialID = value;
  }
constructor(
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef
  ) {
    this.TutorialID = this.route.snapshot.queryParamMap.get('tutorialid');
  }
  ngOnInit(): void {

    this.TutorialID = this.route.snapshot.queryParamMap.get('tutorialid');

    this.route.queryParamMap.subscribe((params) => {
      this.TutorialID = params.get('tutorialid');
      if (this.TutorialID) {
        this.getTutorialDetails();
        this.getRelatedTutorials();
      }
      window.scrollTo(0, 0);
    });
   
}
  getTutorialDetails() {
    this._spinner.show();
    this._apiCall.PostCallWithoutToken(null, `Tutorial/GetTutorialById?TutorialId=${this.TutorialID}`).subscribe(
      (response: { data: any; }) => {
        this.isLoading = false;
        this.isDataLoading = false;
        this.isErrorOccurred = false;
        this.tutorialData = response.data;
        this.cdr.detectChanges();
        const editorElement = document.querySelector('.editor') as HTMLElement;
        if (editorElement) {
          editorElement.innerHTML = this.tutorialData?.contentUrl || '';          
        }
        this.tutorialData.moduleType = "tutorial";
         this.getBannerData(this.tutorialData);
        this.tutorialData.blobUrls = [];
        if (Array.isArray(this.tutorialData.images)) {
          this.tutorialData.images.forEach((image: { imageBase64: string }) => {
            if (image.imageBase64 && image.imageBase64.includes('base64,')) {
              const base64Data = image.imageBase64.split('base64,')[1];
              const blobUrl = this._utils.convertBase64ToBlobUrl(base64Data, 'image/jpeg');
              this.tutorialData.blobUrls  = blobUrl; 
              console.log("Institute Detail Blob URL:", blobUrl);
            }
             else {
              this.tutorialData.blobUrls.push('path/to/default/image.png');
            }
          });
        }
        this.isLoading = false;
        this._spinner.hide();
      },
      (error: any) => {
        this.isLoading = false;
        this.isDataLoading = false;
        this.isErrorOccurred = true;  
        this._spinner.hide();
        console.error('Error fetching institute details:', error);
      }
    );
  }

  getBannerData(data: any){
    this.tutorialName = data.tutorialName;
    this.tutorialCategory = data.tutorialCategory;
    this.bannerImage = data.imageBase64;
  }


  handleSearch(searchTerm: string) {
    console.log('Search Term:', searchTerm);
    
  }

  downloadDocument() {
    if (this.tutorialData?.contentUrl) {
      const element = document.createElement('a');
      const fileContent = this.tutorialData.contentUrl; // Assuming contentUrl contains the HTML content
      const file = new Blob([fileContent], { type: 'text/html' }); // You can change to 'text/plain' for plain text
      element.href = URL.createObjectURL(file);
      element.download = `${this.tutorialName || 'document'}.html`; // Default name or tutorialName
      document.body.appendChild(element); // Append to the body
      element.click(); // Simulate the click to download
      document.body.removeChild(element); // Remove the element
    } else {
      console.error('No content available for download');
    }
  }

  getRelatedTutorials(){
    this._spinner.show();
    this._apiCall.PostCallWithoutToken(null, `Tutorial/GetRelatedTutorial?TutorialId=${this.TutorialID}`).subscribe(
      (response: { data: any; }) => {
        this.isLoading = false;
        this.isDataLoading = false;
        this.isErrorOccurred = false;
        this.tutorialData = response.data;
        this.relatedTutorials = response.data.map((tutorial: any) => ({
          name: tutorial.tutorialName,
          image: tutorial.imageBase64
            ? this._utils.convertBase64ToBlobUrl(tutorial.imageBase64.split('base64,')[1], 'image/jpeg')
            : 'path/to/default/image.png',
            id: tutorial.id  
        }));
       
        
        this.isLoading = false;
        this._spinner.hide();
      },
      (error: any) => {
        this.isLoading = false;
        this.isDataLoading = false;
        this.isErrorOccurred = true;  
        this._spinner.hide();
        console.error('Error fetching related institute details:', error);
      }
    );
  }

}
