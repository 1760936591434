<!-- <page size="A4"> -->
<div class="container">
    <div class="leftPanel">
        <img [src]="userData?.userDetails.profileBase64 || 'https://default-image-url.com/default-profile.png'"
            class="img-fluid bg-img" alt="profile-image" />
        <div class="details">
            <div class="item bottomLineSeparator">
                <h2>
                    CONTACT
                </h2>
                <div class="smallText">
                    @if(userData?.userDetails.phoneNumber){

                    <p>
                        <i class="fa fa-phone contactIcon" aria-hidden="true"></i>
                        {{userData?.userDetails.phoneNumber ||"+92 123 4567890"}}
                    </p>
                    }
                    @if(userData?.userDetails.email){

                    <p>
                        <i class="fa fa-envelope contactIcon" aria-hidden="true"></i>
                        <a href="mailto:lorem@ipsum.com@gmail.com">
                            {{ userData?.userDetails.email||"MYEMAILgmail.com"}} </a>
                    </p>
                    }
                    @if(userData?.userDetails.address){

                    <p>
                        <i class="fa fa-map-marker contactIcon" aria-hidden="true"></i>
                        {{ userData?.userDetails.address }},<br /> {{ userData?.userDetails.city }},
                        {{userData?.userDetails.state }},<br>
                        {{userData?.userDetails.country }}
                    </p>
                    }
                </div>
            </div>
            @if(userData?.skillsDetails?.length > 0){

            <div class="item bottomLineSeparator">
                <h2>
                    SKILLS
                </h2>
                <div class="smallText">
                    <div class="skill" *ngFor="let skill of userData?.skillsDetails">
                        <div>
                            <span> {{ skill.skillTitle}}</span>
                        </div>
                        <div class="yearsOfExperience">
                            <span class="alignright">{{skill.percentage + "%"}}</span>
                        </div>
                    </div>
                </div>
            </div>
            }
            @if(userData?.educationDetails?.length > 0){

            <div class="item">
                <h2>
                    EDUCATION
                </h2>
                <div class="smallText">
                    @for (education of userData?.educationDetails;track education){

                    <p class="bolded white">
                        {{ education?.degreeTitle || "Bachelor of Science in Computer Science"
                        }} </p>
                    <p>
                        {{education?.institute || "UNIVERSITY OF EDUCATION"}}
                    </p>
                    <p>
                        {{education?.startDate?.substring(0,4) +" - " +
                        education?.endDate?.substring(0,4)}} </p>
                    }
                </div>
            </div>
            }
        </div>

    </div>
    <div class="rightPanel">
        <div>
            <h1>
                {{userData?.userDetails.firstName + " " + userData?.userDetails.lastName}}
            </h1>
            <div class="smallText">
                <h3>
                    <!-- {{ userData?.userDetails.gender }} -->
                </h3>
            </div>
        </div>
        <div>
            <h2>
                About me
            </h2>
            <div class="smallText">
                <p>
                    {{ userData?.userDetails.description || "lorem ipsum dolor sit amet consectetur adipisicing
                    elit. At iure mollitia
                    commodi odio ipsa qui, similique perferendis in sequi, eligendi inventore laborum quos numquam
                    voluptatibus illo
                    delectusamet cumque explicabo Lorem ipsum dolor, sit amet consectetur adipisicing elit. Itaque
                    reiciendis non inventore accusamus
                    omnis" }}
                </p>

            </div>
        </div>
        @if(userData?.experienceDetails?.length > 0){

        <div class="workExperience">
            <h2>
                Work experience
            </h2>
            <ul>
                <li *ngFor="let experience of userData?.experienceDetails">
                    <div class="jobPosition">
                        <span class="bolded">
                            {{ experience?.role || "SENIOR UI AND UX DESIGNER"}}
                        </span>
                        <span>
                            {{experience?.startDate?.substring(0,4) +" - " +
                            experience?.endDate?.substring(0,4)}} </span>
                    </div>
                    <div class="projectName bolded">
                        <span>
                            {{experience?.company || "UNIVERSITY OF EDUCATION"}} </span>
                    </div>
                    <div class="smallText">
                        <!-- <p>
                                    {{experience?.description || "lorem ipsum dolor sit amet consectetur adipisicing elit. At
                                    iure mollitia commodi odio ipsa qui, similique perferendis in sequi, eligendi inventore
                                    laborum quos numquam voluptatibus illo delectusamet cumque explicabo"}}
                                </p> -->
                        <ul>
                            <li>
                                {{experience?.description || "lorem ipsum dolor sit amet consectetur adipisicing elit.
                                At
                                iure mollitia commodi odio ipsa qui, similique perferendis in sequi, eligendi inventore
                                laborum quos numquam voluptatibus illo delectusamet cumque explicabo"}}
                            </li>
                        </ul>
                        <p>
                            <!-- <span class="bolded">Skills: </span>Accounting, Word, Powerpoint -->
                        </p>
                    </div>
                </li>



            </ul>
        </div>
        }
        @if(userData?.awardsDetails?.length > 0){

        <div class="workExperience">
            <h2>
                Awards & Achievements
            </h2>
            <ul>
                <li *ngFor="let award of userData?.awardsDetails">
                    <div class="jobPosition">
                        <span class="bolded">
                            {{ award?.position || "SENIOR UI AND UX DESIGNER"}}
                        </span>
                        <span>
                            {{award?.startDate?.substring(0,4) +" - " +
                            award?.endDate?.substring(0,4)}} </span>
                    </div>
                    <div class="projectName bolded">
                        <span>
                            {{award?.company || "UNIVERSITY OF EDUCATION"}} </span>
                    </div>
                    <div class="smallText">

                        <ul>
                            <li>
                                {{award?.description || "lorem ipsum dolor sit amet consectetur adipisicing elit.
                                At
                                iure mollitia commodi odio ipsa qui, similique perferendis in sequi, eligendi inventore
                                laborum quos numquam voluptatibus illo delectusamet cumque explicabo"}}
                            </li>
                        </ul>
                    </div>
                </li>



            </ul>
        </div>
        }
    </div>
</div>
<!-- </page>   -->