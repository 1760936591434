<div class="description-section">
  <div class="description-details">
    <div class="desc-box">
      <div class="menu-top" id="searchBar" [ngClass]="{ 'sticky' : navFixed }">
        <div class="container">
          <ul class="nav">
            @for(navigtor of navigations;track navigtor){
            <li [ngClass]="{
              'active': activeClass == navigtor.value,
              'lengthGrater': navigations?.length > 8,
              'active-lengthGrater': activeClass == navigtor.value && navigations?.length > 8
            }"><a (click)="setPage(navigtor.value)" aria-label="clickable-text">{{navigtor?.title}}</a></li>
            }
          </ul>
        </div>
      </div>
      <!-- @for(data of propertyData?.data; track data){ -->
      <!-- @if(data.value == 'about'){ -->
      <app-property-brief id="description" [instituteData]="instituteData?.description"
        [heading]="'Description'"></app-property-brief>
      <!-- } -->

      <!-- } -->

    </div>


    <div class="desc-box">
      @if(instituteData?.moduleType == "jobs" || instituteData?.moduleType == "scholarships"){
      <app-property-brief id="howToApply" [heading]="'How to Apply?'"
        [instituteData]="instituteData?.applyDescription"></app-property-brief>
      }
    </div>
    <div class="desc-box">
      @if(instituteData?.moduleType == "jobs"){
      <app-property-brief id="responsibilities" [heading]="'Responsibilities'"
        [instituteData]="instituteData?.responsibilitiesDescription"></app-property-brief>
      }
    </div>
    <div class="desc-box">
      @if(instituteData?.moduleType == "jobs"){
      <app-property-brief id="additional" [heading]="'Additional Information'"
        [instituteData]="instituteData?.additionalDescription"></app-property-brief>
      }
    </div>



    <!-- @for(data of propertyData?.data; track data){ -->
    @if(instituteData?.additionalFeatures?.length >= 1){
    <div class="desc-box">
      <app-features [instituteData]="instituteData"></app-features>
    </div>

    }
    @if(instituteData?.moduleType == "jobs" || instituteData?.moduleType == "admission" || instituteData?.moduleType ==
    "scholarships"){
    @if(instituteData?.skillVMs?.length >= 1){
    <div class="desc-box">
      <div class="page-section feature-dec" id="skills">
        <h4 class="content-title head">

          @if (instituteData?.moduleType == "jobs"){
          Expected Skills
          }@else if (instituteData?.moduleType == "admission"){
          Documents
          }@else if (instituteData?.moduleType == "scholarships"){
          Coverages
          }
        </h4>
        <div class="single-feature row">
          <!-- <div class="col-xxl-3 col-xl-4 col-6"> -->
          <div class="row">
            <ul class="row">
              @for(skill of instituteData?.skillVMs;track skill){
              <div class="col-md-6 col-sm-6 col-xl-4 featuresIocns">
                <li>
                  <i class="fa-angle-right fas"></i>{{skill?.skillTitle}}
                </li>
              </div>
              }
            </ul>
          </div>
        </div>
      </div>
    </div>
    }
    }

    @if(instituteData?.departments?.length >= 1){
    <div class="desc-box">
      <div class="page-section feature-dec" id="departments">
        @if(instituteData?.moduleType == "jobs"){
        <h4 class="content-title head">Expected Education</h4>
        }
        @else if(instituteData?.moduleType == "scholarships"){
        <h4 class="content-title head">Required Documents</h4>
        }
        @else if(instituteData?.moduleType == "books"){
        <h4 class="content-title head">Book KeyWords</h4>
        }@else if (instituteData?.moduleType == "hostel"){
          <h4 class="content-title head">Facilities</h4>
        }
        @else{
        <h4 class="content-title head">Departments</h4>
        }
        <div class="single-feature row">
          <!-- <div class="col-xxl-3 col-xl-4 col-6"> -->
          <div class="row">
            <ul class="row">
              @for(depart of instituteData?.departments;track depart){
              <div class="col-md-6 col-sm-6 col-xl-4 featuresIocns">
                <li>
                  <i class="fa-angle-right fas"></i>{{depart?.departmentName}}
                </li>
              </div>
              }
            </ul>
          </div>
        </div>
      </div>
    </div>
    }
    @if(instituteData?.courses?.length >= 1){
    <div class="desc-box">
      <div class="page-section feature-dec" id="courses">
        @if(instituteData?.moduleType == "jobs"){
        <h4 class="content-title head">Expected Experience</h4>
        }
        @else if(instituteData?.moduleType == "scholarships"){
        <h4 class="content-title head">Eligible Courses</h4>
        }@else if(instituteData?.moduleType == "books"){
        <h4 class="content-title head">Authors</h4>
        }
        @else{
        <h4 class="content-title head">Courses</h4>
        }
        <div class="single-feature row">
          <!-- <div class="col-xxl-3 col-xl-4 col-6"> -->
          <div class="row">
            <ul class="row">
              @for(course of instituteData?.courses;track course){
              <div class="col-md-6 col-sm-6 col-xl-4 featuresIocns">
                <li>
                  <i class="fa-angle-right fas"></i>{{course?.courseName}}
                </li>
              </div>
              }
            </ul>
          </div>
        </div>
      </div>
    </div>
    }
    @if(instituteData?.schlorships?.length >= 1){
    <div class="desc-box">
      <div class="page-section feature-dec" id="schlorships">
        <h4 class="content-title head">Schlorships</h4>
        <div class="single-feature row">
          <!-- <div class="col-xxl-3 col-xl-4 col-6"> -->
          <div class="row">
            <ul class="row">
              @for(schlorship of instituteData?.schlorships;track schlorship){
              <div class="col-md-6 col-sm-6 col-xl-4 featuresIocns">
                <li>
                  <i class="fa-angle-right fas"></i>{{schlorship?.scholarshipName}}
                </li>
              </div>
              }
            </ul>
          </div>
        </div>
      </div>
    </div>
    }
    @if(instituteData?.moduleType == "institute" || instituteData?.moduleType == "admission"){

    @if(instituteData?.classes?.length >= 1){
    <div class="desc-box">
      <div class="page-section feature-dec" id="classes">
        <h4 class="content-title head">Classes</h4>
        <div class="single-feature row">
          <!-- <div class="col-xxl-3 col-xl-4 col-6"> -->
          <div class="row">
            <ul class="row">
              @for(class of instituteData?.classes;track class){
              <div class="col-md-6 col-sm-6 col-xl-4 featuresIocns">
                <li>
                  <i class="fa-angle-right fas"></i>{{class?.classTitle}}
                </li>
              </div>
              }
            </ul>
          </div>
        </div>
      </div>
    </div>
    }
    }
    @if(instituteData.moduleType == "institute" || instituteData.moduleType == "admission" || instituteData.moduleType == "scholarships" || instituteData.moduleType == "jobs" || instituteData.moduleType == "hostel"){
    @if(instituteData?.images?.length >= 1 ){
    <div class="desc-box">
      <div class="page-section ratio3_2" id="gallery">
        <app-gallery [instituteData]="instituteData"></app-gallery>
      </div>
    </div>
    }
    }
    <!-- }@else if(data.value == 'video'){ -->
    @if(instituteData?.videoLink){
    <div class="desc-box">
      <app-video [getInstituteData]="safeVideosUrl"></app-video>
    </div>
    }

    @if(instituteData){
    <div class="desc-box">
      <app-propety-details [instituteData]="instituteData"></app-propety-details>
    </div>
    }
    @if(instituteData?.locationSrc){
    @if(safeLocationUrl){
    <div class="desc-box">
      <app-propety-location [getInstituteData]="safeLocationUrl"></app-propety-location>
    </div>
    }
    }
    <!-- @for(data of propertyData?.data; track data){ -->

    <!-- @if(instituteData.moduleType !== "jobs" && instituteData.moduleType !== "admission" && instituteData.moduleType !== "scholarships") {
    <div class="desc-box">
      <app-review></app-review>
    </div>
    } -->

    <!-- } -->

    <!-- @else if(data.value == 'floor_plan'){
        <div class="desc-box">
          <app-propety-floor-plan [floorPlanData]="data.details"></app-propety-floor-plan>
        </div>
      } -->

    <!-- } -->
  </div>
</div>