import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, inject, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ApiCallService } from 'src/app/shared/services/api-call.service';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';
import { PropertyService } from 'src/app/shared/services/property.service';
import intlTelInput from "intl-tel-input";
import { CommonUtilsServiceService } from 'src/app/shared/services/common-utils-service.service';
@Component({
  selector: 'app-add-admission',
  templateUrl: './add-admission.component.html',
  styleUrl: './add-admission.component.scss',
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddAdmissionComponent {
  private intlTelInputInstance: any;
  public themeLogo = 'assets/images/logo/2.png';
  public footerLogo = 'assets/images/logo/footer-logo.png';
  public bgImage = 'assets/images/banner/banner.jpg';
  public title = 'Dashboard';
  public parent = 'Admission';
  public child = 'Add Admission';
  addAdmissionForm: FormGroup;
  boostAdmissionForm: FormGroup;
  admissionData: any;
  gridImages: boolean = false;
  listView: boolean = false;
  thumbnail: boolean = false;
  isDataLoading: boolean = true;
  thumbnail_video: boolean = false;
  public isSpinnerShown = false;
  tagClass: string;
  total: number;
  public selectedImage: string;
  public _spinner = inject(NgxSpinnerService)
  public FormName: string = "Add";

  public __apiCall = inject(ApiCallService);
  public _toastr = inject(ToastrService);
  public propertyService = inject(PropertyService);
  private readonly errorHandlingService = inject(ErrorHandlingService);
  private readonly _utlis = inject(CommonUtilsServiceService);

  public theme_default3 = '#ff5c41';
  public theme_default4 = '#ff8c41';

  constructor(public modal: NgbModal, private fb: FormBuilder) {
    this.addAdmissionForm = this.fb.group({
      institute_name: ["", Validators.required],
      courses_name: ["", Validators.required],
      classes_name: ["", Validators.required],
      department: ["", Validators.required],
      institute_address: ["", Validators.required],
      institute_addressTitle: ["", Validators.required],
      institute_addressLink: ["", [Validators.required, Validators.pattern('https://maps.google.com/maps.+')]],
      site_link: ["", [Validators.required, Validators.pattern('https?://.+')]],

      email_address: ["", [
        Validators.required,
        Validators.email,
        Validators.pattern(
          "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$"
        ),
      ]],
      phone: ["", [
        Validators.required,
        Validators.pattern("^((\\+91-?)|0)?[0-9]{8,16}$"),
        Validators.minLength(8),
        Validators.maxLength(16)
      ]],

      application_fee: ["", Validators.required],
      start_date: ["", Validators.required],
      end_date: ["", Validators.required],
      scholarship_tag: ["", Validators.required],
      required_documents: ["", Validators.required],
      description: ["", [Validators.required, Validators.minLength(400)]],

    });


    this.boostAdmissionForm = this.fb.group({
      title: ["", Validators.required],
      start_date: ["", [Validators.required,]],
      end_date: ["", Validators.required],
      ad_description: ["", [Validators.required, Validators.minLength(200)]],
    });


  }
  ngOnInit() {
    // document.documentElement.style.setProperty('--theme-default', this.theme_default3);
    // document.documentElement.style.setProperty('--theme-default3', this.theme_default3);
    // document.documentElement.style.setProperty('--theme-default4', this.theme_default4);

    this.getAdmission();
    this.getCountry();
  }



  loadPhoneScript() {
    setTimeout(() => {
      const inputElement = document.querySelector('#myInput') as HTMLInputElement;
      if (inputElement) {
        this.intlTelInputInstance = intlTelInput(inputElement, {
          initialCountry: 'pk',
          separateDialCode: true
        });
      }
    }, 500)
  }
  ///////////////////////////////////////////////////
  // End Date limiter STARTS HERE 
  minmumAdDate: any
  getAdMinDate() {
    const maxDate = this.boostAdmissionForm.get('start_date')?.value;
    this.boostAdmissionForm.get('end_date')?.reset();
    this.minmumAdDate = maxDate
  }

  getAdMaxDate(): string {
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    return formattedDate;
  }

  minAdEndDate: any;
  updateAdEndDateMin(): void {
    const startDate = this.boostAdmissionForm.get('start_date')?.value;
    this.minAdEndDate = startDate ? startDate : null;
  }

  //  End Date limiter Ends HERE 
  ///////////////////////////////////////////

  adCharCount: number = 0;
  updateAdCharCount(): void {
    let description = this.boostAdmissionForm.get("ad_description")?.value;
    this.adCharCount = description?.length || 0;
  }


  isAdActivated: boolean = true;
  boostId: number;
  boostCard(content: TemplateRef<any>, id: number) {
    this.boostId = id;
    this.boostAdmissionForm.reset();
    this.imageBase64 = "";
    this.adFiles = [];
    this.modal.open(content, { centered: true, size: 'lg' });
    this.FormName = "Advertisment";

  }

  editAdCard(content: TemplateRef<any>, id: number) {
    this.boostAdmissionForm.reset();
    this.imageBase64 = "";
    this.adFiles = [];
    this.modal.open(content, { centered: true, size: 'lg' });
    this.FormName = "EditAdvertisment";
    this.editAd(id);
  }

  ////////////////////////////////////////////
  //////////// code for Ads image /////////////////////
  //////////////////////////////////////////////

  adFiles: File[] = [];
  adImages: { imageBase64: string }[] = [];
  imageBase64: string | null = null;
  adImageSizeError: boolean = false;

  onAdSelect(event: NgxDropzoneChangeEvent) {
    const newFile = event.addedFiles[0];
    if (newFile) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const sizeInKB = Math.round(newFile.size / 1024);
        const isToDel = this.checkImgeSizeValidity(sizeInKB, newFile);
        if (!isToDel) {
          const base64String = e.target.result as string;
          this.adFiles = [newFile];
          this.adImages = [{ imageBase64: base64String }];
          this.imageBase64 = base64String;
        }
      };
      reader.readAsDataURL(newFile);
    }

  }

  onAdRemove(event: File) {
    this.adFiles = [];
    this.adImages = [];
  }




  /////////////////////////////////////////////////////
  //////////// image update case //////////////////////
  //////////////////////////////////////////////////

  onAdFileSelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {

        const sizeInKB = Math.round(file.size / 1024);
        const isToDel = this.checkImgeSizeValidity(sizeInKB, file)
        if (!isToDel) {
          const base64String = reader.result as string;
          this.adFiles = [file];
          this.adImages = [{ imageBase64: base64String }];
          this.imageBase64 = base64String;
        };
      };
    }
  }

  removeAdFile(file: any): void {
    this.adFiles = [];
    this.adImages = [];
    this.imageBase64 = "";
  }


  public submitBoostAd(): void {

    const adsPayload = this.createAdsApiPayload();
    console.log("Payload", adsPayload);
    this.isSpinnerShown = true;

    this.__apiCall.PostCallWithToken(adsPayload, 'Ad/SaveAndUpdateAd')
      .subscribe(
        (response) => {
          if (response.responseCode === 200) {
            this._toastr.success(response.responseMessage);
            this.boostAdmissionForm.reset();
            this.modal.dismissAll();
            this.isAdActivated = true;
          } else {
            this.errorHandlingService.handleResponseError(response);
          }
          this.isSpinnerShown = false;
        },
        (error) => {
          this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
          this.isSpinnerShown = false;
        }
      );
  }

  createAdsApiPayload() {
    this.updateAdId = this.FormName == "Advertisment" ? 0 : this.updateAdId;
    return {
      moduleId: this.boostId,
      moduleName: "admission",
      title: this.boostAdmissionForm.get('title')?.value,
      startDate: this.boostAdmissionForm.get('start_date')?.value,
      endDate: this.boostAdmissionForm.get('end_date')?.value,
      description: this.boostAdmissionForm.get('ad_description')?.value,
      imageUrl: this.imageBase64,
    };
  }

  adDataById: any;
  updateAdId: number = 0;
  editAdModuleId: number;
  public editAd(id: number): void {
    this.editAdModuleId = id;
    const payload = {
      id: this.editAdModuleId,
      moduleName: "Admission",
    }
    this.isSpinnerShown = true;
    this._spinner.show();

    this.__apiCall.PostCallWithToken(payload, "Ad/GetAdById")
      .subscribe(
        (response) => {
          if (response.responseCode === 200) {
            this.adDataById = response.data;
            this.updateAdId = this.adDataById.id;
            this.boostAdmissionForm.get('title')?.setValue(this.adDataById?.title);
            this.boostAdmissionForm.get("start_date")?.setValue(this.adDataById?.startDate.substring(0, 10));
            this.boostAdmissionForm.get("end_date")?.setValue(this.adDataById?.endDate.substring(0, 10));
            this.boostAdmissionForm.get("ad_description")?.setValue(this.adDataById?.description);

            this.imageBase64 = this.adDataById?.imageUrl;

            this.updateAdCharCount();

          } else {
            this.errorHandlingService.handleResponseError(response);
            this._toastr.error(response.responseMessage);
          }
          this.isSpinnerShown = false;

        },
        (error) => {
          this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
          this.isSpinnerShown = false;
        }
      );
  }



  getAdmission() {
    this.isDataLoading = true;
    this.__apiCall.GetCallWithToken("Dashboard/GetAdmissions").subscribe((response) => {
      if (response.responseCode == 200) {
        this.admissionData = response.data;
        this.isDataLoading = false;
      } else {
        this.admissionData = [];
        this.isDataLoading = false;
      }
    }, (error) => {
      this._toastr.error('Error fetching institutes data', error);
      this.isDataLoading = false;
    }
    )
  }

  ngOnDestroy(): void {
    document.documentElement.style.removeProperty('--theme-default');
    document.documentElement.style.removeProperty('--theme-default3');
    document.documentElement.style.removeProperty('--theme-default4');
  }



  ///////////////////////////////////////////////////
  // End Date limiter STARTS HERE 
  minmumDate: any
  getMinDate() {
    const maxDate = this.addAdmissionForm.get('start_date')?.value;
    this.addAdmissionForm.get('end_date')?.reset();
    this.minmumDate = maxDate
  }

  getMaxDate(): string {
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    return formattedDate;
  }

  //  End Date limiter Ends HERE 
  ///////////////////////////////////////////

  chehckedFeatures: { featureName: string }[] = [];



  charCount: number = 0;
  updateCharCount(): void {
    let description = this.addAdmissionForm.get("description")?.value;
    this.charCount = description?.length || 0;
  }

  updateCheckedValues(feature: any) {
    feature.checked = !feature.checked;
  }

  addCard(content: TemplateRef<any>) {
    this.FormName = "Add";
    this.addAdmissionForm.reset();
    this.updateAdmissionId = 0;
    this.Country = "";
    this.State = "";
    this.InstitueType = "";
    this.City = "";
    this.TestType = "";
    this.coursestags = [];
    this.departmentTags = [];
    this.classesTags = [];
    this.scholarshipTags = [];
    this.requiredDocuments = [];
    this.showDepartment = false;
    this.showcourses = false;
    this.showClasses = false;
    this.showScholarship = false;
    this.showEntranceExam = false;
    this.showDocuments = false;
    this.images = [];
    this.files = [];
    this.modal.open(content, { centered: true, size: 'lg' });
    this.loadPhoneScript();
  }


  editCard(content: TemplateRef<any>, id: number) {
    this.modal.open(content, { centered: true, size: 'lg' });
    this.FormName = "Edit";
    this.addAdmissionForm.reset();
    this.showDepartment = false;
    this.showcourses = false;
    this.showClasses = false;
    this.showScholarship = false;
    this.showEntranceExam = false;
    this.showDocuments = false;
    this.Country = "";
    this.State = "";
    this.InstitueType = "";
    this.City = "";
    this.TestType = "";
    this.coursestags = [];
    this.departmentTags = [];
    this.classesTags = [];
    this.scholarshipTags = [];
    this.requiredDocuments = [];
    this.images = [];
    this.files = [];
    this.editAdmission(id);
    this.loadPhoneScript();
  }


  //edit form logistics ///
  admissionByIdData: any
  updateAdmissionId: number = 0;
  editAdmission(id: number) {
    this.updateAdmissionId = id;
    this.isSpinnerShown = true;
    this.__apiCall.PostCallWithToken(null, `Dashboard/GetAdmissionById?AdmissionId=${id}`).subscribe((res) => {
      if (res.responseCode == 200) {
        this.admissionByIdData = res.data;
        this.addAdmissionForm.get('institute_name')?.setValue(this.admissionByIdData?.instituteName);
        this.addAdmissionForm.get("description")?.setValue(this.admissionByIdData?.description);
        this.addAdmissionForm.get("phone")?.setValue(this.admissionByIdData?.contactNumber);
        this.addAdmissionForm.get("site_link")?.setValue(this.admissionByIdData?.websiteLink);
        this.addAdmissionForm.get("email_address")?.setValue(this.admissionByIdData?.email);
        this.addAdmissionForm.get("institute_address")?.setValue(this.admissionByIdData?.address);
        this.addAdmissionForm.get("institute_addressTitle")?.setValue(this.admissionByIdData?.locationTitle);
        this.addAdmissionForm.get("institute_addressLink")?.setValue(this.admissionByIdData?.locationSrc);
        this.addAdmissionForm.get("video_link")?.setValue(this.admissionByIdData?.videoLink);
        this.addAdmissionForm.get("start_date")?.setValue(this.admissionByIdData?.startDate?.substring(0, 10));
        this.addAdmissionForm.get("end_date")?.setValue(this.admissionByIdData?.endDate?.substring(0, 10));
        this.addAdmissionForm.get("application_fee")?.setValue(this.admissionByIdData?.applicationFee);

        this.InstitueType = this.admissionByIdData?.instituteType;
        this.Country = this.admissionByIdData?.country;
        this.State = this.admissionByIdData?.state;
        this.City = this.admissionByIdData?.city;
        this.images = this.admissionByIdData.admissionImages;
        this.coursestags = this.admissionByIdData?.admissionCourses;
        this.classesTags = this.admissionByIdData?.admissionClasses;
        this.departmentTags = this.admissionByIdData?.admissionDepartments;
        this.scholarshipTags = this.admissionByIdData?.admissionScholarships;
        this.requiredDocuments = this.admissionByIdData?.admissionDocuments;
        this.TestType = this.admissionByIdData?.testType;
        this.updateCharCount();
        //this line for updating phone number country code
        this.intlTelInputInstance.setNumber(this.admissionByIdData?.contactNumber.substring(3));

        if (this.InstitueType == "University") {
          this.showDepartment = true;
          this.showcourses = true;
          this.showScholarship = true;
          this.showEntranceExam = true;
          this.showDocuments = true;
          this.showClasses = false;
        } else if (this.InstitueType == "College") {
          this.showClasses = true;
          this.showScholarship = true;
          this.showDocuments = true;
          this.showEntranceExam = false;
          this.showcourses = false;
          this.showDepartment = false;
        } else if (this.InstitueType == "School") {
          this.showClasses = true;
          this.showScholarship = true;
          this.showDocuments = true;
          this.showDepartment = false;
          this.showEntranceExam = false;
          this.showcourses = false;

        }
        else if (this.InstitueType == "Academy") {
          this.showClasses = true;
          this.showDocuments = true;
          this.showcourses = false;
          this.showScholarship = false;
          this.showEntranceExam = false;
          this.showDepartment = false;

        }
        this.isSpinnerShown = false;
      }
      else {
        this._toastr.error(res.responseMessage);
        this.isSpinnerShown = false;
      }
    })

    this.addAdmissionForm.updateValueAndValidity();
    this.isSpinnerShown = false;
  }

  delId: number;
  deleteCard(content: TemplateRef<any>, id: number) {
    this.modal.open(content, { centered: true, size: 'sm' });
    this.delId = id;
  }

  deleteAdmission() {
    this.isSpinnerShown = true;
    this._spinner.show();
    this.__apiCall
      .PostCallWithToken(null, `Dashboard/DeleteAdmission?id=${this.delId}`).subscribe((res) => {
        if (res.responseCode === 200) {
          this._toastr.success(res.responseMessage);
          this.getAdmission();
          this.modal.dismissAll();
          this.isSpinnerShown = false;
          this._spinner.hide();

        }
        else {
          this._toastr.error(res.responseMessage);
          this.modal.dismissAll();
          this.isSpinnerShown = false;
          this._spinner.hide();
        }
      })
  }


  public Options = {
    items: 1,
    loop: true,
    nav: true,
    dots: true,
    autoplay: true,
    autoplayTimeout: 2000,
    navText: [
      "<i class='fa fa-chevron-left'></i>",
      "<i class='fa fa-chevron-right'></i>",
    ],
  };

  public thumbnailCarouselOptions = {
    items: 4,
    margin: 10,
    center: true,
    loop: true,
    nav: false,
    dots: false,
  };

  changeImage(image: string) {
    this.selectedImage = image;
  }



  files: File[] = [];

  images: { imageBase64: string }[] = [];

  onSelect(event: NgxDropzoneChangeEvent) {
    const newFiles = event.addedFiles;
    this.files.push(...newFiles);


    newFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = (e: any) => {

        const sizeInKB = Math.round(file.size / 1024);
        const istoDel = this.checkImgeSizeValidity(sizeInKB, file)
        if (!istoDel) {
          const base64String = e.target.result as string;
          this.images.push({ imageBase64: base64String });
        }
      };
      reader.readAsDataURL(file);
    });

  }

  onRemove(event: File) {
    const index = this.files.indexOf(event);
    if (index !== -1) {
      this.files.splice(index, 1);
      this.images.splice(index, 1);
    }
  }
  imageSizeError: boolean = false
  checkImgeSizeValidity(size: number, file: any) {
    if (size > 1024) {
      this.imageSizeError = true;
      this.onRemove(file)
      return true;

    }
    else {
      this.imageSizeError = false;
      return false;
    }
  }
  ////////////////////////////////////////////
  //////////// code for image /////////////////////
  //////////////////////////////////////////////


  onFileSelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {

        const sizeInKB = Math.round(file.size / 1024);
        const isToDel = this.checkImgeSizeValidity(sizeInKB, file)
        if (!isToDel) {
          const base64String = reader.result as string;
          this.images.push({ imageBase64: base64String });
        };
      };
    }
  }

  removeFile(file: any): void {

    this.images = this.images.filter((t: any) => t !== file);

  }



  ////////////////////////////////////////////
  //////////// code for image /////////////////////
  //////////////////////////////////////////////



  InstitueTypeList = [
    { id: 1, value: "University" },
    { id: 2, value: "College" },
    { id: 3, value: "School" },
    { id: 4, value: "Academy" }
  ];

  InstitueType: string = "";
  showDepartment: boolean = false;
  showcourses: boolean = false;
  showClasses: boolean = false;
  showScholarship: boolean = false;
  showEntranceExam: boolean = false;
  showDocuments: boolean = false;


  onInstitueTypeSelected(option: any) {
    this.InstitueType = option.value;
    if (option.value == "University") {
      this.showDepartment = true;
      this.showcourses = true;
      this.showScholarship = true;
      this.showEntranceExam = true;
      this.showDocuments = true;
      this.showClasses = false;
      // this.classesValidationRequired = false;
    } else if (option.value == "College") {
      this.showClasses = true;
      this.showScholarship = true;
      this.showDocuments = true;
      this.showEntranceExam = false;
      this.showcourses = false;
      this.showDepartment = false;
      // this.departmentValidationRequired = false;
    } else if (option.value == "School") {
      this.showClasses = true;
      this.showScholarship = true;
      this.showDocuments = true;
      this.showDepartment = false;
      this.showEntranceExam = false;
      this.showcourses = false;

    }
    else if (option.value == "Academy") {
      this.showClasses = true;
      this.showDocuments = true;
      this.showcourses = false;
      this.showScholarship = false;
      this.showEntranceExam = false;
      this.showDepartment = false;

    }

  }


  ////////////////////////////////////////////
  // code for Custom NonSearchable  DropDown for TestType Starts Here
  //////////////////////////////////////////////

  TestType: string = "";

  EntranceExamList = [
    { id: 1, value: "MDCAT" },
    { id: 2, value: "ECAT" },
    { id: 3, value: "NET" },
    { id: 4, value: "NAT" },
    { id: 5, value: "GAT" },
    { id: 6, value: "NONE" },
  ];

  onTestTypeSelected(option: any) {
    this.TestType = option.value;

  }

  ////////////////////////////////////////////
  // code for Custom NonSearchable  DropDown for TestType Ends Here
  //////////////////////////////////////////////


  ////////////////////////////////////////////////////
  /////////  Country drop down  //////////
  ///////////////////////////////////////////////////// 
  CountrysearchTerm: string = "";
  Country: string = "";
  State: string = '';
  City: string = '';
  CountryList = []
  SearchTermCountry(searchTerm: string) {
    this.CountrysearchTerm = searchTerm;
    this.Country = searchTerm;
  }
  getCountry() {
    this.__apiCall
      .GetCallWithoutToken('DropDown/GetCountryDropDown')
      .subscribe((response: any) => {
        if (response.responseCode === 200) {
          this.CountryList = response?.data;
        }
      })

  }

  onCountrySelected(option: any) {
    if (this.Country != option.value) {
      this.State = '';
      this.City = '';
    }
    this.Country = option.value;
    this.getStateList(option.id);

  }
  onCountryValueChange(searchValue: any) {
    this.Country = searchValue
    if (this.Country === '') {
      this.State = '';
      this.City = ''
    }
  }

  ////////////////////////////////////////////////////
  /////////  State drop down data //////////
  ////////////////////////////////////////////////////

  StatesearchTerm: string = "";

  StateList = []
  SearchTermState(searchTerm: string) {
    this.StatesearchTerm = searchTerm;
    this.State = searchTerm;
  }

  onStateSelected(option: any) {
    if (this.State !== option.value) {
      this.City = '';
    }
    this.State = option.value;
    this.getCityList(option.id);

  }
  onStateValueChange(searchValue: any) {
    this.State = searchValue
    if (this.State === '') {
      this.City = ''
    }
  }

  onCountryFocused(isTouched: boolean) {
    if (isTouched) {
      if (this.CountrysearchTerm === "" && this.Country === "") {
        this.countryError = true
      }
    }
  }

  getStateList(countryId: number) {
    this.__apiCall
      .GetCallWithoutToken('DropDown/GetStateDropDown?CountryId=' + countryId)
      .subscribe((response: any) => {
        if (response.responseCode === 200) {
          this.StateList = response?.data;
        }
      });
  }


  ////////////////////////////////////////////////////
  /////////  City drop down data //////////
  ////////////////////////////////////////////////////


  CityList = []
  onCitySelected(option: any): void {
    this.City = option.value;
  }

  onStateFocused(isTouched: boolean) {
    if (isTouched) {
      if (this.StatesearchTerm === "" && this.State === "") {
        this.stateError = true;
      }
    }

  }

  getCityList(stateId: number) {
    this.__apiCall.GetCallWithoutToken('DropDown/GetCityDropDown?StateId=' + stateId).subscribe(
      (response: any) => {
        if (response.responseCode === 200) {
          this.CityList = response?.data;
        }
      }
    )
  }

  ////////////////////////////////////////////
  // code for showig errors
  //////////////////////////////////////////////
  instituteTypeError: boolean = false;
  countryError: boolean = false;
  stateError: boolean = false;
  cityError: boolean = false;
  entranceexamError: boolean = false;
  imageError: boolean = false;
  departmentTagError: boolean = false;
  coursesTagError: boolean = false;
  classesTagError: boolean = false;
  scholarshipTagError: boolean = false;
  documentTagError: boolean = false;

  ShowError() {
    this.instituteTypeError = this.InstitueType == "" ? true : false;
    this.countryError = this.Country == "" ? true : false;
    this.stateError = this.State == "" ? true : false;
    this.cityError = this.City == "" ? true : false;
    this.departmentTagError = this.departmentTags.length < 1 ? true : false;
    this.coursesTagError = this.coursestags.length < 1 ? true : false;
    this.classesTagError = this.classesTags.length < 1 ? true : false;
    this.scholarshipTagError = this.scholarshipTags.length < 1 ? true : false;
    this.documentTagError = this.requiredDocuments.length < 1 ? true : false;
    this.entranceexamError = this.TestType == "" ? true : false;
    this.imageError = this.images.length < 1 ? true : false
  }



  ///////////////////////////////////////////////////////////
  // Function to add SCHOLARSHIPS tag functionality start here
  scholarshipTags: any[] = [];

  addScholarshipTags(): void {
    const tagInput = this.addAdmissionForm.get("scholarship_tag")?.value.trim();

    if (tagInput && !this.scholarshipTags.some((tag) => tag.scholarshipName === tagInput)) {
      const newTag = { scholarshipName: tagInput };
      this.scholarshipTags.push(newTag);
      this.addAdmissionForm.get("scholarship_tag")?.setValue("");
      this.scholarshipTagError = this.scholarshipTags.length < 1;
    }
  }

  removeScholarshipTags(tag: any): void {
    this.scholarshipTags = this.scholarshipTags.filter((t) => t !== tag);
    this.scholarshipTagError = this.scholarshipTags.length < 1;
  }

  onEnterScholarship(event: Event): void {
    if (event instanceof KeyboardEvent && event.key === "Enter") {
      event.preventDefault();
      this.addScholarshipTags();
    }
  }
  // Function to add SCHOLARSHIPS tag functionality end here
  /////////////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////
  // Function to add Required Documents tag functionality start here
  requiredDocuments: any[] = [];

  addDocumentsTags(): void {
    const tagInput = this.addAdmissionForm.get("required_documents")?.value.trim();

    if (tagInput && !this.requiredDocuments.some((tag) => tag.documentName === tagInput)) {
      const newTag = { documentName: tagInput };
      this.requiredDocuments.push(newTag);
      this.addAdmissionForm.get("required_documents")?.setValue("");
      this.documentTagError = this.requiredDocuments.length < 1;
    }
  }

  removeDocumentsTags(tag: any): void {
    this.requiredDocuments = this.requiredDocuments.filter((t) => t !== tag);
    this.documentTagError = this.requiredDocuments.length < 1;
  }

  onEnterDocuments(event: Event): void {
    if (event instanceof KeyboardEvent && event.key === "Enter") {
      event.preventDefault();
      this.addDocumentsTags();
    }
  }
  // Function to add Required Documents tag functionality end here
  /////////////////////////////////////////////////////////////////////////



  ////////////////////////////////////////////
  // code for Custom Searchable DropDown for Language
  //////////////////////////////////////////////




  ///////////////////////////////////////////////////////////
  // Function to add COURSE tag //////////////////////////////
  ////////////////////////////////////////////////////////////


  coursestags: any[] = [];

  addCourseTag(): void {

    const tagInput = this.addAdmissionForm.get("courses_name")?.value.trim();

    if (
      tagInput &&
      !this.coursestags.some((tag) => tag.courseName === tagInput)
    ) {
      const newTag = { courseName: tagInput };
      this.coursestags.push(newTag);
      this.addAdmissionForm.get("courses_name")?.setValue("");
      this.coursesTagError = this.coursestags.length < 1;
    }
  }

  removeCourseTag(tag: any): void {
    this.coursestags = this.coursestags.filter((t) => t !== tag);
    this.coursesTagError = this.coursestags.length < 1;
  }

  onEnterKeyPress(event: Event): void {
    if (event instanceof KeyboardEvent && event.key === "Enter") {
      event.preventDefault();
      this.addCourseTag();
    }
  }


  ///////////////////////////////////////////////////////////
  // Function to add COURSE tag //////////////////////////////
  ////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////
  // Function to add COURSE tag ////////////////////////////
  ////////////////////////////////////////////////////////////


  classesTags: any[] = [];

  addClassTags(): void {
    const tagInput = this.addAdmissionForm.get("classes_name")?.value.trim();

    if (
      tagInput &&
      !this.classesTags.some((tag) => tag.className === tagInput)
    ) {
      const newTag = { className: tagInput };
      this.classesTags.push(newTag);
      this.addAdmissionForm.get("classes_name")?.setValue("");
      this.classesTagError = this.classesTags.length < 1;
    }
  }

  removeClassTags(tag: any): void {
    this.classesTags = this.classesTags.filter((t) => t !== tag);
    this.classesTagError = this.classesTags.length < 1;
  }

  onEnterKey(event: Event): void {
    if (event instanceof KeyboardEvent && event.key === "Enter") {
      event.preventDefault();
      this.addClassTags();
    }
  }

  ///////////////////////////////////////////////////////////
  // Function to add COURSE tag ////////////////////////////
  ////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////
  // Function to add Department tag ///////////////////////
  //////////////////////////////////////////////////////////////


  departmentTags: any[] = [];

  adddepartmentTags(): void {
    const tagInput = this.addAdmissionForm.get("department")?.value.trim();

    if (tagInput && !this.departmentTags.some((tag) => tag.departmentName === tagInput)) {
      const newTag = { departmentName: tagInput };
      this.departmentTags.push(newTag);
      this.addAdmissionForm.get("department")?.setValue("");
      this.departmentTagError = this.departmentTags.length < 1;
    }
  }

  removeDepartmentTags(tag: any): void {
    this.departmentTags = this.departmentTags.filter((t) => t !== tag);
    this.departmentTagError = this.departmentTags.length < 1;
  }

  onEnterDepartment(event: Event): void {
    if (event instanceof KeyboardEvent && event.key === "Enter") {
      event.preventDefault();
      this.adddepartmentTags();
    }
  }


  ///////////////////////////////////////////////////////////
  // Function to add Department tag ///////////////////////
  //////////////////////////////////////////////////////////////




  checkTagsValidity(): boolean {
    if (this.InstitueType === "University") {
      if (this.departmentTags.length < 1 || this.coursestags.length < 1 || this.scholarshipTags.length < 1 || this.requiredDocuments.length < 1) {
        return true;
      }
    }
    if (this.InstitueType === "College" || this.InstitueType === "School") {
      if (this.classesTags.length < 1 || this.requiredDocuments.length < 1 || this.scholarshipTags.length < 1) {
        return true;
      }
    }
    if (this.InstitueType === "Academy") {
      if (this.classesTags.length < 1 || this.requiredDocuments.length < 1) {
        return true;
      }
    }

    return false;
  }


  ////////////////////////////////////////////
  // function to clear and add Validations 
  //////////////////////////////////////////////


  removeUmwantedControl() {

    this.addAdmissionForm.removeControl('classes_name');
    this.addAdmissionForm.removeControl('courses_name');
    this.addAdmissionForm.removeControl('department');
    this.addAdmissionForm.removeControl('scholarship_tag');
    this.addAdmissionForm.removeControl('required_documents');
    this.addAdmissionForm.updateValueAndValidity();

    // console.log("val remove", this.addAdmissionForm.value)
  }
  addControls() {

    if (this.InstitueType === "University") {
      if (!this.addAdmissionForm.contains('courses_name')) {
        this.addAdmissionForm.addControl('courses_name', new FormControl("", Validators.required));
      }
      if (!this.addAdmissionForm.contains('department')) {
        this.addAdmissionForm.addControl('department', new FormControl("", Validators.required));
      }
      if (!this.addAdmissionForm.contains('scholarship_tag')) {
        this.addAdmissionForm.addControl('scholarship_tag', new FormControl("", Validators.required));
      }
      if (!this.addAdmissionForm.contains('required_documents')) {
        this.addAdmissionForm.addControl('required_documents', new FormControl("", Validators.required));
      }

    }
    else if (this.InstitueType === "College") {

      if (!this.addAdmissionForm.contains('classes_name')) {
        this.addAdmissionForm.addControl('classes_name', new FormControl("", Validators.required));
      }
      if (!this.addAdmissionForm.contains('scholarship_tag')) {
        this.addAdmissionForm.addControl('scholarship_tag', new FormControl("", Validators.required));
      }
      if (!this.addAdmissionForm.contains('required_documents')) {
        this.addAdmissionForm.addControl('required_documents', new FormControl("", Validators.required));
      }
    }
    else if (this.InstitueType === "School") {
      if (!this.addAdmissionForm.contains('classes_name')) {
        this.addAdmissionForm.addControl('classes_name', new FormControl("", Validators.required));
      }
      if (!this.addAdmissionForm.contains('scholarship_tag')) {
        this.addAdmissionForm.addControl('scholarship_tag', new FormControl("", Validators.required));
      }
      if (!this.addAdmissionForm.contains('required_documents')) {
        this.addAdmissionForm.addControl('required_documents', new FormControl("", Validators.required));
      }
    }
    this.addAdmissionForm.updateValueAndValidity();
  }


  ////////////////////////////////////////////
  // function to clear and add Validations 
  //////////////////////////////////////////////




  ////////////////////////////////////
  // API CALL AND VALIDATIONS CHECK
  ////////////////////////////////////


  public async onSubmit() {
    this.addAdmissionForm.markAllAsTouched();
    const tagsValidity = this.checkTagsValidity();
    this.ShowError();
    this.removeUmwantedControl();

    const description = this.addAdmissionForm.get('description')?.value;
    if (
      this.Country === "" ||
      this.InstitueType === "" ||
      this.City === "" || 
      this.State === "" ||
      this.images.length < 1 ||
      tagsValidity ||
      (this.InstitueType === "University" && this.TestType === "") || description?.length < 400
    ) {
      const errorMessage = this.getFirstError();
      if (errorMessage) {
        this._toastr.error(errorMessage);
        this.addControls();
        return;
      }
      this.addControls();
    }
    else {
      this.isSpinnerShown = true;
      try {
        const payload = this.createApiPayload();

        const response = await this.__apiCall
          .PostCallWithToken(payload, "Dashboard/CreateAdmissions")
          .subscribe((response: any) => {
            this.addControls();
            if (response.responseCode == 200) {
              this._toastr.success(response.responseMessage);
              this.modal.dismissAll();
              this.getAdmission();
              this.isSpinnerShown = false;
            } else {
              this._toastr.error(response.errorMessage);
              this.isSpinnerShown = false;
            }

          });
      } catch (error) {
        this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
        this.isSpinnerShown = false;
      } finally {
        // this.isSpinnerShown = false;
      }
    }

  }

  private getFirstError(): string {
    for (const key of Object.keys(this.addAdmissionForm.controls)) {
      const control = this.addAdmissionForm.get(key);
      if (control && control.invalid) {
        if (this.getFriendlyFieldName(key) != "") {
          return `${this.getFriendlyFieldName(key)} is invalid.`;
        } else {
          return "";
        }
      }
    }

    if (this.InstitueType === "") return "Institute Type is required.";
    if (this.Country === "") return "Country is required.";
    if (this.State === "") return "State is required.";
    if (this.City === "") return "City is required.";
    if (this.TestType === "") return "Entrance exam is required.";
    if (this.InstitueType === "University") {
      if (this.departmentTags?.length < 1) return "At least one Department tag is required.";
      if (this.coursestags?.length < 1) return "At least one Course tag is required.";
      if (this.scholarshipTags?.length < 1) return "At least one Scholarship tag is required.";
      if (this.requiredDocuments?.length < 1) return "At least one Required Document is required.";
    } else if (this.InstitueType === "College" || this.InstitueType === "School") {
      if (this.classesTags?.length < 1) return "At least one Class tag is required.";
      if (this.requiredDocuments?.length < 1) return "At least one Required Document is required.";
      if (this.scholarshipTags?.length < 1) return "At least one Scholarship tag is required.";
    } else if (this.InstitueType === "Academy") {
      if (this.classesTags?.length < 1) return "At least one Class tag is required.";
      if (this.requiredDocuments?.length < 1) return "At least one Required Document is required.";
    }
    if (this.images.length < 1) return "At least one image is required.";


    return "";
  }

  private getFriendlyFieldName(field: string): string {
    const fieldNames: { [key: string]: string } = {
      institute_name: "Institute Name",
      institute_address: "Institute Address",
      institute_addressTitle: "Institute Address Title",
      institute_addressLink: "Institute Google Maps Link",
      site_link: "Website Link",
      email_address: "Email Address",
      phone: "Phone Number",
      application_fee: "Application Fee",
      start_date: "Start Date",
      end_date: "End Date",
      description: "Description",
    };

    return fieldNames[field] || "";
  }



  minEndDate: any;
  updateEndDateMin(): void {
    const startDate = this.addAdmissionForm.get('start_date')?.value;
    this.minEndDate = startDate ? startDate : null;
  }
  ///////////////////////////////////////
  ///////   function for API PAYLOAD ////
  /////////////////////////////////////


  createApiPayload() {
    const payload: any = {
      id: this.updateAdmissionId,
      instituteName: this.addAdmissionForm.get("institute_name")?.value,
      description: this.addAdmissionForm.get("description")?.value,
      instituteType: this.InstitueType,
      contactNumber: this.addAdmissionForm.get("phone")?.value,
      websiteLink: this.addAdmissionForm.get("site_link")?.value,
      email: this.addAdmissionForm.get("email_address")?.value,
      country: this.Country,
      state: this.State,
      city: this.City,
      address: this.addAdmissionForm.get("institute_address")?.value,
      locationTitle: this.addAdmissionForm.get("institute_addressTitle")?.value,
      locationSrc: this.addAdmissionForm.get("institute_addressLink")?.value,
      admissionImages: this.images,
      applicationFee: this.addAdmissionForm.get("application_fee")?.value,
      startDate: this.addAdmissionForm.get("start_date")?.value,
      endDate: this.addAdmissionForm.get("end_date")?.value,
      testType: this.TestType
    };


    if (this.InstitueType == "University") {
      payload.admissionDepartments = this.departmentTags;
      payload.admissionCourses = this.coursestags;
      payload.admissionScholarships = this.scholarshipTags;
      payload.admissionDocuments = this.requiredDocuments;
    }

    if (this.InstitueType == "College" || this.InstitueType == "School") {
      payload.admissionClasses = this.classesTags;
      payload.admissionScholarships = this.scholarshipTags;
      payload.admissionDocuments = this.requiredDocuments;
    }

    if (this.InstitueType == "Academy") {
      payload.admissionClasses = this.classesTags;
      payload.admissionDocuments = this.requiredDocuments;
    }


    payload.contactNumber = this._utlis.numberWithCountryCode(this.addAdmissionForm.get("phone")?.value, this.intlTelInputInstance);

    return payload;

  }

  ///////////////////////////////////////
  ///////   function for API PAYLOAD ////
  /////////////////////////////////////


}
