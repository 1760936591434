import { ChangeDetectionStrategy, Component, inject, input, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { agencyAgent } from 'src/app/shared/interface/property';
import { ApiCallService } from 'src/app/shared/services/api-call.service';
import { MetaService } from 'src/app/shared/services/meta.service';
import intlTelInput from "intl-tel-input";
import { CommonUtilsServiceService } from 'src/app/shared/services/common-utils-service.service';
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrl: './contact-us.component.scss',
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactUsComponent {
  @Input() banner: boolean = true;
  @Input() location: boolean = true;
  @Input() titleClass: string = 'title-1';
  @Input() type: string = 'simple';
  @Input() heading: string = 'Location';
  @Input() desc: string = '';
  @Input() isOnContactUsPage: boolean = true;

  public bgImage = 'assets/images/banner/banner.jpg';
  public title = 'Contact Us';
  public parent = 'Home';
  public child = 'Contact Us';
  public tag = 'Our Location';
  public tag1 = 'Contact';
  private readonly metaService: MetaService = inject(MetaService);
  private _toaster = inject(ToastrService);
  private _apicall = inject(ApiCallService);
  private readonly _utlis = inject(CommonUtilsServiceService);
  private intlTelInputInstance: any;
  contactUsForm: FormGroup;
  constructor(
    private fb: FormBuilder
  ) {
    this.contactUsForm = this.fb.group({
      name: ['', [Validators.required]],
      mobnumber: ['', [
        Validators.required,
        Validators.pattern("^((\\+91-?)|0)?[0-9]{10,18}$")
      ]],
      email: ['', [
        Validators.required, Validators.email, Validators.pattern(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
      ]],
      message: ['', [Validators.required]],
    })
  }

  ngOnInit() {
    this.loadPhoneScript();
    
    // this.metaService.updateTitle('Contact Us | AdmissionLylo');
    // this.metaService.updateMetaTags([
    //   { name: 'description', content: 'Your page description here' },
    //   { name: 'keywords', content: 'angular, seo, meta tags' },
    //   { name: 'author', content: 'Your Name' },
    //   { name: 'robots', content: 'index, follow' }
    // ]);
    // this.metaService.setCanonicalURL();
    // this.metaService.initializeMetaData();
  }

  loadPhoneScript() {
    setTimeout(() => {
      const inputElement = document.querySelector('#phoneNumber') as HTMLInputElement;
      if (inputElement) {
        this.intlTelInputInstance = intlTelInput(inputElement, {
          initialCountry: 'pk',
          separateDialCode: true
        });
      }
    }, 500)
  }
  onSubmit() {

    if (this.contactUsForm.invalid) {
      this.contactUsForm.markAllAsTouched();
      this._toaster.error('Invalid form data');
      return;
    }
    else {
      this._apicall.PostCallWithoutToken(this.createApiPayload(), 'AdmissionLeloVisitor/ContactUs').subscribe((res) => {
        if (res.responseCode == 200) {
          this._toaster.success(res.responseMessage);
          this.contactUsForm.reset();
        }
        else {
          this._toaster.error(res.responseMessage);
          this.contactUsForm.reset();
        }
      })
    }
  }
  createApiPayload() {
    return {
      name: this.contactUsForm.get('name')?.value,
      email: this.contactUsForm.get('email')?.value,
      phoneNumber: this.contactUsForm.get('mobnumber')?.value,
      message: this.contactUsForm.get('message')?.value
    }
  }


  openEmail() {
    this.contactUsForm.markAllAsTouched();
    if (this.contactUsForm.invalid) {
      this._toaster.error('Please fill out all required fields correctly.', 'Error');
      return;
    }

    const name = this.contactUsForm.get('name')?.value;
    const subscriberEmail = this.contactUsForm.get('email')?.value;
    let contactNumber = this.contactUsForm.get('mobnumber')?.value;
    const description = this.contactUsForm.get('message')?.value;
    const ATGMail = '360nomi@gmail.com';
    contactNumber = this._utlis.numberWithCountryCode(this.contactUsForm.get("mobnumber")?.value, this.intlTelInputInstance);
    if (ATGMail) {
      const subject = `Subscriber Email from ${name}`;
      const body = `Name: ${name} \nEmail : ${subscriberEmail} \nContact Number: ${contactNumber}\nMessage: ${description}`;
      const gmailLink = `https://mail.google.com/mail/?view=cm&fs=1&to=${encodeURIComponent(ATGMail)}&su=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
      // for email
      // const mailtoLink = `mailto:${encodeURIComponent(teacherEmail)}?subject=${encodeURIComponent(
      //   subject
      // )}&body=${encodeURIComponent(body)}`;
      window.open(gmailLink, '_blank');
      this.contactUsForm.reset();
    } else {
     // console.warn("Recipient Email is Invalid");
    }
  }

}
