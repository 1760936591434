import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TutorialsRoutingModule } from './tutorials-routing.module';
import { TutorialListComponent } from './tutorial-list/tutorial-list.component';
import { PropertyModule } from '../property/property.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { HomeModule } from '../home/home.module';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TutorialDetailsComponent } from './tutorial-details/tutorial-details.component';


@NgModule({
  declarations: [
    TutorialListComponent,
    TutorialDetailsComponent
  ],
  imports: [
    CommonModule,
    TutorialsRoutingModule,
    PropertyModule,
    SharedModule,
    HomeModule,
    NgbPaginationModule,
    NgxSkeletonLoaderModule,
    NgxSpinnerModule.forRoot({ type: 'ball-spin-clockwise' }),
  ]
})
export class TutorialsModule { }
