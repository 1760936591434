import { ChangeDetectionStrategy, Component, inject, Input, TemplateRef } from '@angular/core';
import { lookingForData } from '../../../../data/slider-filter-search';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-looking-for-icons',
  templateUrl: './looking-for-icons.component.html',
  styleUrls: ['./looking-for-icons.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class LookingForIconsComponent {

  @Input() text: boolean = false;

  @Input() public lookingForData = lookingForData;

}
