<app-loader-two [isLoading]="isSpinnerShown"></app-loader-two>
<app-header-one></app-header-one>
<app-loader-two [isLoading]="isSpinnerShown"></app-loader-two>
<app-common-banner-1 [imagePath]="bannerImage" [heading1]="'Teachers'"
  [heading2]="'for Innovation, Education, and Success'" [showCountryDropdown]="true" [showStateDropdown]="true"
  [showCityDropdown]="true" [showSubjectDropDown]="true" [showRangeSlider]="false" [showDegreeDropdown]="false"
  [showJobTypeDropdown]="false" [showScholarshipTypeDropdown]="false" (childEvent)="handleChildEvent($event)"
  (callAPionEmptyFields)="onReset()" [ResetValue]="DropdownonReset"
  [popUpButton]="{ text: 'Add teachear', isShown: true, componentName: 'teachear' }"
  ></app-common-banner-1>
<div class="container-fluid">
  @if(teachersData.length === 0){}
  <div class="text-center">
    <!-- @if(teachersData.length === 0){ -->
    <!-- <div class="text-center">
      <img src="../../../../assets/images/svg/Nodata.gif" class="error-img"  alt="No records found">
      <p class="text-msg" >"No Teachers Found"</p>
    </div> -->
    <!-- }@else{ -->
    <section class="agent-section property-section">
      <div class="container">
        <div class="row ratio2_3">
          <div class="col-xl-12 col-lg-12 property-grid-3 agent-grids">
            <!-- <app-grid-panel [agency]="true"  [isList]="true"></app-grid-panel> -->
            <app-grid-panel [heading]="'All Teachers'" [currentPage]="currentPage" [totalRecords]="totalRecords"
              [itemsPerPage]="itemsPerPage"></app-grid-panel>
            @if(isDataLoading){
            <div class="item">
              <ngx-skeleton-loader count="8" [theme]="{
                  height: '22.5rem',
                  width: '23%',
                  'border-radius': '10px',
                  'margin-right': '0.5rem',
                  'margin-left': '0.5rem'
                  }" class="skeleton-card" />
            </div>
            }@else if (noRecordsFound) {
            <div class="no-record">
              <img src="../../../../assets/images/svg/Nodata.gif" alt="No records found">
              <p>No Teachers Found</p>
            </div>
            }
            @else if(isErrorOccurred){
            <div>
              <div class="no-record error-svg">
                <img src="../../../../assets/images/svg/error.gif" alt="Error Ocuurred">
                <p>An Error Occurred,Try refreshing Page</p>
              </div>
            </div>
            }
            @else{

            <div class="property-wrapper-grid list-view">
              <app-common-agency [agencyData]="teachersData" [type]="'basic'"></app-common-agency>
            </div>


            <!-- pagination starts here -->
            <div class="row w-100 pt-3 pagination-container">
              <div class="mt30 d-flex justify-content-center">
                <ngb-pagination [collectionSize]="totalRecords" [(page)]="currentPage" [maxSize]="5" [rotate]="true"
                  [ellipses]="false" [boundaryLinks]="true" (pageChange)="onPageChanged($event)" />
              </div>
              <div class="tRecord text-center">
                {{ getDisplayRange() }} of {{ totalRecords }}
              </div>
            </div>
            <!-- pagination ends here -->
            }
          </div>
        </div>
      </div>
    </section>
    <!-- } -->

  </div>

  <app-adly-footer></app-adly-footer>