<div class="container">
    <div class="two-column resume ">
        <section class="resume__section resume__header">
            <div class="resume__content">
                <h1>{{ userData?.personalDetails?.data.firstName + ' ' +
                    userData?.personalDetails?.data.lastName }}</h1>
                <!-- @if(userData?.personalDetails.data.address){ -->
                <div class="info-item">

                    <span class="info-label"><i class="fa fa-location-arrow"></i></span>
                    <span class="info-text"> {{ userData?.personalDetails?.data.address+", " +
                        userData?.personalDetails.data.city + ", " +
                        userData?.personalDetails.data.state + ", " + userData?.personalDetails.data.country }}
                    </span>
                </div>
                <!-- } -->
                <!-- @if(userData?.personalDetails.data.email){ -->

                <div class="info-item">
                    <span class="info-label"><i class="fa fa-envelope"></i></span>
                    <span class="info-text">{{ userData?.personalDetails.data.email || 'MYEMAIL@gmail.com' }}</span>
                </div>
                <!-- } -->
                <!-- @if(userData?.personalDetails.data.phoneNumber){ -->

                <div class="info-item">
                    <span class="info-label"><i class="fa fa-phone"></i></span>
                    <span class="info-text">{{ userData?.personalDetails.data.phoneNumber || '+92 123 4567890'
                        }}</span>
                </div>
                <!-- } -->
            </div>
        </section>

        <div class="resume__columns">
            <div class="resume__main">
                <section class="resume__section resume__summary">
                    <div class="resume__content">
                        <div class="resume__section-title">
                            <i class="fa fa-pencil-square-o"></i>
                            <h2>Professional Summary</h2>
                        </div>
                        <div class="other">
                            <div class="other-info">
                                <p>
                                    {{ userData?.personalDetails.data.description }}
                                </p>

                            </div>
                        </div>
                    </div>
                </section>
                <!-- @if(userData?.experienceDetails.data.length > 0)
                { -->

                <section class="resume__section resume__experience">
                    <div class="resume__content">
                        <div class="resume__section-title">
                            <i class="fa fa-briefcase"></i>
                            <h2>Employment History</h2>
                        </div>
                        <div class="xp-item" *ngFor="let experience of userData?.experienceDetails.data">
                            <div class="xp-job">
                                {{ experience?.role || "SENIOR UI AND UX DESIGNER"}}
                                <br>
                                <small>{{experience?.company || "Company Name"}}</small>
                            </div>
                            <div class="xp-date">{{experience?.startDate?.substring(0,4) +" - " +
                                experience?.endDate?.substring(0,4)}}</div>
                            <div class="xp-detail">
                                <ul>
                                    {{experience?.description || "lorem ipsum dolor sit amet consectetur adipisicing
                                    elit.
                                    At
                                    iure mollitia commodi odio ipsa qui, similique perferendis in sequi, eligendi
                                    inventore
                                    laborum quos numquam voluptatibus illo delectusamet cumque explicabo"}}
                                </ul>
                            </div>
                        </div>

                    </div>
                </section>
                <!-- } -->
            </div>

            <div class="resume__side">
                <!-- @if(userData?.skillsDetails.data.length > 0){ -->

                <section class="resume__section resume__skills">
                    <div class="resume__content">
                        <div class="resume__section-title">
                            <i class="fa fa-align-center"></i>
                            <h2>Skills</h2>
                        </div>
                        <div class="resume__text">
                            <div class="extra" *ngFor="let skill of userData?.skillsDetails.data">
                                <div class="extra-info">
                                    {{skill.skillTitle}}
                                </div>
                                <div class="extra-details">
                                    <div class="extra-details__progress" [style.width.%]="skill?.percentage"></div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                <!-- }
                @if(userData?.languageDetails.data.length > 0){ -->

                <section class="resume__section resume__languages">
                    <div class="resume__content">
                        <div class="resume__section-title">
                            <i class="fa fa-globe"></i>
                            <h2>Languages</h2>
                        </div>
                        <div class="extra" *ngFor="let language of userData?.languageDetails.data">
                            <div class="extra-info">{{language?.language}} <small>{{language?.languageLevel}}</small>
                            </div>

                        </div>

                    </div>
                </section>
                <!-- }
                @if(userData?.certificatesDetails?.data.length > 0){ -->

                <section class="resume__section resume__languages">
                    <div class="resume__content">
                        <div class="resume__section-title">
                            <i class="fa fa-globe"></i>
                            <h2>Certificates</h2>
                        </div>
                        <div class="extra" *ngFor="let Certificate of userData?.certificateDetails.data">
                            <div class="extra-info">{{Certificate?.title}} <small>({{Certificate?.institute}})</small>
                            </div>

                        </div>

                    </div>
                </section>
                <!-- } -->
            </div>
        </div>
    </div>
</div>