<app-loader-two [isLoading]="isSpinnerShown"></app-loader-two>
<app-header-one></app-header-one>


<section class="user-dashboard small-section">
    <div class="container">
        <div class="row">

            <div class="col-lg-12">
                <div class="dashboard-content">
                    <div class="create-tab" id="create-property">
                        <div class="property-wizard common-card">
                            <div class="common-header">
                                <h5>Create CV</h5>
                            </div>
                            <div class="create-property-form">
                                <div class="form-inputs">
                                    <app-cv-profile-details></app-cv-profile-details>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- languages  -->
                    <div class="create-tab" id="create-property">
                        <div class="property-wizard common-card">
                            <div class="create-property-form">
                                <div class="form-inputs">
                                    <app-cv-language-details></app-cv-language-details>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- subjects  -->
                    <div class="create-tab" id="create-property">
                        <div class="property-wizard common-card">
                            <div class="create-property-form">
                                <div class="form-inputs">
                                    <app-cv-education-details></app-cv-education-details>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- skills  -->
                    <div class="create-tab" id="create-property">
                        <div class="property-wizard common-card">
                            <div class="create-property-form">
                                <div class="form-inputs">
                                    <app-cv-experience-details></app-cv-experience-details>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- education  -->
                    <div class="create-tab" id="create-property">
                        <div class="property-wizard common-card">
                            <div class="create-property-form">
                                <div class="form-inputs">
                                    <app-cv-skill-details></app-cv-skill-details>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- experience  -->
                    <div class="create-tab" id="create-property">
                        <div class="property-wizard common-card">
                            <div class="create-property-form">
                                <div class="form-inputs">
                                    <app-cv-achievement-details></app-cv-achievement-details>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- awards  -->
                    <div class="create-tab" id="create-property">
                        <div class="property-wizard common-card">
                            <div class="create-property-form">
                                <div class="form-inputs">
                                    <app-cv-certificate-details></app-cv-certificate-details>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="text-center mt-4">
                        <!-- <button class="btn btn-primary show-cv-btn" ></button> -->
                        <button type="button" class="btn btn-gradient color-2 btn-pill" (click)="showCv()"
                        aria-label="Show CV">
                        Done
                      </button>
                    </div>


                </div>
            </div>
        </div>
    </div>
</section>

<app-adly-footer></app-adly-footer>