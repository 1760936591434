import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-cv5',
  templateUrl: './cv5.component.html',
  styleUrl: './cv5.component.scss'
})
export class Cv5Component {
  @Input() userData: any = {};
}
