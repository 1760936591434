<ng-template #AddOrEditModal>
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Add New Book
        </h5>
        <button type="button" class="btn-close" (click)="modal.dismissAll()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="addBookForm">
          <div class="row gx-3">
            <div class="form-group col-6 col-sm-4">
              <label for="a-na">Book Title<span class="text-danger">*</span></label>
              <input type="text" class="form-control" id="a-na" placeholder="Enter Book Title"
                formControlName="book_title" />
              @if(addBookForm.get('book_title')?.hasError('required') &&
              addBookForm.get('book_title')?.touched){
              <small class="text-danger">Title is Required</small>
              }
            </div>
            <div class="form-group col-6 col-sm-4">
              <label for="a-na">Book Edition<span class="text-danger">*</span></label>
              <input type="text" class="form-control" id="a-na" placeholder="Enter Book Edition"
                formControlName="book_edition" />
              @if(addBookForm.get('book_edition')?.hasError('required') &&
              addBookForm.get('book_edition')?.touched){
              <small class="text-danger">Edition is Required</small>
              }
            </div>
            <div class="form-group col-6 col-sm-4">
              <label for="a-na">Publisher<span class="text-danger">*</span></label>
              <input type="text" class="form-control" id="a-na" placeholder="Enter Publisher Name"
                formControlName="publisher" />
              @if(addBookForm.get('publisher')?.hasError('required') &&
              addBookForm.get('publisher')?.touched){
              <small class="text-danger">Publisher is Required</small>
              }
            </div>
            <div class="form-group col-6 col-sm-4">
              <label for="application-deadline">Publish Date<span class="text-danger">*</span></label>
              <input type="date" class="form-control" id="application-deadline" formControlName="publish_date"
                max="{{ getMaxDate() }}" />
              @if(addBookForm.get('publish_date')?.hasError('required') &&
              addBookForm.get('publish_date')?.touched) {
              <small class="text-danger">Publish Date is required</small>
              }
            </div>
            <div class="form-group col-6 col-sm-4 modal-container">
              <label for="a-na">Language<span class="text-danger">*</span></label>
              <app-common-dropdowns class="first-dropdown" [defaultText]="'Enter Language Type'" [dataList]="languageList"
                [selectedOption]="Language" [clearThevalue]="Language" (optionSelected)="onLanguageSelected($event)"
                (sendTheValue)="onLanguageValueChange($event)"></app-common-dropdowns>
              @if(Language == "" && languageError){
              <small class="text-danger">Language is Required</small>
              }
            </div>
            <!-- </div>
          <div class="row gx-3"> -->
  
            <div class="form-group col-6 col-sm-4 modal-container">
              <label for="a-na">Book Category<span class="text-danger">*</span></label>
              <app-common-dropdowns class="second-dropdown" [defaultText]="'Enter Book Category'"
                [dataList]="CategoryList" [selectedOption]="bookCategory" [clearThevalue]="bookCategory"
                (optionSelected)="onCategorySelected($event)"
                (sendTheValue)="SearchTermCategory($event)"></app-common-dropdowns>
              @if(bookCategory == "" && categoryError){
              <small class="text-danger"> Category is Required</small>
              }
            </div>
  
  
  
            <!-- </div>
          <div class="row gx-3"> -->
            <div class="form-group col-6 col-sm-4">
              <label for="a-na">ISBN Number<span class="text-danger">*</span></label>
              <input type="text" class="form-control" id="a-na" placeholder="Enter ISBN Number"
                formControlName="ISBN_Number" />
              @if(addBookForm.get('ISBN_Number')?.hasError('required') &&
              addBookForm.get('ISBN_Number')?.touched){
              <small class="text-danger">ISBN Number is Required</small>
              }
            </div>
            <div class="form-group col-12 col-sm-4">
              <label for="a-n">Authors<span class="text-danger">*</span></label>
              <input type="text" class="form-control" id="a-n" placeholder="Add Authors Name"
                formControlName="authors_name" (keyup.enter)="onEnterAuthor($event)" />
              @if(authorTags.length == 0 &&
              addBookForm.get('authors_name')?.touched){
              <small class="text-danger">Add atleast one Authors Name</small>
              } @for(authorTag of authorTags; track authorTag){
              <div class="tag" (click)="removeAuthorTags(authorTag)">
                {{ authorTag.author }}
                <span class="close-icon">&times;</span>
              </div>
              }
            </div>
            <div class="form-group col-12 col-sm-4">
              <label for="a-n">Keywords or Tags<span class="text-danger">*</span></label>
              <input type="text" class="form-control" id="a-n" placeholder="Add Keywords" formControlName="keywords"
                (keyup.enter)="onEnterKeywords($event)" />
              @if(keywordTags.length == 0 &&
              addBookForm.get('keywords')?.touched){
              <small class="text-danger">Add atleast one Keywords</small>
              } @for(keywordTag of keywordTags; track keywordTag){
              <div class="tag" (click)="removeKeywordsTags(keywordTag)">
                {{ keywordTag.keyWord }}
                <span class="close-icon">&times;</span>
              </div>
              }
            </div>
          </div>
  
          <div class="row gx-3">
            <div class="form-group col-12">
              <label for="a-na">Description<span class="text-danger">*</span></label>
  
              <textarea rows="4" name="" id="" class="form-control" placeholder="Enter Description"
                formControlName="description" (input)="updateCharCount()"></textarea>
              <small class="">{{ charCount }}/400</small>
              @if(addBookForm.get('description')?.hasError('required') &&
              addBookForm.get('description')?.touched){
              <small class="text-danger">Description is Required</small>
              }
            </div>
          </div>
        </form>
        <!-- PDF Upload Section -->
        <div class="dropzone-admin mb-0" >
          <label>Media (PDF) <span class="font-danger">*</span></label>
          <form class="dropzone" id="pdfUpload">
            <ngx-dropzone (change)="onPdfSelect($event)" accept=".pdf" [multiple]="false">
              <ngx-dropzone-label>
                <div class="dz-message needsclick">
                  <i class="fas fa-cloud-upload-alt"></i>
                  <h6 class="f-w-700 mb-0">Drop PDF here or click to upload.</h6>
                </div>
              </ngx-dropzone-label>
  
              <!-- PDF Preview Section with Custom Class -->
              <ngx-dropzone-image-preview *ngIf="pdfFile" ngProjectAs="ngx-dropzone-preview" [removable]="true"
                (removed)="onRemovePdf()" [file]="pdfFile" class="filePreview pdf-preview-container">
                <ngx-dropzone-label>
                  <span id="fileName">{{ pdfFile.name }}</span>
                  <app-feather-icons [icon]="'file-text'"></app-feather-icons>
                  <br />
                  <span>PDF</span>
                </ngx-dropzone-label>
              </ngx-dropzone-image-preview>
            </ngx-dropzone>
          </form>
        </div>
  
        <!-- Image (Cover Photo) Upload Section -->
        <div class="dropzone-admin mb-0" >
          <label>Media (Cover Photo) <span class="text-danger">*</span></label>
          <form class="dropzone" id="imageUpload">
            <ngx-dropzone (change)="onImageSelect($event)" accept="image/jpeg,image/jpg,image/png" [multiple]="false">
              <ngx-dropzone-label>
                <div class="dz-message needsclick">
                  <i class="fas fa-cloud-upload-alt"></i>
                  <h6 class="f-w-700 mb-0">
                    Drop files here or click to upload an image.
                  </h6>
                </div>
              </ngx-dropzone-label>
              <ngx-dropzone-image-preview *ngIf="coverImageFile" ngProjectAs="ngx-dropzone-preview" [removable]="true"
                (removed)="onRemoveImage()" [file]="coverImageFile">
                <ngx-dropzone-label>{{ coverImageFile.name }} ({{
                  coverImageFile.type
                  }})</ngx-dropzone-label>
              </ngx-dropzone-image-preview>
            </ngx-dropzone>
          </form>
  
          <!-- For Edit Case -->
  
        </div>
  
  
  
  
    
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-dashed color-2 btn-pill" (click)="modal.dismissAll()">
          Cancel
        </button>
        <button type="button" class="btn btn-gradient color-2 btn-pill" (click)="onSubmit()"> Add Book
        </button>
      </div>
    </div>
  </ng-template>