import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { homeSectionSlider } from 'src/app/shared/interface/property';
import { MetaService } from 'src/app/shared/services/meta.service';
import { PropertyService } from 'src/app/shared/services/property.service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrl: './home-page.component.scss',
  // changeDetection: ChangeDetectionStrategy.OnPush

})
export class HomePageComponent {
  private readonly metaService: MetaService = inject(MetaService);
  public isLoading = false;

  public title = 'corporate';

  public heading: 'Latest Property Listing';
  public type: 'basic';
  public titleClass: 'title-2';
  public descClass: 'mb-0';
  public desc: 'Discover New York’s best things to do, restaurants, theatre, nightlife and more';

  public homeSectionSliderData: homeSectionSlider[] = [];


  constructor(private sanitizer: DomSanitizer, private propertyService: PropertyService) { }

  ngOnInit() {

    this.metaService.updateTitle('Home-Page | AdmissionLylo');
    this.metaService.updateMetaTags([
      { name: 'description', content: 'Your page description here' },
      { name: 'keywords', content: 'angular, seo, meta tags' },
      { name: 'author', content: 'Your Name' },
      { name: 'robots', content: 'index, follow' }
    ]);
    this.metaService.setCanonicalURL();
    this.metaService.initializeMetaData();

  }
}
