<div class="property-box">
  <div class="agent-image d-flex justify-content-center align-items-center">
    <div class="bg-size position-relative centralized-bg" [style.background-image]="'url(' + agentsData.blobUrl + ')'">
      <img [src]="agentsData.blobUrl" class="bg-img img-fluid centered-img" alt="agency-image" />

      @if (agentsData.moduleType != "books") {
      @if(agentsData.teacherType){
      <span class="label badge label-shadow">{{agentsData.teacherType}}
      </span>
      }
      }


      <!-- @if (agentsData.moduleType != "books") {
      <div class="agent-overlay"></div>
      <div class="overlay-content">
        <ul>
          <li>
            <a href="https://accounts.google.com/" aria-label="clickable-text" target="_blank"><img src="assets/images/about/icon-1.png"
                alt="google-logo" /></a>
          </li>
          <li>
            <a href="https://twitter.com/" aria-label="clickable-text" target="_blank"><img src="assets/images/about/icon-2.png"
                alt="twitter-logo" /></a>
          </li>
          <li>
            <a href="https://www.facebook.com/" aria-label="clickable-text" target="_blank"><img src="assets/images/about/icon-3.png"
                alt="facebook-logo" /></a>
          </li>
        </ul>
        <span>Connect</span>
      </div>
      } -->

    </div>
  </div>
  <div class="agent-content">
    <span class="font-roboto card-title d-flex justify-content-between align-items-center">
      <span class="left-content">
        <app-feather-icons class="color" [icon]="'star'"></app-feather-icons>{{ agentsData.rating }}
      </span>
      <span class="right-content">
        <app-feather-icons class="color" [icon]="'map-pin'"></app-feather-icons>{{ agentsData.subTitle }}
      </span>
    </span>


    <h3>
      <!-- [routerLink]="['/agent/agent-profile']" -->
      <a href="javascript:void(0)" aria-label="clickable-text" class="single-line-clamp break-word"
       >{{
        agentsData.title }}</a>
    </h3>
    <!-- <p class="font-roboto">{{ agentsData.subTitle }}</p> -->

    @if (agentsData.moduleType === "books") {
    <!-- <p class="font-roboto text-justify">{{ agentsData.fax | clamp : 2 }}</p> -->
    }@else {
    <ul class="agent-contact">
      <!-- <li [ngClass]="{ show: isMobile }">
        <i class="fas fa-phone-alt color"></i>
        <span [ngClass]="isMobile == true ? 'phone-number' : 'character'">+ {{ mobileNumber }}</span>
        <span class="label label-light label-flat color-2" (click)="showMobile(agentsData)" style="font-size: 14px">
          {{ isMobile ? "hide" : "show" }}
        </span>
      </li> -->
      <li class="tooltip-container">
        <i class="fas fa-phone-alt color"></i>{{
          agentsData.mobile }} <app-feather-icons (mouseover)="Tooltip(true)" (mouseout)="Tooltip(false)"
          (click)="copyToClipBoard(agentsData.mobile)" class="color" [icon]="'copy'"></app-feather-icons>
        <div class="tooltip" *ngIf="tooltipVisible">{{toolTipText}}</div>
      </li>
      <li class="tooltip-container">
        <i class="fas fa-envelope color"></i>       
        @if(agentsData.email){
        {{ agentsData.email.substring(0, 12) }}
        @if(agentsData.email.length > 12){ ... }
        }

        <app-feather-icons (mouseover)="Tooltip(true)" (mouseout)="Tooltip(false)"
          (click)="copyToClipBoard(agentsData.email)" class="color" [icon]="'copy'"></app-feather-icons>
        <div class="tooltip" *ngIf="tooltipVisible">{{toolTipText}}</div>
      </li>
      <!-- <li class="break-word single-line-clamp">
        <i class="fas fa-envelope color"></i> {{ agentsData.email | clamp : 1 }}
      </li> -->
      <!-- <li class="break-word single-line-clamp">
          <i class="fas fa-fax"></i> {{ agentsData.fax | clamp : 1 }}
        </li> -->
    </ul>
    }

    <div class="tag">
      <div class="tagContent">

        <span *ngFor="let sub of agentsData.fax; let i = index">
          {{ sub?.subject + " - " + sub?.fee + "/" + sub?.duration   }}
        </span>

        <!-- <span>
          {{ agentsData?.subject + " - " + agentsData?.fee + "/" + agentsData?.duration   }}
          
        </span> -->
      </div>
    </div>

    <a href="javascript:void(0)" (click)="navigateTospecificComponent(agentsData.id, 'teacher')"
      class="view-profile-link">
      View profile <i class="fas fa-arrow-right"></i>
    </a>


  </div>
</div>