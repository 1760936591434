<app-loader-two [isLoading]="isLoading"></app-loader-two>
<app-header-one></app-header-one>
<app-common-banner-1 [imagePath]="bannerImage" [heading1]="tutorialName" [heading2]="tutorialCategory" [showSearch]="false"
 [popUpButton]="{ text: 'Add Tutorial', isShown: true, componentName: 'tutorial' }"
  > </app-common-banner-1>

  

  <div class="container">
    <div class="sidebar">
      <app-property-list-left-sidebar [heading]="'Tutorial'"  [title]="tutorialTopics " (search)="handleSearch($event)"
      relatedListHeading="Related Tutorials" [relatedItems]="relatedTutorials" 
      [relatedConfig]="{ displayProperty: 'name', imageProperty: 'image', linkProperty: 'url' }"></app-property-list-left-sidebar>
      
    </div>
    <div class="details">
      <h2>Documentation</h2>
      <div [innerHTML]="tutorialData?.contentUrl"></div>     
    </div>


    <div class="download">
      <h3>Download this Document</h3>
      <button (click)="downloadDocument()">Download</button>
    </div>

  </div>
    
<app-adly-footer></app-adly-footer>
