import { Component, inject } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { PropertyService } from 'src/app/shared/services/property.service';

@Component({
  selector: 'app-books-list',
  templateUrl: './books-list.component.html',
  styleUrl: './books-list.component.scss'
})
export class BooksListComponent {
  public noRecordFound = false;
  public isDataLoading = true;

  bannerImage: string = 'assets/images/banner/library.jpg';

  data: any;
  receivedData: any;
  booksData: any[] = [];

  private _spinner = inject (NgxSpinnerService)

  constructor(public propertyService: PropertyService) {}

  ngOnInit(): void {
    this.fetchAllBooksData(this.currentPage);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  onReset() {
    this.fetchAllBooksData(this.currentPage);
  }

  handleChildEvent(data: any) {
    this.receivedData = data; 
    this.CheckAndCallAPI();
  }

  ///////////////////   Pagination  //////////////////////////////

  pages: (number | string)[] = [];
  currentPage: number = 1;
  totalRecords: number = 0;
  itemsPerPage: number = 8;
  maxVisiblePages: number = 5;
  displayedItems: any[] = [];

  fetchAllBooksData(pageNumber: number): void {
    this.propertyService.getBooks(this.receivedData || {}, pageNumber, this.itemsPerPage).subscribe(
      (response) => {


       
        this.isDataLoading = false;
        this.booksData = response.data;
        this.totalRecords = this.booksData[0]?.totalRecords;
        this.calculatePages();
        if(this.booksData == null || this.booksData?.length<=0){
          this.noRecordFound = true;
         }
         this._spinner.hide();
      },
      (error) => {
        console.error('Error fetching Book data', error);
        this.isDataLoading = false;
        this._spinner.hide();
       
      }
    );
  }

  onPageChanged(event: any): void {
    this.currentPage = event;
    this.fetchAllBooksData(this.currentPage);
    this.isDataLoading = true;
    this.scrollToContentStart();
  }

  calculatePages(): void {
    const totalPages = Math.ceil(this.totalRecords / this.itemsPerPage);
    this.pages = [];
    if (totalPages <= this.maxVisiblePages) {
      this.pages = Array.from({ length: totalPages }, (_, i) => i + 1);
    } else {
      const startPage = Math.max(
        this.currentPage - Math.floor(this.maxVisiblePages / 2),
        1
      );
      const endPage = Math.min(startPage + this.maxVisiblePages - 1, totalPages);

      if (startPage > 1) {
        this.pages.push(1);
        if (startPage > 2) {
          this.pages.push('...');
        }
      }

      for (let i = startPage; i <= endPage; i++) {
        this.pages.push(i);
      }

      if (endPage < totalPages) {
        if (endPage < totalPages - 1) {
          this.pages.push('...');
        }
        this.pages.push(totalPages);
      }
    }
  }

  navigateToPage(page: any): void {
    if (page >= 1 && page <= this.pages.length) {
      this.currentPage = page;
      this.fetchAllBooksData(this.currentPage);
    }
  }

  navigatePage(direction: 'prev' | 'next'): void {
    if (direction === 'prev' && this.currentPage > 1) {
      this.currentPage--;
    } else if (
      direction === 'next' &&
      this.currentPage < Math.ceil(this.totalRecords / this.itemsPerPage)
    ) {
      this.currentPage++;
    }
    this.fetchAllBooksData(this.currentPage);
  }

  getDisplayRange(): string {
    const start = (this.currentPage - 1) * this.itemsPerPage + 1;
    const end = Math.min(start + this.itemsPerPage - 1, this.totalRecords);
    return `${start} – ${end}`;
  }

  scrollToContentStart(): void {
    const contentStart = document.getElementById('content-start');
    if (contentStart) {
      contentStart.scrollIntoView({ behavior: 'smooth' });
    }
  }

  resetPagination(): void {
    this.currentPage = 1; 
    this.calculatePages(); 
    this.fetchAllBooksData(this.currentPage);
    this.scrollToContentStart(); 
  }

  onResetPagination(): void {
    this.resetPagination();
  }

  // TS Code for Pagination End

  CheckAndCallAPI() {
    if (
      this.receivedData?.country !== '' ||
      this.receivedData?.state !== '' ||
      this.receivedData?.city !== '' ||
      this.receivedData?.search !== ''
    ) {
      this.propertyService.getBooks(this.receivedData, this.currentPage, this.itemsPerPage).subscribe(
        (response) => {

          this.booksData = response.data;
          this.totalRecords = this.booksData[0]?.totalRecords;
          this.calculatePages();
          if(this.booksData == null || this.booksData?.length<=0){
            this.noRecordFound = true;
           }
          this._spinner.hide();
        },
        (error) => {
          console.error('Error fetching filtered Book data', error);
          this.isDataLoading = false;
          this._spinner.hide();
        }
      );
    }
  }

}
