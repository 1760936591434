import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { CommonUtilsServiceService } from 'src/app/shared/services/common-utils-service.service';

@Component({
  selector: 'app-property-list-left-sidebar',
  templateUrl: './property-list-left-sidebar.component.html',
  styleUrl: './property-list-left-sidebar.component.scss'
})
export class PropertyListLeftSidebarComponent {
  @Input() heading: string = ''; 
  @Input() title: { title: string;}[] = []; 

  
  @Input() relatedListHeading: string = ''; // Heading for the related list
  @Input() relatedItems: any[] = []; // Data for the related list
  @Input() relatedConfig: { 
    displayProperty: string; 
    imageProperty?: string; 
    linkProperty?: string;
  } = { displayProperty: '' };

  @Output() search = new EventEmitter<string>(); 


  private _utils = inject(CommonUtilsServiceService);

  onSearch(term: string) {
    this.search.emit(term);
  }

  navigateToDetails(id: any){
    this._utils.navigateTo('/tutorials/tutorial-details',{tutorialid:id});
  }
}
