import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-cv7',
  templateUrl: './cv7.component.html',
  styleUrl: './cv7.component.scss'
})
export class Cv7Component implements OnInit {
  @Input() userData: any = {};
  ngOnInit(): void {
    console.log("CV Data of cv7", this.userData);
  }

}