<div class="main">
    <div class="left">
        <div class="picture">
            <div class="pic">
                <img [src]="userData?.userDetails.profileBase64 || 'https://default-image-url.com/default-profile.png'"
                    class="img-fluid bg-img" alt="profile-image" />

            </div>
            <div class="name">
                <!-- MUHAMMAD ALI -->
                {{userData?.userDetails.firstName + " " + userData?.userDetails.lastName}}
            </div>
            <div class="role">
                {{ userData?.userDetails.gender }}
            </div>
        </div>

        <div class="bottom">

            <div class="cont">
                <div class="contact"></div>
                <div class="contact-title">
                    contact
                </div>
                @if(userData?.userDetails?.phoneNumber?.length > 0){
                <div class="phone">

                    <div class="ph-title"><i class="fa fa-phone"></i> PHONE </div>

                    <div class="ph-info">
                        {{userData?.userDetails.phoneNumber ||"+92 123 4567890"}}
                    </div>
                </div>
                }
                @if(userData?.userDetails?.email?.length > 0){

                <div class="email">
                    <div class="mail-title"><i class="fa fa-envelope"></i> EMAIL</div>
                    <div class="mail-info">
                        {{ userData?.userDetails.email||"MYEMAILgmail.com"}} </div>
                </div>
                }
                @if(userData?.userDetails?.address?.length > 0){

                <div class="address">
                    <div class="address-title"><i class="fa fa-map-marker" aria-hidden="true"></i> ADDRESS</div>
                    <div class="address-info">
                        {{ userData?.userDetails.address }},<br /> {{ userData?.userDetails.city }},
                        <br />{{userData?.userDetails.state }},<br>
                        {{userData?.userDetails.country }}
                    </div>
                </div>

                }
            </div>
            @if(userData?.skillsDetails?.length > 0){
            <div class="skills">
                <div class="skill-title">
                    SKILLS
                </div>
                <div class="skill-info">
                    <div *ngFor="let skill of userData?.skillsDetails">
                        {{ skill.skillTitle + " - " + skill.percentage + "%" }}
                    </div>

                </div>

            </div>
            }
        </div>
    </div>
    <div class="right">
        <div class="about">
            <div class="about-title">
                ABOUT
            </div>
            <div class="about-info">

                {{ userData?.userDetails.description || "lorem ipsum dolor sit amet consectetur adipisicing
                elit. At iure mollitia
                commodi odio ipsa qui, similique perferendis in sequi, eligendi inventore laborum quos numquam
                voluptatibus illo
                delectusamet cumque explicabo Lorem ipsum dolor, sit amet consectetur adipisicing elit. Itaque
                reiciendis non inventore accusamus
                omnis" }}
            </div>
        </div>
        @if(userData?.experienceDetails?.length > 0){

        <div class="experience">
            <div class="experience-title">EXPERIENCE</div>
            <div *ngFor="let experience of userData?.experienceDetails">

                <div class="exp-data">
                    <div class="exp-one">
                        {{ experience?.role || "SENIOR UI AND UX DESIGNER"}}
                    </div>
                    <div class="time">{{experience?.startDate?.substring(0,4) +" - " +
                        experience?.endDate?.substring(0,4)}}</div>
                    <div class="exp-one-info">
                        <ul class="data">
                            {{experience?.description || "lorem ipsum dolor sit amet consectetur adipisicing elit. At
                            iure mollitia commodi odio ipsa qui, similique perferendis in sequi, eligendi inventore
                            laborum quos numquam voluptatibus illo delectusamet cumque explicabo"}}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        }
        @if(userData?.educationDetails?.length > 0){

        <div class="education">
            <div class="education-title">EDUCATION</div>
            @for(education of userData?.educationDetails;track education){

            <div class="education-info">
                {{ education?.degreeTitle || "Bachelor of Science in Computer Science"
                }} </div>
            {{education?.startDate?.substring(0,4) +" - " +
            education?.endDate?.substring(0,4)}}
            <div class="edu-info">
                {{education?.institute || "UNIVERSITY OF EDUCATION"}}

            </div>
            }
        </div>
        }
    </div>
</div>