import { Component, inject, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { ApiCallService } from 'src/app/shared/services/api-call.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CommonUtilsServiceService } from 'src/app/shared/services/common-utils-service.service';
import { PropertyService } from 'src/app/shared/services/property.service';

@Component({
  selector: 'app-hostel-detail',
  templateUrl: './hostel-detail.component.html',
  styleUrl: './hostel-detail.component.scss'
})
export class HostelDetailComponent {
  private routeSub: Subscription;
  public isSpinnerShown = false;
  public isRelatedHostelLoading = true;
  relatedhostelData: any[] = [];
  private _utilsService = inject(CommonUtilsServiceService);
  @Input() heading = "Related Hostels";


  hostelid: any;
  hostelData: any;
  private _apiCall = inject(ApiCallService);
  private _propertyService = inject(PropertyService);
  private readonly _spinner = inject(NgxSpinnerService);
  private readonly _auth = inject(AuthService);


  constructor(private route: ActivatedRoute) { }


  ngOnInit(): void {

    this.hostelid = this.route.snapshot.queryParamMap.get('hostelid');

    this.route.queryParamMap.subscribe((params) => {
      this.hostelid = params.get('hostelid');
      if (this.hostelid) {
        this.getHostelDetails();
      }
      window.scrollTo(0, 0);
    });
  }



  getHostelDetails() {
    this.isSpinnerShown = true;
    this.hostelid = this.route.snapshot.queryParamMap.get('hostelid');

    this._apiCall.PostCallWithoutToken(null, `Hostel/GetHostelById?hostelId=${this.hostelid}`).subscribe(
      (response) => {
        this.hostelData = {
          moduleType: "hostel",
          images: response.data.images.map((image: any) => ({
            //id: image.id,
            imageBase64: image.imageBase64
          })),
          instituteName: response.data?.name,
          country: response.data?.country,
          state: response.data?.state,
          city: response.data?.city,
          address: response.data?.address,
          startDate: response.data?.openingTime,
          endDate: response.data?.closingTime,
          description: response.data?.description,
          departments: response.data?.features.map((department: any) => ({
            id: department.id,
            departmentName: department.featureTitle
          })),
          locationSrc: response.data?.locationUrl,
          locationTitle: response.data?.locationName,
          email: response.data?.email,
          jobType: response.data?.type,
          contactNumber: response.data?.phoneNumber,
          noOfSeats: response.data?.noOfSeats,
          websiteLink: response.data?.siteLink,
          seats: response.data?.seats
        };

        // Initialize Blob URL array
        this.hostelData.blobUrls = [];

        // Convert each image to Blob URL if `hostelImages` exists
        if (Array.isArray(response.data.images)) {
          response.data.images.forEach((image: { imageBase64: string }) => {
            if (image.imageBase64 && image.imageBase64.includes('base64,')) {
              const base64Data = image.imageBase64.split('base64,')[1];
              const blobUrl = this._utilsService.convertBase64ToBlobUrl(base64Data, 'image/jpeg');
              this.hostelData.blobUrls.push(blobUrl);
            } else {
              const fallbackImage = this._auth.generateFallbackImage('H');
              this.hostelData.blobUrls.push(fallbackImage); // Fallback URL
            }
          });
        }
        this.getRelatedHostel();
        this.isSpinnerShown = false;
      },
      (error) => {
       this.isSpinnerShown = false;
      }
    );
    
  }


  // Get Related hostels 
  getRelatedHostel() {
    this.isRelatedHostelLoading = true;
    this._propertyService.getRelatedHostels(this.hostelid).subscribe(
      (response) => {
        this.relatedhostelData = response;
        this.isRelatedHostelLoading = false;

        // Convert each related hostel's image URL to a Blob URL
        this.relatedhostelData.forEach((hostel) => {
          if (hostel && hostel.img && hostel.img.url) {
            if (hostel.img.url.includes('base64,')) {
              const base64Data = hostel.img.url.split('base64,')[1];
              hostel.blobUrl = this._utilsService.convertBase64ToBlobUrl(base64Data, 'image/jpeg');
            } else {
              hostel.blobUrl = 'path/to/default/image.png';
            }
          } else {
            hostel.blobUrl = 'path/to/default/image.png';
          }
        });
      },
      (error) => {
        this.isRelatedHostelLoading = false;
      }
    );
  }



}
