import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { latestForRent } from '../../../../shared/interface/property';
import { PropertyService } from '../../../../shared/services/property.service';

@Component({
  selector: 'app-home-details',
  templateUrl: './home-details.component.html',
  styleUrls: ['./home-details.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeDetailsComponent {

  @Input() propertyDetails: latestForRent;
  @Input() instituteData: any;

  constructor(public propertyService: PropertyService){
    
  }

  print(){
    window.print()
  }



  getFullStars() {
    return Math.floor(this.instituteData?.averageRating);
  }

  getHalfStar() {
    return (this.instituteData?.averageRating % 1) >= 0.5;
  }

  getEmptyStars() {
    return 5 - Math.ceil(this.instituteData?.averageRating);
  }
}
