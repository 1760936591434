<div class="row ratio_55 mt-40 advertisement">
  <div class="col-xl-10 ps-2" [ngClass]="{ 'col-xl-12': adImages.length == 0 }">
    <app-title [isLineAdded]="true" [isLabelAdded]="true" [heading]="'Top Institutes'" [tag]="tag" [textWhite]="false"
      [type]="'simple'"></app-title>

    <!-- Filter buttons are always visible -->
    <div class="container filter-btns">
      <div class="toggle-btns">
        <input type="radio" id="university" name="toggle" checked (change)="onFilterClick('University')" />
        <label [ngClass]="{'active': selectedInstituteType === 'University'}" for="university">University</label>

        <input type="radio" id="college" name="toggle" (change)="onFilterClick('College')" />
        <label [ngClass]="{'active': selectedInstituteType === 'College'}" for="college">College</label>

        <input type="radio" id="school" name="toggle" (change)="onFilterClick('School')" />
        <label [ngClass]="{'active': selectedInstituteType === 'School'}" for="school">School</label>

        <input type="radio" id="academies" name="toggle" (change)="onFilterClick('Academy')" />
        <label [ngClass]="{'active': selectedInstituteType === 'Academy'}" for="academies">Academy</label>
      </div>
    </div>


    <!-- Show the slider when there are institutes -->
    @defer {
    <app-slider-filter-latest-rent *ngIf="institutesData.length > 0" [isDescriptionShown]="isDescriptionShown" 
      [propertyClass]="true" [tagClass]="'color-6'" [title]="title" [heading]="'Top Institutes'" [showFilters]="false"
      [institutesData]="institutesData" [isTopTeachersModule]="false"></app-slider-filter-latest-rent>
    }

    <!-- Show "No Institutes Found" message if no institutes are present -->
    <div *ngIf="noRecordsFound" class="no-record">
      <img src="../../../../assets/images/svg/Nodata.gif" alt="No Institutes Found" />
      <p>No Institutes Found</p>
    </div>
    @if(isErrorOccurred){
    <div>
      <div class="no-record error-svg">
        <img src="../../../../../assets/images/svg/error.gif" alt="Error Ocuurred">
        <p>An Error Occurred,Try refreshing Page</p>
      </div>
    </div>
    }

    <!-- Show the skeleton loader while data is being fetched -->
    <div *ngIf="isDataLoading && !noRecordsFound" class="item">
      <ngx-skeleton-loader count="8" [theme]="{
          height: '16.5rem',
          width: '23%',
          'border-radius': '10px',
          'margin-right': '0.5rem',
          'margin-left': '0.5rem'
        }"></ngx-skeleton-loader>
    </div>



    @if(institutesData.length > 0){
    <div class="all-institute-btn" >
      <app-center-button [buttonText]="'All Institutes'" [route]="'/institutes/all-institutes'"></app-center-button>
    </div>
  }
  </div>

  <!-- Advertisement section -->
  <div class=" advertisement ads-section" [ngClass]="{
    'col-lg-2': !isScreenSmall, 
    'hideAdvertsment': adImages.length == 0, 
    'col-lg-12': isScreenSmall 
  }">
    <!-- <app-title [heading]="''" [textWhite]="false" [type]="'simple'"></app-title> -->
    @if(!isAdLoading){
    <div class="main-ads-section" (mouseenter)="pauseScroll()" (mouseleave)="startScroll()">
      <div class="ad-images-wrapper">
        @for (ad of adImages; track ad) { @if (ad?.imageUrl?.length > 20) {
        <a (click)="getAdById(ad?.id,moduleName)" aria-label="clickable-text">
          <img [src]="ad?.imageUrl" class="ad-image" alt="Advertisement" />
        </a>
        } }
        @for(ad of adImages;track ad){ @if(ad?.imageUrl?.length > 20){
        <a (click)="getAdById(ad?.id,moduleName)" aria-label="clickable-text"><img [src]="ad?.imageUrl"
            class="ad-image" alt="Advertisement" />
        </a>
        } }
      </div>
    </div>
    }
    @else{
    <!-- <ng-template #advertismnets> -->
    <div class="item add-container">
      <ngx-skeleton-loader count="1" [theme]="{
            height: addSectionCss.height,
            width: addSectionCss.width,
            'border-radius': '10px',
            'margin-right': '0.5rem',
            'margin-left': '0.5rem'
          }"></ngx-skeleton-loader>
    </div>
    }
    <!-- </ng-template> -->

  </div>
</div>