<div class="single-property-section" style="z-index: 1;">
  <div class="container">
    <div class="single-title">
      <div class="left-single">
        <div class="d-flex">
          <h2 class="mb-0 main-Color">{{instituteData?.instituteName}} </h2>
          <!-- <span><span class="label label-shadow ms-2">For Sale</span></span> -->
        </div>
        <p class="mt-2">
          @if(instituteData?.moduleType == "books"){
          by
          }

          {{instituteData?.address + " " + instituteData?.country + " " + instituteData?.state + " " +
          instituteData?.city}}</p>
        @if(instituteData?.moduleType === "jobs"){
        <p class="mt-2">
          <span style="color: var(--mainColor);">Deadline:</span> {{instituteData?.deadline?.substring(0, 10)}}
        </p>
        }@else if(instituteData?.moduleType === "admission" || instituteData?.moduleType === "scholarships"  ){

        <p class="mt-2">
          <span style="color: var(--mainColor);">From:</span> {{ instituteData?.startDate?.substring(0, 10) }}
          <span style="color: var(--mainColor);">To:</span> {{ instituteData?.endDate?.substring(0, 10) }}
        </p>

        }@else if (instituteData?.moduleType == "hostel"){
          <span style="color: var(--mainColor);">Avaliable Seats:</span> {{ instituteData?.noOfSeats}}

        }
        @if(instituteData?.additionalFeatures?.length >= 1){

        <ul>
          @for(featue of instituteData?.additionalFeatures;track featue){
          <li>
            <div>
              @if(featue?.featureName == "Hostels/Dormitories"){
              <i class="fa-building fas main-Color"></i>
              <span> Hostels</span>
              }
              @if(featue?.featureName == "Laboratories"){
              <i class="fa-flask fas main-Color"></i>
              <span> Laboratories</span>
              }
              @if(featue?.featureName == "Scholarships and Financial Aid"){
              <i class="fa-award fas main-Color"></i>
              <span> Scholarships</span>
              }
              @if(featue?.featureName == "Research Centers"){
              <i class="fa-microscope fas main-Color"></i>
              <span> Research</span>
              }
              @if(featue?.featureName == "Library Resources and Services"){
              <i class="fa-book fas main-Color"></i>
              <span> Library</span>
              }
              @if(featue?.featureName == "Health and Wellness Facilities"){
              <i class="fa-stethoscope fas main-Color"></i>
              <span> Health Facilities</span>
              }
            </div>
          </li>
          }

        </ul>
        }
        <!-- @if(instituteData?.moduleType == "jobs"){
        <div class="share-buttons">
          <div class="d-inline-block">
            <a href="javascript:void(0)" class="btn btn-gradient btn-pill color-2">
              {{instituteData?.jobType}}
            </a>
          </div>
        </div>
        } -->

        <!-- <ul>
          <li>
            <div>
              <img src="assets/images/svg/icon/double-bed.svg" class="img-fluid" alt="bed-icon">
              <span>{{ propertyDetails ? propertyDetails.bed : '4' }} Bedrooms</span>
            </div>
          </li>
          <li>
            <div>
              <img src="assets/images/svg/icon/bathroom.svg" class="img-fluid" alt="bathroom-icon">
              <span>{{ propertyDetails ? propertyDetails.bath : '4' }} Bathrooms</span>
            </div>
          </li>
          <li>
            <div>
              <img src="assets/images/svg/icon/sofa.svg" class="img-fluid" alt="sofa-icon">
              <span>{{ propertyDetails ? propertyDetails.rooms : '2' }} Rooms</span>
            </div>
          </li>
          <li>
            <div>
              <img src="assets/images/svg/icon/square-ruler-tool.svg" class="img-fluid ruler-tool" alt="ruler-icon">
              <span>{{ propertyDetails ? propertyDetails.sqft : '5000' }} Sq ft</span>
            </div>
          </li>
          <li>
            <div>
              <img src="assets/images/svg/icon/garage.svg" class="img-fluid" alt="garage-icon">
              <span>1 Garage</span>
            </div>
          </li>
        </ul> -->
        <!-- <div class="share-buttons">
          <div class="d-inline-block">
            <a href="javascript:void(0)" class="btn btn-gradient btn-pill color-2"><i class="far fa-share-square"></i>
              share
            </a>
            <div class="share-hover">
              <ul>
                <li>
                  <a href="https://www.facebook.com/" class="icon-facebook">
                    <app-feather-icons [icon]="'facebook'"></app-feather-icons>
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/" class="icon-twitter">
                    <app-feather-icons [icon]="'twitter'"></app-feather-icons>
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/" class="icon-instagram">
                    <app-feather-icons [icon]="'instagram'"></app-feather-icons>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <a href="javascript:void(0)" class="btn btn-dashed btn-pill color-2 ms-md-2 ms-1 save-btn"><i class="far fa-heart"></i> Save</a>
          <a href="javascript:void(0)" class="btn btn-dashed btn-pill color-2 ms-md-2 ms-1" (click)="print()">
            <app-feather-icons [icon]="'printer'"></app-feather-icons> Print</a>
        </div> -->
      </div>
      <div class="right-single">
        @if(instituteData?.moduleType == "institute"){
        <!-- <h2 class="price">{{instituteData?.averageRating}} <span>/ 5</span></h2> -->
        <!-- <div class="rating">
          @for(star of [].constructor(getFullStars());track star){
          <i class="fas fa-star"></i>
          }@if(getHalfStar()){
          <i class="fas fa-star-half-alt"></i>
          }@for(star of [].constructor(getEmptyStars());track star){
          <i class="far fa-star"></i>
          }

        </div> -->
        }
        @if(instituteData?.moduleType == "hostel"){
          <h2 class="price">{{instituteData?.seats}} <span>/ per Seat</span></h2>
          }
        @if(instituteData?.moduleType == "books"){
        <h2 class="price">{{instituteData?.edition}} <span>
          </span></h2>
        }


        <!-- @if(propertyDetails){
          <h2 class="price">{{  propertyDetails.price | currencySymbol: propertyService.Currency }} <span>/ start From</span></h2>
        }@else if(!propertyDetails){
          <h2 class="price">{{ 2045472 | currencySymbol: propertyService.Currency }} <span>/ start From</span></h2>
        } -->
        @if(instituteData?.moduleType === "institute" || instituteData?.moduleType === "hostel" ){
        <div class="share-buttons mt-3">
          <div class="d-inline-block">
            <a [href]="instituteData?.websiteLink" target="_blank" class="btn btn-gradient btn-pill color-2" aria-label="clickable-text">
              Visit Website
            </a>
          </div>
        </div>
        }

        @if(instituteData?.moduleType === "books"){
        <div class="feature-label">
          <span class="btn btn-gradient btn-pill color-2 ms-1 ">{{instituteData?.bookLanguage}}</span>
        </div>
        }

        @if(instituteData?.moduleType === "jobs" || instituteData?.moduleType === "admission" ||
        instituteData?.moduleType === "scholarships"){
        <h2 class="price">{{instituteData?.salary }} <span>
            @if(instituteData?.moduleType === "jobs" ){
            / Salary
            }@else if(instituteData?.moduleType === "admission"){
            / Application Fee
            }
            @else if(instituteData?.moduleType === "scholarships"){
            / Scholarship Amount
            }
          </span></h2>

        @if(instituteData?.moduleType === "scholarships" || instituteData?.moduleType === "admission" ){
        <div class="feature-label">
          <a [href]="instituteData?.websiteLink" target="_blank" class="color-2" aria-label="clickable-text"><span
              class="btn btn-dashed color-2 btn-pill">Application Link</span></a>

        </div>
        }


        }
        @if(instituteData?.moduleType === "jobs"){
        <div class="share-buttons mt-3">
          <div class="d-inline-block">
            <a href="javascript:void(0)" class="btn btn-gradient btn-pill color-2" aria-label="clickable-text">
              {{instituteData?.jobType}}
            </a>
          </div>
        </div>
        }
      </div>
    </div>
  </div>
</div>