import { Component, Input, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ApiCallService } from 'src/app/shared/services/api-call.service';
import { DataShareService } from 'src/app/shared/services/data-share.service';
import { PropertyService } from 'src/app/shared/services/property.service';
import { ToastrService } from 'ngx-toastr';
import { CommonUtilsServiceService } from 'src/app/shared/services/common-utils-service.service';
import intlTelInput from "intl-tel-input";

@Component({
  selector: 'app-advance-filter-request-exploration',
  templateUrl: './advance-filter-request-exploration.component.html',
  styleUrls: ['./advance-filter-request-exploration.component.scss']
})
export class AdvanceFilterRequestExplorationComponent {
  @Input() instituteData: any;
  @Input() teacherData: any;


  requestForm: FormGroup;

  teachersData: any;
  contactNumber: string;

  userName: string = '';
  userMail: string = '';
  userNumber: string = '';
  userMesg: string = '';
  teacherId: string = '';
  private intlTelInputInstance: any;

  private _dataShare = inject(DataShareService);
  private readonly _utlis = inject(CommonUtilsServiceService);

  constructor(
    private propertyService: PropertyService,
    private fb: FormBuilder, private toastr: ToastrService) {

    this.requestForm = this.fb.group({
      userName: ['', Validators.required],
      // userMail: ["", [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]],
      userNumber: ["", [Validators.required,
      Validators.pattern("^((\\+91-?)|0)?[0-9]{8,16}$"),
      Validators.minLength(8),
      Validators.maxLength(16)]],
      userMesg: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    // console.log("contact of teacher", this.teacherData);
    this.loadPhoneScript();

  }
  sendRequest() {
    if (this.requestForm.valid) {
      const teacherDetails = this._dataShare.getTeacherDetails()
      const payload = {
        userName: this.requestForm.get('userName')?.value,
        userMail: this.requestForm.get('userMail')?.value,
        userNumber: this.requestForm.get('userNumber')?.value,
        userMesg: this.requestForm.get('userMesg')?.value,
        recepientId: teacherDetails.id,
      };

      this.propertyService.sendRequest(payload).subscribe(
        response => {
          // console.log('Request sent successfully', response);
          this.requestForm.reset();
        },
        error => {
          console.log('Error sending request', error);
        }
      );
    }
  }







  //////////Whatsapp Button////////////////


  openWhatsApp() {
    debugger
    const contactNumber = this.teacherData?.phoneNumber;
    if (contactNumber) {
      const url = `https://wa.me/${contactNumber}`;
      window.open(url, '_blank');
    } else {
      console.log('Contact number is not available');
    }
  }

  //////////Email Button////////////////


  openEmail() {
    debugger
    if (this.requestForm.invalid) {
      this.requestForm.markAllAsTouched();

      this.toastr.error('Please fill out all required fields correctly.', 'Error');
      return;
    }

    const name = this.requestForm.get('userName')?.value;
    let contactNumber = this.requestForm.get('userNumber')?.value;
    const description = this.requestForm.get('userMesg')?.value;
    const teacherEmail = this.teacherData?.email;
    contactNumber = this._utlis.numberWithCountryCode(this.requestForm.get("userNumber")?.value, this.intlTelInputInstance);
    if (teacherEmail) {
      const subject = `Email from ${name}`;
      const body = `Name: ${name}\nContact Number: ${contactNumber}\nMessage: ${description}`;

      // Open Gmail web interface with pre-filled email details
      const gmailLink = `https://mail.google.com/mail/?view=cm&fs=1&to=${encodeURIComponent(teacherEmail)}&su=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
      window.open(gmailLink, '_blank');

      this.toastr.success('Email successfully opened in Gmail.', 'Success');

      this.requestForm.reset();
    } else {
      console.warn("Teacher's email address is not available");
    }
  }




  loadPhoneScript() {
    setTimeout(() => {
      const inputElement = document.querySelector('#myInput') as HTMLInputElement;
      if (inputElement) {
        this.intlTelInputInstance = intlTelInput(inputElement, {
          initialCountry: 'pk',
          separateDialCode: true
        });
      }
    }, 500)
  }

}
