<app-loader-two [isLoading]="isLoading"></app-loader-two>
<app-header-one></app-header-one>
@if(scholarshipsData){
<app-sticky-tab [instituteData]="scholarshipsData"></app-sticky-tab>
<app-related-property 
[type]="'grid-3'" 
[heading]="heading" 
[totalData]="3" 
[instituteData]="relatedScholarshipData"
[isDescriptionShown]="true"
[isSkeletonShown]="isRelatedScholarshipsLoading"
></app-related-property>
<app-adly-footer></app-adly-footer>
}




