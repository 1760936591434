<app-loader-two [isLoading]="isSpinnerShown"></app-loader-two>
<app-header-one></app-header-one>
@if(hostelData){
<app-sticky-tab [instituteData]="hostelData"></app-sticky-tab>
<app-related-property [type]="'grid-3'" [heading]="heading" [totalData]="3" [instituteData]="relatedhostelData"
    [isDescriptionShown]="true" [isSkeletonShown]="isRelatedHostelLoading">
</app-related-property>

<app-adly-footer></app-adly-footer>
}
