<app-loader-two [isLoading]="isSpinnerShown"></app-loader-two>
<app-header-one></app-header-one>

<section class="user-dashboard small-section">
    <div class="container">
        <div class="row">
            <div class="col-lg-3">
                <div class="sidebar-user sticky-cls">
                    <div class="user-profile">
                        <app-user-info></app-user-info>
                    </div>
                    <div class="dashboard-list">
                        <app-user-panel-side-menu [activeTab]="'add-institute'"></app-user-panel-side-menu>
                    </div>
                </div>
            </div>
            <div class="col-lg-9">
                <div class="dashboard-content">
                    <div class="payment-user" id="payment">
                        <div class="common-card">
                            <div class="common-header">
                                <h5>Institute</h5>
                            </div>
                            <div class="row card-payment">
                                @if (isDataLoading) {
                                <div class="item">
                                    <ngx-skeleton-loader count="3" [theme]="{
                                                        height: '22.5rem',
                                                        width: '30%',
                                                        'border-radius': '10px',
                                                        'margin-right': '0.5rem',
                                                        'margin-left': '0.5rem'
                                                        }" />
                                </div>
                                }@else{
                                @for(data of institutesData; track data){
                                <div class="col-xl-4 col-sm-6">
                                    <!-- //ultra new cards  -->

                                    <div class="property-box payment-card">
                                        <div class="property-image">
                                            <!-- <owl-carousel-o [options]="Options">
                                                @for(images of data?.images; track images){
                                               
                                                <ng-template carouselSlide>
                                                    <div class="property-slider" [class]="tagClass">
                                                        <a href="javascript:void(0)" class="bg-size"
                                                            [style.background-image]="'url('+images?.imageBase64+')'">
                                                            <img [src]="images?.imageBase64" class="bg-img"
                                                                alt="property-image" style="display: none;" />
                                                        </a>
                                                    </div>
                                                </ng-template>
                                                
                                                }
                                            </owl-carousel-o> -->

                                            <div class="property-slider" [class]="tagClass">
                                                <div href="javascript:void(0)" class="bg-size"
                                                    [style.background-image]="'url(' + data?.imageBase64 + ')'">
                                                    <img [src]="data?.imageBase64" class="bg-img" alt="property-image"
                                                        style="display: none;" />
                                                    <div class="card-labels">
                                                        @if(data?.status=="Verified"){
                                                        <span class="label  label-success">Verified
                                                        </span>
                                                        }@else if(data?.status=="Pending"){
                                                        <span class="label  label-dark">Pending
                                                        </span>
                                                        }@else if(data?.status=="Declined"){
                                                        <span class="label  label-danger">Declined
                                                        </span>
                                                        }

                                                    </div>

                                                </div>
                                            </div>
                                            <!-- <div class="seen-data">
                                                <app-feather-icons [icon]="'camera'"></app-feather-icons>
                                                <span>{{ data?.img?.length }}</span>
                                            </div> -->
                                        </div>
                                        <div class="property-details">
                                            <span class="font-roboto card-title">
                                                <app-feather-icons class="color"
                                                    [icon]="'map-pin'"></app-feather-icons>{{
                                                data?.city + ", "
                                                + data?.country }}</span>
                                            <a href="javascript:void(0)">
                                                <h3 class="line-clamp">{{ data?.instituteName }}</h3>
                                            </a>
                                            <p class="font-roboto description-clamp">{{ data.description }}</p>
                                            <ul>
                                                <li>
                                                    <img src="assets/images/svg/icon/telephone.png"
                                                        class="img-fluid ruler-tool" alt="ruler-icon" />{{
                                                    data?.contactNumber }}
                                                </li>
                                                <li class="border-line"></li>
                                                <li>
                                                    <img src="assets/images/svg/icon/email.png"
                                                        class="img-fluid ruler-tool" alt="ruler-icon" />
                                                    {{ data?.email }}
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="edit-card">
                                            <a href="javascript:void(0)"
                                                (click)="editCard(addInstitute,data?.id)">edit</a>
                                            <a href="javascript:void(0)"
                                                (click)="deleteCard(deleteModal,data?.id)">delete</a>
                                            <!-- @if(data?.status=="Verified"){
                                            <a href="javascript:void(0)"
                                                (click)="boostCard(boostInstitute,data?.id)">Boost</a>
                                            } -->
                                            <!-- @if(data?.isActive=="Verified"){ -->
                                            <!-- <a href="javascript:void(0)"
                                                (click)="editAdCard(boostInstitute,data?.id)">Edit Ad</a> -->
                                            <!-- } -->
                                        </div>
                                    </div>

                                </div>
                                }
                                }
                                <div class="col-xl-4 col-sm-6">
                                    <div class="payment-card add-card">
                                        <div class="card-details" (click)="addCard(addInstitute)">
                                            <div>
                                                <i class="fas fa-plus-circle"></i>
                                                <h5>add new Institute</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<app-adly-footer></app-adly-footer>



<ng-template #addInstitute>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">
                @if(FormName == "Add"){
                Add New Institute
                }@else if (FormName == 'Edit'){
                Update Institute
                }
            </h5>
            <button type="button" class="btn-close" (click)="modal.dismissAll()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <form [formGroup]="addInstituteForm">
                <div class="row gx-3">
                    <div class="form-group col-6 col-sm-4">
                        <label for="a-na">Institute Name<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="a-na" placeholder="Enter Institute Name"
                            formControlName="institute_name">
                        @if(addInstituteForm.get('institute_name')?.hasError('required') &&
                        addInstituteForm.get('institute_name')?.touched){
                        <small class="text-danger">Name is Required</small>
                        }
                    </div>
                    <div class="form-group col-6 col-sm-4 modal-container">
                        <label for="a-n">Institute Type<span class="text-danger">*</span></label>
                        <app-common-dropdowns class="first-dropdown" [defaultText]="'Select Institute Type'"
                            [dataList]="InstitueTypeList" (optionSelected)="onInstitueTypeSelected($event)"
                            [isDisabled]="true" [selectedOption]="InstitueType"
                            [clearThevalue]="InstitueType"></app-common-dropdowns>
                        @if(InstitueType == "" && instituteTypeError){
                        <small class="text-danger">Institute Type is Required</small>
                        }
                    </div>
                    <div class="form-group col-6 col-sm-4 modal-container">
                        <label for="a-n">Country<span class="text-danger">*</span></label>
                        <app-common-dropdowns class="second-dropdown" [defaultText]="'Enter Country Name'"
                            [dataList]="CountryList" (optionSelected)="onCountrySelected($event)"
                            [selectedOption]="Country" [clearThevalue]="Country"
                            (sendTheValue)="onCountryValueChange($event)"></app-common-dropdowns>
                        @if(Country == "" && countryError){
                        <small class="text-danger">Country is Required</small>
                        }
                    </div>
                    <div class="form-group col-6 col-sm-4 modal-container">
                        <label for="a-na">State<span class="text-danger">*</span></label>
                        <app-common-dropdowns class="third-dropdown" [defaultText]="'Enter State Name'"
                            [dataList]="StateList" (optionSelected)="onStateSelected($event)" [selectedOption]="State"
                            [clearThevalue]="State" (sendTheValue)="onStateValueChange($event)"
                            (touched)="onCountryFocused($event)"></app-common-dropdowns>
                        @if(countryError && Country === "" && CountrysearchTerm ===""){
                        <small class="text-danger">Please Select Country First</small>
                        }
                    </div>
                    <div class="form-group col-6 col-sm-4 modal-container">
                        <label for="a-n">City<span class="text-danger">*</span></label>
                        <app-common-dropdowns class="fourth-dropdown" [defaultText]="'Enter City Name'"
                            [dataList]="CityList" (optionSelected)="onCitySelected($event)" [selectedOption]="City"
                            [clearThevalue]="City" (touched)="onStateFocused($event)"></app-common-dropdowns>
                        @if(stateError && State === "" && StatesearchTerm === ""){
                        <small class="text-danger">Please Select State First</small>
                        }
                    </div>
                    <div class="form-group col-6 col-sm-4">
                        <label for="a-n">Address<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="a-n" placeholder="Enter Address"
                            formControlName="institute_address">
                        @if(addInstituteForm.get('institute_address')?.hasError('required') &&
                        addInstituteForm.get('institute_address')?.touched){
                        <small class="text-danger">Address is Required</small>
                        }
                    </div>
                    <div class="form-group col-6 col-sm-4">
                        <label for="a-na">Address title<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="a-na" placeholder="Enter Location title"
                            formControlName="institute_addressTitle">
                        @if(addInstituteForm.get('institute_addressTitle')?.hasError('required') &&
                        addInstituteForm.get('institute_addressTitle')?.touched){
                        <small class="text-danger">location is Required</small>
                        }
                    </div>
                    <div class="form-group col-6 col-sm-4">
                        <label for="a-n">Address link<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="a-n" placeholder="Enter Location link"
                            formControlName="institute_addressLink">
                        @if(addInstituteForm.get('institute_addressLink')?.hasError('required') &&
                        addInstituteForm.get('institute_addressLink')?.touched){
                        <small class="text-danger">location link is Required</small>
                        }
                        @if(addInstituteForm.get('institute_addressLink')?.touched &&
                        addInstituteForm.get('institute_addressLink')?.hasError('pattern')
                        ){
                        <small class="text-danger"> https://maps.google.com/maps</small>
                        }
                    </div>
                    <div class="form-group col-6 col-sm-4">
                        <label for="a-n">Site link<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="a-n" placeholder="Enter Website link"
                            formControlName="site_link">
                        @if(addInstituteForm.get('site_link')?.hasError('required') &&
                        addInstituteForm.get('site_link')?.touched){
                        <small class="text-danger">Site link is Required</small>
                        }
                        @if(addInstituteForm.get('site_link')?.touched &&
                        addInstituteForm.get('site_link')?.hasError('pattern')
                        ){
                        <small class="text-danger"> https://</small>
                        }
                    </div>
                    <div class="form-group col-6 col-sm-4">
                        <label for="a-na">Email<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="a-na" placeholder="Enter Email"
                            formControlName="email_address">
                        @if(addInstituteForm.get('email_address')?.hasError('required') &&
                        addInstituteForm.get('email_address')?.touched){
                        <small class="text-danger">Email is Required</small>
                        }
                        @if(addInstituteForm.get('email_address')?.touched &&
                        addInstituteForm.get('email_address')?.hasError('pattern')
                        ){
                        <small class="text-danger">Invalid Email</small>
                        }
                    </div>
                    <div class="form-group col-6 col-sm-4">
                        <label for="a-n">Phone Number<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="myInput" placeholder="Enter Phone Number"
                            formControlName="phone" minlength="8" maxlength="16" min="0">
                        @if(addInstituteForm.get('phone')?.hasError('required') &&
                        addInstituteForm.get('phone')?.touched){
                        <small class="text-danger">Phone is Required</small>
                        }
                        @if(addInstituteForm.get('phone')?.touched && addInstituteForm.get('phone')?.hasError('pattern')
                        ){
                        <small class="text-danger">Phone Number must in between 10-18</small>
                        }
                    </div>
                    @if(showDepartment){
                    <div class="form-group col-12 col-sm-4">
                        @if(addInstituteForm.get('department')){
                        <label for="a-n">Departments<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="a-n" placeholder="Add Departments"
                            formControlName="department" (keyup.enter)="onEnterDepartment($event)">
                        @if((departmentTags.length < 1 && addInstituteForm.get('department')?.touched) ||
                            departmentTagError ){ <small class="text-danger">Add atleast one Department</small>
                            }
                            @for(departmemntTag of departmentTags; track departmemntTag){
                            <div class="tag" (click)="removeDepartmentTags(departmemntTag)">
                                {{ departmemntTag.departmentName }}
                                <span class="close-icon">&times;</span>
                            </div>
                            }
                            }
                    </div>


                    <div class="form-group col-12 col-sm-4">
                        @if(addInstituteForm.get('courses_name')){
                        <label for="a-n">Courses<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="a-n" placeholder="Add Courses"
                            formControlName="courses_name" (keyup.enter)="onEnterKeyPress($event)">
                        @if((coursestags.length < 1 && addInstituteForm.get('courses_name')?.touched) ||
                            coursesTagError){ <small class="text-danger">Add atleast one Course</small>
                            }
                            @for(courseTag of coursestags; track coursestags){
                            <div class="tag" (click)="removeCourseTag(courseTag)">
                                {{ courseTag.courseName }}
                                <span class="close-icon">&times;</span>
                            </div>
                            }
                            }
                    </div>

                    }
                    @if(showClasses){
                    <div class="form-group col-12 col-sm-4">
                        @if(addInstituteForm.get('classes_name')){
                        <label for="a-n">Classes<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="a-n" placeholder="Add Classes"
                            formControlName="classes_name" (keyup.enter)="onEnterKey($event)">
                        @if((classesTags.length < 1 && addInstituteForm.get('classes_name')?.touched) || classesTagError
                            ){ <small class="text-danger">Add atleast one Class</small>
                            }
                            @for(classTag of classesTags; track classesTags){
                            <div class="tag" (click)="removeClassTags(classTag)">
                                {{ classTag.classTitle }}
                                <span class="close-icon">&times;</span>
                            </div>
                            }
                            }
                    </div>
                    }
                </div>

                <div class="row gx-3">
                    <div class="form-group col-12">
                        <label for="a-na">Description<span class="text-danger">*</span></label>
                        <textarea rows="4" name="" id="" class="form-control" placeholder="Enter Description"
                            formControlName="description" (input)="updateCharCount()"></textarea>
                        <small class="">{{ charCount }}/400</small>
                        @if(addInstituteForm.get('description')?.hasError('required') &&
                        addInstituteForm.get('description')?.touched){
                        <br><small class="text-danger">Description is Required</small>
                        }@else if(addInstituteForm.get('description')?.touched &&
                        addInstituteForm.get('description')?.value?.length < 400){ <br><small
                                class="text-danger">Description should be more than 400 characters</small>
                            }
                    </div>
                </div>
                <div class="row gx-3">
                    <div class="form-group col-12">
                        <label for="a-na">Vedio(mp4)</label>
                        <input type="text" class="form-control" id="a-na" placeholder="Enter Youtube link"
                            formControlName="video_link">
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-sm-12 mb-0">
                        <label>Additional features</label>
                        <div class="additional-checkbox">
                            @for(features of AdditionalFeatures; track features){
                            <label for="{{ features.id }}">
                                <input class="checkbox_animated color-4" id="{{ features.id }}" type="checkbox"
                                    (change)="updateCheckedValues(features)" [checked]="features.checked" />
                                {{ features.title }}
                            </label>
                            }
                        </div>
                    </div>
                </div>
            </form>
            <div class="dropzone-admin mb-0">
                <label>Media <span class="font-danger">*</span></label>

                <!-- for add case  -->
                @if(FormName == "Add"){
                <form class="dropzone" id="multiFileUpload">
                    <ngx-dropzone (change)="onSelect($event)" accept="image/jpeg,image/jpg,image/png"
                        [multiple]="false">
                        <ngx-dropzone-label>
                            <div class="dz-message needsclick">
                                <i class="fas fa-cloud-upload-alt"></i>
                                <h6 class="f-w-700 mb-0">
                                    Drop files here or click to upload.
                                </h6>
                            </div>
                        </ngx-dropzone-label>
                        @for(f of files; track f){
                        <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" [removable]="true"
                            (removed)="onRemove(f)" [file]="f">
                            <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>

                        </ngx-dropzone-image-preview>
                        }
                    </ngx-dropzone>
                </form>
                @if(imageError){
                <small class="text-danger">Image is Required</small>
                }
                @else if(imageSizeError){
                <small class="text-danger">Image size should be less than 1MB</small>
                }
                } @else if (FormName == "Edit"){
                <!-- for update case  -->
                <div class="image-gallery">
                    <div *ngFor="let image of images" class="image-container">
                        <app-feather-icons [icon]="'x'" class="closeImageIcon"
                            (click)="removeFile(image)"></app-feather-icons>
                        <img [src]="image.imageBase64" alt="image">
                    </div>
                </div>
                <div class="image-gallery">
                    <div class="upload-container" (click)="fileInput.click()">
                        <app-feather-icons [icon]="'upload'"></app-feather-icons>
                        <input #fileInput type="file" style="display: none;" accept="image/jpeg,image/jpg,image/png"
                            (change)="onFileSelected($event)">
                        Upload more Files
                    </div>
                </div>
                }




            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-dashed color-2 btn-pill" (click)="modal.dismissAll()">Cancel</button>
            <button type="button" class="btn btn-gradient color-2 btn-pill" (click)="onSubmit()">
                @if(FormName == "Add"){
                Add Institute
                }@else if (FormName == 'Edit'){
                Update Institute
                }
            </button>
        </div>
    </div>

</ng-template>


<ng-template #deleteModal>
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="btn-close" (click)="modal.dismissAll()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="text">
                Are You Sure you want to delete this Institute ?
            </div>
            <div class="deleteButtons">
                <button type="button" class="btn btn-dashed color-2 btn-pill"
                    (click)="modal.dismissAll()">Cancel</button>
                <button type="button" class="btn btn-gradient color-2 btn-pill" (click)="deleteInstitute()">
                    Delete
                </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #boostInstitute>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">
                @if(FormName == "Advertisment"){
                Boost Institute
                }@else if (FormName == "EditAdvertisment"){
                Edit Ad
                }
            </h5>
            <button type="button" class="btn-close" (click)="modal.dismissAll()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <form [formGroup]="boostInstituteForm">
                <div class="row gx-3">
                    <div class="form-group col-12 col-md-12 col-sm-12">
                        <label for="a-na">Title<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="a-na" placeholder="Enter Title"
                            formControlName="title">
                        @if(boostInstituteForm.get('title')?.hasError('required') &&
                        boostInstituteForm.get('title')?.touched){
                        <small class="text-danger">title is Required</small>
                        }
                    </div>
                </div>
                <div class="row gx-3">
                    <div class="form-group col-6 col-md-6 col-sm-6">
                        <label for="a-n"> Start Date</label>
                        <input type="date" formControlName="start_date" [min]="getMaxDate()" (change)="getMinDate()"
                            (change)="updateEndDateMin()" class="form-control" id="a-n" placeholder="Enter Start Date">
                        @if(boostInstituteForm.get('start_date')?.hasError('required') &&
                        boostInstituteForm.get('start_date')?.touched){
                        <small class="text-danger">Start Date is Required</small>
                        }
                    </div>
                    <div class="form-group col-6 col-md-6 col-sm-6">
                        <label for="a-n"> End Date</label>
                        <input type="date" formControlName="end_date" class="form-control" id="a-n"
                            placeholder="Enter End Date" [min]="minEndDate">
                        @if(boostInstituteForm.get('end_date')?.hasError('required') &&
                        boostInstituteForm.get('end_date')?.touched){
                        <small class="text-danger">End Date is Required</small>
                        }
                    </div>
                </div>

                <div class="row gx-3">
                    <div class="form-group col-12">
                        <label for="a-na">Description<span class="text-danger">*</span></label>
                        <textarea rows="4" name="" id="" class="form-control" placeholder="Enter Description"
                            formControlName="ad_description" (input)="updateAdCharCount()"></textarea>
                        <small class="">{{ adCharCount }}/200</small>
                        @if(boostInstituteForm.get('ad_description')?.hasError('required') &&
                        boostInstituteForm.get('ad_description')?.touched){
                        <br><small class="text-danger">Description is Required</small>
                        }@else if(boostInstituteForm.get('ad_description')?.touched &&
                        boostInstituteForm.get('ad_description')?.value?.length < 200){ <br><small
                                class="text-danger">Description should be more than 200 characters</small>
                            }
                    </div>
                </div>

            </form>
            <div class="dropzone-admin mb-0">
                <label>Media <span class="font-danger">*</span></label>

                <!-- for add case  -->
                @if(FormName == "Advertisment"){
                <form class="dropzone" id="multiFileUpload">
                    <ngx-dropzone (change)="onAdSelect($event)" accept="image/jpeg,image/jpg,image/png"
                        [multiple]="false">
                        <ngx-dropzone-label>
                            <div class="dz-message needsclick">
                                <i class="fas fa-cloud-upload-alt"></i>
                                <h6 class="f-w-700 mb-0">
                                    Drop files here or click to upload.
                                </h6>
                            </div>
                        </ngx-dropzone-label>
                        @for(f of adFiles; track f){
                        <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" [removable]="true"
                            (removed)="onAdRemove(f)" [file]="f">
                            <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>

                        </ngx-dropzone-image-preview>
                        }
                    </ngx-dropzone>
                </form>
                @if(imageError){
                <small class="text-danger">Image is Required</small>
                }
                @else if(adImageSizeError){
                <small class="text-danger">Image size should be less than 1MB</small>
                }
                } @else if (FormName == "EditAdvertisment"){
                <!-- for update case  -->
                <div class="image-gallery">
                    <div class="image-gallery">
                        <div class="image-container">
                            <app-feather-icons [icon]="'x'" class="closeImageIcon"
                                (click)="removeAdFile(imageBase64)"></app-feather-icons>
                            <img [src]="imageBase64" alt="image" />
                        </div>
                    </div>
                </div>
                <div class="image-gallery">
                    <div class="upload-container" (click)="fileInput.click()">
                        <app-feather-icons [icon]="'upload'"></app-feather-icons>
                        <input #fileInput type="file" style="display: none;" accept="image/jpeg,image/jpg,image/png"
                            (change)="onAdFileSelected($event)">
                        Upload Other File
                    </div>
                </div>
                }




            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-dashed color-2 btn-pill" (click)="modal.dismissAll()">Cancel</button>
            <button type="button" class="btn btn-gradient color-2 btn-pill" (click)="submitBoostAd()">
                @if(FormName == "Advertisment"){
                Boost
                }@else if(FormName == "EditAdvertisment"){
                Update Ad
                }
            </button>
        </div>
    </div>

</ng-template>