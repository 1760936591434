<div class="page-section" id="details">
  <h4 class="content-title head">
    @if(instituteData.moduleType == "jobs")
    {
    Job Details
    }
    @else if (instituteData.moduleType == "scholarships"){
    Scholarship Details
    }
    @else if (instituteData.moduleType == "books"){
    Book Details
    }@else if (instituteData.moduleType == "hostel"){
    Hostel Details
    }
    @else{
    Institute Details
    }
    @if(instituteData?.moduleType != "books"){
    <a [href]="'https://www.google.com/maps/search/?api=1&query=' + (instituteData?.address)" target="_blank "
      aria-label="clickable-text" class="icon-size">
      <i class="fa-map-marker-alt fas"></i> View on Map
    </a>
    }

  </h4>
  <!-- } -->

  <!-- @else{
    <h4 class="content-title head">Institute Details
      <a [href]="'https://www.google.com/maps/search/?api=1&query=' + (instituteData?.address)"
          target="_blank " class="icon-size">
          <i class="fa-map-marker-alt fas"></i>  View on Map
      </a>
  </h4>
  } -->

  <div class="row">
    <ul class="property-list-details row">
      @if(instituteData.moduleType != "book"){
      @if(instituteData?.moduleType == "jobs" || instituteData?.moduleType == "admission" || instituteData?.moduleType == "institute"){
      <div class="col-md-6 col-sm-6 col-xl-4">
          <li class="d-flex"><span>Website Link: </span> <a [href]="instituteData?.websiteLink" target="_blank" class="d-flex" aria-label="clickable-text">
            @if(instituteData?.websiteLink?.length > 20){
            {{instituteData?.websiteLink?.substring(0, 20) + '...'}}
            }@else{
            {{instituteData?.websiteLink}}
            }
          </a></li>
      </div>
      }
      @if(instituteData?.contactNumber){
        <div class="col-md-6 col-sm-6 col-xl-4">
          <li class="d-flex contact"><span >Contact : </span>
            <a  [href]="'tel:' + instituteData?.contactNumber" class="d-flex" aria-label="clickable-text">
              <!-- <app-feather-icons [icon]="'phone-call'" class="me-2"></app-feather-icons> -->
              {{instituteData?.contactNumber}}
            </a></li>
        </div>
      }@if(instituteData?.email){
        <div class="col-md-6 col-sm-6 col-xl-4">
          <li class="d-flex contact"><span>Email : </span>
            <a [href]="'mailto:' + instituteData?.email" class="d-flex" aria-label="clickable-text">
              {{instituteData?.email}}
            </a></li>
      </div>
      }
      
      
      }

      <!-- <ul>
          @if(instituteData?.moduleType == "jobs" || instituteData?.moduleType == "admission"){
          <li>
            <a [href]="instituteData?.websiteLink" target="_blank" class="d-flex" aria-label="clickable-text">
              <app-feather-icons [icon]="'globe'" class="me-2"></app-feather-icons>
              @if(instituteData?.websiteLink?.length > 20){
              {{instituteData?.websiteLink?.substring(0, 20) + '...'}}
              }@else{
              {{instituteData?.websiteLink}}
              }
            </a>
          </li>
          }@else if(instituteData.email || instituteData?.moduleType == "admission"){
          <li>
            <a [href]="'mailto:' + instituteData?.email" class="d-flex" aria-label="clickable-text">
              <app-feather-icons [icon]="'mail'" class="me-2"></app-feather-icons>
              {{instituteData?.email}}
            </a>
          </li>
          }

          <li>
            <a [href]="'tel:' + instituteData?.contactNumber" class="d-flex" aria-label="clickable-text">
              <app-feather-icons [icon]="'phone-call'" class="me-2"></app-feather-icons>
              {{instituteData?.contactNumber}}
            </a>
          </li>

          <li>
            <a [href]="'https://www.google.com/maps/search/?api=1&query=' + (instituteData?.address)" aria-label="clickable-text"
              target="_blank" class="d-flex">
              <app-feather-icons [icon]="'map-pin'" class="me-2"></app-feather-icons>
              {{instituteData?.address}}
            </a>
          </li>

        </ul> -->

      @if(instituteData.moduleType == "scholarships"){
      <div class="col-md-6 col-sm-6 col-xl-4">
        <li><span>Application Fee :</span> {{instituteData?.applicationFee}}</li>
      </div>
      <div class="col-md-6 col-sm-6 col-xl-4">
        <li><span>Duration :</span> {{instituteData?.duration}}</li>
      </div>
      <div class="col-md-6 col-sm-6 col-xl-4">
        <li><span>Minimum CGPA :</span> {{instituteData?.minimumCGPA}}</li>
      </div>
      }

      @if(instituteData.moduleType == "books"){
      <div class="col-md-6 col-sm-6 col-xl-4">
        <li><span>Category :</span> {{instituteData?.category}}</li>
      </div>
      @if(instituteData?.isbNumber){
      <div class="col-md-6 col-sm-6 col-xl-4">
        <li><span>ISBN :</span>{{instituteData?.isbNumber}}</li>
      </div>
      }
      <div class="col-md-6 col-sm-6 col-xl-4">
        <li><span>Publication date :</span>{{instituteData?.publishedDate}}</li>
      </div>
      }@else{
      <div class="col-md-6 col-sm-6 col-xl-4">
        <li><span>Country :</span> {{instituteData?.country}}</li>
      </div>
      <div class="col-md-6 col-sm-6 col-xl-4">
        <li><span>State :</span>{{instituteData?.state}}</li>
      </div>
      <div class="col-md-6 col-sm-6 col-xl-4">
        <li><span>City :</span>{{instituteData?.city}}</li>
      </div>
      @if(instituteData.moduleType == "hostel"){
      <div class="col-md-6 col-sm-6 col-xl-4">
        <li><span>Opening Time :</span> {{instituteData?.startDate}}</li>
      </div>
      <div class="col-md-6 col-sm-6 col-xl-4">
        <li><span>Closing Time :</span> {{instituteData?.endDate}}</li>
      </div>

      }
      <div class="col-md-12 col-sm-12 col-xl-12">
        <li><span>Address :</span>{{instituteData?.address}}</li>
      </div>
      }


    </ul>
  </div>
  <!-- <h4 class="content-title">Property Details
    <a href="https://www.google.com/maps/place/New+York,+NY,+USA/@40.697488,-73.979681,8z/data=!4m5!3m4!1s0x89c24fa5d33f083b:0xc80b8f06e177fe62!8m2!3d40.7127753!4d-74.0059728?hl=en" target="_blank">
      <i class="fa fa-map-marker-alt"></i> view on map</a>
  </h4>
  <div class="row">
    @for(propertyDetails of propertyDetailsData; track propertyDetails){
      <div class="col-md-6 col-xl-4">
        <ul class="property-list-details">
          <li><span>{{ propertyDetails.title }} :</span> {{ propertyDetails.data }}</li>
        </ul>
      </div>
    }
  </div>
  <h4 class="content-title mt-4">Attachments</h4>
  <a href="javascript:void(0)" class="attach-file"><i class="far fa-file-pdf"></i>Demo Property Document </a> -->
</div>