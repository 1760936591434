import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, inject, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ApiCallService } from 'src/app/shared/services/api-call.service';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';
import { PropertyService } from 'src/app/shared/services/property.service';

@Component({
  selector: 'app-add-tutorial',
  templateUrl: './add-tutorial.component.html',
  styleUrl: './add-tutorial.component.scss'
})
export class AddTutorialComponent implements OnInit, OnDestroy {
  public themeLogo = 'assets/images/logo/2.png';
  public footerLogo = 'assets/images/logo/footer-logo.png';
  public bgImage = 'assets/images/banner/banner.jpg';
  public title = 'Dashboard';
  public parent = 'Tutorials';
  public child = 'Add Tutorial'

  addTutorialForm: FormGroup;
  html!: string;
  tutorialData: any;
  gridImages: boolean = false;
  listView: boolean = false;
  thumbnail: boolean = false;
  isDataLoading: boolean = true;
  thumbnail_video: boolean = false;
  public isSpinnerShown = false;
  tagClass: string;
  total: number;

  public selectedImage: string;
  public FormName: string = "Add";

  public readonly __apiCall = inject(ApiCallService);
  public readonly _toastr = inject(ToastrService);
  public readonly propertyService = inject(PropertyService);
  private readonly errorHandlingService = inject(ErrorHandlingService);
  public readonly _spinner = inject(NgxSpinnerService)

  public theme_default3 = '#ff5c41';
  public theme_default4 = '#ff8c41';

  content: string = '';

  editorContent: string = '';
  linkUrl: string = '';

  topicData: any;
  addTopicForm: FormGroup;

  constructor(public modal: NgbModal, private fb: FormBuilder, private cdr: ChangeDetectorRef) {
    this.addTutorialForm = this.fb.group({
      tutorial_name: ["", Validators.required],
      contentUrl: ["", [Validators.required, Validators.minLength(400)]],
      keyword_name: ["", Validators.required],
    })

    this.addTopicForm = this.fb.group({
      topic_name: ["", Validators.required],
      topic_description: ["", [Validators.required, Validators.minLength(400)]],
      contentUrl: ['', Validators.required],
    })

  }

  ngOnInit() {

    //this.getTutorial();    
    this.getTopic();
  }

  getTopic() {
    this.isDataLoading = true;
    this.__apiCall.GetCallWithToken("Dashboard/GetTopic").subscribe((response) => {
      if (response.responseCode == 200) {
        this.topicData = response.data;
        this.isDataLoading = false;
      } else {
        this.topicData = [];
        this.isDataLoading = false;
      }
    }, (error) => {
      this._toastr.error('Error fetching Topic data', error);
      this.isDataLoading = false;
    }
    )
  }

  ngOnDestroy(): void {
    document.documentElement.style.removeProperty('--theme-default');
    document.documentElement.style.removeProperty('--theme-default3');
    document.documentElement.style.removeProperty('--theme-default4');

  }
  chehckedFeatures: { featureName: string }[] = [];
  TutorialContentCharCount: number = 0;
  charCount: number = 0;
  updateCharCount(): void {
    let description = this.addTopicForm.get("topic_description")?.value;
    this.charCount = description?.length || 0;
  }
  updateTutorialContentCharCount(editorElement: HTMLElement): number {
    const editorContent = editorElement.innerText.trim(); // Get the inner text of the editable div
    const charCount = editorContent.length; // Calculate the character count
  
    // Log the count for debugging purposes
    console.log('Tutorial Content Length:', charCount);
  
    return charCount;
  }
  






  updateCheckedValues(feature: any) {
    feature.checked = !feature.checked;
  }

  addCard(content: TemplateRef<any>) {
    this.modal.dismissAll();
    this.FormName = "Add";
    this.addTopicForm.reset();
    this.updateTopicId = 0;
    this.thumbnailImages = [];
    this.thumbnailImageFile = null;
    this.thumbnailImageBase64 = null;
    this.updateCharCount();
    this.topicByIdData = {};
    this.modal.open(content, { centered: true, size: 'lg' })
  }

  editCard(content: TemplateRef<any>, id: number) {
    this.modal.open(content, { centered: true, size: 'lg' });
    this.FormName = "Edit";
    this.addTopicForm.reset();
    this.thumbnailImages = [];
    this.thumbnailImageFile = null;
    this.thumbnailImageBase64 = null;
    this.editTopic(id);
  }

  topicByIdData: any
  updateTopicId: number = 0;
  editTopic(id: number) {
    this.updateTopicId = id;
    this.isSpinnerShown = true;
    this.__apiCall.PostCallWithToken(null, `Dashboard/GetTopicById?topicId=${id}`).subscribe((res) => {
      if (res.responseCode == 200) {
        this.topicByIdData = res.data;

        this.addTopicForm.get('topic_name')?.setValue(this.topicByIdData?.topicName);
        this.addTopicForm.get('topic_description')?.setValue(this.topicByIdData?.topicDescription);
        this.thumbnailImageBase64 = this.topicByIdData?.imageBase64;
        this.updateCharCount();
      }
      else {
        this._toastr.error(res.responseMessage);
        this._spinner.hide();
      }
      this.isSpinnerShown = false;
    })
    this.addTopicForm.updateValueAndValidity();
  }

  delId: number;
  deleteCard(content: TemplateRef<any>, id: number) {
    this.modal.open(content, { centered: true, size: 'sm' });
    this.delId = id;
  }

  deleteTopic() {
    this.isSpinnerShown = true;
    this._spinner.show();
    this.__apiCall
      .PostCallWithoutToken(null, `Dashboard/DeleteTopic?id=${this.delId}`).subscribe((res) => {
        if (res.responseCode === 200) {
          this._toastr.success(res.responseMessage);
          this.getTopic();
          this.modal.dismissAll();
          this.isSpinnerShown = false;
          this._spinner.hide();
        }
        else {
          this._toastr.error(res.responseMessage);
          this.modal.dismissAll();
          this.isSpinnerShown = false;
          this._spinner.hide();
        }
      })
  }

  imageError: boolean = false;
  tutorialCategoryError: boolean = false;

  ShowError() {
    this.imageError = this.thumbnailImageBase64 == null ? true : false;
    this.tutorialCategoryError = this.tutorialCategory == "" ? true : false;
  }

  public Options = {
    items: 1,
    loop: true,
    nav: true,
    dots: true,
    autoplay: true,
    autoplayTimeout: 2000,
    navText: [
      "<i class='fa fa-chevron-left'></i>",
      "<i class='fa fa-chevron-right'></i>",
    ],
  };

  public thumbnailCarouselOptions = {
    items: 1,
    margin: 10,
    center: true,
    loop: true,
    nav: false,
    dots: false,
  };

  changeImage(image: string) {
    this.selectedImage = image;
  }



  files: File[] = [];

  thumbnailImageFile: File | null = null;
  thumbnailImageBase64: string | null = null;
  thumbnailImages: { imageBase64: string }[] = [];

  onImageSelect(event: NgxDropzoneChangeEvent) {
    const newFile = event.addedFiles[0];
    if (newFile) {
      this.thumbnailImageFile = newFile;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.thumbnailImageBase64 = e.target.result as string;
        // console.log('Image Base64:', this.thumbnailImageBase64);
      };
      reader.readAsDataURL(newFile);
    }
  }

  onRemoveImage() {
    this.thumbnailImageFile = null;
    this.thumbnailImageBase64 = null;
    console.log('Image removed');
  }

  onFileSelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result as string;
        this.thumbnailImages.push({ imageBase64: base64String });
        console.log('Thumbnail Image (Edit Case) added:', base64String);
      };
      reader.readAsDataURL(file);
    }
  }

  removeCoverImage(image: { imageBase64: string }): void {
    this.thumbnailImages = this.thumbnailImages.filter((img) => img !== image);
    console.log('Cover Image (Edit Case) removed');
  }

  checkImageSizeValidity(size: number, file: File): boolean {
    if (size > 1024) {
      this.onRemoveImage();
      console.error('Image size exceeds 1MB, file removed');
      return false;
    }
    return true;
  }

  onImageFileSelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        // const sizeInKB = Math.round(file.size / 1024);
        // const istoDel = this.checkImageSizeValidity(sizeInKB)
        // if (!istoDel) {
        const base64String = reader.result as string;
        this.thumbnailImageBase64 = base64String;
        // }
      };
    }
  }
  removeImageFile(): void {
    this.thumbnailImageBase64 = null;
    console.log('Image removed');

  }



  vediolink: any;
  public onSubmit() {
    this.addTopicForm.markAllAsTouched();
    this.ShowError();
    debugger
    if (
      this.thumbnailImageBase64 == null ||
      this.addTopicForm.invalid
    ) {
      const errorMessage = this.getFirstError();

    }
    else {
      this.isSpinnerShown = true;
      this._spinner.show();
      try {
        const payload = this.createApiPayload();
        this.__apiCall
          .PostCallWithToken(payload, "Dashboard/SaveTopic")
          .subscribe((response: any) => {
            if (response.responseCode == 200) {
              this.isSpinnerShown = false;
              this._spinner.hide();
              this._toastr.success(response.responseMessage);
              this.modal.dismissAll();
              this.getTopic();
            } else {
              this.errorHandlingService.handleResponseError(response);

            }
          });
      } catch (error) {
        this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
      } finally {
        this.isSpinnerShown = false;
        this._spinner.hide();
        this.cdr.detectChanges();
      }
    }

  }
  private getFirstError(): string {
    debugger
    for (const key of Object.keys(this.addTopicForm.controls)) {
      const control = this.addTopicForm.get(key);
      if (control && control.invalid) {
        return `${this.getFriendlyFieldName(key)} is invalid.`;
      }
    }
    if (this.thumbnailImageBase64 == null) return "Thumnail Image is Required";
    const description = this.addTopicForm.get('description')?.value;
    if ((description?.length ?? 0) < 400)
      return "Description must be at least 400 characters long.";
    return "";
  }

  private getFriendlyFieldName(field: string): string {
    const fieldNames: { [key: string]: string } = {
      topic_name: "Topic Name",
      topic_description: "Topic Description",
      thumbnailImageBase64: " Thumbnail Image",
    };
    return fieldNames[field] || field;
  }

  createApiPayload() {
    const payload: any = {
      id: this.updateTopicId,
      topicName: this.addTopicForm.get("topic_name")?.value,
      topicDescription: this.addTopicForm.get("topic_description")?.value || '',
      imageBase64: this.thumbnailImageBase64,
    }
    return payload;

  }
  //==============================================================//==============================================================
  //==============================================================//==============================================================
  //=============================================================Topic============================================================
  //==============================================================//==============================================================
  //==============================================================//==============================================================


  getTutorial() {
    this.isDataLoading = true;
    this.__apiCall.GetCallWithToken("Dashboard/GetTutorial").subscribe((response) => {
      if (response.responseCode == 200) {
        this.tutorialData = response.data;
        this.isDataLoading = false;
      } else {
        this.tutorialData = [];
        this.isDataLoading = false;
      }
    }, (error) => {
      this._toastr.error('Error fetching Tutorials data', error);
      this.isDataLoading = false;
    }
    )
  }

  getTutorialById(topicId: number) {
    //this.isSpinnerShown = true;
    // this._spinner.show();
    this.__apiCall
      .PostCallWithToken(null, `Dashboard/GetTutorialByTopicId?TopicId=${topicId}`)
      .subscribe((res) => {
        if (res.responseCode === 200) {
          this.tutorialData = res.data.tutorials;
          console.log("TutorialById By Id:", this.tutorialData);
        } else {
          this._toastr.error(res.responseMessage);
          this.tutorialData = [];
          this.isDataLoading = false;
        }
        this._spinner.hide();
      }, (error) => {
        this._toastr.error('Error fetching Tutorial data By Topic Id', error);
        this.isDataLoading = false;
      }

      );
  }



  topicTutorialId: number = 0;
  addTutorialCard(content: TemplateRef<any>, topicId: number) {
    console.log('Selected Topic ID:', topicId);
    this.FormName = "AddTutorial";
    this.addTutorialForm.reset();
    this.updateTutorialId = 0;
    this.topicTutorialId = topicId;
    console.log('topicTutorialId:', this.topicTutorialId);
    this.thumbnailImages = [];
    this.thumbnailImageFile = null;
    this.thumbnailImageBase64 = null;
    this.tutorialCategory;
    this.KeywordTags = [];
    this.modal.open(content, { centered: true, size: 'lg' })
  }

  editTutorialCard(content: TemplateRef<any>, id: number) {
    this.modal.dismissAll();
    console.log('Editing Tutorial ID:', id);
    this.FormName = "EditTutorial";
    this.addTutorialForm.reset();
    this.topicTutorialId = id;
    this.updateTutorialId = id;
    this.thumbnailImages = [];
    this.thumbnailImageFile = null;
    this.thumbnailImageBase64 = null;
    this.tutorialCategory;
    this.KeywordTags = [];
    this.modal.open(content, { centered: true, size: 'lg' });
    this.editTutorial(id);
  }

  tutorialCard(content: TemplateRef<any>, topicId: number) {
    console.log('Selected Tutorial ID:', topicId);
    this.FormName = "tutorialForm";
    this.addTutorialForm.reset();
    this.updateTopicId = 0;
    this.topicTutorialId = topicId;
    this.tutorialCategory;
    this.thumbnailImages = [];
    this.thumbnailImageFile = null;
    this.thumbnailImageBase64 = null;
    console.log('topicCourseId:', this.topicTutorialId);
    this.getTutorialById(topicId);
    this.modal.open(content, { centered: true, size: 'lg' })
  }

  tutorialByIdData: any
  updateTutorialId: number = 0;
  editTutorial(id: number) {
    this.updateTopicId = id;
    this.isSpinnerShown = true;
    this._spinner.show();

    this.__apiCall.PostCallWithToken(null, `Dashboard/GetTutorialById?TutorialId=${id}`).subscribe((res) => {
      if (res.responseCode == 200) {
        this.tutorialByIdData = res.data;
        console.log('TutorialById Data:', this.tutorialByIdData);
        this.addTutorialForm.get('tutorial_name')?.setValue(this.tutorialByIdData?.tutorialName);
        this.KeywordTags = this.tutorialByIdData?.keyword;
        this.tutorialCategory = this.tutorialByIdData.tutorialCategory;
        if (res.data?.imageBase64) {
          this.thumbnailImageBase64 = res.data.imageBase64;
        } else {
          console.error('Image Base64 not found in API response');
        }
        const editorElement = document.querySelector('.editor') as HTMLElement;

        if (editorElement) {
          // Update the editor content with the tutorial content or a fallback message
          editorElement.innerHTML = this.tutorialByIdData?.contentUrl || 'No Content Available';

          // Update and log the character count
          const updatedCharCount = this.updateTutorialContentCharCount(editorElement);

          console.log('Updated Tutorial Content Char Count:', updatedCharCount);
        } else {
          console.error('.editor element not found');
          this._toastr.error(res.responseMessage);
        }




      }
      else {
        this._toastr.error(res.responseMessage);
        this._spinner.hide();
      }
      this.isSpinnerShown = false;
    })
    this.addTutorialForm.updateValueAndValidity();
  }

  TutorialCategoryList = [
    { id: 1, value: "Education" },
    { id: 2, value: "Development" },
    { id: 3, value: "Cooking" },
    { id: 4, value: "Gaming" },
    { id: 5, value: "Music" },
  ];
  tutorialCategory: string = "";

  onTutorialCategorySelected(option: any) {
    this.tutorialCategory = option.value;
  }

  KeywordTags: any[] = [];
  tagTooLong: boolean = false;

  addKeywordTags(): void {
    const tagInput = this.addTutorialForm.get("keyword_name")?.value.trim();

    // Check for max length
    if (tagInput.length > 15) {
      this.tagTooLong = true;
      return;
    }

    // Reset max length validation
    this.tagTooLong = false;

    // Add tag if not already in the list
    if (tagInput && !this.KeywordTags.some(tag => tag.keyword === tagInput)) {
      const newTag = { keyword: tagInput };
      this.KeywordTags.push(newTag);
      this.addTutorialForm.get("keyword_name")?.setValue("");
    }
  }

  removeKeywordTags(tag: any): void {
    this.KeywordTags = this.KeywordTags.filter((t) => t !== tag);
  }

  onEnterKeyword(event: Event): void {
    event.preventDefault();
    if (event instanceof KeyboardEvent && event.key === "Enter") {
      this.addKeywordTags();
    }
  }

  trackByFn(index: number, item: any): number {
    return index;
  }

  deleteId: number;
  deleteTutorialCard(content: TemplateRef<any>, id: number) {
    this.modal.dismissAll();
    this.deleteId = id;
    this.modal.open(content, { centered: true, size: 'sm' });
  }

  deleteTutorial() {
    console.log('Tutorial ID being sent to API:', this.deleteId);
    this.isSpinnerShown = true;
    this._spinner.show();
    this.__apiCall
      .PostCallWithoutToken(null, `Dashboard/DeleteTutorial?TutorialId=${this.deleteId}`).subscribe((res) => {
        if (res.responseCode === 200) {
          this._toastr.success(res.responseMessage);
          this.getTutorial();
          this.modal.dismissAll();
          this.isSpinnerShown = false;
          this._spinner.hide();
        }
        else {
          this._toastr.error(res.responseMessage);
          this.modal.dismissAll();
          // this.isSpinnerShown = false;
          //this._spinner.hide();
        }
      })
    this.isSpinnerShown = false;
  }


  public async onSubmitTutorial(): Promise<void> {
    this.addTutorialForm.markAllAsTouched();
    const tagsValidity = this.checkTutorialTagsValidity();
    this.ShowError();
    this.removeTutorialUmwantedControl();

    if (
      this.thumbnailImageBase64 == "" ||
      tagsValidity ||
      this.addTutorialForm.invalid
    ) {
      // const errorMessage = this.getFirstError();
      const errorMessage = this.getTutorialError();
      if (errorMessage) {
        this._toastr.error(errorMessage);
        console.log('Error Message:', errorMessage);
        this.addTutorialControls();
        return;
      }
      // this.addTutorialControls();
    }
    else {
      this.isSpinnerShown = true;
      this._spinner.show();
      try {
        const payload = this.createTutorialPayload();
        console.log('Submitting Payload:', payload);
        const response = await this.__apiCall
          .PostCallWithToken(payload, "Dashboard/AddTutorial")
          .subscribe((response: any) => {
            this.addTutorialControls();
            if (response.responseCode == 200) {
              this.isSpinnerShown = false;
              this._spinner.hide();
              this._toastr.success(response.responseMessage);
              console.log('Response Message:', response.responseMessage);
              this.modal.dismissAll();
              this.getTutorial();
            }
            else {
              this.errorHandlingService.handleResponseError(response);
            }
          });
      } catch (error) {
        this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
        console.log('Error:', error);
      } finally {
        this.isSpinnerShown = false;
        this._spinner.hide();
        this.addTutorialControls();
        this.cdr.detectChanges();
      }
    }
  }
  private getTutorialError(): string {
    debugger

    // Loop over all form controls
    for (const key of Object.keys(this.addTutorialForm.controls)) {
      console.log(`Checking control: ${key}`);

      const control = this.addTutorialForm.get(key);
      console.log(`Control value: `, control?.value);  // Log control's value

    }


    // Check if tags are valid
    if (this.checkTutorialTagsValidity()) {
      console.log('Tags are invalid');
      return "Tags are invalid.";
    }

    console.log('No errors found');
    return "";
  }



  private getTutorialFieldName(field: string): string {
    const fieldNames: { [key: string]: string } = {
      tutorial_name: "Tutorial Name",
      tutorialCategory: "Tutorial Category",
      contentUrl: "Tutorial Content",
      keyword_name: "Tutorial Keywords",
      thumbnailImageBase64: " Thumbnail Image",
      tags: "Tags",
    };
    return fieldNames[field] || field;
  }

  createTutorialPayload() {
    const payload: any = {
      id: this.updateTutorialId,
      topicId: this.topicTutorialId,
      tutorialName: this.addTutorialForm.get("tutorial_name")?.value,
      tutorialCategory: this.tutorialCategory,
      contentUrl: this.addTutorialForm.get("contentUrl")?.value,
      imageBase64: this.thumbnailImageBase64,
      keyword: this.KeywordTags,
    }
    return payload;
  }

  removeTutorialUmwantedControl() {

    this.addTutorialForm.removeControl('keyword_name');
    this.addTutorialForm.updateValueAndValidity();
  }
  addTutorialControls() {
    if (!this.addTutorialForm.contains('keyword_name')) {
      this.addTutorialForm.addControl('keyword_name', new FormControl("", Validators.required));
    }
    this.addTutorialForm.updateValueAndValidity();
  }
  checkTutorialTagsValidity(): boolean {

    if (this.KeywordTags?.length < 1) {
      return true;
    }
    return false;
  }

  //====================================================Editor Code
  applyBold(): void {
    document.execCommand('bold', false);
  }
  applyItalic(): void {
    document.execCommand('italic', false);
  }
  applyUnderline(): void {
    document.execCommand('underline', false);
  }
  applyStrikethrough(): void {
    document.execCommand('strikeThrough', false);
  }
  applyBulletList(): void {
    document.execCommand('insertUnorderedList', false);
  }
  applyNumberedList(): void {
    document.execCommand('insertOrderedList', false);
  }
  alignLeft(): void {
    document.execCommand('justifyLeft', false);
  }
  alignCenter(): void {
    document.execCommand('justifyCenter', false);
  }
  alignRight(): void {
    document.execCommand('justifyRight', false);
  }
  // // Add a hyperlink
  // applyLink(): void {
  //   if (this.linkUrl.trim() !== '') {
  //     document.execCommand('createLink', false, this.linkUrl);
  //     this.linkUrl = '';
  //   } else {
  //     alert('Please enter a valid URL');
  //   }
  // }

  changeFontSize(action: string) {
    const selection = window.getSelection();

    if (!selection || selection.rangeCount === 0) {
      return; // Exit if there's no selected text
    }

    const range = selection.getRangeAt(0);
    const selectedContent = range.cloneContents();
    const wrapper = document.createElement('div');
    wrapper.appendChild(selectedContent);

    const currentElement = wrapper.querySelector('*');
    const currentStyle = currentElement
      ? window.getComputedStyle(currentElement)
      : window.getComputedStyle(range.startContainer.parentElement as HTMLElement);

    const currentSize = parseInt(currentStyle.fontSize || '16', 10);
    let newSize = currentSize;

    if (action === 'increase') {
      newSize += 2;
    } else if (action === 'decrease') {
      newSize = Math.max(1, newSize - 2);
    }

    const span = document.createElement('span');
    span.style.fontSize = `${newSize}px`;
    span.appendChild(range.extractContents());
    range.insertNode(span);

    selection.removeAllRanges();
    const newRange = document.createRange();
    newRange.selectNodeContents(span);
    selection.addRange(newRange);
  }


  onEditorInput(event: Event): void {
    const editorContent = (event.target as HTMLElement).innerText.trim();  // Get the inner text of the editable div
    this.charCount = editorContent.length;  // Calculate the character count
  }

  insertImage() {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*';

    fileInput.click();

    fileInput.onchange = (event: any) => {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          const imgElement = document.createElement('img');
          imgElement.src = reader.result as string;
          imgElement.style.maxWidth = '300px';
          imgElement.style.height = 'auto';
          document.execCommand('insertHTML', false, imgElement.outerHTML);
        };
        reader.readAsDataURL(file);
      }
    };
  }
}



