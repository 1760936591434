
<app-loader-two [isLoading]="isLoading"></app-loader-two>
<app-header-one></app-header-one>
@if(institutesData){
<app-sticky-tab [instituteData]="institutesData"></app-sticky-tab>
    <app-related-property 
    [type]="'grid-3'" 
    [heading]="heading" 
    [totalData]="3" 
    [instituteData]="relatedInstituteData"
    [isDescriptionShown]="true"
    [isSkeletonShown]="isRelatedInstituteLoading"
    >
    </app-related-property>

    <app-adly-footer></app-adly-footer>
}

