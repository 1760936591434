import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserDashboardComponent } from './user-dashboard/user-dashboard.component';
import { MyListingComponent } from './my-listing/my-listing.component';
import { CreatePropertyComponent } from './create-property/create-property.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { FavouritesComponent } from './favourites/favourites.component';
import { CardsPaymentComponent } from './cards-payment/cards-payment.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { ComparePropertyComponent } from './compare-property/compare-property.component';
import { AddInstituteComponent } from './manage-institute/add-institute/add-institute.component';
import { AddAdmissionComponent } from './manage-admission/add-admission/add-admission.component';
import { AddJobComponent } from './manage-jobs/add-job/add-job.component';
import { AddScholarshipComponent } from './manage-scholarship/add-scholarship/add-scholarship.component';
import { AddBooksComponent } from './manage-library/add-books/add-books.component';
import { ManageCvComponent } from './manage-cv\'s/manage-cv/manage-cv.component';
import { CvEditComponent } from './manage-cv\'s/cv-edit/cv-edit.component';
import { AddHostelComponent } from './manage-hostel/add-hostel/add-hostel.component';
import { TeacherTestComponent } from './manage-teacherTest/teacher-test/teacher-test.component';
import { QuestionTemplateComponent } from './manage-teacherTest/question-template/question-template.component';
import { authenticationGuard } from 'src/app/shared/guard/authentication.guard';
import { TestSummaryComponent } from './manage-teacherTest/test-summary/test-summary.component';
import { AddTutorialComponent } from './manage-tutorials/add-tutorial/add-tutorial.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'user-dashboard',
    pathMatch: 'full'
  },
  {
    canActivate : [authenticationGuard],
    path: 'user-dashboard',
    component: UserDashboardComponent,
  },
  {
    canActivate : [authenticationGuard],
    path: 'my-services',
    component: MyListingComponent,
  },
  {
    canActivate : [authenticationGuard],
    path: 'profile',
    component: CreatePropertyComponent,
  },
  {
    canActivate : [authenticationGuard],
    path: 'my-profile',
    component: MyProfileComponent,
  },
  {
    canActivate : [authenticationGuard],
    path: 'favourite',
    component: FavouritesComponent,
  },
  {
    canActivate : [authenticationGuard],
    path: 'compare-property',
    component: ComparePropertyComponent
  },
  {
    canActivate : [authenticationGuard],
    path: 'cards-payment',
    component: CardsPaymentComponent,
  },
  {
    canActivate : [authenticationGuard],
    path: 'privacy',
    component: PrivacyComponent
  },
  {
    canActivate : [authenticationGuard],
    path: 'add-institute',
    component: AddInstituteComponent
  },
  {
    canActivate : [authenticationGuard],
    path: 'add-admission',
    component: AddAdmissionComponent
  },
  {
    canActivate : [authenticationGuard],
    path: 'add-job',
    component: AddJobComponent
  },
  {
    canActivate : [authenticationGuard],
    path: 'add-library',
    component: AddBooksComponent
  },
  {
    canActivate : [authenticationGuard],
    path: 'add-scholarship',
    component: AddScholarshipComponent
  },
  {
    canActivate : [authenticationGuard],
    path: 'add-books',
    component: AddBooksComponent
  },
  {
    canActivate : [authenticationGuard],
    path: 'add-hostel',
    component: AddHostelComponent,
  },
  {
    canActivate : [authenticationGuard],
    path: 'add-cv',
    component: ManageCvComponent
  },
  {
    canActivate : [authenticationGuard],
    path: 'teacher-test',
    component: TeacherTestComponent
  },
  { 
    canActivate : [authenticationGuard],
    path: 'question',
   component: QuestionTemplateComponent 
  },
  { 
    canActivate : [authenticationGuard],
    path: 'test-summary',
   component: TestSummaryComponent 
  },
  {
    canActivate : [authenticationGuard],
    path: 'cv-templates/cv/:templateId',
    component: CvEditComponent
  },
  {
    canActivate : [authenticationGuard],
    path: 'add-tutorialDocumentation',
    component: AddTutorialComponent
  },
  {
    canActivate : [authenticationGuard],
    path: 'add-tutorialVideo',
    component: AddTutorialComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserPanelRoutingModule { }
