<div class="wrapper mt-lg-5">
    <div class="sidebar-wrapper">
        <div class="profile-container">
            <img [src]="userData?.userDetails?.profileBase64 || 'https://default-image-url.com/default-profile.png'"
                class="img-fluid bg-img" alt="profile-image" />
            <h1 class="name">{{ userData?.userDetails?.firstName + ' ' +
                userData?.userDetails?.lastName }}</h1>
            <h3 class="tagline">{{ userData?.userDetails?.gender }}</h3>
        </div><!--//profile-container-->

        <div class="contact-container container-block">
            <ul class="list-unstyled contact-list">
                @if(userData?.userDetails.email){

                <li class="email"><i class="fa-solid fa-envelope"></i><a>{{ userData?.userDetails.email ||
                        'MYEMAIL@gmail.com' }}</a></li>
                }
                @if(userData?.userDetails.phoneNumber){

                <li class="phone"><i class="fa-solid fa-phone"></i><a>{{ userData?.userDetails.phoneNumber || '+92 123
                        4567890'
                        }}</a></li>
                }
                @if(userData?.userDetails.address){

                <li class="website"><i class="fa-solid fa-globe"></i><a>{{ userData?.userDetails.address }}<br />
                        {{ userData?.userDetails.city }}<br />
                        {{ userData?.userDetails.state }}<br />
                        {{ userData?.userDetails.country }}</a></li>
                }

            </ul>
        </div><!--//contact-container-->
        @if(userData?.educationDetails.length > 0){

        <div class="education-container container-block">
            <h2 class="container-block-title">Education</h2>
            <div class="item" *ngFor="let education of userData?.educationDetails">
                <h4 class="degree">{{ education?.degreeTitle || 'Bachelor of Science in Computer Science' }}</h4>
                <h5 class="meta">{{ education?.institute || 'UNIVERSITY OF EDUCATION' }}</h5>
                <div class="time">{{ education?.startDate?.substring(0, 4) + ' - ' +
                    education?.endDate?.substring(0, 4) }}</div>
            </div>

        </div>
        }
        @if(userData?.languageDetails.length > 0){

        <div class="languages-container container-block">
            <h2 class="container-block-title">Languages</h2>
            <ul class="list-unstyled interests-list" *ngFor="let language of userData?.languageDetails">
                <li>
                    {{ language?.language }}
                    <span class="lang-desc">{{ language?.languageLevel }}</span>
                </li>
            </ul>


        </div>
        }
    </div><!--//sidebar-wrapper-->

    <div class="main-wrapper">

        <section class="section summary-section">
            <h2 class="section-title"><span class="icon-holder"><i class="fa-solid fa-user"></i></span>Career
                Profile</h2>
            <div class="summary">
                <p> {{ userData?.userDetails.description || "lorem ipsum dolor sit amet consectetur adipisicing
                    elit. At iure mollitia
                    commodi odio ipsa qui, similique perferendis in sequi, eligendi inventore laborum quos numquam
                    voluptatibus illo
                    delectusamet cumque explicabo Lorem ipsum dolor, sit amet consectetur adipisicing elit. Itaque
                    reiciendis non inventore accusamus
                    omnis" }}</p>
            </div><!--//summary-->
        </section><!--//section-->
        @if(userData?.experienceDetails.length > 0){

        <section class="section experiences-section">
            <h2 class="section-title"><span class="icon-holder"><i class="fa-solid fa-briefcase"></i></span>Experiences
            </h2>

            <div class="item" *ngFor="let experience of userData?.experienceDetails">
                <div class="meta">
                    <div class="upper-row">
                        <h3 class="job-title"> {{ experience?.role || "SENIOR UI AND UX DESIGNER"}}
                        </h3>
                        <div class="time">{{experience?.startDate?.substring(0,4) +" - " +
                            experience?.endDate?.substring(0,4)}}</div>
                    </div>
                    <div class="company">{{ experience?.company || "UNIVERSITY OF EDUCATION"}}</div>
                </div>
                <div class="details">
                    <p> {{experience?.description || "lorem ipsum dolor sit amet consectetur adipisicing elit. At
                        iure mollitia commodi odio ipsa qui, similique perferendis in sequi, eligendi inventore
                        laborum quos numquam voluptatibus illo delectusamet cumque explicabo"}}</p>
                </div>
            </div>


        </section>
        }


        @if(userData?.skillsDetails.length > 0){

        <section class="skills-section section">
            <h2 class="section-title"><span class="icon-holder"><i class="fa-solid fa-rocket"></i></span>Skills
                &amp; Proficiency</h2>
            <div class="skillset" *ngFor="let skill of userData?.skillsDetails">
                <div class="item">
                    <h3 class="level-title">{{ skill?.skillTitle || 'HTML' }}</h3>
                    <div class="progress level-bar">
                        <div class="progress-bar theme-progress-bar" role="progressbar"
                            [style.width.%]="skill?.percentage" aria-valuenow="99" aria-valuemin="0"
                            aria-valuemax="100"></div>
                    </div>
                </div>


            </div>
        </section>
        }

    </div>
</div>