<div class="cv-container">
    <div class="left-column">
        <h1 class="name"> {{userData?.userDetails.firstName + " " + userData?.userDetails.lastName}}
        </h1>
        <p class="title"> {{ userData?.userDetails.gender }}
        </p>
        @if(userData?.educationDetails?.length > 0){

        <div class="section">
            <h2>Education</h2>
            @for(education of userData?.educationDetails;track education){

            <div class="item">
                <h3>{{ education?.degreeTitle || "Bachelor of Science in Computer Science"
                    }}</h3>
                <p> {{education?.startDate?.substring(0,4) +" - " +
                    education?.endDate?.substring(0,4)}}</p>
                <p>{{education?.institute || "UNIVERSITY OF EDUCATION"}}</p>
            </div>
            }
        </div>
        }
        @if(userData?.experienceDetails?.length > 0){

        <div class="section">
            <h2>Experience</h2>
            <div class="item" *ngFor="let experience of userData?.experienceDetails">
                <h3>{{ experience?.role || "SENIOR UI AND UX DESIGNER"}}</h3>
                <p>{{experience?.startDate?.substring(0,4) +" - " +
                    experience?.endDate?.substring(0,4)}}</p>
                <p>{{experience?.description || "lorem ipsum dolor sit amet consectetur adipisicing elit. At
                    iure mollitia commodi odio ipsa qui, similique perferendis in sequi, eligendi inventore
                    laborum quos numquam voluptatibus illo delectusamet cumque explicabo"}}</p>
            </div>


        </div>
        }

        <div class="section">
            @if(userData?.languageDetails?.length > 0){

            <h2>Languages</h2>
            @for(language of userData?.languageDetails;track language){
            <div class="language">
                <div>{{language?.languageLevel}}</div>
                <p>{{ language?.language}}</p>
            </div>
            }

            }
            @if(userData?.skillsDetails?.length > 0){

            <div class="skills">
                <h2>Skills</h2>
                <div class="skill" *ngFor="let skill of userData?.skillsDetails">
                    <p>{{skill.skillTitle}}</p>
                    <div class="bar">
                        <div [style.width.%]="skill?.percentage"></div>
                    </div>
                </div>

            </div>
            }

        </div>
    </div>
    <div class="right-column">
        <div class="photo">
            <img [src]="userData?.userDetails.profileBase64 || 'https://default-image-url.com/default-profile.png'"
                class="img-fluid bg-img" alt="profile-image" />
        </div>
        <div class="about-me">
            <h2>About Me</h2>
            <p> {{ userData?.userDetails.description || "lorem ipsum dolor sit amet consectetur adipisicing
                elit. At iure mollitia
                commodi odio ipsa qui, similique perferendis in sequi, eligendi inventore laborum quos numquam
                voluptatibus illo
                delectusamet cumque explicabo Lorem ipsum dolor, sit amet consectetur adipisicing elit. Itaque
                reiciendis non inventore accusamus
                omnis" }}</p>
        </div>
        <div class="contact">
            <h2>Contact Me</h2>
            @if(userData?.userDetails.phoneNumber){

            <p> {{userData?.userDetails.phoneNumber ||"+92 123 4567890"}}
            </p>
            }
            @if(userData?.userDetails.email){

            <p>{{ userData?.userDetails.email||"MYEMAILgmail.com"}}</p>
            }
        </div>
        @if(userData?.userDetails.address){

        <div class="address">
            <h2>{{ userData?.userDetails.address }}</h2>
            <p>{{ userData?.userDetails.city }}</p>
            <p>{{userData?.userDetails.state }}, {{userData?.userDetails.country }}
            </p>
        </div>
        }
    </div>
</div>