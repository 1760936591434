<article class="resume-wrapper text-center position-relative">
  <div class="resume-wrapper-inner mx-auto text-left bg-white shadow-lg">
    <header class="resume-header pt-4 pt-md-0">
      <div class="media flex-column flex-md-row">
        <img class="mr-3 img-fluid picture mx-auto img-fluid bg-img "
          [src]="userData?.personalDetails?.data?.profileBase64" alt="profile-image">
        <div class="media-body p-4 d-flex flex-column flex-md-row mx-auto mx-lg-0">
          <div class="primary-info">
            <h1 class="name mt-0 mb-1 text-white text-uppercase"> {{userData?.personalDetails.data.firstName + " " +
              userData?.personalDetails.data.lastName}}
            </h1>
            <div class="title mb-3"> {{ userData?.personalDetails.data.gender }}
            </div>
            <ul class="list-unstyled">
              @if(userData?.personalDetails.data.email){

              <li class="mb-2">
                <a>
                  <i class="far fa-envelope fa-fw mr-2"></i>
                  {{ userData?.personalDetails.data.email||"MYEMAILgmail.com"}} </a>
              </li>
              }
              <br>
              @if(userData?.personalDetails.data.phoneNumber){

              <li>
                <a><i class="fa fa-phone fa-fw mr-2"></i>
                  {{userData?.personalDetails.data.phoneNumber ||"+92 123 4567890"}}
                </a>
              </li>
              }
              <br>
              <!-- @if(userData?.personalDetails.data.address){ -->

              <li>
                <a><i class="fa fa-map-marker fa-fw mr-2"></i>
                  {{ userData?.personalDetails.data.city+", "+userData?.personalDetails.data.state+",
                  "+userData?.personalDetails.data.country }}
                </a>
              </li>
              <!-- } -->
            </ul>
          </div>
        </div>
      </div>
    </header>


    <div class="resume-body p-5">
      <div class="resume-body p-5">
        <section class="resume-section summary-section mb-5">
          <h2 class="resume-section-title text-uppercase font-weight-bold pb-3 mb-3">
            About Me
          </h2>
          <div class="resume-section-content">
            <p class="mb-0">
              {{ userData?.personalDetails.data.description }} 
            </p>
          </div>
        </section>
      </div>



      <div class="row">
        <div class="col-lg-9">

          <section class="resume-section experience-section mb-5">
            <h2 class="resume-section-title text-uppercase font-weight-bold pb-3 mb-3">Experience</h2>
            <div class="resume-section-content">
              <div class="resume-timeline position-relative">
                <article class="resume-timeline-item position-relative pb-5"
                  *ngFor="let experience of userData?.experienceDetails?.data">

                  <div class="resume-timeline-item-header mb-2">
                    <div class="d-flex flex-column flex-md-row">
                      <h3 class="resume-position-title font-weight-bold mb-1"> {{
                        experience?.role}}
                      </h3>
                      <div class="resume-company-name ml-auto">
                        {{ experience?.company}}</div>
                    </div>
                    <div class="resume-position-time">{{ experience?.startDate?.substring(0, 4) + ' - ' +
                      experience?.endDate?.substring(0, 4) }}</div>
                  </div>
                  <div class="resume-timeline-item-desc">
                    <p> {{ experience?.description?.length > 500
                      ? experience.description.substring(0, 500) + '...'
                      : experience?.description }}
                    </p>

                  </div>

                </article>

              </div>






            </div>
          </section>

        </div>
        <div class="col-lg-3">
          <!-- @if(userData?.skillsDetails.data.length > 0){ -->

          <section class="resume-section skills-section mb-5">
            <h2 class="resume-section-title text-uppercase font-weight-bold pb-3 mb-3">Skills</h2>
            <div class="resume-section-content">
              <div class="resume-skill-item">
                <ul class="list-unstyled mb-4">
                  <li class="mb-2" *ngFor="let skill of userData?.skillsDetails.data">
                    <div class="resume-skill-name">{{skill.skillTitle}}</div>
                    <div class="progress resume-progress">
                      <div class="progress-bar theme-progress-bar-dark" role="progressbar"
                        [style.width.%]="skill?.percentage" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

            </div>
          </section>
          <!-- } -->
          <!-- @if(userData?.educationDetails.data.length > 0){ -->

          <section class="resume-section education-section mb-5">
            <h2 class="resume-section-title text-uppercase font-weight-bold pb-3 mb-3">Education</h2>
            <div class="resume-section-content">
              <ul class="list-unstyled">
                @for(education of userData?.educationDetails.data ;track education){

                <li class="mb-2">
                  <div class="resume-degree font-weight-bold">{{ education?.degreeTitle
                    }}</div>
                  <div class="resume-degree-org"> {{education?.institute }}
                  </div>
                  <div class="resume-degree-time"> {{education?.startDate?.substring(0,4) +" - " +
                    education?.endDate?.substring(0,4)}}</div>
                </li>
                }

              </ul>
            </div>
          </section>
          <!-- } -->
          <!-- @if(userData?.languageDetails.data.length > 0){ -->

          <section class="resume-section language-section mb-5">
            <h2 class="resume-section-title text-uppercase font-weight-bold pb-3 mb-3">Language</h2>
            <div class="resume-section-content">
              <ul class="list-unstyled resume-lang-list">
                @for(language of userData?.languageDetails.data ;track language){

                <li class="mb-2 align-middle">
                  <span class="resume-lang-name font-weight-bold">{{ language?.language}}</span>
                  <small class="text-muted font-weight-normal">{{language?.languageLevel}}</small>
                </li>
                <br>
                }

              </ul>
            </div>
          </section>
          <!-- } -->

        </div>
      </div>
    </div>


  </div>
</article>