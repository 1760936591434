import { EventEmitter, Injectable, inject } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonUtilsServiceService {
  public buttonClicked = new EventEmitter<void>();

  public triggerAfterLogin: EventEmitter<void> = new EventEmitter<void>();
  constructor(private router: Router) { }


  navigateTo(route: string, params?: { [key: string]: any }): void {
    // ;
    let navigationExtras: NavigationExtras = {};

    if (params) {
      navigationExtras = {
        ...navigationExtras,
        queryParams: params,
      };
    }

    this.router.navigate([route], navigationExtras).then(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    });

    this.router.events.subscribe((event) => {
      // console.log('Router Event:', event);
    });
  }


  scrollToSection(fragment: string) {
    const element = document.getElementById(fragment);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
      console.error(`Element with ID ${fragment} not found.`);
    }
  }




  // code for range slider reset 

  private slider = new BehaviorSubject<boolean>(false);
  resetSlider$: Observable<any> = this.slider.asObservable();


  sliderReset(Data: boolean) {
    this.slider.next(Data);
  }


  userPersonalData: any
  setUserPersonalDetails(data: any) {
    this.userPersonalData = data;
  }

  getUserPersonalDetails() {
    return this.userPersonalData;
  }

  private hasNavigated: boolean = false;

  setHasNavigated(value: boolean): void {
    this.hasNavigated = value;
  }

  getHasNavigated(): boolean {
    return this.hasNavigated;
  }

  emitAfterLogin() {
    this.triggerAfterLogin.emit();
  }

  private userDetailsUpdated = new BehaviorSubject<boolean>(false);
  private profileImageUpdated = new BehaviorSubject<string>('');
  private socialLinksUpdated = new BehaviorSubject<any>(null);

  userDetailsUpdated$ = this.userDetailsUpdated.asObservable();
  profileImageUpdated$ = this.profileImageUpdated.asObservable();
  socialLinkUpdated$ = this.socialLinksUpdated.asObservable();

  notifyUserDetailsUpdated() {
    console.log('Notifying user details updated');
    this.userDetailsUpdated.next(true);
  }

  updateProfileImage(imageUrl: string) {
    console.log('Updating profile image');
    this.profileImageUpdated.next(imageUrl);
  }

  updateSocialLinks(socialLinks: any) {
    console.log('Updating socialLinks image');
    this.socialLinksUpdated.next(socialLinks);
  }

  // Convert base64 to Blob
  public convertBase64ToBlob(base64: string, contentType: string): Blob {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: contentType });
  }

  // Convert base64 to Blob and generate a Blob URL
  public convertBase64ToBlobUrl(base64: string, contentType: string): string {
    const base64Data = base64.includes('base64,') ? base64.split('base64,')[1] : base64;
    const blob = this.convertBase64ToBlob(base64Data, contentType);
    return URL.createObjectURL(blob);
  }




  // code for Phone Number with Country Code

  numberWithCountryCode(number: string, countrycodevar: any): string {
    if (countrycodevar) {
      const selectedCountryData = countrycodevar.getSelectedCountryData();
      const countryCode = selectedCountryData.dialCode;
      const num = number;
      const contactNum = '+' + countryCode + num;
      return contactNum;
    }
    return number;
  }


  getComponentById(id: number, moduleType: any) {
    if (moduleType == "institute") {
      this.navigateTo("/institutes/institute-details", {
        instituteid: id,
      });
    } else if (moduleType == "admission") {
      this.navigateTo("/admissions/admission-details", {
        admissionid: id,
      });
    } else if (moduleType == "jobs") {
      this.navigateTo("/jobs/job-details", { jobid: id });
    } else if (moduleType == "scholarships") {
      this.navigateTo("/scholarships/scholarship-details", {
        scholarshipid: id,
      });
    } else if (moduleType == "books") {
      this.navigateTo("/books/book-details", { bookid: id });
    } else if (moduleType == "hostel") {
      this.navigateTo('/hostels/hostel-details', { hostelid: id });
    } else if (moduleType == "course") {
      this.navigateTo('/tutorials/tutorial-details', { tutorialid: id });
    }
  }

}
