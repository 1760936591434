import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { agencyAgent } from 'src/app/shared/interface/property';
import { ApiCallService } from 'src/app/shared/services/api-call.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CommonUtilsServiceService } from 'src/app/shared/services/common-utils-service.service';
import { PropertyService } from 'src/app/shared/services/property.service';

@Component({
  selector: 'app-teachear-details',
  templateUrl: './teachear-details.component.html',
  styleUrl: './teachear-details.component.scss',
})
export class TeachearDetailsComponent {

  private readonly _spinner = inject(NgxSpinnerService);
  private _apiCall = inject(ApiCallService);
  private _propertyService = inject(PropertyService);
  private _authService = inject(AuthService);
  private cdr = inject(ChangeDetectorRef); // Inject ChangeDetectorRef
  public isLoading = true;
  public isRelatedTeachersLoading = true;
  relatedTeachersData: any[] = [];
  public agencyData: agencyAgent[];
  public imageUrl: string | null = null;
  public relatedTeachersBlobUrls: string[] = [];

  constructor(private route: ActivatedRoute, private commonUtilsService: CommonUtilsServiceService) {
    this.teacherID = this.route.snapshot.queryParamMap.get('teacherid');
  }

  teacherID: any;
  TeacherData: any;

  ngOnInit(): void {
    this.teacherID = this.route.snapshot.queryParamMap.get('teacherid');

    this.route.queryParamMap.subscribe((params) => {
      this.teacherID = params.get('teacherid');
      if (this.teacherID) {
        this.getTeacherDetails();
      }
      window.scrollTo(0, 0);
    });
    // this.getTeacherDetails();
    // this.getRelatedTeachers();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['teacherData'] && !this.isLoading) {
      this.cdr.detectChanges();
    }
  }

  getTeacherDetails() {
    this._spinner.show();
    this.isLoading = true;
    this._apiCall.PostCallWithoutToken(null, `Teacher/GetTeacherById?TeacherId=${this.teacherID}`).subscribe(
      (response) => {
        if (!this.TeacherData) {
          this.TeacherData = response.data;

          console.log("Teacher Details:", this.TeacherData);

          // Convert the teacher's profile image
          if (this.TeacherData?.profileBase64 && this.TeacherData.profileBase64.includes(',')) {
            const base64Data = this.TeacherData.profileBase64.split(',')[1];
            if (base64Data) {
              this.imageUrl = this.commonUtilsService.convertBase64ToBlobUrl(base64Data, 'image/png');
            } else {
              const fallbackBase64 = this._authService.generateFallbackImage(this.TeacherData.firstName);
              this.imageUrl = fallbackBase64;
            }
          } else {
            const fallbackBase64 = this._authService.generateFallbackImage(this.TeacherData.firstName);
            this.imageUrl = fallbackBase64;
          }
        }

        this._spinner.hide();
        this.isLoading = false;
        this.getRelatedTeachers();
      },
      (error) => {
        this.isLoading = false;
        this._spinner.hide();
        console.error("Error fetching teacher details:", error);
      }
    );
  }

  getRelatedTeachers() {
    this.isRelatedTeachersLoading = true;
    this._propertyService.getRelatedTeachers(this.teacherID).subscribe(
      (response) => {
        this.isRelatedTeachersLoading = false;
        this.relatedTeachersData = response;
        // Convert each related teacher's base64 image to Blob URL
        this.relatedTeachersData.forEach((teacher) => {
          if (teacher.img && teacher.img.includes(',')) {

            const base64Data = teacher.img.split(',')[1];
            if (base64Data) {
              teacher.blobUrl = this.commonUtilsService.convertBase64ToBlobUrl(teacher.img.split(',')[1], 'image/jpeg');
            }
            else {
              const fallbackBase64 = this._authService.generateFallbackImage(teacher.title);
              teacher.img = fallbackBase64;
              teacher.blobUrl = teacher.img;
            }
          } else {
            const fallbackBase64 = this._authService.generateFallbackImage(teacher.title);
            teacher.img = fallbackBase64;
            teacher.blobUrl = teacher.img;
          }
        });

        // // console.log("Related Teachers Data with Blob URLs:", this.relatedTeachersData);
        // // Check specifically for the blob URL
        // this.relatedTeachersData.forEach((teacher) => {
        //   // console.log("Teacher ID:", teacher.id, "Blob URL:", teacher.blobUrl);
        // });
        this.cdr.detectChanges();
      },
      (error) => {
        console.error("Error fetching related teachers:", error);
      }
    );
  }

}
