import { Component, inject } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonUtilsServiceService } from 'src/app/shared/services/common-utils-service.service';
import { MetaService } from 'src/app/shared/services/meta.service';
import { PropertyService } from 'src/app/shared/services/property.service';

@Component({
  selector: 'app-tutorial-list',
  templateUrl: './tutorial-list.component.html',
  styleUrl: './tutorial-list.component.scss'
})
export class TutorialListComponent {
  public noRecordFound = false;
  public isDataLoading = true;
  public title: string = 'Tutorials';
  isErrorOccurred = false;
  public isSpinnerShown = false;
  bannerImage = 'assets/images/banner/tutorials-banner.jpg';
  recievedData: any;
  tutorialData: any[] = [];
  currentPage = 1;
  totalRecords = 0;
  itemsPerPage = 8;
  maxVisiblePages = 5;
  pages: (number | string)[] = [];

  private readonly _spinner = inject(NgxSpinnerService);
  private readonly _utils = inject(CommonUtilsServiceService);
  private readonly metaService: MetaService = inject(MetaService);
  public propertyService: PropertyService;

  constructor(propertyService: PropertyService) {
    this.propertyService = propertyService;
  }

  ngOnInit() {
    this.fetchTutorialsData(this.currentPage);
    this.setupMetaTags();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  setupMetaTags() {
    this.metaService.updateTitle('All Tutorials | AdmissionLylo');
    this.metaService.updateMetaTags([
      { name: 'description', content: 'Your page description here' },
      { name: 'keywords', content: 'angular, seo, meta tags' },
      { name: 'author', content: 'Your Name' },
      { name: 'robots', content: 'index, follow' }
    ]);
    this.metaService.setCanonicalURL();
    this.metaService.initializeMetaData();
  }

  onReset() {
    this.currentPage = 1;
    this.isSpinnerShown = true;
    this._spinner.show();
    this.fetchTutorialsData(this.currentPage);
  }

  handleChildEvent(data: any) {
    this.recievedData = data;
    this.CheckAndCallAPI();
  }

  handleSearchData(event: any) {
    if (event === '') {
      this.isSpinnerShown = true;
      this._spinner.show();
      this.recievedData.search = '';
      this.fetchTutorialsData(1);
    }
  }

  fetchTutorialsData(pageNumber: number): void {
    this.isSpinnerShown = true;
    this._spinner.show();
    this.propertyService.getAllTutorials({
      search: this.recievedData?.search || "",
      Name: this.recievedData?.tutorialName || "",
      Category: this.recievedData?.tutorialCategory || "",
    }, pageNumber).subscribe(
      response => {
        this.isDataLoading = false;
        this.isErrorOccurred = false;
        this.tutorialData = response.data;
        this.totalRecords = response.totalRecords; // Ensure totalRecords is being fetched correctly
        this.calculatePages(); // Update pagination

        if (this.tutorialData?.length <= 0) {
          this.noRecordFound = true;
          this.tutorialData = [];
          this.totalRecords = 0;
        } else {
          this.noRecordFound = false;
          this.convertBase64ImagesToBlobUrls();
        }

        this.isSpinnerShown = false;
        this._spinner.hide();
      },
      error => {
        console.error('Error fetching Tutorials data', error);
        this.isDataLoading = false;
        // this.isErrorOccurred = true;
        this.isSpinnerShown = false;
        this._spinner.hide();
      }
    );
  }

  convertBase64ImagesToBlobUrls(): void {
    this.tutorialData.forEach(tutorial => {
      tutorial.blobUrls = [];

      if (Array.isArray(tutorial.img)) {
        tutorial.img.forEach((image: { url: string }) => {
          // Ensure the image URL exists and contains base64
          const blobUrl = image.url?.includes('base64,')
            ? this._utils.convertBase64ToBlobUrl(image.url.split('base64,')[1], 'image/jpeg')
            : 'path/to/default/image.png';

          tutorial.blobUrls.push(blobUrl);
        });
      }
    });
  }



  onPageChanged(event: any): void {
    this.currentPage = event;
    this.fetchTutorialsData(this.currentPage);
    this.isDataLoading = true;
    this.scrollToContentStart();
  }

  calculatePages(): void {
    const totalPages = Math.ceil(this.totalRecords / this.itemsPerPage);
    this.pages = [];

    if (totalPages <= this.maxVisiblePages) {
      this.pages = Array.from({ length: totalPages }, (_, i) => i + 1);
    } else {
      this.createPageRange(totalPages);
    }
  }

  createPageRange(totalPages: number): void {
    const startPage = Math.max(this.currentPage - Math.floor(this.maxVisiblePages / 2), 1);
    const endPage = Math.min(startPage + this.maxVisiblePages - 1, totalPages);

    if (startPage > 1) {
      this.pages.push(1);
      if (startPage > 2) this.pages.push('...');
    }

    for (let i = startPage; i <= endPage; i++) {
      this.pages.push(i);
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) this.pages.push('...');
      this.pages.push(totalPages);
    }
  }

  navigateToPage(page: any): void {
    if (page >= 1 && page <= this.pages.length) {
      this.currentPage = page;
      this.fetchTutorialsData(this.currentPage);
      this.scrollToContentStart();
    }
  }

  navigatePage(direction: 'prev' | 'next'): void {
    if (direction === 'prev' && this.currentPage > 1) {
      this.currentPage--;
    } else if (direction === 'next' && this.currentPage < Math.ceil(this.totalRecords / this.itemsPerPage)) {
      this.currentPage++;
    }
    this.fetchTutorialsData(this.currentPage);
    this.scrollToContentStart();
  }

  getDisplayRange(): string {
    const start = (this.currentPage - 1) * this.itemsPerPage + 1;
    const end = Math.min(start + this.itemsPerPage - 1, this.totalRecords);
    return `${start} – ${end}`;
  }

  scrollToContentStart(): void {
    const contentStart = document.getElementById('content-start');
    if (contentStart) {
      const topOffset = contentStart.getBoundingClientRect().top + window.pageYOffset - 95;
      window.scrollTo({ top: topOffset, behavior: 'smooth' });
    }
  }

  resetPagination(): void {
    this.currentPage = 1;
    this.calculatePages();
    this.fetchTutorialsData(this.currentPage);
    this.scrollToContentStart();
  }

  onResetPagination(): void {
    this.resetPagination();
  }


  CheckAndCallAPI(): void {
    const filters = {
      search: this.recievedData?.search || "",
      Name: this.recievedData?.tutorialName || "",
      Category: this.recievedData?.tutorialCategory || "",
    };

    if (filters.Name || filters.Category || filters.search) {
      this.currentPage = 1; // Reset to first page when filters change
      this.isSpinnerShown = true;
      this._spinner.show();

      this.propertyService.getAllTutorials(filters, this.currentPage).subscribe(
        response => {
          this.tutorialData = response.data;
          this.totalRecords = response.totalRecords; // Ensure totalRecords is correct
          this.calculatePages(); // Update pagination
          this.noRecordFound = !this.tutorialData?.length;
          this.isSpinnerShown = false;
          this._spinner.hide();
        },
        error => {
          console.error('Error fetching tutorials data', error);
          this.isDataLoading = false;
          this.isSpinnerShown = false;
          this._spinner.hide();
        }
      );
    }
  }
}
