import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-cv6',
  templateUrl: './cv6.component.html',
  styleUrl: './cv6.component.scss'
})
export class Cv6Component {
  @Input() userData: any = {};


}
