<section class="ratio_40 breadcrumb-section p-0 single-property-images">
  <div class="main-property-slider arrow-image">
    @if(instituteData){
    <app-home-section-slider [homeSectionSliderData]="homeSectionSliderData" [type]="'standard'"
      [instituteData]="instituteData"></app-home-section-slider>
    }
  </div>
  <app-home-details [instituteData]="instituteData"></app-home-details>
</section>

<section class="single-property">
  <div class="container">
    <div class="row ratio_65">
      <!-- [ngClass]="{ 'col-lg-12 col-xl-12': instituteData?.moduleType == 'books' }" -->
      <div class="col-xl-9 col-lg-8 ">
        <app-property [propertyData]="propertyData" [instituteData]="instituteData" [type]="'simple'"></app-property>
      </div>
      <!-- @if(propertyData){ -->
      @if(instituteData?.moduleType != "books"){
      <div class="col-xl-3 col-lg-4">
        <div class="left-sidebar sticky-cls single-sidebar">
          <div class="filter-cards">
            <div class="advance-card">
              <h6>Contact Info</h6>
              <!-- <div class="category-property">
                <ul>
                  @if(instituteData?.moduleType == "jobs" || instituteData?.moduleType == "admission"){
                  <li>
                    <a [href]="instituteData?.websiteLink" target="_blank" class="d-flex" aria-label="clickable-text">
                      <app-feather-icons [icon]="'globe'" class="me-2"></app-feather-icons>
                      @if(instituteData?.websiteLink?.length > 20){
                      {{instituteData?.websiteLink?.substring(0, 20) + '...'}}
                      }@else{
                      {{instituteData?.websiteLink}}
                      }
                    </a>
                  </li>
                  }@else if(instituteData.email || instituteData?.moduleType == "admission"){
                  <li>
                    <a [href]="'mailto:' + instituteData?.email" class="d-flex" aria-label="clickable-text">
                      <app-feather-icons [icon]="'mail'" class="me-2"></app-feather-icons>
                      {{instituteData?.email}}
                    </a>
                  </li>
                  }

                  <li>
                    <a [href]="'tel:' + instituteData?.contactNumber" class="d-flex" aria-label="clickable-text">
                      <app-feather-icons [icon]="'phone-call'" class="me-2"></app-feather-icons>
                      {{instituteData?.contactNumber}}
                    </a>
                  </li>

                  <li>
                    <a [href]="'https://www.google.com/maps/search/?api=1&query=' + (instituteData?.address)" aria-label="clickable-text"
                      target="_blank" class="d-flex">
                      <app-feather-icons [icon]="'map-pin'" class="me-2"></app-feather-icons>
                      {{instituteData?.address}}
                    </a>
                  </li>

                </ul>
              </div> -->
              <!-- <h6>Request exploration</h6> -->
              <div class="category-property">
                <form [formGroup]="requestForm">
                  <div class="form-group">
                    <input type="text" class="form-control" placeholder="Your Name" formControlName="userName">
                    @if(requestForm.get('userName')?.hasError('required') && requestForm.get('userName')?.touched){
                    <small class="text-danger">Name is Required</small>
                    }
                  </div>

                  <!-- <div class="form-group">
                    <input type="email" class="form-control" placeholder="Email Address" formControlName="userMail">
                    @if(requestForm.get('userMail')?.hasError('required') && requestForm.get('userMail')?.touched){
                    <small class="text-danger">Email is Required</small>
                    }
                    @if(requestForm.get('userMail')?.touched && requestForm.get('userMail')?.hasError('pattern')){
                    <small class="text-danger">Invalid Email</small>
                    }
                  </div> -->

                  <div class="form-group">
                    <input placeholder="Phone Number" class="form-control" name="mobnumber" id="myInput" type="number"
                      formControlName="userNumber" minlength="8" maxlength="16">
                    @if(requestForm.get('userNumber')?.hasError('required') && requestForm.get('userNumber')?.touched){
                    <small class="text-danger">Phone Number is Required</small>
                    }
                    @if(requestForm.get('userNumber')?.touched && requestForm.get('userNumber')?.hasError('pattern')){
                    <small class="text-danger">Phone number must be between 10 and 18 digits</small>
                    }
                  </div>

                  <div class="form-group">
                    <textarea placeholder="Message" class="form-control" rows="3" formControlName="userMesg"></textarea>
                    @if(requestForm.get('userMesg')?.hasError('required') && requestForm.get('userMesg')?.touched){
                    <small class="text-danger">Message is Required</small>
                    }
                  </div>
                  <div class="d-flex justify-content-center">
                    <app-center-button [buttonText]="'Submit Request'" (click)="openEmail()"></app-center-button>
                  </div>
                  <div class="divider">
                    <span>Or</span>
                  </div>
                  <div class=" d-flex justify-content-center">
                    <app-center-button [buttonText]="' Whatsapp'" [icon]="'fab fa-whatsapp'" (click)="openWhatsApp()">
                    </app-center-button>
                  </div>
                </form>
              </div>
            </div>

          </div>
        </div>
        <!-- <app-advance-filter [propertyFilterSliderData]="propertyData.propertyFilterSlider" [instituteData]="instituteData"></app-advance-filter> -->
      </div>
      }@else if(instituteData?.moduleType == "books"){
      <div class="col-xl-3 col-lg-4">
        <div class="left-sidebar sticky-cls single-sidebar">
          <div class="filter-cards">
            <div class="advance-card">
              <h6>Enjoy Your Book</h6>
              <div class="category-property">
                <ul>
                  <li>
                    <a (click)="getBookbyId(instituteData?.id,'getBook',instituteData?.instituteName)" class="d-flex">
                      <app-feather-icons [icon]="'book-open'" class="me-2"></app-feather-icons>
                      Read Book
                    </a>
                  </li>
                  <li>
                    <a (click)="getBookbyId(instituteData?.id,'downloadpdf',instituteData?.instituteName)"
                      class="d-flex">
                      <app-feather-icons [icon]="'download'" class="me-2"></app-feather-icons>
                      Download
                    </a>
                  </li>
                </ul>
              </div>
            </div>

          </div>
        </div>
        <!-- <app-advance-filter [propertyFilterSliderData]="propertyData.propertyFilterSlider" [instituteData]="instituteData"></app-advance-filter> -->
      </div>
      }
      <!-- } -->
    </div>
  </div>
</section>
<!-- @if(instituteData?.moduleType !== "teacher" || instituteData?.moduleType !== "books"){
<app-related-property [type]="'grid-3'" [heading]="heading" [totalData]="3" [instituteData]="instituteData"></app-related-property>
} -->