import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { homeSectionSlider, latestForRent, propertyDetailsData } from '../../../shared/interface/property';
import { PropertyService } from '../../../shared/services/property.service';
import { ApiCallService } from 'src/app/shared/services/api-call.service';
import { Subject, takeUntil } from 'rxjs';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DataShareService } from 'src/app/shared/services/data-share.service';
import intlTelInput from "intl-tel-input";
import { CommonUtilsServiceService } from "src/app/shared/services/common-utils-service.service";
@Component({
  selector: 'app-sticky-tab',
  templateUrl: './sticky-tab.component.html',
  styleUrls: ['./sticky-tab.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class StickyTabComponent implements OnInit, OnDestroy {
  private intlTelInputInstance: any;
  private destroy$ = new Subject<void>();
  @Input() instituteData: any;
  @Input() heading: any;
  requestForm: FormGroup;
  
formData = {
  email : "",
  phone : "",
  moduleName : ""
}
  contactNumber: string;

  userName: string = '';
  userMail: string = '';
  userNumber: string = '';
  userMesg: string = '';
  teacherId: string = '';

  public themeLogo = 'assets/images/logo/2.png';
  public footerLogo = 'assets/images/logo/footer-logo.png';
  public title = 'sticky_tab';

  public total = 3;
  public homeSectionSliderData: homeSectionSlider[];
  public latestForRentData: latestForRent[] = [];
  public propertyData: propertyDetailsData;
  private apiService = inject(ApiCallService);
  private errorHandlingService = inject(ErrorHandlingService);
  private spinner = inject(NgxSpinnerService);
  private _dataShare = inject(DataShareService);
  private readonly _utlis = inject(CommonUtilsServiceService);
  public theme_default3 = '#ff5c41';
  public theme_default4 = '#ff8c41';


  constructor(private propertyService: PropertyService, private cdr: ChangeDetectorRef,private fb: FormBuilder, private toastr: ToastrService) {

    this.requestForm = this.fb.group({
      userName: ['', Validators.required],
      // userMail: ["", [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]],
      userNumber: ["", [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10,18}$"), Validators.minLength(10), Validators.maxLength(18)]],
      userMesg: ['', Validators.required],
    });
  }

  ngOnInit() {
    // document.documentElement.style.setProperty('--theme-default', this.theme_default3);
    // document.documentElement.style.setProperty('--theme-default3', this.theme_default3);
    // document.documentElement.style.setProperty('--theme-default4', this.theme_default4);
    this.formData.email = this.instituteData?.email;
    this.formData.phone = this.instituteData?.contactNumber;
    this.formData.moduleName = this.instituteData?.moduleType
    // this.propertyService.homeSliderData().subscribe((response) => {
    //   this.homeSectionSliderData = response.homeSection.filter(
    //     (item) => item.type == this.title
    //   );
    // });

    // this.propertyService.propertyDetailsData().subscribe((response) => {
    //   this.propertyData = response;
    // });

    // this.propertyService.latestForRentData().subscribe((response) => {
    //   this.latestForRentData = response.latestForRent.filter((item) =>
    //     item.type.includes('slider_filter_search')
    //   );
    // });
    this.loadPhoneScript();
  }


  ngOnDestroy(): void {
    // document.documentElement.style.removeProperty('--theme-default');
    // document.documentElement.style.removeProperty('--theme-default3');
    // document.documentElement.style.removeProperty('--theme-default4');
    this.destroy$.next();
    this.destroy$.complete();
  }

  public async getBookbyId(id: number, type: string,bookName:string): Promise<void> {
    this.spinner.show();

    try {
      const response = await this.apiService.PostCallWithoutToken(null, `Book/GetBookPdf?bookId=${id}`)
        .pipe(takeUntil(this.destroy$))
        .toPromise();
      if (response.responseCode === 200) {
        if (type == 'getBook') {
          const url = this.base64toUrl(response?.data?.pdfBase64);
          window.open(url);
        } else if (type == "downloadpdf") {
          this.downloadPDF(response?.data?.pdfBase64,bookName)
        }
      } else {
        this.errorHandlingService.handleResponseError(response);
      }
    } catch (error) {
      this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
    } finally {
      this.spinner.hide();
      this.cdr.detectChanges();
    }

  }



  getPDF(bookFile: any) {

  }

  private base64toUrl(bookFile: string): string {
    const byteCharacters = atob(bookFile.split(',')[1]);
    const byteNumbers = new Array(byteCharacters.length).fill(0).map((_, i) => byteCharacters.charCodeAt(i));
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });
    return window.URL.createObjectURL(blob);
  }


  downloadPDF(bookFile: string, title: string): void {
    const url = this.base64toUrl(bookFile);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${title}.pdf`;
    link.click();
  }














  // code for contact form


  
  
    

  
    
  
  
  
  
  
  
  
    //////////Whatsapp Button////////////////
  
  
    openWhatsApp() {
      const contactNumber = this.formData?.phone;
      if (contactNumber) {
        const url = `https://wa.me/${contactNumber}`;
        window.open(url, '_blank');
      } else {
        console.warn('Invalid Contact Number');
      }
    }
  
    //////////Email Button////////////////
  
  
    openEmail() {
      this.requestForm.markAllAsTouched();
      if (this.requestForm.invalid) { 
        this.toastr.error('Please fill out all required fields correctly.', 'Error');
        return; 
      }
  
      const name = this.requestForm.get('userName')?.value;
      let contactNumber = this.requestForm.get('userNumber')?.value;
      const description = this.requestForm.get('userMesg')?.value;
      const teacherEmail = this.formData?.email;
      contactNumber = this._utlis.numberWithCountryCode(this.requestForm.get("userNumber")?.value, this.intlTelInputInstance);
      if (teacherEmail) {
        const subject = `${this.formData.moduleName} Email from ${name}`;
        const body = `Name: ${name}\nContact Number: ${contactNumber}\nMessage: ${description}`;
        const gmailLink = `https://mail.google.com/mail/?view=cm&fs=1&to=${encodeURIComponent(teacherEmail)}&su=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
        // for email
        // const mailtoLink = `mailto:${encodeURIComponent(teacherEmail)}?subject=${encodeURIComponent(
        //   subject
        // )}&body=${encodeURIComponent(body)}`;
        window.open(gmailLink, '_blank');  
        this.requestForm.reset();
      } else {
        console.warn("Recipient Email is Invalid");
      }
    }
  
  
  
  
  
    loadPhoneScript() {
      setTimeout(() => {
        const inputElement = document.querySelector('#myInput') as HTMLInputElement;
        if (inputElement) {
          this.intlTelInputInstance = intlTelInput(inputElement, {
            initialCountry: 'pk',
            separateDialCode: true
          });
        }
      }, 500)
    }
}

