import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, inject, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { ApiCallService } from 'src/app/shared/services/api-call.service';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';
import { UserCentralizedDataService } from 'src/app/shared/services/user-centralized-data.service';

@Component({
  selector: 'app-cv-education-details',
  templateUrl: './cv-education-details.component.html',
  styleUrl: './cv-education-details.component.scss'
})
export class CvEducationDetailsComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  // VARIABLES
  educationForm: FormGroup;
  public FormName: string = "Add";
  editEducationId: number = 0;
  delEducationId: number;
  isLoading: boolean = true;
  public isExpanded: boolean = false;
  hasBeenOpened: boolean = false;
  isEducationAdded: boolean = false;
  educationDetails: any = [
    // {
    //   institute: "GOVT. HIGH SCHOOL JHNAG",
    //   startDate: "2005-07-21T00:00:00",
    //   endDate: "2005-07-21T00:00:00",
    //   cgpa: "2.5",
    //   degreeTitle: "BS Afganistan"
    // },
    // {
    //   institute: "GOVT. FARAN SCHOOL JHNAG",
    //   startDate: "2005-07-21T00:00:00",
    //   endDate: "2005-07-21T00:00:00",
    //   cgpa: "2.5",
    //   degreeTitle: "Associate Degree Arts"
    // }
  ];

  // SERVICES
  private readonly _apiCall = inject(ApiCallService);
  private readonly _toaster = inject(ToastrService);
  private readonly _spinner = inject(NgxSpinnerService);
  private readonly errorHandlingService = inject(ErrorHandlingService);
  private readonly _userCentralizedDataService = inject(UserCentralizedDataService);

  constructor(private fb: FormBuilder, public modal: NgbModal, private cdr: ChangeDetectorRef) {
    this.educationForm = this.fb.group({
      institute: ['', Validators.required],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      cgpa: ['', [Validators.required, Validators.pattern(/^(?:[0-3](?:\.\d{1,2})?|4(?:\.00?)?)$/)]],
      // total: ['', [Validators.required, Validators.min(0)]],
      // obtained: ['', [Validators.required, Validators.min(0)]]
    });
  }

  ngOnInit(): void {
    this.getDegreeDropDown();
    this.getEducationDetails();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }



  // FUNCTION FOR ADD FORM POPUP
  addCard(content: TemplateRef<any>) {
    this.educationForm.reset();
    this.degree = "";
    this.isPresent = false;
    if (!this.isPresent) {
      this.educationForm.get('endDate')?.enable();
    }
    this.modal.open(content, { centered: true, size: 'lg' });
    this.FormName = "Add";
  }

  newEducationsDetails: any;

  // FUNCTION FOR EDIT CARD POPUP
  editCard(content: TemplateRef<any>, tag: any) {
    this.educationForm.reset();
    this.degree = "";
    this.isPresent = false;
    this.modal.open(content, { centered: true, size: 'lg' });
    this.FormName = "Edit";
    this.editEducationId = tag?.id;
    this.educationForm.get('institute')?.setValue(tag?.institute);
    this.educationForm.get('startDate')?.setValue(tag?.startDate?.substring(0, 10));
    this.educationForm.get('endDate')?.setValue(tag?.endDate?.substring(0, 10));
    this.educationForm.get('cgpa')?.setValue(tag?.cgpa);
    this.degree = tag?.degreeTitle;
    this.isPresent = tag?.isPresent;
    if (this.isPresent) {
      this.educationForm.get('endDate')?.disable();
    } else {
      this.educationForm.get('endDate')?.enable();
    }
    // this.educationForm.get('total')?.setValue("Atg System");
    // this.educationForm.get('obtained')?.setValue("Atg System");

  }

  // FUNCTION FOR DELETE CARD POPUP
  deleteCard(content: TemplateRef<any>, id: any) {
    this.delEducationId = id;
    this.modal.open(content, { centered: true, size: 'sm' });
  }

  // public async deleteEducation(): Promise<void> {
  //   this._spinner.show();
  //   try {
  //     const res = await this._apiCall.PostCallWithToken(null, `AdmissionLeloUsers/DeleteTeacherEducation?EducationId=${this.delEducationId}`).pipe(takeUntil(this.destroy$))
  //       .toPromise();
  //     if (res.responseCode == 200) {
  //       this._toaster.success(res.responseMessage);
  //       this.getEducationDetails();
  //       this._spinner.hide();
  //       this.modal.dismissAll();
  //     } else {
  //       this.errorHandlingService.handleResponseError(res);
  //     }
  //   } catch (error) {
  //     this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
  //   } finally {
  //     this._spinner.hide();
  //     this.cdr.detectChanges();
  //   }
  // }



  // FUNCTION TO GET EDUCATION DETAILS
  private async getEducationDetails(): Promise<void> {
    this.isLoading = true;
    try {
      // Get education details from session storage
      this.educationDetails = this._userCentralizedDataService.getEducationDetails();
      this.isEducationAdded = this.educationDetails && this.educationDetails.length > 0
      if (!this.educationDetails || this.educationDetails.length === 0) {
        this.educationDetails = [];
      }
    } catch (error) {
      this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
    } finally {
      this._spinner.hide();
      this.cdr.detectChanges();
      this.isLoading = false;
    }
  }

  // FUNCTION TO CHECK IF DEGREE EXISTS
  checkDegreeExsists(): boolean {
    const payload = this.createApiPayload();
    if (this.educationDetails?.length > 0) {
      if (this.FormName == "Add") {
        const isDegreeExist = this._userCentralizedDataService.isDegreeExists(payload.degreeTitle);
        if (isDegreeExist) {
          this._toaster.error("This Degree Already Exist");
          return true;
        }
      } else {
        let newEducations = this.educationDetails.filter((item: any) => item.id !== this.editEducationId);
        const isDegreeExist = newEducations.some((tag: any) => tag.degreeTitle == payload.degreeTitle && tag.id != this.editEducationId);
        if (isDegreeExist) {
          this._toaster.error("This Degree Already Exist");
          return true;
        }
      }
      return false;
    }
    return false;
  }

  // FUNCTION TO SAVE EDUCATION DETAILS
  public async saveEducationDetails(): Promise<void> {
    this.degreeError = this.degree === "" ? true : false;
    this.educationForm.markAllAsTouched();
    const isDegExsists = this.checkDegreeExsists();
    if (!isDegExsists) {
      const payload = this.createApiPayload();
      if (this.educationForm.invalid || this.degree === "") {
        this._toaster.error("Invalid Form");
        return;
      }
      else {
        this._userCentralizedDataService.saveEducationDetails(payload);
        this.getEducationDetails();
        this.modal.dismissAll();

      }
    }
  };

  // FUNCTION TO CREATE API PAYLOAD
  createApiPayload() {
    const date = new Date().toJSON();

    this.editEducationId = this.FormName == "Add" ? 0 : this.editEducationId;
    return {
      id: this.editEducationId,
      degreeTitle: this.degree,
      institute: this.educationForm.get('institute')?.value,
      startDate: this.educationForm.get('startDate')?.value || date,
      endDate: this.educationForm.get('endDate')?.value || date,
      cgpa: this.educationForm.get('cgpa')?.value,
      total: this.educationForm.get('total')?.value,
      obtained: this.educationForm.get('obtained')?.value,
      IsPresent: this.isPresent
    };
  }



  // FUNCTIOND FOR DATES
  minmumDate: any
  getMaxDate(): string {
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    return formattedDate;
  }
  getMinDate() {
    const maxDate = this.educationForm.get('startDate')?.value;
    this.educationForm.get('endDate')?.reset();
    this.minmumDate = maxDate
  }


  // DEGREE DROPDOWN DATA
  degree: string = '';
  degreeList = []
  degreeError = false;
  onDegreeSelected(option: any) {
    this.degree = option.value;
  }
  onDegreeValueChange(searchValue: any) {
    this.degree = searchValue
  }
  getDegreeDropDown() {
    this._apiCall
      .GetCallWithoutToken("DropDown/GetDegreeDropDown")
      .subscribe((response) => {
        this.degreeList = response?.data;
      });
  }



  // PRESENT BUTTON LOGISTICS
  isPresent: boolean = false;
  getCheckboxValue() {
    console.log(this.isPresent);
    if (this.isPresent) {
      this.educationForm.get('endDate')?.disable();
    } else {
      this.educationForm.get('endDate')?.enable();
    }
  }



  // collapsed the tab
  toggleForm(event: MouseEvent) {
    event.stopPropagation();  // Stop click event propagation to prevent parent click
    this.isExpanded = !this.isExpanded;  // Toggle expanded state for the arrow
  }


}

