import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { CommonUtilsServiceService } from 'src/app/shared/services/common-utils-service.service';
import { DataShareService } from 'src/app/shared/services/data-share.service';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';
import { MetaService } from 'src/app/shared/services/meta.service';
import { PropertyService } from 'src/app/shared/services/property.service';

@Component({
  selector: 'app-institutes-list',
  templateUrl: './institutes-list.component.html',
  styleUrl: './institutes-list.component.scss'
})
export class InstitutesListComponent implements OnInit {

  // variables
  public noRecordFound = false;
  public isDataLoading = true;
  public isErrorOccurred: boolean = false;
  public isSpinnerShown = false;
  country!: string;
  state!: string;
  city!: string;
  data: any;
  DropdownonReset : string = '';
  receivedData: any;
  institutesData: any[] = [];
  public title = 'slider_filter_search';
  bannerImage: string = 'assets/images/banner/all-institute-banner.jpg';
  public ImagesSubscription : Subscription = new Subscription;


  // services
  private _utils = inject(CommonUtilsServiceService);
  private _spinner = inject(NgxSpinnerService)
  private errorHandlingService = inject(ErrorHandlingService);
  private readonly metaService: MetaService = inject(MetaService);
  private readonly _dataShare = inject(DataShareService);



  constructor(public propertyService: PropertyService,private cdr: ChangeDetectorRef) {

    // this.ImagesSubscription = this._dataShare.imageFormatChanged$.subscribe((data: any) => {
    //   if (data) {
    //     debugger;
    //     if (this.institutesData) {
    //       this.loadImageBlobUrls();
    //       this.cdr.detectChanges();
    //       // this.ImagesSubscription.unsubscribe();
    //     }
    //   }
    // })
  }



  ngOnInit(): void {

    this.fetchInstitutesData(this.currentPage);

    this.metaService.updateTitle('All Institutes | AdmissionLylo');
    this.metaService.updateMetaTags([
      { name: 'description', content: 'Your page description here' },
      { name: 'keywords', content: 'angular, seo, meta tags' },
      { name: 'author', content: 'Your Name' },
      { name: 'robots', content: 'index, follow' }
    ]);
    this.metaService.setCanonicalURL();
    this.metaService.initializeMetaData();

    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  // for reset search
  onReset() {
    this.currentPage = 1
    this._spinner.show();
    this.DropdownonReset = '';
    this.isSpinnerShown = true;
    this._dataShare.refreshDropdowns(true);
    this.fetchInstitutesData(this.currentPage);
  }

  // for Reciving data fro common banner
  handleChildEvent(data: any) {
    this.receivedData = data;
    this.CheckAndCallAPI();
  }

  // for reciving the search value to check and call api
  handleSearchData(event: any) {
    if (event === '') {
      this.isSpinnerShown = true;
      this.receivedData.search = '';
      this.fetchInstitutesData(1)
    }
  }

  ///////////////////   Pagination  //////////////////////////////

  pages: (number | string)[] = [];
  currentPage: number = 1;
  totalRecords: number = 0;
  itemsPerPage: number = 8;
  maxVisiblePages: number = 5;
  displayedItems: any[] = [];

  fetchInstitutesData(pageNumber: number): void {
    this.institutesData = [];
    this.isDataLoading = true;
    this.propertyService.getInstitutes(this.receivedData || {}, pageNumber, this.itemsPerPage).subscribe(
      (response) => {
        this.isDataLoading = false;
        this.isErrorOccurred = false;
        this.institutesData = response.data;
        this.totalRecords = this.institutesData[0]?.totalRecords;
        this.calculatePages();

        if (this.institutesData == null || this.institutesData?.length <= 0) {
          this.noRecordFound = true;
          this.institutesData = [];
          this.totalRecords = 0;
        } else {
          this.noRecordFound = false;
          this.loadImageBlobUrls();
        }
        this._spinner.hide();
        this.isSpinnerShown = false;
      },
      (error) => {
        this.isDataLoading = false;
        this.isErrorOccurred = true;
        this._spinner.hide();
        this.isSpinnerShown = false;
        this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
      }
    );
  }


  onPageChanged(event: any): void {
    this.currentPage = event;
    this.fetchInstitutesData(this.currentPage);
    this.isDataLoading = true;
    this.scrollToContentStart();
  }

  calculatePages(): void {
    const totalPages = Math.ceil(this.totalRecords / this.itemsPerPage);
    this.pages = [];
    if (totalPages <= this.maxVisiblePages) {
      this.pages = Array.from({ length: totalPages }, (_, i) => i + 1);
    } else {
      const startPage = Math.max(
        this.currentPage - Math.floor(this.maxVisiblePages / 2),
        1
      );
      const endPage = Math.min(startPage + this.maxVisiblePages - 1, totalPages);

      if (startPage > 1) {
        this.pages.push(1);
        if (startPage > 2) {
          this.pages.push('...');
        }
      }

      for (let i = startPage; i <= endPage; i++) {
        this.pages.push(i);
      }

      if (endPage < totalPages) {
        if (endPage < totalPages - 1) {
          this.pages.push('...');
        }
        this.pages.push(totalPages);
      }
    }
  }

  navigateToPage(page: any): void {
    if (page >= 1 && page <= this.pages.length) {
      this.currentPage = page;
      this.fetchInstitutesData(this.currentPage);
      this.scrollToContentStart();
    }
  }

  navigatePage(direction: 'prev' | 'next'): void {
    if (direction === 'prev' && this.currentPage > 1) {
      this.currentPage--;
    } else if (
      direction === 'next' &&
      this.currentPage < Math.ceil(this.totalRecords / this.itemsPerPage)
    ) {
      this.currentPage++;
    }
    this.fetchInstitutesData(this.currentPage);
    this.scrollToContentStart();
  }

  getDisplayRange(): string {
    const start = (this.currentPage - 1) * this.itemsPerPage + 1;
    const end = Math.min(start + this.itemsPerPage - 1, this.totalRecords);
    return `${start} – ${end}`;
  }

  scrollToContentStart(): void {
    const contentStart = document.getElementById('content-start');
    if (contentStart) {
      const topOffset = contentStart.getBoundingClientRect().top + window.pageYOffset - 95;
      window.scrollTo({ top: topOffset, behavior: 'smooth' });
    }
  }

  resetPagination(): void {
    this.currentPage = 1;
    this.calculatePages();
    this.fetchInstitutesData(this.currentPage);
    this.scrollToContentStart();
  }

  onResetPagination(): void {
    this.resetPagination();
  }

  // TS Code for Pagination End


  // for checking the values if values are avaliable for search then send api
  CheckAndCallAPI() {
    if (
      this.receivedData?.country !== '' ||
      this.receivedData?.state !== '' ||
      this.receivedData?.city !== '' ||
      this.receivedData?.search !== '' ||
      this.receivedData?.instituteType !== ''
    ) {
      this.institutesData = [];
      this.currentPage = 1;
      this.isSpinnerShown = true;
      this.isDataLoading = true;
      this.propertyService.getInstitutes(this.receivedData, this.currentPage, this.itemsPerPage).subscribe(
        (response) => {

          this.institutesData = response.data;
          this.totalRecords = this.institutesData[0]?.totalRecords;
          this.calculatePages();
          if (this.institutesData == null || this.institutesData?.length <= 0) {
            this.noRecordFound = true;
            this.institutesData = [];
            this.totalRecords = 0;
          } else {
            this.noRecordFound = false;
            this.loadImageBlobUrls();
          }
          this.isSpinnerShown = false;
          this.isDataLoading = false;
        },
        (error) => {
          this.isDataLoading = false;
          this.isSpinnerShown = false;
        }
      );

    }
  }




  loadImageBlobUrls() {
    debugger
    this.institutesData.forEach((institute) => {
      debugger
      institute.blobUrls = []; 
      if (Array.isArray(institute.img)) {
        debugger
        institute.img.forEach((image: { url: string; }) => {
          debugger
          if (image.url && image.url.includes('base64,')) {
            const base64Data = image.url.split('base64,')[1];
            const blobUrl = this._utils.convertBase64ToBlobUrl(base64Data, 'image/jpeg');
            institute.blobUrls.push(blobUrl);
          } else {
            institute.blobUrls.push('../../../../assets/images/others/no-image-found.jpg'); // Fallback URL
          }
        });
      }
    });
  }
}
