<app-loader-two [isLoading]="isSpinnerShown"></app-loader-two>
<app-header-one></app-header-one>


<section class="user-dashboard small-section">
  <div class="container">
    <div class="row">
      <div class="col-lg-3">
        <div class="sidebar-user sticky-cls">
          <div class="user-profile">
            <app-user-info></app-user-info>
          </div>
          <div class="dashboard-list">
            <app-user-panel-side-menu [activeTab]="'services'"></app-user-panel-side-menu>
          </div>
        </div>
      </div>
      <div class="col-lg-9">
        <div class="dashboard-content">
          <div class="payment-user" id="payment">
            <div class="common-card">
              <div class="common-header">
                <h5>My Services</h5>
              </div>
              <div class="row card-payment">
                @if (isLoading) {
                <div class="item">
                  <ngx-skeleton-loader count="3" [theme]="{
                                            height: '22.5rem',
                                            width: '30%',
                                            'border-radius': '10px',
                                            'margin-right': '0.5rem',
                                            'margin-left': '0.5rem'
                                            }" />
                </div>
                }@else{
                @for(data of servicesData; track data){
                <div class="col-xl-4 col-sm-6">
                  <!-- //ultra new cards  -->

                  <div class="property-box payment-card">
                    <div class="property-image">
                      <!-- <owl-carousel-o [options]="Options">
                                    @for(images of data?.images; track images){
                                   
                                    <ng-template carouselSlide>
                                        <div class="property-slider" [class]="tagClass">
                                            <a href="javascript:void(0)" class="bg-size"
                                                [style.background-image]="'url('+images?.imageBase64+')'">
                                                <img [src]="images?.imageBase64" class="bg-img"
                                                    alt="property-image" style="display: none;" />
                                            </a>
                                        </div>
                                    </ng-template>
                                    
                                    }
                                </owl-carousel-o> -->

                      <div class="property-slider">
                        <div href="javascript:void(0)" class="bg-size"
                          [style.background-image]="'url(' + data?.imageBase64 + ')'">
                          <img [src]="data?.imageBase64" class="bg-img" alt="property-image" style="display: none;" />
                          @if(data.serviceType){
                          <span class="label badge label-shadow">{{data.serviceType}}
                          </span>
                          }
                        </div>
                      </div>
                      <!-- <div class="seen-data">
                                    <app-feather-icons [icon]="'camera'"></app-feather-icons>
                                    <span>{{ data?.img?.length }}</span>
                                </div> -->
                    </div>
                    <div class="property-details">
                      <span class="font-roboto card-title">
                        <app-feather-icons class="color" [icon]="'map-pin'"></app-feather-icons>{{
                        data?.city + ", "
                        + data?.country }}</span>
                      <a href="javascript:void(0)">
                        <h3 class="line-clamp">{{ data?.teacherName }}</h3>
                      </a>
                      <ul>
                        <li>
                          <img src="assets/images/svg/icon/telephone.png" class="img-fluid ruler-tool"
                            alt="ruler-icon" />{{
                          data?.phoneNumber }}
                        </li>
                        <li class="border-line"></li>
                        <li>
                          <img src="assets/images/svg/icon/email.png" class="img-fluid ruler-tool" alt="ruler-icon" />
                          {{ data?.email }}
                        </li>
                      </ul>
                      <div class="tag">
                        <div class="tagContent">
                          <span>
                            {{ data?.subject + " - " + data?.fee + "/" + data?.duration }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="edit-card">
                      <a href="javascript:void(0)" (click)="editCard(addService,data)">edit</a>
                      <a href="javascript:void(0)" (click)="deleteCard(deleteModal,data?.id)">delete</a>
                    </div>
                  </div>

                </div>
                }
                }
                <div class="col-xl-4 col-sm-6">
                  <div class="payment-card add-card">
                    <div class="card-details" (click)="addCard(addService)">
                      <div>
                        <i class="fas fa-plus-circle"></i>
                        <h5>Add new Service</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<app-adly-footer></app-adly-footer>



<ng-template #addService>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">
        @if(FormName == "Add"){
        Add New Service
        }@else if(FormName == "Edit"){
        Update Service
        }
      </h5>
      <button type="button" class="btn-close" (click)="modal.dismissAll()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form [formGroup]="addServiceForm">
        <div class="row gx-3">
          <div class="form-group col-sm-6 modal-container">
            <label>Subject<span class="text-danger">*</span></label>
            <app-common-dropdowns class="third-dropdown" [dataList]="subjectList" [defaultText]="'Subject'"
              (optionSelected)="onSubjectSelected($event)" [selectedOption]="subject" [searchTerm]="subject"
              [clearThevalue]="subject" (sendTheValue)="onSubjectValueChange($event)">
            </app-common-dropdowns>
            @if(subject == "" && subjectError){
            <small class="text-danger">Subject is Required</small>
            }
          </div>
          <div class="form-group col-sm-6 modal-container">
            <label>Duration<span class="text-danger">*</span></label>
            <app-common-dropdowns class="fourth-dropdown" [dataList]="timeList" [defaultText]="'Time Slot'"
              class="commondropdown" (optionSelected)="onTimeSelcted($event)" [isDisabled]="true"
              [selectedOption]="timeSlot" [clearThevalue]="timeSlot">
            </app-common-dropdowns>
            @if(timeSlot == "" && durationError){
            <small class="text-danger">Duration is Required</small>
            }
          </div>

          <div class="form-group col-sm-6 modal-container">
            <label>Type*</label>
            <app-common-dropdowns class="fifth-dropdown" [defaultText]="'Select Service Type'"
              [dataList]="teacherTypeList" (optionSelected)="onTypeSelected($event)" [isDisabled]="true"
              [selectedOption]="teacherType" [clearThevalue]="teacherType"></app-common-dropdowns>
            @if(teacherType == "" && teacherTypeError){
            <small class="text-danger">Service Type is Required</small>
            }
          </div>

          <div class="form-group col-sm-6">
            <label>Fee<span class="text-danger">*</span></label>
            <input type="number" class="form-control" formControlName="fee" placeholder="Enter fee" min="0" />
            @if(addServiceForm.get('fee')?.hasError('required') &&
            addServiceForm.get('fee')?.touched){
            <small class="text-danger">Fee is Required</small>
            } @if(addServiceForm.get('fee')?.hasError('min') &&
            addServiceForm.get('fee')?.touched){
            <small class="text-danger">Fee cannot be negative</small>
            }
          </div>
        </div>
      </form>

      <div class="dropzone-admin mb-0">
        <label>Media <span class="font-danger">*</span></label>

        <!-- for add case  -->
        @if(FormName == "Add"){
        <form class="dropzone" id="multiFileUpload">
          <ngx-dropzone (change)="onSelect($event)" accept="image/jpeg,image/jpg,image/png" [multiple]="false">
            <ngx-dropzone-label>
              <div class="dz-message needsclick">
                <i class="fas fa-cloud-upload-alt"></i>
                <h6 class="f-w-700 mb-0">
                  Drop files here or click to upload.
                </h6>
              </div>
            </ngx-dropzone-label>
            @for(f of files; track f){
            <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" [removable]="true" (removed)="onRemove(f)"
              [file]="f">
              <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>

            </ngx-dropzone-image-preview>
            }
          </ngx-dropzone>
        </form>
        @if(imageError){
        <small class="text-danger">Image is Required</small>
        }
        @else if(imageSizeError){
        <small class="text-danger">Image size should be less than 1MB</small>
        }
        } @else if (FormName == "Edit"){
        <!-- for update case  -->
        <div class="image-gallery">
          <div class="image-gallery">
            <div class="image-container">
              <app-feather-icons [icon]="'x'" class="closeImageIcon"
                (click)="removeFile(imageBase64)"></app-feather-icons>
              <img [src]="imageBase64" alt="image" />
            </div>
          </div>
          <!-- <div *ngFor="let image of images" class="image-container">
                <app-feather-icons [icon]="'x'" class="closeImageIcon"
                    (click)="removeFile(image)"></app-feather-icons>
                <img [src]="image.imageBase64" alt="image">
            </div> -->
        </div>
        @if(imageBase64 ==  "" || null ){
        <div class="image-gallery">
          <div class="upload-container" (click)="fileInput.click()">
            <app-feather-icons [icon]="'upload'"></app-feather-icons>
            <input #fileInput type="file" style="display: none;" accept="image/jpeg,image/jpg,image/png"
              (change)="onFileSelected($event)">
            Upload Other File
          </div>
        </div>
        }
        }




      </div>

    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-dashed color-2 btn-pill" (click)="modal.dismissAll()">Cancel</button>
      <button type="button" class="btn btn-gradient color-2 btn-pill" (click)="saveServiceDetails()">
        @if(FormName == "Add"){
        Add Service
        }@else{
        Update Service
        }
      </button>
    </div>
  </div>

</ng-template>


<ng-template #deleteModal>
  <div class="modal-content">
    <div class="modal-header">
      <button type="button" class="btn-close" (click)="modal.dismissAll()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="text">
        Are You Sure you want to delete this Service ?
      </div>
      <div class="deleteButtons">
        <button type="button" class="btn btn-dashed color-2 btn-pill" (click)="modal.dismissAll()">Cancel</button>
        <button type="button" class="btn btn-gradient color-2 btn-pill" (click)="deleteService()">
          Delete
        </button>
      </div>
    </div>
  </div>
</ng-template>