import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ApiCallService } from 'src/app/shared/services/api-call.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CommonUtilsServiceService } from 'src/app/shared/services/common-utils-service.service';
import { DataShareService } from 'src/app/shared/services/data-share.service';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';

@Component({
  selector: 'app-log-in-user',
  templateUrl: './log-in-user.component.html',
  styleUrl: './log-in-user.component.scss',
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogInUserComponent implements OnInit {


  //variabeels
  formName: string = "Sign-in";
  inputType: string = "password";
  signUpPassword: string = "password";
  signUpConfirmPassword: string = "password";
  isLoading: boolean = false;


  //formgroups
  loginForm: FormGroup;
  sign_in_form: FormGroup;
  sign_up_form: FormGroup;
  forget_form: FormGroup;

  //services
  private readonly _apiService = inject(ApiCallService);
  private readonly _authService = inject(AuthService);
  private readonly _toaster = inject(ToastrService);
  private readonly _dataShare = inject(DataShareService);
  private readonly _utilsService = inject(CommonUtilsServiceService);
  private readonly _spinner = inject(NgxSpinnerService)
  private readonly errorHandlerService = inject(ErrorHandlingService);

  //emitter
  // @Output() userLoggedIn = new EventEmitter<void>();

  //inputs

  @Input() navigateToDashboard: boolean = true;


  constructor(private fb: FormBuilder, private router: Router, private modal: NgbModal) {

    //signInform FormGroup
    this.sign_in_form = this.fb.group({
      sign_in_email: ['', Validators.required],
      sign_in_password: ['', Validators.required],
      rememberMe: [false]
    });


    //signup Form FormGroup
    this.sign_up_form = this.fb.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      user_name: ['', Validators.required],
      // contact: ['', [
      //   Validators.required,
      //   Validators.pattern("^((\\+91-?)|0)?[0-9]{10,18}$"),
      //   // Validators.minLength(10),
      //   // Validators.maxLength(18)
      // ]],
      sign_up_email: ['', [
        Validators.required, Validators.email, Validators.pattern(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
      ]],
      sign_up_password: ['', [Validators.required, this.passwordValidator.bind(this)]],
      confirm_password: ['', Validators.required],
    });

    //forget Password FormGroup
    this.forget_form = this.fb.group({
      forget_email: ['', [Validators.required, Validators.email, Validators.pattern(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)]],
    })


  }



  ngOnInit(): void {
    this.rememberMeInSignInForm();
  }
  //animation logic for signin and signUp form
  ngOnDestroy(): void {
  }


  rememberMeInSignInForm() {
    //remember me
    const savedCredentials = this.getSavedCredentials();
    if (savedCredentials) {
      this.sign_in_form.patchValue({
        sign_in_email: savedCredentials.email,
        sign_in_password: savedCredentials.password,
        rememberMe: true
      });
    }
  }
  closeModal() {
    this.modal.dismissAll();
  }

  saveCredentials(email: string, password: string) {
    localStorage.setItem('credentials', JSON.stringify({ email, password }));
  }


  getSavedCredentials() {
    const credentials = localStorage.getItem('credentials');
    return credentials ? JSON.parse(credentials) : null;
  }

  clearSavedCredentials() {
    localStorage.removeItem('credentials');
  }


  isActive: boolean = false;

  activate(): void {
    this.isActive = true;
    this.formName = "Sign-up";
    this.sign_in_form.reset();
    this.sign_up_form.reset();
  }

  deactivate(): void {
    this.isActive = false;
    this.formName = "Sign-in";
    this.rememberMeInSignInForm();

  }


  //for foret password screen
  showForgetandSignIn: boolean = false;
  chnageForgetForms() {
    this.showForgetandSignIn = !this.showForgetandSignIn;
    this.sign_in_form.reset();
    this.forget_form.reset();
  }


  //signIn user api call
  signInUser() {

    if (this.sign_in_form.invalid) {
      this.sign_in_form.markAllAsTouched();
      this._toaster.error("Invalid Form");
    }
    else {
      const signInPayload: any = {
        mail: this.sign_in_form.get('sign_in_email')?.value,
        password: this.sign_in_form.get('sign_in_password')?.value
      };
      // this._spinner.show();
      this.isLoading = true;
      this._apiService.PostCallWithoutToken(signInPayload, 'User/UserLogin').subscribe((response) => {
        if (response.responseCode == 200) {
          this._toaster.success(response.responseMessage);
          // this.userLoggedIn.emit();
          // this._spinner.hide();
          this.isLoading = false;
          this._dataShare.closePopUp();
          // this._authService.saveToken(res.data, "email");
          debugger
          this._authService.saveToken(response.data.token);
          localStorage.setItem('userDetails', JSON.stringify(response.data));
          this._utilsService.emitAfterLogin();
          debugger
          if (this.navigateToDashboard) {
            this.router.navigate(['/user']);
            this._dataShare.passResultofLoginPopUp(false);
          } else {
            this.modal.dismissAll();
            this._dataShare.passResultofLoginPopUp(true);
            this._utilsService.notifyUserDetailsUpdated();
          }
          if (this.sign_in_form.get('rememberMe')?.value) {
            this.saveCredentials(signInPayload.mail, signInPayload.password);
          } else {
            this.clearSavedCredentials();
          }
        } else {
          this.isLoading = false;
          this.errorHandlerService.handleResponseError(response);
          // this._toaster.error(response.responseMessage);
          // this._spinner.hide();
        }
      }, (error) => {
        this.errorHandlerService.handleHttpError(error as HttpErrorResponse);
        this.isLoading = false;
      });
    };

  };



  //signup user apicall
  signUpUser() {

    if (this.sign_up_form.invalid) {
      this.sign_up_form.markAllAsTouched();
      this._toaster.error("Invalid Form");
    }
    else {
      // this._spinner.show();
      this.isLoading = true;
      const signUpPayload: any = {
        firstName: this.sign_up_form.get('first_name')?.value,
        lastName: this.sign_up_form.get('last_name')?.value,
        username: this.sign_up_form.get('user_name')?.value,
       // phoneNumber: this.sign_up_form.get('contact')?.value,
        email: this.sign_up_form.get('sign_up_email')?.value,
        password: this.sign_up_form.get('sign_up_password')?.value
      };

      this._apiService.PostCallWithoutToken(signUpPayload, 'User/CreateUser').subscribe((res) => {
        if (res.responseCode == 200) {
          // this.userLoggedIn.emit();
          this._toaster.success(res.responseMessage);
          this._dataShare.closePopUp();
          // this._spinner.hide();
          this.isLoading = false;
          this.router.navigate(['']);

        } else {
          this._toaster.error(res.errorMessage);
          // this._spinner.hide();
          this.isLoading = false;
          // this.errorHandlerService.handleResponseError(res);
        }

      }, (error) => {
        this.errorHandlerService.handleHttpError(error as HttpErrorResponse);
        this.isLoading = false;
      });
    };
  }



  //forget password api call
  forgetPassword() {
    if (this.forget_form.invalid) {
      this._toaster.error("Invalid Form");
    } else {
      this.isLoading = true;
      this._spinner.show();
      const Email = this.forget_form.get('forget_email')?.value;
      this._apiService.PostCallWithoutToken(null, `User/ForgetPassword?Email=${Email}`).subscribe((res) => {
        if (res.responseCode == 200) {
          this._toaster.success(res.responseMessage);
          this.isLoading = false;
          this._dataShare.closePopUp();
          this._spinner.hide();
        } else {
          this._toaster.error(res.errorMessage);
          this.isLoading = false;
          this._spinner.hide();
        }
      }, (error) => {
        this.errorHandlerService.handleHttpError(error as HttpErrorResponse);
        this.isLoading = false;
      });
    }
  }




  //show and hide password logic
  showAndHidePassword(fieldName: string) {
    if (fieldName === "password") {
      this.inputType = this.inputType === 'password' ? 'text' : 'password';
    }
    if (fieldName === "signUpPassword") {
      this.signUpPassword = this.signUpPassword === 'password' ? 'text' : 'password';
    }
    if (fieldName === "signUpConfirmPassword") {
      this.signUpConfirmPassword = this.signUpConfirmPassword === 'password' ? 'text' : 'password';
    }
  }


  //strong password validation logic
  passRequire: boolean = false;
  passMinlength: boolean = false;
  passUpperCase: boolean = false;
  passLowerCase: boolean = false;
  passNumber: boolean = false;
  passSpecialChar: boolean = false;
  passwordValidator(control: FormControl): { [key: string]: boolean } | null {
    this.passRequire = false;
    this.passMinlength = false;
    this.passUpperCase = false;
    this.passLowerCase = false;
    this.passNumber = false;
    this.passSpecialChar = false;
    const value: string = control.value;
    if (value?.length >= 8) {
      this.passMinlength = true;
    }

    if (/[A-Z]/.test(value)) {
      this.passUpperCase = true;
    }

    if (/[a-z]/.test(value)) {
      this.passLowerCase = true;
    }

    if (/\d/.test(value)) {
      this.passNumber = true;
    }

    if (/[!@#$%^&*(),.?":{}|<>]/.test(value)) {
      this.passSpecialChar = true;
    }

    return null;
  }


  //pasword match logic
  passwordsMatching = false;
  isConfirmPasswordDirty = false;
  checkPasswords(pw: string, cpw: string) {
    this.isConfirmPasswordDirty = true;
    if (pw == cpw) {
      this.passwordsMatching = true;
    } else {
      this.passwordsMatching = false;
    }
  }



  // small screen page logo images

  isScreenSmall: boolean = false;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenSize();
  }

  checkScreenSize() {
    const width = window.innerWidth;
    this.isScreenSmall = width < 991;
  }
}
