import { Component, AfterViewInit, Output, EventEmitter, Input, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CommonUtilsServiceService } from 'src/app/shared/services/common-utils-service.service';

@Component({
  selector: 'app-rang-slider',
  templateUrl: './rang-slider.component.html',
  styleUrl: './rang-slider.component.scss'
})
export class RangSliderComponent  implements OnDestroy{
  @Input() minValue: string = '0';
  @Input() maxValue: string = '100000';
  private subscription: Subscription;

  @Output() sliderValues = new EventEmitter<{ min: string, max: string }>();

  constructor(private http: HttpClient, private sliderService: CommonUtilsServiceService) {

    this.subscription = this.sliderService.resetSlider$.subscribe(data => {
      if (data) {
        this.resetValues();
      }
    });

  }
  ngOnDestroy(): void {
    if(this.subscription){
      this.subscription.unsubscribe();
    }
  }



  ngAfterViewInit(): void {
    const rangeInputs = document.querySelectorAll<HTMLInputElement>(".range-input input");
    const range = document.querySelector(".slider .progress") as HTMLElement;
    const minValueDisplay = document.querySelector(".slider .value.min") as HTMLElement;
    const maxValueDisplay = document.querySelector(".slider .value.max") as HTMLElement;

    rangeInputs.forEach(input => {
      input.addEventListener("input", (event) => {
        let minVal = parseInt(rangeInputs[0].value);
        let maxVal = parseInt(rangeInputs[1].value);

        // Emit values to parent component
        // this.sliderValues.emit({ min: minVal.toString(), max: maxVal.toString() });

        let priceGap = 5000;

        // if ((maxVal - minVal) < priceGap) {
        //   if ((event.target as HTMLInputElement)?.classList.contains("range-min")) {
        //     rangeInputs[0].value = (maxVal - priceGap).toString();
        //   } else {
        //     rangeInputs[1].value = (minVal + priceGap).toString();
        //   }
        // } else {
        //   range.style.left = (minVal / parseInt(rangeInputs[0].max)) * 100 + "%";
        //   range.style.right = 100 - (maxVal / parseInt(rangeInputs[1].max)) * 100 + "%";
        // }

        if (minVal >= maxVal) {
          if ((event.target as HTMLInputElement)?.classList.contains("range-min")) {
            rangeInputs[0].value = (maxVal - priceGap > 0 ? maxVal - priceGap : 0).toString();
            minVal = parseInt(rangeInputs[0].value);
          } else {
            rangeInputs[1].value = (minVal + priceGap).toString();
            maxVal = parseInt(rangeInputs[1].value);
          }
        }

        this.sliderValues.emit({ min: minVal.toString(), max: maxVal.toString() });

        if ((maxVal - minVal) >= priceGap) {
          range.style.left = (minVal / parseInt(rangeInputs[0].max)) * 100 + "%";
          range.style.right = 100 - (maxVal / parseInt(rangeInputs[1].max)) * 100 + "%";
        }


        // Update component variables
        this.minValue = minVal.toString();
        this.maxValue = maxVal.toString();

        // Update displayed values
        minValueDisplay.textContent = this.minValue;
        maxValueDisplay.textContent = this.maxValue;
      });
    });
  }


  resetValues(): void {
    this.minValue = '0';
    this.maxValue = '100000';
    this.sliderValues.emit({ min: this.minValue, max: this.maxValue });

    const rangeInputs = document.querySelectorAll<HTMLInputElement>(".range-input input");
    rangeInputs[0].value = this.minValue;
    rangeInputs[1].value = this.maxValue;

    const range = document.querySelector(".slider .progress") as HTMLElement;
    range.style.left = '0%';
    range.style.right = '0%';

    const minValueDisplay = document.querySelector(".slider .value.min") as HTMLElement;
    const maxValueDisplay = document.querySelector(".slider .value.max") as HTMLElement;
    minValueDisplay.textContent = this.minValue;
    maxValueDisplay.textContent = this.maxValue;
  }

}
