import { Injectable, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataShareService {

  constructor(private modalService: NgbModal) { }

  // private institteTotalRecords: number = 0;

  // private instituteTotalRecords = new BehaviorSubject<number>(0);
  // institteTotalRecords$ =  this.instituteTotalRecords.asObservable();


  private ModalSubject = new BehaviorSubject<boolean>(false);
  modalpopUp$ = this.ModalSubject.asObservable();






  // Create a BehaviorSubject with an initial value
  private instituteTotalRecords = new BehaviorSubject<any>(0);

  // Create an observable to expose the BehaviorSubject
  currentValue = this.instituteTotalRecords.asObservable();

  // Function to update the shared value
  updateValue(newValue: any) {
    this.instituteTotalRecords.next(newValue);
  }






  openModal() {
    this.ModalSubject.next(true);
  }
  closeModal() {
    this.ModalSubject.next(false);
  }

  // Function to set Teacher Deatisl 
  teacherDetails: any;
  setTeacherDetails(details: any) {
    this.teacherDetails = details;
  }
  // Function to get Teacher ID 
  getTeacherDetails() {
    if (this.teacherDetails) {
      return this.teacherDetails;
    }
  }

  getInstituteRecords() {
    return this.instituteTotalRecords;
  }

  setInstituteRecords(totalRecords: number) {
    this.instituteTotalRecords.next(totalRecords);
  }




  openSignInModal(content: TemplateRef<any>) {
    this.modalService.open(content, {
      windowClass: 'full-screen-modal',
      backdropClass: 'light-black-backdrop',
      size: 'lg'
    });
  }


  closePopUp() {

    this.modalService.dismissAll();

  }



  // for services PopUps

  private popupSource = new BehaviorSubject<string>('');
  currentPopup$ = this.popupSource.asObservable();

  openServicesPopup(popupName: string) {
    this.popupSource.next(popupName);
  }




  private isToopenPopUpAfterLogin = new BehaviorSubject<boolean>(false);
  openLoginPopUp$ = this.isToopenPopUpAfterLogin.asObservable();

  passResultofLoginPopUp(res : boolean){
    this.isToopenPopUpAfterLogin.next(res);
  }



  private isImageFormatChanged = new BehaviorSubject<number>(4);
  imageFormatChanged$ = this.isImageFormatChanged.asObservable();

  passImageFormatChanged(res : number){
    this.isImageFormatChanged.next(res);
  }




  private isDropdownsReset = new BehaviorSubject<boolean>(false);
  RefreshDropdowns$ = this.isDropdownsReset.asObservable();


  refreshDropdowns(isRefreshed : true){
    this.isDropdownsReset.next(isRefreshed);
  }
}
