import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { ApiCallService } from 'src/app/shared/services/api-call.service';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';
import { PropertyService } from 'src/app/shared/services/property.service';
import intlTelInput from "intl-tel-input";
import { CommonUtilsServiceService } from 'src/app/shared/services/common-utils-service.service';
@Component({
  selector: 'app-add-job',
  templateUrl: './add-job.component.html',
  styleUrl: './add-job.component.scss',
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddJobComponent implements OnInit, OnDestroy {
  private intlTelInputInstance: any;
  public themeLogo = 'assets/images/logo/2.png';
  public footerLogo = 'assets/images/logo/footer-logo.png';
  public bgImage = 'assets/images/banner/banner.jpg';
  public title = 'Dashboard';
  public parent = 'Jobs';
  public child = 'Add Job';
  jobsData: any;
  gridImages: boolean = false;
  listView: boolean = false;
  thumbnail: boolean = false;
  thumbnail_video: boolean = false;
  public isSpinnerShown = false;
  tagClass: string;
  total: number;
  public selectedImage: string;
  addJobForm: FormGroup;
  boostJobForm: FormGroup;
  jobDescriptionCharCount: number = 0;
  howToApplyCharCount: number = 0;
  responsibilitiesCharCount: number = 0;
  additionalNoteCharCount: number = 0;
  isDataLoading: boolean = true;
  public FormName: string = "Add";


  public readonly __apiCall = inject(ApiCallService);
  public readonly _toastr = inject(ToastrService);
  public readonly propertyService = inject(PropertyService);
  public readonly _spinner = inject(NgxSpinnerService);
  private readonly errorHandlingService = inject(ErrorHandlingService);
  private readonly _utlis = inject(CommonUtilsServiceService);


  public cardsData: any = [
    {
      id: 1,
      cardType: 'master',
      cardName: 'Credit',
      cardNumber: 'XXXX-XXXX-XXXX-3401',
      expDate: '12/24',
      holderName: 'Zack Lee',
      cardImage: 'assets/images/icon/master.png',
    },
    {
      id: 2,
      cardType: 'visa',
      cardName: 'Credit',
      cardNumber: 'XXXX-XXXX-XXXX-3401',
      expDate: '12/24',
      holderName: 'Zack Lee',
      cardImage: 'assets/images/icon/visa.png',
    },
    {
      id: 3,
      cardType: 'american-express',
      cardName: 'Credit',
      cardNumber: 'XXXX-XXXX-XXXX-3401',
      expDate: '12/24',
      holderName: 'Zack Lee',
      cardImage: 'assets/images/icon/american.jpg',
    },
  ];

  public theme_default3 = '#ff5c41';
  public theme_default4 = '#ff8c41';


  constructor(public modal: NgbModal, private fb: FormBuilder, private cdr: ChangeDetectorRef) {
    this.addJobForm = this.fb.group({
      job_title: ["", Validators.required],
      company_name: ["", Validators.required],
      salary: ["", [Validators.required,Validators.min(0)]],
      job_address: ["", Validators.required],
      job_addressTitle: ["", Validators.required],
      job_addressLink: ["", [Validators.required, Validators.pattern('https://maps.google.com/maps.+')]],
      job_siteLink: ["", [Validators.required, Validators.pattern('https://.+')]],
      job_phone: ["", [Validators.required,
      Validators.pattern("^((\\+91-?)|0)?[0-9]{8,16}$"),
      Validators.minLength(8),
      Validators.maxLength(16)]],
      education: ["", Validators.required],
      experience: ["", Validators.required],
      skills: ["", Validators.required],
      application_deadline: ["", [Validators.required]],
      job_description: ["", [Validators.required]],
      how_to_apply: ["", [Validators.required]],
      responsibilities: [""],
      additional_note: [""],

    })

    this.boostJobForm = this.fb.group({
      title: ["", Validators.required],
      start_date: ["", [Validators.required,]],
      end_date: ["", Validators.required],
      ad_description: ["", [Validators.required, Validators.minLength(200)]],
    });
  }


  ngOnInit() {
    this.getJobs();
    this.getCountry();
  }

  loadPhoneScript() {
    setTimeout(() => {
      const inputElement = document.querySelector('#myInput') as HTMLInputElement;
      if (inputElement) {
        this.intlTelInputInstance = intlTelInput(inputElement, {
          initialCountry: 'pk',
          separateDialCode: true
        });
      }
    }, 500)
  }

  ///////////////////////////////////////////////////
  // Ad End Date limiter STARTS HERE 
  minmumDate: any
  getMinDate() {
    const maxDate = this.boostJobForm.get('start_date')?.value;
    this.boostJobForm.get('end_date')?.reset();
    this.minmumDate = maxDate
  }

  getMaxDate(): string {
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    return formattedDate;
  }

  minEndDate: any;
  updateEndDateMin(): void {
    const startDate = this.boostJobForm.get('start_date')?.value;
    this.minEndDate = startDate ? startDate : null;
  }

  // Ad End Date limiter Ends HERE 
  ///////////////////////////////////////////

  adCharCount: number = 0;
  updateAdCharCount(): void {
    let description = this.boostJobForm.get("ad_description")?.value;
    this.adCharCount = description?.length || 0;
  }



  isAdActivated: boolean = true;
  boostId: number;
  boostCard(content: TemplateRef<any>, id: number) {
    this.boostId = id;
    this.boostJobForm.reset();
    this.imageBase64 = "";
    this.adFiles = [];
    this.modal.open(content, { centered: true, size: 'lg' });
    this.FormName = "Advertisment";
  }

  editAdCard(content: TemplateRef<any>, id: number) {
    this.boostJobForm.reset();
    this.imageBase64 = "";
    this.adFiles = [];
    this.modal.open(content, { centered: true, size: 'lg' });
    this.FormName = "EditAdvertisment";
    this.editAd(id);
  }

  ////////////////////////////////////////////
  //////////// code for Ads image /////////////////////
  //////////////////////////////////////////////

  adFiles: File[] = [];
  adImages: { imageBase64: string }[] = [];
  imageBase64: string | null = null;
  adImageSizeError: boolean = false;

  onAdSelect(event: NgxDropzoneChangeEvent) {
    const newFile = event.addedFiles[0];
    if (newFile) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const sizeInKB = Math.round(newFile.size / 1024);
        const isToDel = this.checkImgeSizeValidity(sizeInKB, newFile);
        if (!isToDel) {
          const base64String = e.target.result as string;
          this.adFiles = [newFile];
          this.adImages = [{ imageBase64: base64String }];
          this.imageBase64 = base64String;
        }
      };
      reader.readAsDataURL(newFile);
    }

  }

  onAdRemove(event: File) {
    this.adFiles = [];
    this.adImages = [];
  }




  /////////////////////////////////////////////////////
  //////////// image update case //////////////////////
  //////////////////////////////////////////////////

  onAdFileSelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {

        const sizeInKB = Math.round(file.size / 1024);
        const isToDel = this.checkImgeSizeValidity(sizeInKB, file)
        if (!isToDel) {
          const base64String = reader.result as string;
          this.adFiles = [file];
          this.adImages = [{ imageBase64: base64String }];
          this.imageBase64 = base64String;
        };
      };
    }
  }

  removeAdFile(file: any): void {
    this.adFiles = [];
    this.adImages = [];
    this.imageBase64 = "";
  }


  public submitBoostAd(): void {

    const adsPayload = this.createAdsApiPayload();
    console.log("Payload", adsPayload);
    this.isSpinnerShown = true;

    this.__apiCall.PostCallWithToken(adsPayload, 'Ad/SaveAndUpdateAd')
      .subscribe(
        (response) => {
          if (response.responseCode === 200) {
            this._toastr.success(response.responseMessage);
            this.boostJobForm.reset();
            this.modal.dismissAll();
          } else {
            this.errorHandlingService.handleResponseError(response);
          }
          this.isSpinnerShown = false;
        },
        (error) => {
          this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
          this.isSpinnerShown = false;
        }
      );
  }

  createAdsApiPayload() {
    this.updateAdId = this.FormName == "Advertisment" ? 0 : this.updateAdId;
    return {
      moduleId: this.boostId,
      moduleName: "job",
      title: this.boostJobForm.get('title')?.value,
      startDate: this.boostJobForm.get('start_date')?.value,
      endDate: this.boostJobForm.get('end_date')?.value,
      description: this.boostJobForm.get('ad_description')?.value,
      imageUrl: this.imageBase64,
    };
  }

  adDataById: any;
  updateAdId: number = 0;
  editAdModuleId: number;
  public editAd(id: number): void {
    this.editAdModuleId = id;
    const payload = {
      id: this.editAdModuleId,
      moduleName: "Job",
    }
    this.isSpinnerShown = true;
    this._spinner.show();

    this.__apiCall.PostCallWithToken(payload, "Ad/GetAdById")
      .subscribe(
        (response) => {
          if (response.responseCode === 200) {
            this.adDataById = response.data;
            this.updateAdId = this.adDataById.id;
            this.boostJobForm.get('title')?.setValue(this.adDataById?.title);
            this.boostJobForm.get("start_date")?.setValue(this.adDataById?.startDate.substring(0, 10));
            this.boostJobForm.get("end_date")?.setValue(this.adDataById?.endDate.substring(0, 10));
            this.boostJobForm.get("ad_description")?.setValue(this.adDataById?.description);

            this.imageBase64 = this.adDataById?.imageUrl;

            this.updateAdCharCount();

          } else {
            this.errorHandlingService.handleResponseError(response);
            this._toastr.error(response.responseMessage);
          }
          this.isSpinnerShown = false;

        },
        (error) => {
          this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
          this.isSpinnerShown = false;
        }
      );
  }



  private async getJobs() {
    this.isDataLoading = true;
    try {
      this.__apiCall.GetCallWithToken("Dashboard/GetJobs").subscribe((response) => {
        if (response.responseCode == 200) {
          this.jobsData = response.data;
          this.isDataLoading = false;
        } else {
          this.jobsData = [];
          this.isDataLoading = false;
          this.errorHandlingService.handleResponseError(response);
        }
      });

    } catch (error) {
      this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
    } finally {
      // this._spinner.hide();
      this.isDataLoading = false;
      this.cdr.detectChanges();
    }
  }


  ngOnDestroy(): void {
    document.documentElement.style.removeProperty('--theme-default');
    document.documentElement.style.removeProperty('--theme-default3');
    document.documentElement.style.removeProperty('--theme-default4');

    // this.destroy$.next();
    // this.destroy$.complete();
  }


  addCard(content: TemplateRef<any>) {
    this.addJobForm.reset();
    this.updateJobId = 0;
    this.Country = "";
    this.State = "";
    this.City = "";
    this.jobType = "";
    this.skillsTags = [];
    this.educationTags = [];
    this.experienceTags = [];
    this.images = [];
    this.files = [];
    this.updateAdditionalNoteCharCount();
    this.updateHowToApplyCharCount();
    this.updateJobDescriptionCharCount();
    this.updateResponsibilitiesCharCount();
    this.modal.open(content, { centered: true, size: 'lg' });
    this.jobTypeError = false;
    this.countryError = false;
    this.stateError = false;
    this.cityError = false;
    this.imageError = false;
    this.FormName = "Add";
    this.loadPhoneScript();

  }



  editCard(content: TemplateRef<any>, id: number) {
    this.addJobForm.reset();
    this.Country = "";
    this.State = "";
    this.City = "";
    this.jobType = "";
    this.skillsTags = [];
    this.educationTags = [];
    this.experienceTags = [];
    this.images = [];
    this.files = [];
    this.updateAdditionalNoteCharCount();
    this.updateHowToApplyCharCount();
    this.updateJobDescriptionCharCount();
    this.updateResponsibilitiesCharCount();
    this.jobTypeError = false;
    this.countryError = false;
    this.stateError = false;
    this.cityError = false;
    this.imageError = false;
    this.modal.open(content, { centered: true, size: 'lg' })
    this.FormName = "Edit";
    this.editJob(id);
    this.loadPhoneScript();
  }

  public Options = {
    items: 1,
    loop: true,
    nav: true,
    dots: true,
    autoplay: true,
    autoplayTimeout: 2000,
    navText: [
      "<i class='fa fa-chevron-left'></i>",
      "<i class='fa fa-chevron-right'></i>",
    ],
  };

  public thumbnailCarouselOptions = {
    items: 4,
    margin: 10,
    center: true,
    loop: true,
    nav: false,
    dots: false,
  };

  changeImage(image: string) {
    this.selectedImage = image;
  }

  /////////EDIT JOB SECTION ////////
  jobByIdData: any
  updateJobId: number = 0;

  private async editJob(id: number): Promise<void> {
    this.updateJobId = id;
    this.isSpinnerShown = true;
    this._spinner.show();
    try {
      this.__apiCall.PostCallWithToken(null, `Dashboard/GetJobById?JobId=${id}`).subscribe((res) => {
        if (res.responseCode === 200) {
          this.jobByIdData = res.data;
          this.addJobForm.get('job_title')?.setValue(this.jobByIdData?.jobName);
          this.addJobForm.get('company_name')?.setValue(this.jobByIdData?.companyName);
          this.addJobForm.get('salary')?.setValue(this.jobByIdData?.salary);
          this.addJobForm.get('job_address')?.setValue(this.jobByIdData?.location);
          this.addJobForm.get('job_addressTitle')?.setValue(this.jobByIdData?.locationTitle);
          this.addJobForm.get('job_addressLink')?.setValue(this.jobByIdData?.locationSrc);
          this.addJobForm.get('job_siteLink')?.setValue(this.jobByIdData?.websiteLink);
          this.addJobForm.get('job_phone')?.setValue(this.jobByIdData?.contactNumber.substring(3));
          this.addJobForm.get('application_deadline')?.setValue(this.jobByIdData?.deadline?.substring(0, 10));
          this.addJobForm.get('job_description')?.setValue(this.jobByIdData?.description);
          this.addJobForm.get('how_to_apply')?.setValue(this.jobByIdData?.applyDescription);
          this.addJobForm.get('responsibilities')?.setValue(this.jobByIdData?.responsibilitiesDescription);
          this.addJobForm.get('additional_note')?.setValue(this.jobByIdData?.additionalDescription);

          this.jobType = this.jobByIdData?.jobType;
          this.Country = this.jobByIdData?.countryName;
          this.State = this.jobByIdData?.stateName;
          this.City = this.jobByIdData?.cityName;
          this.educationTags = this.jobByIdData?.educationVMs;
          this.experienceTags = this.jobByIdData?.experienceVMs;
          this.skillsTags = this.jobByIdData?.skillVMs;
          this.images = this.jobByIdData?.imageVMs;

          this.updateAdditionalNoteCharCount();
          this.updateHowToApplyCharCount();
          this.updateJobDescriptionCharCount();
          this.updateResponsibilitiesCharCount();

          //this line for updating phone number country code
          this.intlTelInputInstance.setNumber(this.jobByIdData?.contactNumber);

          this.isSpinnerShown = false;
          this._spinner.hide();

        } else {
          this._toastr.error(res.responseMessage);
          this.isSpinnerShown = false;
          this._spinner.hide();
        }
      });

    } catch (error) {
      this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
    } finally {
      this.isSpinnerShown = false;
      this._spinner.hide();
      this.cdr.detectChanges();
    }


    this.addJobForm.updateValueAndValidity();
  }


  delId: number;
  deleteCard(content: TemplateRef<any>, id: number) {
    this.modal.open(content, { centered: true, size: 'sm' });
    this.delId = id;
  }

  deleteJob() {
    this.isSpinnerShown = true;
    this._spinner.show();
    this.__apiCall
      .PostCallWithoutToken(null, `Dashboard/DeleteJob?jobId=${this.delId}`).subscribe((res) => {
        if (res.responseCode === 200) {
          this._toastr.success(res.responseMessage);
          this.getJobs();
          this.modal.dismissAll();
          this.isSpinnerShown = false;
          this._spinner.hide();
        }
        else {
          this._toastr.error(res.responseMessage);
          this.modal.dismissAll();
          this.isSpinnerShown = false;
          this._spinner.hide();
        }
      })
  }




  files: File[] = [];
  images: { imageBase64: string }[] = [];

  onSelect(event: NgxDropzoneChangeEvent) {
    const newFiles = event.addedFiles;
    this.files.push(...newFiles);


    newFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = (e: any) => {

        const sizeInKB = Math.round(file.size / 1024);
        const istoDel = this.checkImgeSizeValidity(sizeInKB, file)
        if (!istoDel) {
          const base64String = e.target.result as string;
          this.images.push({ imageBase64: base64String });
        }
      };
      reader.readAsDataURL(file);
    });

  }

  onRemove(event: File) {
    const index = this.files.indexOf(event);
    if (index !== -1) {
      this.files.splice(index, 1);
      this.images.splice(index, 1);
    }
  }
  imageSizeError: boolean = false
  checkImgeSizeValidity(size: number, file: any) {
    if (size > 1024) {
      this.imageSizeError = true;
      this.onRemove(file)
      return true;

    }
    else {
      this.imageSizeError = false;
      return false;
    }
  }

  ////////////////////////////////////////////
  //////////// code for image /////////////////////
  //////////////////////////////////////////////


  onFileSelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {

        const sizeInKB = Math.round(file.size / 1024);
        const isToDel = this.checkImgeSizeValidity(sizeInKB, file)
        if (!isToDel) {
          const base64String = reader.result as string;
          this.images.push({ imageBase64: base64String });
        };
      };
    }
  }

  removeFile(file: any): void {

    this.images = this.images.filter((t: any) => t !== file);

  }



  ////////////////////////////////////////////
  //////////// code for image /////////////////////
  //////////////////////////////////////////////






  ////////////////////////////////////////////
  // code for Custom Searchable DropDown for Job 
  //////////////////////////////////////////////
  // jobTypeSearchTerm: string = '';
  JobTypeList = [
    { id: 1, value: "Full-Time" },
    { id: 2, value: "Part-Time" },
    { id: 3, value: "Contract" },
    { id: 4, value: "Internship" },
    { id: 5, value: "Remote" },
  ];
  jobType: string = "";

  onJobTypeSelected(option: any) {
    this.jobType = option.value;
  }


  ////////////////////////////////////////////////////
  /////////  Country drop down  //////////
  ///////////////////////////////////////////////////// 
  CountrysearchTerm: string = "";
  Country: string = "";
  State: string = '';
  City: string = '';
  CountryList = []
  SearchTermCountry(searchTerm: string) {
    this.CountrysearchTerm = searchTerm;
    this.Country = searchTerm;
  }
  getCountry() {
    this.__apiCall
      .GetCallWithoutToken('DropDown/GetCountryDropDown')
      .subscribe((response: any) => {
        if (response.responseCode === 200) {
          this.CountryList = response?.data;
        }
      })

  }


  onCountrySelected(option: any) {
    if (this.Country != option.value) {
      this.State = '';
      this.City = '';
    }
    this.Country = option.value;
    this.getStateList(option.id);

  }
  onCountryValueChange(searchValue: any) {
    this.Country = searchValue
    if (this.Country === '') {
      this.State = '';
      this.City = ''
    }
  }

  ////////////////////////////////////////////////////
  /////////  State drop down data //////////
  ////////////////////////////////////////////////////

  StatesearchTerm: string = "";

  StateList = []
  SearchTermState(searchTerm: string) {
    this.StatesearchTerm = searchTerm;
    this.State = searchTerm;
  }

  onStateSelected(option: any) {
    if (this.State !== option.value) {
      this.City = '';
    }
    this.State = option.value;
    this.getCityList(option.id);

  }
  onStateValueChange(searchValue: any) {
    this.State = searchValue
    if (this.State === '') {
      this.City = ''
    }
  }
  onCountryFocused(isTouched: boolean) {
    if (isTouched) {
      if (this.CountrysearchTerm === "" && this.Country === "") {
        this.countryError = true
      }
    }
  }

  getStateList(countryId: number) {
    this.__apiCall
      .GetCallWithoutToken('DropDown/GetStateDropDown?CountryId=' + countryId)
      .subscribe((response: any) => {
        if (response.responseCode === 200) {
          this.StateList = response?.data;
        }
      });
  }


  ////////////////////////////////////////////////////
  /////////  City drop down data //////////
  ////////////////////////////////////////////////////


  CityList = []
  onCitySelected(option: any): void {
    this.City = option.value;
  }

  onStateFocused(isTouched: boolean) {
    if (isTouched) {
      if (this.StatesearchTerm === "" && this.State === "") {
        this.stateError = true;
      }
    }

  }

  getCityList(stateId: number) {
    this.__apiCall.GetCallWithoutToken('DropDown/GetCityDropDown?StateId=' + stateId).subscribe(
      (response: any) => {
        if (response.responseCode === 200) {
          this.CityList = response?.data;
        }
      }
    )
  }


  //////////////////// Education Tags\\\\\\\\\\\\\\\\\
  educationTags: any[] = [];

  addEducationTag(): void {
    const tagInput = this.addJobForm.get("education")?.value.trim();
    if (tagInput && !this.educationTags.some(tag => tag.educationTitle === tagInput)) {
      const newTag = { educationTitle: tagInput };
      this.educationTags.push(newTag);
      this.addJobForm.get("education")?.setValue("");
      this.educationTagError = this.educationTags.length < 1;
    }
  }

  removeEducationTag(tag: any): void {
    this.educationTags = this.educationTags.filter(t => t !== tag);
    this.educationTagError = this.educationTags.length < 1;
  }

  onEnterEducation(event: Event): void {
    if (event instanceof KeyboardEvent && event.key === "Enter") {
      event.preventDefault();
      this.addEducationTag();
    }
  }

  ///////////// Experience Tags\\\\\\\\\\\\\\\\
  experienceTags: any[] = [];

  addExperienceTag(): void {
    const tagInput = this.addJobForm.get("experience")?.value.trim();
    if (tagInput && !this.experienceTags.some(tag => tag.experienceTitle === tagInput)) {
      const newTag = { experienceTitle: tagInput };
      this.experienceTags.push(newTag);
      this.addJobForm.get("experience")?.setValue("");
      this.experienceTagError = this.experienceTags.length < 1;
    }
  }

  removeExperienceTag(tag: any): void {
    this.experienceTags = this.experienceTags.filter(t => t !== tag);
    this.experienceTagError = this.experienceTags.length < 1;
  }

  onEnterExperience(event: Event): void {
    if (event instanceof KeyboardEvent && event.key === "Enter") {
      event.preventDefault();
      this.addExperienceTag();
    }
  }

  //////////// Skill Tags ////////////////
  skillsTags: any[] = [];

  addSkillTag(): void {
    const tagInput = this.addJobForm.get("skills")?.value.trim();
    if (tagInput && !this.skillsTags.some(tag => tag.skillTitle === tagInput)) {
      const newTag = { skillTitle: tagInput };
      this.skillsTags.push(newTag);
      this.addJobForm.get("skills")?.setValue("");
      this.skillTagError = this.skillsTags.length < 1;
    }
  }

  removeSkillTag(tag: any): void {
    this.skillsTags = this.skillsTags.filter(t => t !== tag);
    this.skillTagError = this.skillsTags.length < 1;
  }

  onEnterSkills(event: Event): void {
    if (event instanceof KeyboardEvent && event.key === "Enter") {
      event.preventDefault();
      this.addSkillTag();
    }
  }

  ////////////////////////////////////////////
  // code for showig errors
  //////////////////////////////////////////////

  jobTypeError: boolean = false;
  countryError: boolean = false;
  stateError: boolean = false;
  cityError: boolean = false;
  imageError: boolean = false;
  educationTagError: boolean = false;
  experienceTagError: boolean = false;
  skillTagError: boolean = false;

  ShowError() {
    this.jobTypeError = this.jobType == "" ? true : false;
    this.countryError = this.Country == "" ? true : false;
    this.stateError = this.State == "" ? true : false;
    this.cityError = this.City == "" ? true : false;
    this.imageError = this.images.length < 1 ? true : false;
    this.educationTagError = this.educationTags.length < 1 ? true : false;
    this.experienceTagError = this.experienceTags.length < 1 ? true : false;
    this.skillTagError = this.skillsTags.length < 1 ? true : false;
  }


  futureDateValidator(control: AbstractControl): { [key: string]: any } | null {
    const selectedDate = new Date(control.value);
    const currentDate = new Date();
    return selectedDate <= currentDate ? { invalidDate: true } : null;
  }

  // Method for word count validation
  minWordsValidator(minWords: number) {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value || '';
      const wordCount = value.trim().split(/\s+/).length;
      return wordCount < minWords ? { minWords: true } : null;
    };
  }


  // Method to update job description char count
  updateJobDescriptionCharCount(): void {
    const jobDescription = this.addJobForm.get("job_description")?.value;
    this.jobDescriptionCharCount = jobDescription?.length || 0;
  }

  // Method to update how to apply char count
  updateHowToApplyCharCount(): void {
    const howToApply = this.addJobForm.get("how_to_apply")?.value;
    this.howToApplyCharCount = howToApply?.length || 0;
  }

  // Method to update responsibilities char count
  updateResponsibilitiesCharCount(): void {
    const responsibilities = this.addJobForm.get("responsibilities")?.value;
    this.responsibilitiesCharCount = responsibilities?.length || 0;
  }

  // Method to update additional note char count
  updateAdditionalNoteCharCount(): void {
    const additionalNote = this.addJobForm.get("additional_note")?.value;
    this.additionalNoteCharCount = additionalNote?.length || 0;
  }
  checkJobFieldsValidity(): boolean {
    if (this.educationTags.length < 1 || this.experienceTags.length < 1 || this.skillsTags.length < 1) {
      return true;
    }
    return false;
  }

  removeUmwantedControl() {

    this.addJobForm.removeControl('education');
    this.addJobForm.removeControl('experience');
    this.addJobForm.removeControl('skills');
    this.addJobForm.updateValueAndValidity();
  }



  addControls() {
    if (!this.addJobForm.contains('education')) {
      this.addJobForm.addControl('education', new FormControl("", Validators.required));
    }

    if (!this.addJobForm.contains('experience')) {
      this.addJobForm.addControl('experience', new FormControl("", Validators.required));
    }

    if (!this.addJobForm.contains('skills')) {
      this.addJobForm.addControl('skills', new FormControl("", Validators.required));
    }

    this.addJobForm.updateValueAndValidity();
  }



  public async onSubmit(): Promise<void> {

    this.addJobForm.markAllAsTouched();
    const tagsValidity = this.checkJobFieldsValidity();

    this.ShowError();
    this.removeUmwantedControl();
    const description = this.addJobForm.get('job_description')?.value;
    const applyDescription = this.addJobForm.get('how_to_apply')?.value;
    console.log("data from job form ", this.addJobForm.value)



    if (
      this.Country === "" ||
      this.State === "" ||
      this.City === "" ||
      this.jobType === "" ||
      this.images.length < 1 ||
      tagsValidity ||
      description?.length < 400 ||
      applyDescription?.length < 400 ||
      this.addJobForm.invalid
    ) {
      const errorMessage = this.getFirstError();
      if (errorMessage) {
        this._toastr.error(errorMessage);
        this.addControls();
        return;
      }
      this.addControls();
    }
    else {
      this.isSpinnerShown = true;
      this._spinner.show();
      try {
        const payload = this.createApiPayload();
        this.__apiCall
          .PostCallWithToken(payload, "Dashboard/SaveJob")
          .subscribe((response) => {
            this.addControls();
            if (response.responseCode == 200) {
              this._toastr.success(response.responseMessage);
              this.modal.dismissAll();
              this.getJobs();
              this.isSpinnerShown = false;
            } else {
              this.errorHandlingService.handleResponseError(response);
              this.isSpinnerShown = false;
            }
          });

      } catch (error) {
        this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
        this.isSpinnerShown = false;
      } finally {
        this.cdr.detectChanges();
      }
    }
  }


  private getFirstError(): string {
    for (const key of Object.keys(this.addJobForm.controls)) {
      const control = this.addJobForm.get(key);
      if (control && control.invalid) {
        if (this.getFriendlyFieldName(key) != "") {
          return `${this.getFriendlyFieldName(key)} is invalid.`;
        } else {
          return "";
        }
      }
    }
    
    if (this.jobType === "") return "Job Type is required.";
    if (this.Country === "") return "Country is required.";
    if (this.State === "") return "State is required.";
    if (this.City === "") return "City is required.";

    if (this.educationTags?.length < 1) return "At least one Education tag is required.";
    if (this.experienceTags?.length < 1) return "At least one Experience tag is required.";
    if (this.skillsTags?.length < 1) return "At least one Skill tag is required.";

    if (this.images.length < 1) return "At least one image is required.";

    return "";
  }

  private getFriendlyFieldName(field: string): string {
    const fieldNames: { [key: string]: string } = {
      job_title: "Job Title",
      company_name: "Company Name",
      salary: "Salary",
      job_address: "Job Address",
      job_addressTitle: "Job Address Title",
      job_addressLink: "Job Google Maps Link",
      job_siteLink: "Job Website Link",
      job_phone: "Job Phone Number",
      application_deadline: "Application Deadline",
      job_description: "Job Description",
      how_to_apply: "How to Apply",
      responsibilities: "Responsibilities",
      additional_note: "Additional Note",
    };

    return fieldNames[field] || "";
  }

  ///////////////////////////////////////
  ///////   function for API PAYLOAD ////
  /////////////////////////////////////

  createApiPayload() {
    const payload: any = {
      id: this.updateJobId,
      jobName: this.addJobForm.get("job_title")?.value,
      companyName: this.addJobForm.get("company_name")?.value,
      jobType: this.jobType,
      salary: this.addJobForm.get("salary")?.value,
      countryName: this.Country,
      stateName: this.State,
      cityName: this.City,
      location: this.addJobForm.get("job_address")?.value,
      locationTitle: this.addJobForm.get("job_addressTitle")?.value,
      locationSrc: this.addJobForm.get("job_addressLink")?.value,
      websiteLink: this.addJobForm.get("job_siteLink")?.value || '',
      contactNumber: this.addJobForm.get("job_phone")?.value,
      educationVMs: this.educationTags,
      experienceVMs: this.experienceTags,
      skillVMs: this.skillsTags,
      deadline: this.addJobForm.get("application_deadline")?.value,
      description: this.addJobForm.get("job_description")?.value || '',
      applyDescription: this.addJobForm.get("how_to_apply")?.value || '',
      responsibilitiesDescription: this.addJobForm.get("responsibilities")?.value || '',
      additionalDescription: this.addJobForm.get("additional_note")?.value || '',
      imageVMs: this.images,
    };

    payload.contactNumber = this._utlis.numberWithCountryCode(this.addJobForm.get("job_phone")?.value, this.intlTelInputInstance);
    return payload;

  }

}
