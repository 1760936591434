<app-loader-two [isLoading]="isSpinnerShown"></app-loader-two>
<app-header-one></app-header-one>

<section class="user-dashboard small-section">
    <div class="container">
        <div class="row">
            <div class="col-lg-3">
                <div class="sidebar-user sticky-cls">
                    <div class="user-profile">
                        <app-user-info></app-user-info>
                    </div>
                    <div class="dashboard-list">
                        <app-user-panel-side-menu [activeTab]="'add-topic'"></app-user-panel-side-menu>
                    </div>
                </div>
            </div>
            <div class="col-lg-9">
                <div class="dashboard-content">
                    <div class="payment-user" id="payment">
                        <div class="common-card">
                            <div class="common-header">
                                <h5>Topics</h5>
                            </div>
                            <div class="row card-payment">
                                @if (isDataLoading) {
                                <div class="item">
                                    <ngx-skeleton-loader count="3" [theme]="{
                                                        height: '22.5rem',
                                                        width: '30%',
                                                        'border-radius': '10px',
                                                        'margin-right': '0.5rem',
                                                        'margin-left': '0.5rem'
                                                        }" />
                                </div>
                                }@else{
                                @for(data of topicData; track data){
                                <div class="col-xl-4 col-sm-6">

                                    <div class="property-box payment-card">
                                        <div class="property-image">
                                            <div class="property-slider">
                                                <div href="javascript:void(0)" class="bg-size"
                                                    [style.background-image]="'url(' + data?.imageBase64 + ')'">
                                                    <img [src]="data?.imageBase64" class="bg-img" alt="property-image"
                                                        style="display: none;" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="property-details">
                                            <!-- <span class="font-roboto card-title">
                                                <app-feather-icons class="color"
                                                    [icon]="'book'"></app-feather-icons>{{
                                                data?.courseType  }}</span>    -->
                                            <!-- + ", "
                                                + data?.courseLevel -->
                                            <a href="javascript:void(0)">
                                                <h3 class="line-clamp">{{ data?.topicName }}</h3>
                                            </a>
                                            <p class="font-roboto description-clamp">{{ data?.topicDescription }}</p>
                                            <!-- <ul>
                                                <li>
                                                    <img src="assets/images/svg/icon/telephone.png"
                                                        class="img-fluid ruler-tool" alt="ruler-icon" />{{
                                                    data?.courseLevel }}
                                                </li>
                                                <li class="border-line"></li>
                                                <li>
                                                    <img src="assets/images/svg/icon/email.png"
                                                        class="img-fluid ruler-tool" alt="ruler-icon" />
                                                    {{ data?.category }}
                                                </li>
                                            </ul> -->
                                        </div>
                                        <div class="edit-card">
                                            <div class="main-edit-del-cont">
                                                <div class="edit-delete-buttons">
                                                    <a href="javascript:void(0)"
                                                        (click)="editCard(addTopic,data?.id)">Edit</a>
                                                    <a href="javascript:void(0)"
                                                        (click)="deleteCard(deleteModal,data?.id)">Delete</a>
                                                </div>
                                                <div class="edit-delete-buttons">
                                                    <a href="javascript:void(0)"
                                                        (click)="tutorialCard(tutorialForm,data?.id)">
                                                        Manage Tutorials
                                                    </a>
                                                </div>
                                            </div>
                                            <!-- <a href="javascript:void(0)" (click)="editTopicCard(AddTopic,data?.id)">edit topic</a>
                                            <a href="javascript:void(0)" (click)="deleteTopicCard(deleteTopicModal,data?.id)">delete topic</a>   -->
                                        </div>
                                    </div>

                                </div>
                                }
                                }
                                <div class="col-xl-4 col-sm-6">
                                    <div class="payment-card add-card">
                                        <div class="card-details" (click)="addCard(addTopic)">
                                            <div>
                                                <i class="fas fa-plus-circle"></i>
                                                <h5>add new Topic</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<app-adly-footer></app-adly-footer>



<ng-template #addTopic>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">
                @if(FormName == "Add"){
                Add New Topic
                }@else{
                Update Topic
                }
            </h5>
            <button type="button" class="btn-close" (click)="modal.dismissAll()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <form [formGroup]="addTopicForm">
                <div class="row gx-3">
                    <div class="form-group col-12">
                        <label for="a-na">Topic Name<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="a-na" placeholder="Enter Topic Name"
                            formControlName="topic_name">
                        @if(addTopicForm.get('topic_name')?.hasError('required') &&
                        addTopicForm.get('topic_name')?.touched){
                        <small class="text-danger">Name is Required</small>
                        }
                    </div>
                </div>

                <div class="row gx-3">
                    <div class="form-group col-12">
                        <label for="a-na">Topic Description<span class="text-danger">*</span></label>
                        <textarea rows="4" name="" id="" class="form-control" placeholder="Enter Description"
                            formControlName="topic_description" (input)="updateCharCount()"></textarea>
                        <small class="">{{ charCount }}/400</small>
                        @if(addTopicForm.get('topic_description')?.hasError('required') &&
                        addTopicForm.get('topic_description')?.touched){
                        <small class="text-danger">Description is Required</small>
                        }
                    </div>
                </div>
                @if(FormName === 'Edit'){
                <div class="row gx-3">
                    <div class="form-group col-12">
                        <label for="a-na">Topic Tutorials<span class="text-danger">*</span></label>
                    </div>
                    <div class="tutrialsContainer">
                        @for(item of topicByIdData?.tutorials;track item){
                        <div class="tutorialtag">
                            <div class="tutorialtagContent">
                                {{ item?.tutorialName || 'No Tutorials' }}
                                <span class="icons">
                                    <i class="fas fa-edit edit-icon"
                                        (click)="editTutorialCard(AddTutorial,item?.id)"></i>
                                    <i class="fas fa-trash delete-icon"
                                        (click)="deleteTutorialCard(deleteTutorialModal,item?.id)"></i>
                                </span>
                            </div>

                        </div>
                        }
                        @if(topicByIdData?.tutorials?.length <= 0 ) { <div
                            class="d-flex gap-2 justify-content-center add-button">
                            <p style="padding-top: 12px;">Currently you have no tutorials added.</p>
                            <!-- <button type="button" class="btn btn-gradient color-2 btn-pill" (click)="addCard(addTopic)">
                                Add Tutorial
                            </button> -->
                    </div>
                    }
                    <span class="label label-shadow btn-sm pointer-cursor" (click)="addTutorialCard(AddTutorial,topicByIdData?.id)">Add
                        Tutorial </span>

                </div>


        </div>
        <!-- </div> -->
        }

        </form>

        <div class="dropzone-admin mb-0" *ngIf="FormName === 'Add'">
            <label>Topic Thumbnail<span class="text-danger">*</span></label>
            <form class="dropzone" id="imageUpload">
                <ngx-dropzone (change)="onImageSelect($event)" accept="image/jpeg,image/jpg,image/png"
                    [multiple]="false">
                    <ngx-dropzone-label>
                        <div class="dz-message needsclick">
                            <i class="fas fa-cloud-upload-alt"></i>
                            <h6 class="f-w-700 mb-0">
                                Drop file here or click to upload an image.
                            </h6>
                        </div>
                    </ngx-dropzone-label>
                    <ngx-dropzone-image-preview *ngIf="thumbnailImageFile" ngProjectAs="ngx-dropzone-preview"
                        [removable]="true" (removed)="onRemoveImage()" [file]="thumbnailImageFile">
                        <ngx-dropzone-label>{{thumbnailImageFile.name }} ({{
                            thumbnailImageFile.type
                            }})</ngx-dropzone-label>
                    </ngx-dropzone-image-preview>
                </ngx-dropzone>
            </form>
        </div>

        <div class="dropzone-admin mb-0" *ngIf="FormName === 'Edit'">
            <label>Topic Thumbnail<span class="text-danger">*</span></label>
            @if(thumbnailImageBase64 != null){
            <div class="image-gallery">
                <div class="image-container">
                    <app-feather-icons [icon]="'x'" class="closeImageIcon"
                        (click)="removeImageFile()"></app-feather-icons>
                    <img [src]="thumbnailImageBase64" alt="image" />
                </div>
            </div>
            }
            @if(thumbnailImageBase64 == null){
            <div class="image-gallery">
                <div class="upload-container" (click)="fileInput.click()">
                    <app-feather-icons [icon]="'upload'"></app-feather-icons>
                    <input #fileInput type="file" style="display: none" accept="image/jpeg,image/jpg,image/png"
                        (change)="onImageFileSelected($event)" />
                    Upload Thumbnail
                </div>
            </div>
            }
        </div>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-dashed color-2 btn-pill" (click)="modal.dismissAll()">Cancel</button>
        <button type="button" class="btn btn-gradient color-2 btn-pill" (click)="onSubmit()">
            @if(FormName == "Add"){
            Add Topic
            }@else{
            Update Topic
            }
        </button>
    </div>
    </div>
</ng-template>

<ng-template #deleteModal>
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="btn-close" (click)="modal.dismissAll()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="text">
                Are You Sure you want to delete this Topic ?
            </div>
            <div class="deleteButtons">
                <button type="button" class="btn btn-dashed color-2 btn-pill"
                    (click)="modal.dismissAll()">Cancel</button>
                <button type="button" class="btn btn-gradient color-2 btn-pill" (click)="deleteTopic()">
                    Delete
                </button>
            </div>
        </div>
    </div>
</ng-template>



<ng-template #AddTutorial>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">
                @if(FormName == "AddTutorial"){
                Add New Tutorial
                }@else{
                Update Tutorial
                }
            </h5>
            <button type="button" class="btn-close" (click)="modal.dismissAll()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <form [formGroup]="addTutorialForm">
                <div class="row gx-3">
                    <div class="form-group col-6 col-sm-6">
                        <label for="a-na">Tutorial Name<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="a-na" placeholder="Enter Tutorial Name"
                            formControlName="tutorial_name">
                        @if(addTutorialForm.get('tutorial_name')?.hasError('required') &&
                        addTutorialForm.get('tutorial_name')?.touched){
                        <small class="text-danger">Name is Required</small>
                        }
                    </div>

                    <div class="form-group col-6 col-sm-6 modal-container">
                        <label for="a-na">Tutorial Category<span class="text-danger">*</span></label>
                        <app-common-dropdowns class="first-dropdown" [defaultText]="'Select Tutorial Category'"
                            [dataList]="TutorialCategoryList" (optionSelected)="onTutorialCategorySelected($event)"
                            [isDisabled]="true" [selectedOption]="tutorialCategory"
                            [clearThevalue]="tutorialCategory"></app-common-dropdowns>
                        @if(tutorialCategory == "" && tutorialCategoryError){
                        <small class="text-danger">Tutorial Category is Required</small>
                        }
                    </div>


                </div>
                <div class="form-group col-12 col-sm-12">
                    <label for="a-na">Tutorial Keywords<span class="text-danger">*</span></label>
                    <input type="text" class="form-control" id="a-na" placeholder="Add Keyword"
                        formControlName="keyword_name" (keyup.enter)="onEnterKeyword($event)">
                    <!-- Validation for max length -->
                    <small class="text-danger" *ngIf="tagTooLong">
                        Keyword must be 15 letters or less.
                    </small>
                
                    <!-- Validation for minimum tags -->
                    <small class="text-danger"
                        *ngIf="KeywordTags.length < 5 && addTutorialForm.get('keyword_name')?.touched">
                        Add at least 5 keywords.
                    </small>
                
                    <div class="tags-container">
                        <div class="tag" *ngFor="let keyword_tag of KeywordTags; trackBy: trackByFn"
                            (click)="removeKeywordTags(keyword_tag)">
                            {{ keyword_tag.keyword }}
                            <span class="close-icon">&times;</span>
                        </div>
                    </div>
                </div>
                

                <div class="form-group col-12 col-sm-4">
                    <div class="form-group col-12">
                        <label for="a-na">Tutorial Content<span class="text-danger">*</span></label>
                        <div class="toolbar">
                            <button (click)="applyBold()" title="Bold">
                                <app-feather-icons [icon]="'bold'" [class]="'icon-small'" ></app-feather-icons>
                            </button>
                            <button (click)="applyItalic()" title="Italic">
                                <app-feather-icons [icon]="'italic'" [class]="'icon-small'"></app-feather-icons>
                            </button>
                            <button (click)="applyUnderline()" title="Underline">
                                <app-feather-icons [icon]="'underline'" [class]="'icon-small'"></app-feather-icons>
                            </button>
                            <button (click)="changeFontSize('increase')" title="Increase Font Size">
                                <app-feather-icons [icon]="'plus'" [class]="'icon-small'"></app-feather-icons>
                            </button>
                            <button (click)="changeFontSize('decrease')" title="Decrease Font Size">
                                <app-feather-icons [icon]="'minus'" [class]="'icon-small'"></app-feather-icons>
                            </button>
                            <button (click)="applyStrikethrough()" title="Strikethrough">
                                <app-feather-icons [icon]="'slash'" [class]="'icon-small'"></app-feather-icons>
                            </button>
                            <button (click)="applyBulletList()" title="Bullet List">
                                <app-feather-icons [icon]="'list'" [class]="'icon-small'"></app-feather-icons>
                            </button>
                            <button (click)="applyNumberedList()" title="Numbered List">
                                <app-feather-icons [icon]="'hash'" [class]="'icon-small'"></app-feather-icons>
                            </button>
                            <button (click)="alignLeft()" title="Align Left">
                                <app-feather-icons [icon]="'align-left'" [class]="'icon-small'"></app-feather-icons>
                            </button>
                            <button (click)="alignCenter()" title="Align Center">
                                <app-feather-icons [icon]="'align-center'" [class]="'icon-small'"></app-feather-icons>
                            </button>
                            <button (click)="alignRight()" title="Align Right">
                                <app-feather-icons [icon]="'align-right'" [class]="'icon-small'"></app-feather-icons>
                            </button>
                            <button (click)="insertImage()" title="Insert Image">
                                <app-feather-icons [icon]="'image'" [class]="'icon-small'"></app-feather-icons>
                            </button>
                        </div>


                        <div class="editor" contenteditable="true"
                            style="border: 1px solid #ccc; padding: 10px; min-height: 200px;"
                            (input)="onEditorInput($event)">

                        </div>
                        <small>{{ TutorialContentCharCount }}/400</small>
                        @if(addTutorialForm.get('contentUrl')?.hasError('required') &&
                        addTutorialForm.get('contentUrl')?.touched){
                        <small class="text-danger">Content is Required</small>
                        }
                    </div>
                </div>
            </form>
            <div class="dropzone-admin mb-0" *ngIf="FormName === 'AddTutorial'">
                <label>Tutorial Thumbnail<span class="text-danger">*</span></label>
                <form class="dropzone" id="imageUpload">
                    <ngx-dropzone (change)="onImageSelect($event)" accept="image/jpeg,image/jpg,image/png"
                        [multiple]="false">
                        <ngx-dropzone-label>
                            <div class="dz-message needsclick">
                                <i class="fas fa-cloud-upload-alt"></i>
                                <h6 class="f-w-700 mb-0">
                                    Drop file here or click to upload an image.
                                </h6>
                            </div>
                        </ngx-dropzone-label>
                        <ngx-dropzone-image-preview *ngIf="thumbnailImageFile" ngProjectAs="ngx-dropzone-preview"
                            [removable]="true" (removed)="onRemoveImage()" [file]="thumbnailImageFile">
                            <ngx-dropzone-label>{{thumbnailImageFile.name }} ({{
                                thumbnailImageFile.type
                                }})</ngx-dropzone-label>
                        </ngx-dropzone-image-preview>
                    </ngx-dropzone>
                </form>
            </div>

            <div class="dropzone-admin mb-0" *ngIf="FormName === 'EditTutorial'">
                <label>Tutorial Thumbnail<span class="text-danger">*</span></label>
                @if(thumbnailImageBase64 != null){
                <div class="image-gallery">
                    <div class="image-container">
                        <app-feather-icons [icon]="'x'" class="closeImageIcon"
                            (click)="removeImageFile()"></app-feather-icons>
                        <img [src]="thumbnailImageBase64" alt="image" />
                    </div>
                </div>
                }
                @if(thumbnailImageBase64 == null){
                <div class="image-gallery">
                    <div class="upload-container" (click)="fileInput.click()">
                        <app-feather-icons [icon]="'upload'"></app-feather-icons>
                        <input #fileInput type="file" style="display: none" accept="image/jpeg,image/jpg,image/png"
                            (change)="onImageFileSelected($event)" />
                        Upload Thumbnail
                    </div>
                </div>
                }
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-dashed color-2 btn-pill" (click)="modal.dismissAll()">Cancel</button>
            <button type="button" class="btn btn-gradient color-2 btn-pill" (click)="onSubmitTutorial()">
                @if(FormName == "AddTutorial"){
                Add Tutorial
                }@else{
                Update Tutorial
                }
            </button>
        </div>
    </div>
</ng-template>

<ng-template #deleteTutorialModal>
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="btn-close" (click)="modal.dismissAll()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="text">
                Are You Sure you want to delete this Topic ?
            </div>
            <div class="deleteButtons">
                <button type="button" class="btn btn-dashed color-2 btn-pill"
                    (click)="modal.dismissAll()">Cancel</button>
                <button type="button" class="btn btn-gradient color-2 btn-pill" (click)="deleteTutorial()">
                    Delete
                </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #tutorialForm>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">
                @if(FormName == "tutorialForm"){
                Tutorials
                }@else{
                Update Tutorial
                }
            </h5>
            <button type="button" class="btn-close" (click)="modal.dismissAll()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row card-payment">
                @if (isDataLoading) {
                <div class="item">
                    <ngx-skeleton-loader count="3" [theme]="{
                                            height: '22.5rem',
                                            width: '30%',
                                            'border-radius': '10px',
                                            'margin-right': '0.5rem',
                                            'margin-left': '0.5rem'
                                            }" />
                </div>
                }@else{
                @for(data of tutorialData; track data){
                <!-- <div class="col-xl-4 col-sm-6">

                    <div class="property-box payment-card" style="width: 230px; height: 250px; display: flex; flex-direction: column; justify-content: space-between;
                                    align-items: center; padding: 10px; border: 1px solid #ddd; border-radius: 10px;
                                    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); margin: 10px;">

                        <div class="property-image">
                            <img [src]="data?.imageBase64" alt="property-image"
                                style="width: 100%; height: 100%; object-fit: cover;" />
                        </div>

                        <div class="property-details" style="margin-top: 5px; text-align: center;">
                            <a href="javascript:void(0)">
                                <h3 class="line-clamp" style="font-size: 14px; font-weight: bold; overflow: hidden; 
                                                                    white-space: nowrap; text-overflow: ellipsis;">
                                    {{ data?.tutorialName }}
                                </h3>
                            </a>
                        </div>

                        <div class="edit-card" style="margin-top: 10px;">
                            <a href="javascript:void(0)" (click)="editTutorialCard(AddTutorial,data?.id)">Edit
                                Tutorial</a>
                            <a href="javascript:void(0)"
                                (click)="deleteTutorialCard(deleteTutorialModal,data?.id)">Delete Tutorial</a>
                        </div>
                    </div>
                </div> -->


                <div class="col-xl-4 col-sm-6">

                    <div class="property-box payment-card Size">
                        <div class="property-image">
                            <div class="property-slider">
                                <div href="javascript:void(0)" class="bg-size"
                                    [style.background-image]="'url(' + data?.imageBase64 + ')'">
                                    <img [src]="data?.imageBase64" class="bg-img" alt="property-image"
                                        style="display: none;" />
                                </div>
                            </div>
                        </div>
                        <div class="property-details tutorial-card-detail">
                            <!-- <span class="font-roboto card-title">
                                <app-feather-icons class="color"
                                    [icon]="'book'"></app-feather-icons>{{
                                data?.courseType  }}</span>    -->
                            <!-- + ", "
                                + data?.courseLevel -->
                            <a href="javascript:void(0)">
                                <h3 class="line-clamp">{{ data?.tutorialName }}</h3>
                            </a>
                        </div>
                        <div class="edit-card">
                            <a href="javascript:void(0)" (click)="editTutorialCard(AddTutorial,data?.id)">Edit
                                Tutorial</a>
                            <a href="javascript:void(0)"
                                (click)="deleteTutorialCard(deleteTutorialModal,data?.id)">Delete Tutorial</a>
                        </div>
                    </div>

                </div>
                }
                }
                <div class="col-xl-4 col-sm-6">
                    <div class="payment-card add-card Size">
                        <div class="card-details" (click)="addTutorialCard(AddTutorial,topicTutorialId)">
                            <div>
                                <i class="fas fa-plus-circle"></i>
                                <h5>add new Tutorial</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-dashed color-2 btn-pill" (click)="modal.dismissAll()">Cancel</button>
        </div>
    </div>
</ng-template>