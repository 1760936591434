<!-- <app-loader-two [isLoading]="isSpinnerShown"></app-loader-two> -->
<app-header-one></app-header-one>
<section class="user-dashboard terms-section">
    <div class="container">
        <div class="row log-in">
            <div class="col-xl-12 col-lg-12 col-12">
                <div class="theme-card">

                    <div class="terms-wrap">
                        @if(formName == 'GPA'){
                        <div class="row">
                            <div class="terms-wrapper col-12 col-md-12" id="introduction">
                                <div class="headers">
                                    <h4>
                                        GPA Calculator
                                    </h4>
                                </div>
                                <div class="table-responsive" style="max-height: 400px; overflow-y: auto;">
                                    <form [formGroup]="gpaForm">
                                        <div formArrayName="courses">
                                            <table class="table table-sm">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Course </th>
                                                        <th>Credits </th>
                                                        <th>Obtain </th>
                                                        <th>Total </th>
                                                        <th>Grade </th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let course of courses.controls; let i = index"
                                                        [formGroupName]="i">
                                                        <td>{{ i + 1 }}</td>
                                                        <td>
                                                            <input type="text" class="form-control form-control-sm"
                                                                formControlName="name" placeholder="Enter Course Name">
                                                        </td>
                                                        <td>
                                                            <input type="number" class="form-control form-control-sm"
                                                                formControlName="credits"
                                                                placeholder="Enter Credit Hours">
                                                        </td>
                                                        <td>
                                                            <input type="number" class="form-control form-control-sm"
                                                                formControlName="Obtained"
                                                                placeholder="Enter Obtained Marks">
                                                        </td>
                                                        <td>
                                                            <input type="number" class="form-control form-control-sm"
                                                                formControlName="total" placeholder="Enter Total Marks">
                                                        </td>
                                                        <td>
                                                            <select formControlName="grade"
                                                                class="form-control form-control-sm">
                                                                <option value="" disabled selected>Select a Grade
                                                                </option>
                                                                <option *ngFor="let grade of gradeOptions"
                                                                    [value]="grade.value">
                                                                    {{ grade.label }}
                                                                </option>
                                                            </select>
                                                        </td>
                                                        <td class="align-middle text-center">
                                                            <button type="button" class="btn p-0 text-danger"
                                                                (click)="removeCourse(i)" title="Remove">
                                                                <i class="fas fa-times"></i>
                                                            </button>
                                                        </td>


                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </form>
                                </div>

                                <div class="d-flex justify-content-between py-3">
                                    <button type="button" class="btn btn-gradient color-2 btn-pill align-right"
                                        (click)="calculateGPA()">
                                        Calculate GPA
                                    </button>
                                    <button type="button" class="btn btn-gradient color-2 btn-pill align-right"
                                        (click)="addCourse()">
                                        Add Course
                                    </button>
                                </div>
                                <div *ngIf="gpa != null" class="text-center">
                                    Your current GPA is <strong class="sectionBlack">{{ gpa }}</strong>
                                </div>
                            </div>
                            <!-- <div class="col-12 col-md-4 d-none d-md-block">
                                <div class="headers">
                                    <h6>
                                        Grading System
                                    </h6>
                                </div>

                                <table class="table table-striped table-bordered">
                                    <thead style="background-color: white; color: #ff5c41;" class="text-center">
                                        <tr>
                                            <th>Grade</th>
                                            <th>Grade Points</th>
                                            <th>Percentage Range</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let grade of gradeOptions">
                                            <td>{{ grade.label }}</td>
                                            <td>{{ grade.value }}</td>
                                            <td>{{ grade.percentageRange }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div> -->
                        </div>
                        }@else if ( formName === 'CGPA' ) {
                        <div class="row">
                            <div class="terms-wrapper col-12" id="introduction">
                                <div class="headers">
                                    <h4>
                                        CGPA Calculator
                                    </h4>
                                </div>
                                <div class="table-responsive" style="max-height: 400px; overflow-y: auto;">
                                    <form [formGroup]="cgpaForm">
                                        <div formArrayName="gpas">
                                            <table class="table table-sm">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>GPA </th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let semgpas of gpas.controls; let i = index"
                                                        [formGroupName]="i">
                                                        <td>{{ i + 1 }}</td>
                                                        <td>
                                                            <input type="number" class="form-control form-control-sm"
                                                                formControlName="semgpa" placeholder="Enter GPA">
                                                            <!-- @if(semgpas.get('semgpa')?.hasError('required') &&
                                                            semgpas.get('semgpa')?.touched){
                                                            <small class="text-danger">CGPA is required.</small>
                                                            } -->
                                                            @if(semgpas.get('semgpa')?.hasError('pattern') &&
                                                            semgpas.get('semgpa')?.touched){
                                                            <small class="text-danger">GPA must be a number between 0
                                                                and 4.</small>
                                                            }
                                                        </td>
                                                        <td class="align-middle text-center">
                                                            <button type="button" class="btn p-0 text-danger"
                                                                (click)="removegpa(i)" title="Remove">
                                                                <i class="fas fa-times"></i>
                                                            </button>
                                                        </td>


                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </form>
                                </div>

                                <div class="d-flex justify-content-between py-3">
                                    <button type="button" class="btn btn-gradient color-2 btn-pill align-right"
                                        (click)="calculateCGPA()">
                                        Calculate CGPA
                                    </button>
                                    <button type="button" class="btn btn-gradient color-2 btn-pill align-right"
                                        (click)="addgpa()">
                                        Add GPA
                                    </button>
                                </div>
                                <div *ngIf="cgpa != null" class="text-center">
                                    Your CGPA is <strong class="sectionBlack">{{ cgpa }}</strong>
                                </div>
                            </div>
                        </div>
                        }

                        <div class="container">
                            <!-- GPA Calculation Guide Section -->
                            <div class="terms-wrapper" id="gpa-calculation">
                                @if(formName === 'GPA'){
                                <h3 class="section-title headingColor">Want to Calculate Your CGPA?</h3>
                                <div class="d-flex justify-content-between py-3">
                                    <!-- <button type="button" class="btn btn-gradient color-2 btn-pill align-right"
                                        (click)="changeForms('CGPA')">
                                        Calculate CGPA
                                    </button> -->
                                    <div class="attempted-test" (click)="changeForms('CGPA')">
                                        <span> Calculate CGPA </span>
                                        <app-feather-icons class="color" [icon]="'external-link'"></app-feather-icons>
                                       
                                    </div>
                                </div>
                                }@else if(formName === 'CGPA'){
                                <h3 class="section-title headingColor">Want to Calculate Your GPA?</h3>
                                <div class="d-flex justify-content-between py-3">
                                    <!-- <button type="button" class="btn btn-gradient color-2 btn-pill align-right"
                                        (click)="changeForms('GPA')">
                                        Calculate GPA
                                    </button> -->
                                    <div class="attempted-test" (click)="changeForms('GPA')">
                                        <span> Calculate GPA </span>
                                        <app-feather-icons class="color" [icon]="'external-link'"></app-feather-icons>
                                       
                                    </div>
                                </div>
                                }

                                <h3 class="section-title headingColor">How Your GPA is Calculated?</h3>
                                <p>Welcome to Admission Lylo! Here, we’ll walk you through the process of calculating
                                    your Grade Point Average (GPA). GPA is an essential metric used by academic
                                    institutions to evaluate your academic performance. Here's how we calculate it:</p>

                                <ol>
                                    <li><strong class="sectionBlack">Step 1: Understand Your Grades and Grade
                                            Points</strong> - Each letter grade you receive is assigned a specific grade
                                        point value. These grade points are used to calculate your GPA. Below is a table
                                        showing the grade points associated with each grade.</li>
                                </ol>

                                <!-- Bootstrap Table -->
                                <table class="table table-striped table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Grade</th>
                                            <th>Grade Points</th>
                                            <th>Percentage Range</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>A+</td>
                                            <td>4.0</td>
                                            <td>90-100%</td>
                                        </tr>
                                        <tr>
                                            <td>A</td>
                                            <td>4.0</td>
                                            <td>85-89%</td>
                                        </tr>
                                        <tr>
                                            <td>A-</td>
                                            <td>3.7</td>
                                            <td>80-84%</td>
                                        </tr>
                                        <tr>
                                            <td>B+</td>
                                            <td>3.3</td>
                                            <td>75-79%</td>
                                        </tr>
                                        <tr>
                                            <td>B</td>
                                            <td>3.0</td>
                                            <td>70-74%</td>
                                        </tr>
                                        <tr>
                                            <td>B-</td>
                                            <td>2.7</td>
                                            <td>65-69%</td>
                                        </tr>
                                        <tr>
                                            <td>C+</td>
                                            <td>2.3</td>
                                            <td>60-64%</td>
                                        </tr>
                                        <tr>
                                            <td>C</td>
                                            <td>2.0</td>
                                            <td>55-59%</td>
                                        </tr>
                                        <tr>
                                            <td>C-</td>
                                            <td>1.7</td>
                                            <td>50-54%</td>
                                        </tr>
                                        <tr>
                                            <td>D+</td>
                                            <td>1.3</td>
                                            <td>45-49%</td>
                                        </tr>
                                        <tr>
                                            <td>D</td>
                                            <td>1.0</td>
                                            <td>40-44%</td>
                                        </tr>
                                        <tr>
                                            <td>F</td>
                                            <td>0.0</td>
                                            <td>Below 40%</td>
                                        </tr>
                                    </tbody>
                                </table>

                                <p><strong class="sectionBlack">Note:</strong> The grade points correspond to the
                                    percentage range you achieve in your courses. For example, if you get an "A" (4.0
                                    grade points), you will receive 4.0 grade points.</p>

                                <ol>
                                    <li><strong class="sectionBlack">Step 2: Understand Credit Hours</strong> - Each
                                        course you take has a certain number of credit hours assigned to it. Credit
                                        hours represent the weight or importance of a course. More challenging or
                                        extensive courses usually carry more credit hours.</li>
                                    <li><strong class="sectionBlack">Step 3: Multiply Grade Points by Credit
                                            Hours</strong> - For each course, multiply the grade points earned (from
                                        Step 1) by the number of credit hours assigned to the course. This gives you the
                                        weighted grade points for each course.</li>
                                </ol>

                                <p><strong class="sectionBlack">Example:</strong> If you earned a grade of "B" (3.0
                                    grade points) in a course worth 3 credit hours, the weighted grade points would be:
                                </p>
                                <p><em>3.0 grade points x 3 credit hours = 9 weighted grade points</em></p>

                                <ol>
                                    <li><strong class="sectionBlack">Step 4: Add All Weighted Grade Points</strong> -
                                        After calculating the weighted grade points for each course, add them together.
                                    </li>
                                    <li><strong class="sectionBlack">Step 5: Add Total Credit Hours</strong> - Add the
                                        total number of credit hours for all the courses you’ve taken.</li>
                                    <li><strong class="sectionBlack">Step 6: Calculate Your GPA</strong> - Finally, to
                                        calculate your GPA, divide the total weighted grade points by the total credit
                                        hours.</li>
                                </ol>

                                <p><strong class="sectionBlack">Formula:</strong> <em>GPA = Total Weighted Grade Points
                                        / Total Credit Hours</em></p>

                                <p><strong class="sectionBlack">Example Calculation:</strong> Let's say you have taken 3
                                    courses with the following grades and credit hours:</p>
                                <ul>
                                    <li>Course 1: Grade = B (3.0 grade points), Credit Hours = 3</li>
                                    <li>Course 2: Grade = A (4.0 grade points), Credit Hours = 4</li>
                                    <li>Course 3: Grade = C+ (2.7 grade points), Credit Hours = 2</li>
                                </ul>

                                <p>The weighted grade points for each course would be:</p>
                                <ul>
                                    <li>Course 1: 3.0 x 3 = 9 weighted grade points</li>
                                    <li>Course 2: 4.0 x 4 = 16 weighted grade points</li>
                                    <li>Course 3: 2.7 x 2 = 5.4 weighted grade points</li>
                                </ul>

                                <p>The total weighted grade points = 9 + 16 + 5.4 = 30.4</p>
                                <p>The total credit hours = 3 + 4 + 2 = 9</p>

                                <p>Now, calculate the GPA:</p>
                                <p><em>GPA = 30.4 / 9 = 3.38</em></p>

                                <p>So, your GPA is <strong class="sectionBlack">3.38</strong>.</p>

                                <p>Remember, you can use the GPA calculator tool on <strong
                                        class="sectionBlack">Admission Lylo</strong> to calculate your GPA quickly!</p>
                            </div>
                        </div>



                    </div>

                </div>
            </div>
        </div>
    </div>
</section>
<app-adly-footer></app-adly-footer>