import { Component, inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-gpa-calculator',
  templateUrl: './gpa-calculator.component.html',
  styleUrl: './gpa-calculator.component.scss'
})
export class GpaCalculatorComponent implements OnInit {




  ///////////////////////////////////////////////////
  //////////////// code for GPA ////////////////////
  //////////////////////////////////////////////////


  gpaForm: FormGroup;
  gpa: number | null = null;
  isOpen = false;
  formName: string = 'GPA';
  private toaster = inject(ToastrService);

  constructor(private fb: FormBuilder) {

    // for gpa form
    this.gpaForm = this.fb.group({
      courses: this.fb.array([this.createCourse()])
    });

    // for cgpa form
    this.cgpaForm = this.fb.group({
      gpas: this.fb.array([this.createcgpa()])
    });

  }
  ngOnInit(): void {
    this.addCourse();
    this.addCourse();
  }

  // function to change forms

  changeForms(Name: string) {
    this.formName = Name;
    this.cgpaForm.reset();
    this.gpaForm.reset();
  }


  get courses(): FormArray {
    return this.gpaForm.get('courses') as FormArray;
  }


  createCourse(): FormGroup {
    const courseGroup = this.fb.group({
      name: ['', Validators.required],
      grade: ['', Validators.required],
      Obtained: ['', Validators.required],
      total: ['', Validators.required],
      credits: [null, [Validators.required, Validators.min(1)]]
    });

    courseGroup.get('Obtained')?.valueChanges.subscribe(() => this.updateGrade(courseGroup));
    courseGroup.get('total')?.valueChanges.subscribe(() => this.updateGrade(courseGroup));
    courseGroup.get('grade')?.valueChanges.subscribe(() => this.updateNumbers(courseGroup));

    return courseGroup;
  }




  addCourse(): void {
    if (this.courses.length >= 10) {
      this.toaster.error("Maximum 10 courses allowed");
    } else {

      this.courses.push(this.createCourse());
    }
  }


  removeCourse(index: number): void {
    this.courses.removeAt(index);
  }


  calculateGPA(): void {
    debugger
    const courses = this.courses.value;
    let totalGradePoints = 0;
    let totalCredits = 0;

    for (const course of courses) {
      debugger
      totalGradePoints += course.grade * course.credits;
      totalCredits += course.credits;
    }

    this.gpa = totalCredits > 0 ? totalGradePoints / totalCredits : null;
  }



  updateGrade(courseGroup: FormGroup): void {
    debugger
    const obtained = +courseGroup.get('Obtained')?.value || 0;
    const total = +courseGroup.get('total')?.value || 0;

    if (total > 0) {
      const percentage = (obtained / total) * 100;
      const gradeOption = this.gradeOptions.find(option => percentage >= option.start && percentage <= option.end);
      if (gradeOption) {
        courseGroup.get('grade')?.setValue(gradeOption.value, { emitEvent: false });
      } else {
        courseGroup.get('grade')?.setValue(0.0, { emitEvent: false });
      }
    } else {
      courseGroup.get('grade')?.setValue(0.0, { emitEvent: false });
    }
  }


  updateNumbers(courseGroup: FormGroup): void {
    debugger
    const grade = courseGroup.get('grade')?.value;
    if (grade) {
      const newGrade = this.gradeOptions.find(option => option.value == grade);
      courseGroup.get('Obtained')?.setValue(newGrade?.start, { emitEvent: false });
      courseGroup.get('total')?.setValue(100, { emitEvent: false });
    }
  }



  gradeOptions = [
    { label: 'A+', value: 4.0, percentageRange: '90-100%', start: 90, end: 100 },
    { label: 'A', value: 4.0, percentageRange: '85-89%', start: 85, end: 89 },
    { label: 'A-', value: 3.7, percentageRange: '80-84%', start: 80, end: 84 },
    { label: 'B+', value: 3.3, percentageRange: '75-79%', start: 75, end: 79 },
    { label: 'B', value: 3.0, percentageRange: '70-74%', start: 70, end: 74 },
    { label: 'B-', value: 2.7, percentageRange: '65-69%', start: 65, end: 69 },
    { label: 'C+', value: 2.3, percentageRange: '60-64%', start: 60, end: 64 },
    { label: 'C', value: 2.0, percentageRange: '55-59%', start: 55, end: 59 },
    { label: 'C-', value: 1.7, percentageRange: '50-54%', start: 50, end: 54 },
    { label: 'D+', value: 1.3, percentageRange: '45-49%', start: 45, end: 49 },
    { label: 'D', value: 1.0, percentageRange: '40-44%', start: 40, end: 44 },
    { label: 'F', value: 0.0, percentageRange: 'Below 40%', start: 0, end: 39 }
  ];



  ///////////////////////////////////////////////////
  //////////////// code for CGPA ////////////////////
  //////////////////////////////////////////////////


  cgpaForm: FormGroup;
  cgpa: number | null = null;

  get gpas(): FormArray {
    return this.cgpaForm.get('gpas') as FormArray;
  }

  createcgpa(): FormGroup {
    return this.fb.group({
      semgpa: ['', [Validators.required, Validators.pattern(/^(?:[0-3](?:\.\d{1,2})?|4(?:\.00?)?)$/)]]
    });
  }

  addgpa(): void {
    if (this.gpas.length >= 8) {
      this.toaster.error("Maximum 8 GPA allowed");
    } else {

      this.gpas.push(this.createcgpa());
    }
  }

  removegpa(index: number): void {
    this.gpas.removeAt(index);
  }

  calculateCGPA(): void {
    debugger
    const gpas = this.gpas.value;
    let totalgpa = 0;
    let noOfGpa = 0;

    for (const gpa of gpas) {
      debugger
      totalgpa += gpa.semgpa;
      noOfGpa++;
    }

    this.cgpa = noOfGpa > 0 ? totalgpa / noOfGpa : null;
  }

}
