import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { TransferState, makeStateKey } from '@angular/platform-browser';

const TITLE_KEY = makeStateKey<string>('title');
const META_TAGS_KEY = makeStateKey<{ name: string; content: string }[]>('metaTags');

@Injectable({
  providedIn: 'root'
})
export class MetaService {

  constructor(private meta: Meta,
    private title: Title,
    private transferState: TransferState,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private document: Document) { }

  updateTitle(title: string) {
    this.title.setTitle(title);
    if (isPlatformBrowser(this.platformId)) {
      this.transferState.set(TITLE_KEY, title);
    }
  }

  updateMetaTags(tags: { name: string; content: string }[]) {
    tags.forEach(tag => {
      this.meta.updateTag({ name: tag.name, content: tag.content });
    });
    if (isPlatformBrowser(this.platformId)) {
      this.transferState.set(META_TAGS_KEY, tags);
    }
  }

  setCanonicalURL(url?: string) {
    const canURL = url || this.getURL();
    const link: HTMLLinkElement = this.getCanonicalLink() || this.createCanonicalLink();
    link.setAttribute('href', canURL);
  }

  private getURL() {
    return document.location.href;
  }

  private getCanonicalLink() {
    return document.head.querySelector('link[rel="canonical"]') as HTMLLinkElement;
  }

  private createCanonicalLink() {
    const link: HTMLLinkElement = document.createElement('link');
    link.setAttribute('rel', 'canonical');
    document.head.appendChild(link);
    return link;
  }

  initializeMetaData() {
    const title = this.transferState.get(TITLE_KEY, null);
    if (title) {
      this.updateTitle(title);
    }

    const metaTags = this.transferState.get(META_TAGS_KEY, null);
    if (metaTags) {
      this.updateMetaTags(metaTags);
    }
  }


}
