import { ChangeDetectionStrategy, Component, inject, input, Input } from '@angular/core';
import { agencyAgent } from '../../../../../shared/interface/property';
import { CommonUtilsServiceService } from 'src/app/shared/services/common-utils-service.service';

@Component({
  selector: 'app-agency-agents',
  templateUrl: './agency-agents.component.html',
  styleUrls: ['./agency-agents.component.scss'],
 // changeDetection: ChangeDetectionStrategy.OnPush
})
export class AgencyAgentsComponent {

  @Input() agentsData: agencyAgent;
  @Input() data: any;

  public isMobile: boolean = false;
  public mobileNumber: string
  private _utils = inject(CommonUtilsServiceService);


  ngOnInit() {
    this.mobileNumber = this.agentsData?.mobile?.replace(
      this.agentsData.mobile.slice(-4), '****');

      console.log("agentdata ",this.agentsData)
  }

  showMobile(data: agencyAgent) {
    this.isMobile = !this.isMobile;
    if (this.isMobile) {
      this.mobileNumber = data.mobile
    } else {
      this.mobileNumber = data.mobile.replace(data.mobile.slice(-4), "****");
    }
  }

  navigateTospecificComponent(id: number, moduleType: any) {
    if (moduleType == "teacher") {
      this._utils.navigateTo("/teacher/teacher-deatils", {
        teacherid: id,
      });
    }
  }


  // copy email or number and tooltip code 

  toolTipText:string = "Copy";
  copyToClipBoard(valueToCopy: any) {
   // const tooltip = document.querySelectorAll(".tooltip");
    if (valueToCopy != "" || valueToCopy?.length >= 1) {
      navigator.clipboard.writeText(valueToCopy);
        this.toolTipText = "Copied";
      
    }
  }


  tooltipVisible = false;

  Tooltip(res : boolean) {
    this.tooltipVisible = res;
    if(res == false){
      this.toolTipText = "Copy"
    }
  }

}

