<app-loader-two [isLoading]="isSpinnerShown"></app-loader-two>
<app-header-one></app-header-one>

<section class="user-dashboard small-section">
    <div class="container">
        <div class="row">
            <div class="col-lg-3">
                <div class="sidebar-user sticky-cls">
                    <div class="user-profile">
                        <app-user-info></app-user-info>
                    </div>
                    <div class="dashboard-list">
                        <app-user-panel-side-menu [activeTab]="'add-jobs'"></app-user-panel-side-menu>
                    </div>
                </div>
            </div>
            <div class="col-lg-9">
                <div class="dashboard-content">
                    <div class="payment-user" id="payment">
                        <div class="common-card">
                            <div class="common-header">
                                <h5>Job</h5>
                            </div>

                            <div class="row card-payment">
                                @if (isDataLoading) {
                                <div class="item">
                                    <ngx-skeleton-loader count="3" [theme]="{
                                                            height: '22.5rem',
                                                            width: '30%',
                                                            'border-radius': '10px',
                                                            'margin-right': '0.5rem',
                                                            'margin-left': '0.5rem'
                                                            }" />
                                </div>
                                }@else{
                                @for(data of jobsData; track data){
                                <div class="col-xl-4 col-sm-6">

                                    <div class="property-box payment-card">
                                        <div class="property-image">
                                            <!-- <owl-carousel-o [options]="Options">
                                                @for(images of data?.img; track images){
                                                @if(images?.fileType === 'image'){
                                                <ng-template carouselSlide>
                                                    <div class="property-slider" [class]="tagClass">
                                                        <a href="javascript:void(0)" class="bg-size"
                                                            [style.background-image]="'url('+images?.url+')'">
                                                            <img [src]="images?.url" class="bg-img" alt="property-image"
                                                                style="display: none;" />
                                                        </a>
                                                    </div>
                                                </ng-template>
                                                }
                                                }
                                            </owl-carousel-o> -->
                                            <!-- <div class="seen-data">
                                                <app-feather-icons [icon]="'camera'"></app-feather-icons>
                                                <span>{{ data?.img?.length }}</span>
                                            </div> -->
                                            <div class="property-slider" [class]="tagClass">
                                                <div href="javascript:void(0)" class="bg-size"
                                                    [style.background-image]="'url(' + data?.imageBase64 + ')'">
                                                    <img [src]="jobsData?.imageBase64" class="bg-img"
                                                        alt="property-image" style="display: none;" />
                                                        <div class="card-labels">
                                                            @if(data?.status=="Verified"){
                                                            <span class="label  label-success">Verified
                                                            </span>
                                                            }@else if(data?.status=="Pending"){
                                                            <span class="label  label-dark">Pending
                                                            </span>
                                                            }@else if(data?.status=="Declined"){
                                                            <span class="label  label-danger">Declined
                                                            </span>
                                                            }    
                                                        </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="property-details">
                                            <span class="font-roboto card-title">
                                                <app-feather-icons class="color"
                                                    [icon]="'map-pin'"></app-feather-icons>{{ data?.cityName + ", "
                                                + data?.countryName }}</span>
                                            <a href="javascript:void(0)">
                                                <h3 class="line-clamp">{{ data?.companyName }}</h3>
                                            </a>
                                            <p class="font-roboto description-clamp">{{ data.description }}</p>
                                            <ul>
                                                <li>
                                                    <img src="assets/images/svg/icon/telephone.png"
                                                        class="img-fluid ruler-tool" alt="ruler-icon" />{{
                                                    data?.contactNumber }}
                                                </li>
                                                <li class="border-line"></li>
                                                <li>
                                                    <img src="assets/images/svg/icon/job-seeker.png"
                                                        class="img-fluid ruler-tool" alt="ruler-icon" />
                                                    {{ data?.jobType }}
                                                </li>
                                            </ul>
                                            <!-- <div class="property-btn d-flex">
                                                <button class="btn btn-link">
                                                    Details<i class="fas fa-arrow-right ms-1"></i>
                                                </button>
                                            </div> -->
                                        </div>
                                        <div class="edit-card">
                                            <a href="javascript:void(0)" (click)="editCard(addJob,data?.id)">edit</a>
                                            <a href="javascript:void(0)"
                                                (click)="deleteCard(deleteModal,data?.id)">delete</a>
                                            <!-- <a href="javascript:void(0)"
                                                (click)="boostCard(boostJob,data?.id)">Boost</a>
                                            <a href="javascript:void(0)"
                                                (click)="editAdCard(boostJob,data?.id)">Edit Ad</a> -->
                                        </div>
                                    </div>


                                </div>
                                }
                                }
                                <div class=" col-xl-4 col-sm-6">
                                    <div class="payment-card add-card">
                                        <div class="card-details" (click)="addCard(addJob)">
                                            <div>
                                                <i class="fas fa-plus-circle"></i>
                                                <h5>Add new Job</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<app-adly-footer></app-adly-footer>


<ng-template #addJob>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">
                @if(FormName == "Add"){
                Add New Job
                }@else{
                Update Job
                }
            </h5>
            <button type="button" class="btn-close" (click)="modal.dismissAll()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <form [formGroup]="addJobForm">
                <div class="row gx-3">
                    <div class="form-group col-6 col-sm-4 ">
                        <label for="a-na">Job Title<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="a-na" placeholder="Enter Job Title"
                            formControlName="job_title">
                        @if(addJobForm.get('job_title')?.hasError('required') &&
                        addJobForm.get('job_title')?.touched){
                        <small class="text-danger">Name is Required</small>
                        }
                    </div>
                    <div class="form-group col-6 col-sm-4 ">
                        <label for="a-na">Company Name<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="a-na" placeholder="Enter Company Name"
                            formControlName="company_name">
                        @if(addJobForm.get('company_name')?.hasError('required') &&
                        addJobForm.get('company_name')?.touched){
                        <small class="text-danger">Company Name is Required</small>
                        }
                    </div>
                    <div class="form-group col-6 col-sm-4 modal-container">
                        <label for="a-na">Job Type<span class="text-danger">*</span></label>
                        <app-common-dropdowns class="first-dropdown" [defaultText]="'Select Job Type'"
                            [dataList]="JobTypeList" (optionSelected)="onJobTypeSelected($event)" [isDisabled]="true"
                            [selectedOption]="jobType" [clearThevalue]="jobType"></app-common-dropdowns>
                        @if(jobType == "" && jobTypeError){
                        <small class="text-danger">Job Type is Required</small>
                        }
                    </div>
                    


                    <div class="form-group col-6 col-sm-4 modal-container">
                        <label for="a-n">Country<span class="text-danger">*</span></label>
                        <app-common-dropdowns 
                        class="second-dropdown" 
                        [defaultText]="'Enter Country Name'"
                        [dataList]="CountryList" 
                        (optionSelected)="onCountrySelected($event)"
                        [selectedOption]="Country" 
                        [clearThevalue]="Country"
                        (sendTheValue)="onCountryValueChange($event)"
                        ></app-common-dropdowns>
                        @if(Country == "" && countryError){
                        <small class="text-danger">Country is Required</small>
                        }
                    </div>
                    <div class="form-group col-6 col-sm-4 modal-container">
                        <label for="a-na">State<span class="text-danger">*</span></label>
                        <app-common-dropdowns 
                        class="third-dropdown" 
                        [defaultText]="'Enter State Name'"
                        [dataList]="StateList"
                        (optionSelected)="onStateSelected($event)" [selectedOption]="State"
                        [clearThevalue]="State" 
                        (sendTheValue)="onStateValueChange($event)"
                        (touched)="onCountryFocused($event)"
                        ></app-common-dropdowns>
                            @if(countryError && Country === "" && CountrysearchTerm ===""){
                                <small class="text-danger">Please Select Country First</small>
                                }
                    </div>
                    <div class="form-group col-6 col-sm-4 modal-container">
                        <label for="a-n">City<span class="text-danger">*</span></label>
                        <app-common-dropdowns 
                        class="fourth-dropdown" 
                        [defaultText]="'Enter City Name'"
                        [dataList]="CityList" 
                        (optionSelected)="onCitySelected($event)" [selectedOption]="City"
                        [clearThevalue]="City"
                        (touched)="onStateFocused($event)"
                        ></app-common-dropdowns>
                        @if(stateError && State === "" && StatesearchTerm === ""){
                            <small class="text-danger">Please Select State First</small>
                            }
                    </div>
                    <div class="form-group col-6 col-sm-4">
                        <label for="job-address">Address<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="job-address" placeholder="Enter Address"
                            formControlName="job_address">
                        @if(addJobForm.get('job_address')?.hasError('required') &&
                        addJobForm.get('job_address')?.touched) {
                        <small class="text-danger">Address is Required</small>
                        }
                    </div>

                    <div class="form-group col-6 col-sm-4">
                        <label for="job-address-title">Address Title<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="job-address-title" placeholder="Enter Address Title"
                            formControlName="job_addressTitle">
                        @if(addJobForm.get('job_addressTitle')?.hasError('required') &&
                        addJobForm.get('job_addressTitle')?.touched) {
                        <small class="text-danger">Address Title is Required</small>
                        }
                    </div>

                    <div class="form-group col-6 col-sm-4">
                        <label for="job-address-link">Address Link<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="job-address-link" placeholder="Enter Address Link"
                            formControlName="job_addressLink">
                        @if(addJobForm.get('job_addressLink')?.hasError('required') &&
                        addJobForm.get('job_addressLink')?.touched) {
                        <small class="text-danger">Address Link is Required</small>
                        }
                        @if(addJobForm.get('job_addressLink')?.touched &&
                        addJobForm.get('job_addressLink')?.hasError('pattern')) {
                        <small class="text-danger">https://maps.google.com/maps link is required</small>
                        }
                    </div>

                    <div class="form-group col-6 col-sm-4">
                        <label for="job-site-link">Site Link<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="job-site-link" placeholder="Enter Website Link"
                            formControlName="job_siteLink">
                        @if(addJobForm.get('job_siteLink')?.hasError('required') &&
                        addJobForm.get('job_siteLink')?.touched) {
                        <small class="text-danger">Site link is Required</small>
                        }
                        @if(addJobForm.get('job_siteLink')?.touched &&
                        addJobForm.get('job_siteLink')?.hasError('pattern')) {
                        <small class="text-danger">Valid link starting with https:// is required</small>
                        }
                    </div>

                    <div class="form-group col-6 col-sm-4">
                        <label for="job-phone">Contact Number<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="myInput" placeholder="Enter Phone Number"
                            formControlName="job_phone" minlength="8" maxlength="16">
                        @if(addJobForm.get('job_phone')?.hasError('required') && addJobForm.get('job_phone')?.touched) {
                        <small class="text-danger">Phone number is Required</small>
                        }
                        @if(addJobForm.get('job_phone')?.touched && addJobForm.get('job_phone')?.hasError('pattern')) {
                        <small class="text-danger">Phone number must be between 10 and 18 digits</small>
                        }
                    </div>
                    <div class="form-group col-6 col-sm-4">
                        <label for="application-deadline">Application Deadline<span class="text-danger">*</span></label>
                        <input type="date" class="form-control" id="application-deadline"
                            formControlName="application_deadline">
                        @if(addJobForm.get('application_deadline')?.hasError('required') &&
                        addJobForm.get('application_deadline')?.touched) {
                        <small class="text-danger">Application deadline is required</small>
                        }
                        @if(addJobForm.get('application_deadline')?.touched &&
                        addJobForm.get('application_deadline')?.hasError('invalidDate')) {
                        <small class="text-danger">Invalid deadline. Please select a future date.</small>
                        }
                    </div>
                    
                    <div class="form-group col-12 col-sm-4">
                        <label for="job-education">Education<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="job-education" placeholder="Add Education"
                            formControlName="education" (keyup.enter)="onEnterEducation($event)">
                        @if((educationTags.length == 0 && addJobForm.get('education')?.touched) || educationTagError ) {
                        <small class="text-danger">Add at least one Education</small>
                        }
                        @for(educationTag of educationTags; track educationTag) {
                        <div class="tag" (click)="removeEducationTag(educationTag)">
                            {{ educationTag.educationTitle }}
                            <span class="close-icon">&times;</span>
                        </div>
                        }
                    </div>

                    <div class="form-group col-12 col-sm-4">
                        <label for="job-experience">Experience<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="job-experience" placeholder="Add Experience"
                            formControlName="experience" (keyup.enter)="onEnterExperience($event)">
                        @if((experienceTags.length == 0 && addJobForm.get('experience')?.touched) || experienceTagError ) {
                        <small class="text-danger">Add at least one Experience</small>
                        }
                        @for(experienceTag of experienceTags; track experienceTag) {
                        <div class="tag" (click)="removeExperienceTag(experienceTag)">
                            {{ experienceTag.experienceTitle }}
                            <span class="close-icon">&times;</span>
                        </div>
                        }
                    </div>

                    <div class="form-group col-12 col-sm-4">
                        <label for="job-skills">Skills<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="job-skills" placeholder="Add Skills"
                            formControlName="skills" (keyup.enter)="onEnterSkills($event)">
                        @if((skillsTags.length == 0 && addJobForm.get('skills')?.touched) || skillTagError) {
                        <small class="text-danger">Add at least one Skill</small>
                        }
                        @for(skillTag of skillsTags; track skillTag) {
                        <div class="tag" (click)="removeSkillTag(skillTag)">
                            {{ skillTag.skillTitle }}
                            <span class="close-icon">&times;</span>
                        </div>
                        }

                    </div>
                   


                </div>

                <div class="form-group col-6 col-sm-4">
                    <label for="a-na">Salary<span class="text-danger">*</span></label>
                    <input type="number" class="form-control" id="a-na" placeholder="Enter Salary"
                        formControlName="salary">
                    @if(addJobForm.get('salary')?.hasError('required') && addJobForm.get('salary')?.touched) {
                    <small class="text-danger">Salary is Required</small>
                    }
                </div>

                <!-- Job Description -->
                <div class="form-group col-12">
                    <label for="job-description">Job Description<span class="text-danger">*</span></label>
                    <textarea rows="4" id="job-description" class="form-control" placeholder="Enter Job Description"
                        formControlName="job_description" (input)="updateJobDescriptionCharCount()"></textarea>
                    <small>{{ jobDescriptionCharCount }}/400</small>
                    @if(addJobForm.get('job_description')?.hasError('required') &&
                    addJobForm.get('job_description')?.touched){
                    <br><small class="text-danger">Job Description is Required</small>
                    }
                </div>

                <!-- How to Apply -->
                <div class="form-group col-12">
                    <label for="how-to-apply">How to Apply<span class="text-danger">*</span></label>
                    <textarea rows="4" id="how-to-apply" class="form-control" placeholder="Enter How to Apply"
                        formControlName="how_to_apply" (input)="updateHowToApplyCharCount()"></textarea>
                    <small>{{ howToApplyCharCount }}/400</small>
                    @if(addJobForm.get('how_to_apply')?.hasError('required') &&
                    addJobForm.get('how_to_apply')?.touched){
                    <br> <small class="text-danger">How to Apply is Required</small>
                    }
                </div>

                <!-- Responsibilities -->
                <div class="form-group col-12">
                    <label for="responsibilities">Responsibilities</label>
                    <textarea rows="4" id="responsibilities" class="form-control" placeholder="Enter Responsibilities"
                        formControlName="responsibilities" (input)="updateResponsibilitiesCharCount()"></textarea>
                    <small>{{ responsibilitiesCharCount }}/400</small>
                    @if(addJobForm.get('responsibilities')?.hasError('required') &&
                    addJobForm.get('responsibilities')?.touched){
                    <small class="text-danger">Responsibilities are Required</small>
                    }
                </div>

                <!-- Additional Note -->
                <div class="form-group col-12">
                    <label for="additional-note">Additional Note</label>
                    <textarea rows="4" id="additional-note" class="form-control" placeholder="Enter Additional Note"
                        formControlName="additional_note" (input)="updateAdditionalNoteCharCount()"></textarea>
                    <small>{{ additionalNoteCharCount }}/400</small>
                    @if(addJobForm.get('additional_note')?.hasError('required') &&
                    addJobForm.get('additional_note')?.touched){
                    <small class="text-danger">Additional Note is Required</small>
                    }
                </div>


                <div class="dropzone-admin mb-0">
                    <label>Media <span class="font-danger">*</span></label>
                    <!-- for add case  -->
                    @if(FormName == "Add"){
                    <form class="dropzone" id="multiFileUpload">
                        <ngx-dropzone (change)="onSelect($event)" accept="image/jpeg,image/jpg,image/png"
                            [multiple]="false">
                            <ngx-dropzone-label>
                                <div class="dz-message needsclick">
                                    <i class="fas fa-cloud-upload-alt"></i>
                                    <h6 class="f-w-700 mb-0">
                                        Drop files here or click to upload.
                                    </h6>
                                </div>
                            </ngx-dropzone-label>
                            @for(f of files; track f){
                            <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" [removable]="true"
                                (removed)="onRemove(f)" [file]="f">
                                <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>

                            </ngx-dropzone-image-preview>
                            }
                        </ngx-dropzone>
                    </form>
                    @if(imageError){
                    <small class="text-danger">Image is Required</small>
                    }
                    @else if(imageSizeError){
                    <small class="text-danger">Image size should be less than 1MB</small>
                    }
                    } @else if (FormName == "Edit"){
                    <!-- for update case  -->
                    <div class="image-gallery">
                        <div *ngFor="let image of images" class="image-container">
                            <app-feather-icons [icon]="'x'" class="closeImageIcon"
                                (click)="removeFile(image)"></app-feather-icons>
                            <img [src]="image.imageBase64" alt="image">
                        </div>
                    </div>
                    <div class="image-gallery">
                        <div class="upload-container" (click)="fileInput.click()">
                            <app-feather-icons [icon]="'upload'"></app-feather-icons>
                            <input #fileInput type="file" style="display: none;" accept="image/jpeg,image/jpg,image/png"
                                (change)="onFileSelected($event)">
                            Upload more Files
                        </div>
                    </div>
                    }
                </div>

            </form>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-dashed color-2 btn-pill" (click)="modal.dismissAll()">Cancel</button>
            <button type="button" class="btn btn-gradient color-2 btn-pill" (click)="onSubmit()"> @if(FormName ==
                "Add"){
                Add Job
                }@else{
                Update Job
                }</button>
        </div>
    </div>

</ng-template>


<ng-template #deleteModal>
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="btn-close" (click)="modal.dismissAll()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="text">
                Are You Sure you want to delete this Job ?
            </div>
            <div class="deleteButtons">
                <button type="button" class="btn btn-dashed color-2 btn-pill"
                    (click)="modal.dismissAll()">Cancel</button>
                <button type="button" class="btn btn-gradient color-2 btn-pill" (click)="deleteJob()">
                    Delete
                </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #boostJob>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">
                @if(FormName == "Advertisment"){
                Boost Job
                }@else if (FormName == "EditAdvertisment"){
                Edit Ad
                }
            </h5>
            <button type="button" class="btn-close" (click)="modal.dismissAll()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <form [formGroup]="boostJobForm">
                <div class="row gx-3">
                    <div class="form-group col-12 col-md-12 col-sm-12">
                        <label for="a-na">Title<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="a-na" placeholder="Enter Title"
                            formControlName="title">
                        @if(boostJobForm.get('title')?.hasError('required') &&
                        boostJobForm.get('title')?.touched){
                        <small class="text-danger">title is Required</small>
                        }
                    </div>
                </div>
                <div class="row gx-3">
                    <div class="form-group col-6 col-md-6 col-sm-6">
                        <label for="a-n"> Start Date</label>
                        <input type="date" formControlName="start_date" [min]="getMaxDate()" (change)="getMinDate()"
                            (change)="updateEndDateMin()" class="form-control" id="a-n" placeholder="Enter Start Date">
                        @if(boostJobForm.get('start_date')?.hasError('required') &&
                        boostJobForm.get('start_date')?.touched){
                        <small class="text-danger">Start Date is Required</small>
                        }
                    </div>
                    <div class="form-group col-6 col-md-6 col-sm-6">
                        <label for="a-n"> End Date</label>
                        <input type="date" formControlName="end_date" class="form-control" id="a-n"
                            placeholder="Enter End Date" [min]="minEndDate">
                        @if(boostJobForm.get('end_date')?.hasError('required') &&
                        boostJobForm.get('end_date')?.touched){
                        <small class="text-danger">End Date is Required</small>
                        }
                    </div>
                </div>

                <div class="row gx-3">
                    <div class="form-group col-12">
                        <label for="a-na">Description<span class="text-danger">*</span></label>
                        <textarea rows="4" name="" id="" class="form-control" placeholder="Enter Description"
                            formControlName="ad_description" (input)="updateAdCharCount()"></textarea>
                        <small class="">{{ adCharCount }}/200</small>
                        @if(boostJobForm.get('ad_description')?.hasError('required') &&
                        boostJobForm.get('ad_description')?.touched){
                        <br><small class="text-danger">Description is Required</small>
                        }@else if(boostJobForm.get('ad_description')?.touched &&
                        boostJobForm.get('ad_description')?.value?.length < 200){ <br><small
                                class="text-danger">Description should be more than 200 characters</small>
                            }
                    </div>
                </div>

            </form>
            <div class="dropzone-admin mb-0">
                <label>Media <span class="font-danger">*</span></label>

                <!-- for add case  -->
                @if(FormName == "Advertisment"){
                <form class="dropzone" id="multiFileUpload">
                    <ngx-dropzone (change)="onAdSelect($event)" accept="image/jpeg,image/jpg,image/png"
                        [multiple]="false">
                        <ngx-dropzone-label>
                            <div class="dz-message needsclick">
                                <i class="fas fa-cloud-upload-alt"></i>
                                <h6 class="f-w-700 mb-0">
                                    Drop files here or click to upload.
                                </h6>
                            </div>
                        </ngx-dropzone-label>
                        @for(f of adFiles; track f){
                        <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" [removable]="true"
                            (removed)="onAdRemove(f)" [file]="f">
                            <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>

                        </ngx-dropzone-image-preview>
                        }
                    </ngx-dropzone>
                </form>
                @if(imageError){
                <small class="text-danger">Image is Required</small>
                }
                @else if(adImageSizeError){
                <small class="text-danger">Image size should be less than 1MB</small>
                }
                } @else if (FormName == "EditAdvertisment"){
                <!-- for update case  -->
                <div class="image-gallery">
                    <div class="image-gallery">
                        <div class="image-container">
                            <app-feather-icons [icon]="'x'" class="closeImageIcon"
                                (click)="removeAdFile(imageBase64)"></app-feather-icons>
                            <img [src]="imageBase64" alt="image" />
                        </div>
                    </div>
                </div>
                <div class="image-gallery">
                    <div class="upload-container" (click)="fileInput.click()">
                        <app-feather-icons [icon]="'upload'"></app-feather-icons>
                        <input #fileInput type="file" style="display: none;" accept="image/jpeg,image/jpg,image/png"
                            (change)="onAdFileSelected($event)">
                        Upload Other File
                    </div>
                </div>
                }




            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-dashed color-2 btn-pill" (click)="modal.dismissAll()">Cancel</button>
            <button type="button" class="btn btn-gradient color-2 btn-pill" (click)="submitBoostAd()">
                @if(FormName == "Advertisment"){
                Boost
                }@else if(FormName == "EditAdvertisment"){
                Update Ad
                }
            </button>
        </div>
    </div>

</ng-template>